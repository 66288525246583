import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { TableInputGen } from '../../components/form/inputs/tableInputGen';
import { TextAreaInput } from '../../components/form/inputs/textAreaInput';
import { TextInput } from '../../components/form/inputs/textInput';
import { CreateSESResponse, SESMetadataResponseDataResults } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertSapMetadata from '../../lib/convertMetadata';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaSes/metaSes.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';

export const CreateServiceEntrySheet = () => {
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [sheetNumber, setSheetNumber] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [isAddActivityOpen, setAddActivityOpen] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const { data: TableMetadata } = await axiosInstance.get<SESMetadataResponseDataResults>(
              `${getAPIUrl(process.env.REACT_APP_EE_SES_METADATA)}`,
            );
            const formMetaData = convertSapMetadata(TableMetadata);
            dispatch(setCurrentMeta(formMetaData));
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const errorResponse = isAxiosError(err) ? err.response : null;

            const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);

  const schema = z.object({
    Vendor: z
      .string()
      .max(metadata['Vendor'] ? metadata['Vendor'].LENGTH : 10)
      .superRefine((val, ctx) => {
        if (val === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
          });
        }
      }),
    ExternalNumber: z
      .string()
      .max(metadata['ExternalNumber'] ? metadata['ExternalNumber'].LENGTH : 16)
      .superRefine((val, ctx) => {
        if (val === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
          });
        }
      }),
    ReferenceDocumentNumber: z
      .string()
      .max(metadata['ReferenceDocumentNumber'] ? metadata['ReferenceDocumentNumber'].LENGTH : 16)
      .superRefine((val, ctx) => {
        if (val === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
          });
        }
      }),
    ShortText: z
      .string()
      .max(metadata['ShortText'] ? metadata['ShortText'].LENGTH : 40)
      .superRefine((val, ctx) => {
        if (val === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
          });
        }
      }),
    BeginDate: z.date().superRefine((val, ctx) => {
      if (val === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    EndDate: z.date().superRefine((val, ctx) => {
      if (val === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    DocumentDate: z.date().superRefine((val, ctx) => {
      if (val === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    PurchaseOrder: z
      .string()
      .max(metadata['PurchaseOrder'] ? metadata['PurchaseOrder'].LENGTH : 10)
      .superRefine((val, ctx) => {
        if (val === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
          });
        }
      }),
    PurchaseOrderItem: z
      .string()
      .max(metadata['PurchaseOrderItem'] ? metadata['PurchaseOrderItem'].LENGTH : 5)
      .superRefine((val, ctx) => {
        if (val === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
          });
        }
      }),
    Location: z
      .string()
      .max(metadata['Location'] ? metadata['Location'].LENGTH : 40)
      .optional(),
    LongText: z.string().optional(),
    SesLines: z.object({
      results: z
        .object({
          LineNo: z.string().optional(),
          Service: z
            .string()
            .max(metadata['Service'] ? metadata['Service'].LENGTH : 10)
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                });
              }
            }),
          Location: z
            .string()
            .max(metadata['Location'] ? metadata['Location'].LENGTH : 40)
            .optional(),
          Quantity: z
            .string()
            .max(metadata['Quantity'] ? metadata['Quantity'].LENGTH : 13)
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                });
              }
            }),
          GrossPrice: z
            .string()
            .max(metadata['GrossPrice'] ? metadata['GrossPrice'].LENGTH : 23)
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                });
              }
            }),
          WorkOrder: z
            .string()
            .max(metadata['WorkOrder'] ? metadata['WorkOrder'].LENGTH : 12)
            .optional(),
          Operation: z
            .string()
            .max(metadata['Operation'] ? metadata['Operation'].LENGTH : 4)
            .optional(),
        })
        .array(),
    }),
  });

  type formSchema = z.infer<typeof schema>;

  const defaultValues: formSchema = {
    Vendor: '',
    DocumentDate: undefined as never,
    PurchaseOrder: '',
    PurchaseOrderItem: '',
    ShortText: '',
    ExternalNumber: '',
    ReferenceDocumentNumber: '',
    BeginDate: undefined as never,
    EndDate: undefined as never,
    Location: '',
    LongText: '',
    SesLines: {
      results: [],
    },
  };

  const methods = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: formSchema) => {
    setLoading(true);
    setErrorMessages([]);
    const resultData = convertFormDates(data);
    try {
      const response = await axiosInstance.post<CreateSESResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_SES_CREATE)}`,
        resultData,
      );
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Service Entry Sheet Created. Sheet Number: ',
          response?.data?.d?.SheetNumber || '',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
        if (titleMessage === 'Success') {
          setSheetNumber(response?.data?.d?.SheetNumber);
        }
      }
    } catch (err) {
      setLoading(false);
      const errorResponse = isAxiosError(err) ? err.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const convertFormDates = function (data: any) {
    try {
      Object.keys(data).forEach((item) => {
        if (typeof data[item] === 'object' && data[item] instanceof Date === true) {
          if (data[item] !== '' && data[item] !== undefined) {
            data[item] = moment(data[item]).format().split('+')[0];
          }
        }
      });
    } catch (err) {
      console.log('errors: ', err);
    }
    return data;
  };

  const [step, setStep] = useState(1);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) {
      // clear errors if there are none to display
      setErrorMessages([]);
      return;
    }
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code === selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Header Data',
      fields: [
        'Vendor',
        'ExternalNumber',
        'ReferenceDocumentNumber',
        'DocumentDate',
        'ShortText',
        'BeginDate',
        'EndDate',
        'PurchaseOrder',
        'PurchaseOrderItem',
        'Location',
      ],
    },
    {
      code: 2,
      name: 'Service Lines',
      fields: ['LineNo', 'Service', 'Location', 'Quantity', 'GrossPrice', 'WorkOrder', 'Operation'],
    },
  ];

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Create Service Entry Sheet',
      }}
      bannerProps={{
        title: 'Create Service Entry Sheet',
      }}
      submenuProps={{
        title: 'Service Entry Sheets',
        selectedIndex: '2',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && titleMessage === 'Success' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={true}
          hidebutton={true}
          navigateLink={`/eeportal/ses/search/results/${sheetNumber}/update`}
        ></MessageModal>
      )}
      {messages.length > 0 && titleMessage !== '' && titleMessage === 'Error' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                    ? FormStepperEnum.Complete
                    : FormStepperEnum.Upcoming,
              }))}
            />
          </div>
          <div className="w-full pt-3">
            <SubBanner
              title={sections[step - 1].name}
              info="To create a Service Entry Sheet, please enter the relevant information below, then select the Next button. The fields marked with an * are mandatory."
            />
          </div>
          <div className="w-full p-2">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, goToErrors)}>
                {step === 1 && (
                  <div className="mb-8 w-full">
                    <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <InputTypeAhead
                          items={[]}
                          fieldLabel="Vendor"
                          fieldPlaceholder="Start typing"
                          name="Vendor"
                          control={methods.control}
                          required={true}
                          codeSet={TypeAheadCodeSet.Vendor}
                          codeSetFilterField="VendorName"
                          codeSetValueField="Vendor"
                          codeSetLabelField="VendorName"
                        />
                        <TextInput
                          fieldLabel="External Number"
                          fieldPlaceholder=" "
                          name="ExternalNumber"
                          control={methods.control}
                          required={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Purchase Order"
                          fieldPlaceholder=" "
                          name="PurchaseOrder"
                          control={methods.control}
                          required={true}
                        />
                        <TextInput
                          fieldLabel="PO Item"
                          fieldPlaceholder=" "
                          name="PurchaseOrderItem"
                          control={methods.control}
                          required={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Reference Document Number"
                          fieldPlaceholder=" "
                          name="ReferenceDocumentNumber"
                          control={methods.control}
                          required={true}
                        />
                        <DateInput
                          fieldLabel="Document Date"
                          fieldPlaceholder=" "
                          name="DocumentDate"
                          control={methods.control}
                          required={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <DateInput
                          fieldLabel="Start Date"
                          fieldPlaceholder=" "
                          name="BeginDate"
                          control={methods.control}
                          required={true}
                        />
                        <DateInput
                          fieldLabel="End Date"
                          fieldPlaceholder=" "
                          name="EndDate"
                          control={methods.control}
                          required={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Service Entry Sheet Description"
                          fieldPlaceholder=" "
                          name="ShortText"
                          control={methods.control}
                          required={true}
                        />
                        <TextInput
                          fieldLabel="Location"
                          fieldPlaceholder=" "
                          name="Location"
                          control={methods.control}
                          required={false}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-1 md:gap-8">
                        <TextAreaInput
                          fieldLabel="Service Entry Sheet Text"
                          fieldPlaceholder=" "
                          name="LongText"
                          control={methods.control}
                          required={false}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="mb-8 w-full">
                    <>
                      <TableInputGen
                        scenario="SES"
                        defaultValues={{
                          GrossPrice: '',
                          LineNo: '10',
                          Location: '',
                          Operation: '',
                          Quantity: '',
                          Service: '',
                          WorkOrder: '',
                        }}
                        keyfield="LineNo"
                        path="SesLines.results"
                        metadata={metadata}
                        onAddActivityChange={setAddActivityOpen}
                        ColumnsHeadersInput={[
                          {
                            accessor: 'LineNo',
                            header: 'Line No',
                          },
                          {
                            accessor: 'Service',
                            header: 'Service',
                          },
                          {
                            accessor: 'Quantity',
                            header: 'Qty',
                          },
                          {
                            accessor: 'GrossPrice',
                            header: 'Gross Price',
                          },
                          {
                            accessor: 'Location',
                            header: 'Location',
                          },
                          {
                            accessor: 'WorkOrder',
                            header: 'Work Order',
                          },
                          {
                            accessor: 'Operation',
                            header: 'Operation',
                          },
                        ]}
                      />
                    </>
                  </div>
                )}
                <div className="flex w-full justify-end">
                  <div className="flex-end flex">
                    {step === 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          nextStep();
                        }}
                        className="button-primary bg-defence-orange-500"
                      >
                        Next
                      </button>
                    )}
                    {step === 2 && !isAddActivityOpen && (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            backStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Back
                        </button>
                        <button type="submit" onClick={() => {}} className="button-primary mr-0 bg-defence-orange-500">
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
