import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from '../constants';

const defaultHelpUrl =
  'https://govteams.sharepoint.com/:u:/r/sites/erikexternal/SitePages/Estate-Exchange-Support.aspx?csf=1&web=1&share=EUVZnl1VGJ9Ni3UkIOQsEDMBt-YB8jZhsqV87VSgOgOdvQ&e=lfEHAV';

const notfHelpUrl = 'https://govteams.sharepoint.com/sites/erikexternal/SitePages/EE-ServiceRequests.aspx';
const woHelpUrl = 'https://govteams.sharepoint.com/sites/erikexternal/SitePages/EE-Work-Orders.aspx.aspx';
const sesHelpUrl = 'https://govteams.sharepoint.com/sites/erikexternal/SitePages/EE-ServiceEntrySheet.aspx';
const addHelpUrl = 'https://govteams.sharepoint.com/sites/erikexternal/SitePages/EE-Additional.aspx';

export const Breadcrumbs = () => {
  const location = useLocation();
  const routes: any = [
    {
      orders: [
        {
          link: ROUTES.WORK_ORDERS,
          key: 'orders',
          label: 'Work Orders',
          helpLink: woHelpUrl,
        },
        {
          link: ROUTES.WORK_ORDERS_SEARCH,
          key: 'search',
          label: 'Search',
          helpLink: woHelpUrl,
        },
        {
          link: ROUTES.WORK_ORDERS_CREATE,
          key: 'create',
          label: 'Create',
          helpLink: woHelpUrl,
        },
        {
          link: ROUTES.WORK_ORDERS_BULK_UPLOAD_DOWNLOAD,
          key: 'bulk-download-upload',
          label: 'Bulk Download',
          helpLink: woHelpUrl,
        },
        {
          link: ROUTES.WORK_ORDERS_BULK_PROGRESS_REPORT,
          key: 'bulk-progress-report',
          label: 'Bulk Progress Report',
          helpLink: woHelpUrl,
        },
        {
          link: ROUTES.WORK_ORDERS_SEARCH_RESULTS,
          key: 'results',
          label: 'Results',
          helpLink: woHelpUrl,
        },
        {
          link: ROUTES.WORK_ORDERS_UPDATE,
          key: 'update',
          label: 'Update',
          helpLink: woHelpUrl,
        },
        {
          link: ROUTES.CONFIRMATIONS,
          key: 'confirmations',
          label: 'Confirmations',
          children: [
            {
              link: ROUTES.CONFIRMATIONS_SEARCH,
              key: 'search',
              label: 'Search',
              helpLink: woHelpUrl,
            },
            {
              link: ROUTES.CONFIRMATIONS_SEARCH_RESULTS,
              key: 'results',
              label: 'Results',
              helpLink: woHelpUrl,
            },
            {
              link: ROUTES.CONFIRMATIONS_CREATE,
              key: 'create',
              helpLink: woHelpUrl,
              label: 'Create',
            },
            {
              link: ROUTES.CONFIRMATIONS_CANCEL,
              key: 'cancel',
              helpLink: woHelpUrl,
              label: 'Cancel',
            },
            {
              link: ROUTES.CONFIRMATIONS_READ,
              key: 'read',
              helpLink: woHelpUrl,
              label: 'Read',
            },
          ],
        },
      ],

      notifications: [
        {
          link: ROUTES.NOTIFICATIONS,
          key: 'notifications',
          helpLink: notfHelpUrl,
          label: 'Service Requests',
        },
        {
          link: ROUTES.NOTIFICATIONS_SEARCH,
          key: 'search',
          helpLink: notfHelpUrl,
          label: 'Search',
        },
        {
          link: ROUTES.NOTIFICATIONS_CREATE,
          key: 'create',
          helpLink: notfHelpUrl,
          label: 'Create',
        },
        {
          link: ROUTES.NOTIFICATIONS_BULK_UPLOAD_DOWNLOAD,
          key: 'bulk-download-upload',
          helpLink: notfHelpUrl,
          label: 'Bulk Upload Download',
        },
        {
          link: ROUTES.NOTIFICATIONS_BULK_PROGRESS_REPORT,
          key: 'bulk-progress-report',
          helpLink: notfHelpUrl,
          label: 'Bulk Progress Report',
        },
        {
          link: ROUTES.NOTIFICATIONS_SEARCH_RESULTS,
          key: 'results',
          helpLink: notfHelpUrl,
          label: 'Results',
          state: {},
        },
        {
          link: ROUTES.NOTIFICATIONS_SEARCH_RESULTS,
          key: 'update',
          helpLink: notfHelpUrl,
          label: 'Update',
        },
      ],

      ses: [
        {
          link: ROUTES.SES,
          key: 'ses',
          helpLink: sesHelpUrl,
          label: 'Service Entry Sheets',
        },

        {
          link: ROUTES.SES_SEARCH,
          key: 'search',
          helpLink: sesHelpUrl,
          label: 'Search',
        },

        {
          link: ROUTES.SES_CREATE,
          key: 'create',
          helpLink: sesHelpUrl,
          label: 'Create',
        },

        {
          link: ROUTES.SES_BULK_UPLOAD_DOWNLOAD,
          key: 'bulk-download-upload',
          helpLink: sesHelpUrl,
          label: 'Bulk Upload Download',
        },

        {
          link: ROUTES.SES_BULK_PROGRESS_REPORT,
          key: 'bulk-progress-report',
          helpLink: sesHelpUrl,
          label: 'Bulk Progress Report',
        },

        {
          link: ROUTES.SES_SEARCH_RESULTS,
          key: 'results',
          helpLink: sesHelpUrl,
          label: 'Results',
          state: {},
        },

        {
          link: ROUTES.SES_UPDATE,
          key: 'update',
          helpLink: sesHelpUrl,
          label: 'Update',
        },
      ],
      other: [
        {
          link: ROUTES.OTHER,
          key: 'other',
          helpLink: addHelpUrl,
          label: 'Additional Services',
        },
        {
          link: ROUTES.RETRIEVAL,
          key: 'retrieval',
          helpLink: addHelpUrl,
          label: 'Retrieval',
          children: [
            {
              link: ROUTES.RETRIEVAL_SEARCH,
              key: 'search',
              helpLink: addHelpUrl,
              label: 'Retrieval Search',
            },
            {
              link: ROUTES.RETRIEVAL_LIST,
              key: 'list',
              helpLink: addHelpUrl,
              label: 'Retrieval List',
            },
            {
              link: ROUTES.RETRIEVAL_LIST_HISTORY,
              key: 'history',
              helpLink: addHelpUrl,
              label: 'History',
            },
          ],
        },

        {
          link: ROUTES.MEASDOC_BULK_UPLOAD_DOWNLOAD,
          key: 'meas-bulk-download-upload',
          helpLink: addHelpUrl,
          label: 'Bulk Upload Download',
        },
        {
          link: ROUTES.MEASDOC_BULK_PROGRESS_REPORT,
          key: 'meas-bulk-progress-report',
          helpLink: addHelpUrl,
          label: 'Bulk Progress Report',
        },
        {
          link: ROUTES.RFX_BULK_UPLOAD_DOWNLOAD,
          key: 'rfx-bulk-download-upload',
          helpLink: addHelpUrl,
          label: 'Bulk Upload',
        },
        {
          link: ROUTES.RFX_BULK_PROGRESS_REPORT,
          key: 'rfx-bulk-progress-report',
          helpLink: addHelpUrl,
          label: 'Bulk Progress Report',
        },
        {
          link: ROUTES.FLOC_BULK_UPLOAD_DOWNLOAD,
          key: 'floc-bulk-download-upload',
          helpLink: addHelpUrl,
          label: 'Bulk Download',
        },
        {
          link: ROUTES.FLOC_BULK_PROGRESS_REPORT,
          key: 'floc-bulk-progress-report',
          helpLink: addHelpUrl,
          label: 'Bulk Progress Report',
        },
        {
          link: ROUTES.SERVICEMASTER_BULK_UPLOAD_DOWNLOAD,
          key: 'servicemaster-bulk-download-upload',
          helpLink: addHelpUrl,
          label: 'Bulk Download',
        },
        {
          link: ROUTES.SERVICEMASTER_BULK_PROGRESS_REPORT,
          key: 'servicemaster-bulk-progress-report',
          helpLink: addHelpUrl,
          label: 'Bulk Progress Report',
        },
      ],
    },
  ];

  const pathSegments: string[] = location.pathname
    .split('/')
    .filter((segment) => segment !== '' && segment !== 'eeportal');

  const sectionArr = routes[0][pathSegments[0]];
  const level1Obj = sectionArr?.find((obj: any) => obj.key === pathSegments[0]);
  const level2Obj = sectionArr?.find((obj: any) => obj.key === pathSegments[1]);
  let level3Obj: any = {};
  let level4Obj: any = {};

  if (level2Obj?.children) {
    level3Obj = level2Obj.children?.find((obj: any) => obj.key === pathSegments[2]);
  } else {
    level3Obj = sectionArr?.find((obj: any) => obj.key === pathSegments[2]);
  }

  if (level2Obj?.children) {
    level4Obj = level2Obj.children?.find((obj: any) => obj.key === pathSegments[3]);
  } else {
    level4Obj = sectionArr?.find((obj: any) => obj.key === pathSegments[3]);
  }

  return (
    <div className="flex justify-between">
      <nav className="mb-3 mt-1">
        {
          // Home link
          location.pathname !== ROUTES.HOME && (
            <>
              <Link to={ROUTES.HOME} className="text-white underline">
                Estate Exchange
              </Link>
              <span className="ml-2 mr-2 text-white">{'>'}</span>
            </>
          )
        }

        {level1Obj?.link !== location.pathname && (
          <Link to={level1Obj?.link} className="text-white underline">
            {level1Obj?.label}
          </Link>
        )}
        {level1Obj?.link === location.pathname && <span className="text-white">{level1Obj?.label}</span>}

        {level2Obj !== undefined && level2Obj?.label !== '' && <span className="ml-2 mr-2 text-white">{'>'}</span>}

        {/* Level 2 link */}

        {level2Obj?.link !== location.pathname && (
          <Link to={level2Obj?.link} className="text-white underline">
            {level2Obj?.label}
          </Link>
        )}

        {level2Obj?.link === location.pathname && <span className="text-white">{level2Obj?.label}</span>}

        {level3Obj !== undefined && level3Obj?.label !== '' && <span className="ml-2 mr-2 text-white">{'>'}</span>}

        {level3Obj?.link !== location.pathname && (
          <Link to={level3Obj?.link} className="text-white underline">
            {level3Obj?.label}
          </Link>
        )}

        {level3Obj?.link === location.pathname && <span className="text-white">{level3Obj?.label}</span>}

        {level4Obj !== undefined && level4Obj?.label !== '' && <span className="ml-2 mr-2 text-white">{'>'}</span>}

        {level4Obj?.link && <span className="text-white">{level4Obj?.label}</span>}
      </nav>
      <a
        href={level4Obj?.helpLink || level3Obj?.helpLink || level2Obj?.helpLink || level1Obj?.helpLink}
        target="_blank"
        className="text-white"
        rel="noreferrer"
      >
        <QuestionMarkCircledIcon width={20} height={20} className="text-white" /> Help
      </a>
    </div>
  );
};
