/* eslint-disable jsx-a11y/anchor-is-valid */
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { FileUpload } from '../../components/form/inputs/FileUpload';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { RadioInput } from '../../components/form/inputs/radioInput';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { WorkOrderMetadataResponse, WorkOrdersDownloadResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertFormData from '../../lib/convertFormData';
import convertSapMetadata from '../../lib/convertMetadata';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaOrder/metaOrder.action';
import { selectCurrentMeta } from '../../store/metaOrder/metaOrder.selector';

export const BulkOrderUploadDownload = () => {
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const response = await axiosInstance.get<WorkOrderMetadataResponse>(
              `${getAPIUrl(process.env.REACT_APP_EE_WO_METADATA)}`,
            );
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
            setTitleMessage(titleMessage);
            setMessages(messages);
            if (titleMessage !== 'Error' && (response.status === 200 || response.status === 201)) {
              const formMetaData = convertSapMetadata(response.data);
              dispatch(setCurrentMeta(formMetaData));
            }
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const errorResponse = isAxiosError(err) ? err.response : null;
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
        return () => {
          ref.current = true;
        };
      };
      fetchTableMetadata();
    }
  }, []);

  const schema = z.object({
    UploadDownload: z.string().optional(),
    OrderType: z.string().superRefine((val, ctx) => {
      if (val === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    CreatedOnFrom: z.date().superRefine((val, ctx) => {
      if (val === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    CreatedOnTo: z.date().optional().nullable(),
    Floc: z.string().optional(),
    BasicStartDateFrom: z.date().optional().nullable(),
    BasicStartDateTo: z.date().optional().nullable(),
    OrderNoFrom: z.string().optional(),
    OrderNoTo: z.string().optional(),
    WorkCenter: z.string().optional(),
    Equipment: z.string().optional(),
  });

  type formSchema = z.infer<typeof schema>;

  const defaultValues: formSchema = {
    UploadDownload: 'Upload',
    OrderType: '',
    CreatedOnFrom: undefined as never,
    CreatedOnTo: undefined,
    Floc: '',
    BasicStartDateFrom: undefined,
    BasicStartDateTo: undefined,
    OrderNoFrom: '',
    OrderNoTo: '',
    WorkCenter: '',
    Equipment: '',
  };

  const { handleSubmit, watch, control, getValues } = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: formSchema) => {
    //
    if (data.UploadDownload === 'Download') {
      const formDataQuery = convertFormData(data) + ' and IncludeLongText eq true';
      setLoading(true);
      const url = `${getAPIUrl(process.env.REACT_APP_EE_WO_DOWNLOAD_REQ)}?filter=(${formDataQuery})&$format=json`;
      try {
        const response = await axiosInstance.get<WorkOrdersDownloadResponse>(url);
        setLoading(false);
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Request successfully submitted, please check Bulk Progress Report page',
          response.data?.d?.results?.[0]?.RequestId,
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      } catch (err) {
        setLoading(false);
        const errorResponse = isAxiosError(err) ? err.response : null;
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
          (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    }
  };

  const handleTemplate = async (template: string) => {
    setLoading(true);
    const file = await axiosInstance.get(`${getAPIUrl(process.env.REACT_APP_EE_WO_TEMPLATE)}/${template}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(file.data);
    const link = document.createElement('a');
    link.href = url;
    let filename = file.headers['x-filename'];
    if (!filename) {
      filename = file.headers['content-disposition'].split('=')[1] || '';
      filename = filename.replace(/"/g, ''); // trim double
    }
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Bulk Upload Download Work Orders',
      }}
      bannerProps={{
        title: 'Bulk Upload/Download',
      }}
      submenuProps={{
        title: 'Work Orders',
        selectedIndex: '2',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white py-2">
        <div className="m-auto w-11/12 py-2 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          <form onSubmit={handleSubmit(onSubmit)}>
            {watch('UploadDownload') === 'Upload' && (
              <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4">
                <a
                  className="w-fit cursor-pointer text-x0 text-defence-orange-500 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTemplate(`('ZM01')`)}
                >
                  ZM01 Work Order (Responsive Works) Template
                </a>
                <a
                  className="w-fit cursor-pointer text-x0 text-defence-orange-500 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTemplate(`('ZM03')`)}
                >
                  ZM03 Work Order (Estate Appraisal) Template
                </a>
                <a
                  className="w-fit cursor-pointer text-x0 text-defence-orange-500 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTemplate(`('ZM04')`)}
                >
                  ZM04 Work Order (Requested Works) Template
                </a>
                <a
                  className="w-fit cursor-pointer text-x0 text-defence-orange-500 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTemplate(`('ZM06')`)}
                >
                  ZM06 Work Order (MISCS) Template
                </a>
                <a
                  className="w-fit cursor-pointer text-x0 text-defence-orange-500 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTemplate(`('CLSD')`)}
                >
                  Mass Close Template
                </a>
              </div>
            )}
            <div className="mb-4 w-full rounded-md bg-white">
              <div className="grid h-full grid-flow-row grid-cols-1 md:grid-cols-1 md:gap-1">
                <RadioInput
                  fieldLabel=" "
                  options={['Upload', 'Download']}
                  horizontal={true}
                  name="UploadDownload"
                  control={control}
                />
                {watch('UploadDownload') === 'Upload' && (
                  <div>
                    <p className="text-2xl font-bold">File Upload</p>
                    <div className="grid grid-flow-row grid-cols-1 gap-1">
                      <FileUpload
                        fieldLabel=""
                        fileType=""
                        fileMax="20 MB"
                        register=""
                        fieldname=""
                        errorMessage=""
                        validation=""
                        url={getAPIUrl(process.env.REACT_APP_EE_WO_UPLOAD)}
                      />
                    </div>
                  </div>
                )}
                {watch('UploadDownload') === 'Download' && (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={metadata['OrderType'] ? JSON.parse(metadata['OrderType'].FVALUE) : []}
                        fieldLabel="Order Type"
                        fieldPlaceholder=" "
                        name="OrderType"
                        control={control}
                        required={true}
                      />
                      <SingleSelectInput
                        items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                        fieldLabel="Main Work Center"
                        fieldPlaceholder="Please select Main Work Center"
                        name="WorkCenter"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Created on Date From"
                        fieldPlaceholder="From Date"
                        name="CreatedOnFrom"
                        control={control}
                        required={true}
                      />
                      <DateInput
                        fieldLabel="Created on Date To"
                        fieldPlaceholder="To Date"
                        name="CreatedOnTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Order Number From"
                        fieldPlaceholder="Start typing"
                        name="OrderNoFrom"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Wo}
                        top="20"
                        codeSetValueField="WorkOrder"
                      />
                      <InputTypeAhead
                        fieldLabel="Order Number To"
                        fieldPlaceholder="Start typing"
                        name="OrderNoTo"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Wo}
                        top="20"
                        codeSetValueField="WorkOrder"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Basic Start Date From"
                        fieldPlaceholder=" "
                        name="BasicStartDateFrom"
                        control={control}
                        required={false}
                      />
                      <DateInput
                        fieldLabel="Basic Start Date To"
                        fieldPlaceholder=" "
                        name="BasicStartDateTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Functional Location"
                        fieldPlaceholder="Start typing"
                        name="Floc"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Floc}
                        top="20"
                        codeSetValueField="Floc"
                        codeSetLabelField="Description"
                      />
                      <InputTypeAhead
                        fieldLabel="Equipment"
                        fieldPlaceholder="Start typing"
                        name="Equipment"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Equip}
                        top="20"
                        codeSetValueField="EquipmentId"
                        codeSetLabelField="Description"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {watch('UploadDownload') === 'Download' && (
              <div className="flex w-full justify-end">
                <button
                  type="submit"
                  onClick={() => {
                    //console.log('getValues() ', getValues());
                  }}
                  className="button-primary mr-0 bg-defence-orange-500"
                >
                  Submit
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </BaseLayout>
  );
};
