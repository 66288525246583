import React from 'react';
import { DSASForm } from '../../../components/ServiceUI/LandingPage/MyDataManagement/DSASForm/DSASForm';
import Header from '../../../components/ServiceUI/Core/Header/Header';
import content from './../Content/DSAS.json';

interface DSASProps {
  profileData: any;
}

export const DSAS: React.FC<DSASProps> = ({ profileData }) => {
  return (
    <>
      <Header {...content.header} bg="orange.standard" />
      <DSASForm profileData={profileData} />
    </>
  );
};
