import { Modal, ModalContent, ModalFooter, ModalOverlay, Flex, Button, Link } from '@chakra-ui/react';
import React from 'react';

interface StartPopupProps {
  close(): void;
  isOpen;
  content: { Content: React.FC; link: string } | undefined;
}

export const StartPopup: React.FC<StartPopupProps> = ({ isOpen, close, content }) => {
  return (
    <Modal size="xlarge" isOpen={isOpen} onClose={() => {}}>
      <ModalOverlay />
      <ModalContent p="3rem">
        <>{content?.Content}</>
        <ModalFooter as={Flex} gap={3} justifyContent="space-between" flexDir={{ lg: 'row', base: 'column' }}>
          <Button variant="secondary" onClick={close} w={{ lg: 'auto', base: '100%' }}>
            Close
          </Button>
          <Button variant="primary" as={Link} href={content?.link} w={{ lg: 'auto', base: '100%' }}>
            Start form
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
