import { CheckIcon } from '@radix-ui/react-icons';
import React from 'react';

export enum FormStepperEnum {
  Complete = 'complete',
  Current = 'current',
  Upcoming = 'upcoming',
}

export interface FormStepperItem {
  code: number;
  name: string;
  status?: FormStepperEnum;
}

export interface FormStepperProps {
  items: FormStepperItem[];
  onChange: (step: FormStepperItem) => void;
}

export default function FormStepper({ items, onChange }: FormStepperProps) {
  const onStepperClick = (e: React.MouseEvent, step: FormStepperItem) => {
    e.preventDefault();
    if (onChange) {
      onChange(step);
    }
  };

  return (
    <nav aria-label="Progress">
      <ol
        role="list"
        className="divide-y divide-defence-light-grey-100 rounded-md border border-defence-light-grey-100 lg:flex lg:divide-y-0"
      >
        {items.map((step, stepIdx) => (
          <li key={step.name} className="relative lg:flex lg:flex-1">
            {step.status === 'complete' ? (
              <a onClick={(e) => onStepperClick(e, step)} className="group flex w-full items-center" href="#">
                <span className="flex items-center px-2 py-4 text-lg font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-defence-orange-500 group-hover:bg-defence-charcoal">
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-lg font-medium text-defence-black">{step.name}</span>
                </span>
              </a>
            ) : step.status === 'current' ? (
              <a
                onClick={(e) => onStepperClick(e, step)}
                className="flex items-center px-2 py-4 text-lg font-medium"
                aria-current="step"
                href="#"
              >
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-defence-charcoal">
                  <span className="text-defence-charcoal">{step.code}</span>
                </span>
                <span className="ml-4 text-lg font-medium text-defence-charcoal">{step.name}</span>
              </a>
            ) : (
              <a onClick={(e) => onStepperClick(e, step)} className="group flex items-center" href="#">
                <span className="flex items-center px-2 py-4 text-lg font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-defence-light-grey-200 group-hover:border-defence-light-grey-100">
                    <span className="text-defence-light-grey-900 group-hover:text-defence-black">{step.code}</span>
                  </span>
                  <span className="ml-4 text-lg font-medium text-defence-light-grey-900 group-hover:text-defence-black">
                    {step.name}
                  </span>
                </span>
              </a>
            )}

            {stepIdx !== items.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 lg:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-defence-light-grey-100"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
}
