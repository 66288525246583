import { METAORDER_TYPES } from './metaOrder.types';

const INITIAL_STATE = {
  currentMeta: {},
};

export const metaOrderReducer = (state = INITIAL_STATE, action :any) => {
  const { type, payload } = action;

  switch (type) {
    case METAORDER_TYPES.SET_CURRENT_META:
      return { ...state, currentMeta: payload };
    default:
      return state;
  }
};