import { Box, Heading, Text } from '@chakra-ui/react';
import React from 'react';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';

export interface LandingPageHeaderProps {
  heading: string;
  subheading: string;
  backgroundImgURL?: string;
  mobileBackgroundImgURL?: string;
}

export const LandingPageBanner: React.FC<LandingPageHeaderProps> = (props: LandingPageHeaderProps): JSX.Element => {
  return (
    <Box
      data-testid="header"
      bg="orange.standard"
      paddingTop={{ lg: '5rem', md: '25rem', base: '18rem' }}
      paddingBottom="5rem"
      backgroundRepeat="no-repeat"
      backgroundSize={{ lg: 'auto 100%', base: '100% auto' }}
      backgroundPosition={{ lg: 'right', base: 'top' }}
      backgroundImage={{
        lg: props.backgroundImgURL,
        base: props.mobileBackgroundImgURL,
      }}
      color="white"
    >
      <SubContainer>
        <Heading as="h1" fontSize="5xl" margin="1rem 0">
          {props.heading}
        </Heading>
        <Text>{props.subheading}</Text>
      </SubContainer>
    </Box>
  );
};

export default LandingPageBanner;
