export const find18YearsAgo = (): Date => {
  var date = new Date();
  date.setFullYear(date.getFullYear() - 18);
  return date;
};

export const pegaHeadingToFontSize = (pegaHeading: string): { as: string; fontSize: string } => {
  switch (pegaHeading) {
    case 'Heading 1':
      return { as: 'h1', fontSize: 'lg' };
    case 'Heading 2':
      return { as: 'h2', fontSize: 'md' };
    case 'Heading 3':
      return { as: 'h3', fontSize: 'sm' };
    case 'Heading 4':
      return { as: 'h4', fontSize: 'regular' };
    case 'Heading 5':
      return { as: 'h5', fontSize: 'xs' };
    case 'Standard':
      return { as: 'p', fontSize: 'regular' };
    default:
      return { as: '', fontSize: '' };
  }
};
