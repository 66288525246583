import React, { useState, useEffect } from 'react';
import { Col, Form, FormControl } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setFieldValue } from '../../../../reducers/requestDetailFormReducer';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoCircle } from 'react-bootstrap-icons';
import { findByProp } from '../../../../helper/findByProp';
import { FormControlTextInput, FormFieldCol } from './styles';
import convertSpecialChars from '../../../../helper/convertSpecialChars';
const FormTextInput = ({
  fieldID,
  maxLength,
  label,
  fieldPropMapping,
  required,
  visible,
  reference,
  isCCCValid,
  isWBSValid,
  formData,
  type,
  value,
  readOnly,
  setCCMessage,
  setWBSMessage,
}) => {
  const reqDetailDataFromReduxStore = useSelector((state) => {
    if (!!state) {
      return findByProp(state.requestForm.formFields, 'reference', reference);
    }
  });
  //const [visibleField, setVisibleField] = useState(true);
  const [displayValue, setDisplayValue] = useState(value);
  const dispatch = useDispatch();
  const [showTooltip, setshowTooltip] = useState(false);

  const onTextInputBlur = (e) => {
    setDisplayValue(e.target.value);
  };
  const renderTooltip = (props) => {
    if (fieldID === 'RequestTitle') {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Enter some key details to help you and others easily identify the request.(Only 128 characters allowed)
        </Tooltip>
      );
    }
    if (fieldID === 'CostCodeCentre') {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Cost Centre Codes are validated against the GEMS Portal as active or inactive. If you are unsure of a valid
          Cost Centre Code, please confirm with your business area. This request can be submitted without a valid Cost
          Centre Code, however you will be contacted by EMOS providers to obtain a valid code before this request can be
          completed.
        </Tooltip>
      );
    }
    if (fieldID === 'WBSCode') {
      return (
        <Tooltip id="button-tooltip" {...props}>
          WBS codes are validated against the GEMS Portal as active or inactive. If you are unsure of a valid WBS code,
          please confirm with your business area. This request can be submitted without a valid WBS code, however you
          will be contacted by EMOS providers to obtain a valid code before this request can be completed. 
        </Tooltip>
      );
    }
  };

  //show tooltip
  useEffect(() => {
    if (fieldID === 'RequestTitle' || fieldID === 'CostCodeCentre' || fieldID === 'WBSCode') {
      setshowTooltip(true);
    }
  }, [fieldID]);

  useEffect(() => {
    dispatch(
      setFieldValue({
        reference,
        value: displayValue,
        formData,
        required,
        visible,
      }),
    );

    //error handling for CCC and WBS
    if (fieldID === 'CostCodeCentre' && displayValue.length >= 6) {
      isCCCValid(displayValue);
    }
    if (fieldID === 'WBSCode' && displayValue.length >= 6) {
      isWBSValid(displayValue);
    }
  }, [displayValue]);

  useEffect(() => {
    const convertedValue = convertSpecialChars(reqDetailDataFromReduxStore?.value);
    setDisplayValue(convertedValue);
  }, [reqDetailDataFromReduxStore]);

  return (
    <>
      {visible && !showTooltip && (
        <FormFieldCol
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="pe-lg-4 mobileButton mb-4"
        >
          <Form.Group controlId={reference}>
            <Form.Label className={required ? 'required-field' : ''} dangerouslySetInnerHTML={{ __html: label }} />
            <FormControlTextInput
              onBlur={(e) => onTextInputBlur(e)}
              onChange={(e) => onTextInputBlur(e)}
              //type={type === "pxInteger" ? "number" : "text"}
              type={
                label === 'Quantity' ||
                label === 'Number Attending' ||
                label === 'Number of assets' ||
                label === 'Number of passengers'
                  ? 'number'
                  : 'text'
              }
              onInput={(e) =>
                (e.target.value = fieldPropMapping[fieldID]?.maxLength
                  ? e.target.value.slice(0, fieldPropMapping[fieldID]?.maxLength)
                  : e.target.value)
              }
              required={required}
              value={displayValue}
              min={0}
              maxLength={fieldPropMapping[fieldID]?.maxLength}
              readOnly={readOnly}
            />
            <Form.Control.Feedback type="invalid">
              {fieldPropMapping[fieldID]?.required?.errorMessage}
            </Form.Control.Feedback>
            <Form.Text id={`helptext-${reference}`}>{fieldPropMapping[fieldID]?.helpText}</Form.Text>
          </Form.Group>
        </FormFieldCol>
      )}

      {visible && showTooltip && (
        <FormFieldCol
          lg={fieldPropMapping[fieldID]?.fieldColumnWidth.desktop ?? 12}
          sm={fieldPropMapping[fieldID]?.fieldColumnWidth.mobile ?? 12}
          className="mb-4 pe-4"
        >
          <Form.Group controlId={fieldID}>
            <Form.Label
              className={required ? 'required-field' : ''}
              dangerouslySetInnerHTML={{
                __html: fieldID === 'RequestTitle' ? 'Name the request' : label,
              }}
            />
            &nbsp;
            <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
              <InfoCircle size={15} className="d-inline" />
            </OverlayTrigger>
            <FormControl
              onBlur={(e) => onTextInputBlur(e)}
              onChange={(e) => onTextInputBlur(e)}
              type="text"
              required={required}
              value={displayValue}
              maxLength={maxLength}
            />
            {fieldID === 'CostCodeCentre' && displayValue.length >= 6 && setCCMessage?.length > 0 && (
              <label className="cost-code-message">{setCCMessage}</label>
            )}
            {fieldID === 'WBSCode' && displayValue.length >= 6 && setWBSMessage?.length > 0 && (
              <label className="cost-code-message">{setWBSMessage}</label>
            )}
            <Form.Control.Feedback type="invalid">
              {fieldPropMapping[fieldID]?.required?.errorMessage}
            </Form.Control.Feedback>
          </Form.Group>
        </FormFieldCol>
      )}
    </>
  );
};

export default FormTextInput;
