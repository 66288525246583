import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { SubBanner } from '../../components/SubBanner';
import { Table } from '../../components/Table';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TextInput } from '../../components/form/inputs/textInput';
import { TypeAheadCodeSet } from '../../lib/api/lookup';

const getNextLineNo = (tableData: any[]) => {
  if (!tableData?.length) {
    return '1';
  }
  const maxLineNo = Math.max(...tableData.map((item) => parseInt(item.LineNo)));
  return `${maxLineNo + 1}`;
};

export const ActivityForm = ({
  addActivity,
  cancelActivity,
  fields,
  control,
  indexToShow,
  metadata,
}: {
  addActivity: any;
  cancelActivity: any;
  fields: any;
  control: any;
  indexToShow: number;
  metadata: any;
}) => {
  // Manage Service Form
  const [showServiceForm, setShowServiceForm] = useState(false);
  const [serviceTableData, setServiceTableData] = useState([]);
  const [servicesIndexToShow, setServicesIndexToShow] = useState(0);
  const [serviceBeingEdited, setServiceBeingEdited] = useState();

  const [oldData, setOldData] = useState<any>();
  const { watch, trigger, getFieldState } = useFormContext();

  // Save old data to be able to cancel changes
  useEffect(() => {
    const path = `ToOperation.results[${indexToShow}]`;
    if (watch(path)) {
      setOldData({ ...watch(path) });
    }
  }, [indexToShow]);

  // Manage Service Form
  const {
    fields: serviceFields,
    append: serviceAppend,
    remove: serviceRemove,
    update: serviceUpdate,
  } = useFieldArray({
    name: `ToOperation.results[${indexToShow}].ToService.results`,
  });

  const serviceDefaultValues = {
    ActionCode: 'NEW',
    LineNo: '',
    ServiceNo: '',
    Location: '',
    Quantity: '',
    GrossPrice: '',
    Description: '',
    NetValue: '',
  };

  const editServiceRow = (data: any) => {
    const index = serviceTableData?.findIndex((item) => item['LineNo'] === data['LineNo']);
    setServicesIndexToShow(index);
    setShowServiceForm(!showServiceForm);
    setServiceBeingEdited({
      ...watch(`ToOperation.results[${indexToShow}].ToService.results[${index}]`),
    });
  };

  const updateServiceTableData = () => {
    setServiceTableData(watch(`ToOperation.results[${indexToShow}].ToService.results`));
  };

  const upsertServiceRow = async (index: number) => {
    // trigger validation for the new item before adding it to the list
    await trigger();

    // if there are errors, do not add the new item
    const state = getFieldState(`ToOperation.results[${indexToShow}].ToService.results[${index}]`);
    if (state?.error) {
      return;
    }
    const service = watch(`ToOperation.results[${indexToShow}].ToService.results[${index}]`);
    serviceUpdate(index, {
      ...service,
      ActionCode: ['NEW', '01'].includes(service['ActionCode']) ? '01' : '02',
      NetValue:
        service.Quantity * service.GrossPrice
          ? (parseFloat(service.Quantity) * parseFloat(service.GrossPrice)).toString()
          : '0.00',
    });
    updateServiceTableData();
    setShowServiceForm(() => !showServiceForm);
  };

  const deleteServiceRow = (data: any) => {
    const index = serviceTableData?.findIndex((item) => item['LineNo'] === data['LineNo']);
    const serviceToRemove = watch(`ToOperation.results[${indexToShow}].ToService.results[${index}]`);
    if (['NEW', '01'].includes(serviceToRemove['ActionCode'])) {
      serviceRemove(index);
    } else {
      serviceToRemove.ActionCode = '03';
      serviceUpdate(index, serviceToRemove);
    }

    updateServiceTableData();
  };

  const cancelService = (index: number) => {
    const service = watch(`ToOperation.results[${indexToShow}].ToService.results[${index}]`);
    if (service['ActionCode'] !== 'NEW') {
      serviceUpdate(index, serviceBeingEdited);
    } else {
      serviceRemove(index);
    }
    setShowServiceForm(!showServiceForm);
    updateServiceTableData();
  };

  useEffect(() => {
    updateServiceTableData();
  }, [watch, indexToShow]);

  // ---  End Manage Service Form

  // Manage Relationship Form
  // Manage Relationship Form
  const [showRelationshipForm, setShowRelationshipForm] = useState(false);
  const [relationshipTableData, setRelationshipTableData] = useState([]);
  const [relationshipIndexToShow, setRelationshipIndexToShow] = useState(0);
  const [relationshipBeingEdited, setRelationshipBeingEdited] = useState();

  const {
    fields: relationshipFields,
    append: relationshipAppend,
    remove: relationshipRemove,
    update: relationshipUpdate,
  } = useFieldArray({
    name: `ToOperation.results[${indexToShow}].ToRelationship.results`,
  });
  const relationshipDefaultValues = {
    ActionCode: 'NEW',
    OrderPredecessor: '',
    OperationPredecessor: '',
    OrderSuccessor: '',
    OperationSuccessor: '',
    RelationshipType: '',
    TimeInterval: '0.0',
    TimeUnit: '',
  };

  const updateRelationshipTableData = () => {
    setRelationshipTableData(watch(`ToOperation.results[${indexToShow}].ToRelationship.results`));
  };

  const cancelRelationship = (index: number) => {
    const relationship = watch(`ToOperation.results[${indexToShow}].ToRelationship.results[${index}]`);
    if (relationship['ActionCode'] !== 'NEW') {
      relationshipUpdate(index, relationshipBeingEdited);
    } else {
      relationshipRemove(index);
    }
    setShowRelationshipForm(!showRelationshipForm);
    updateRelationshipTableData();
  };

  const editRelationshipRow = (data: any, index: number) => {
    const relationships = watch(`ToOperation.results[${indexToShow}].ToRelationship.results`);
    //exclude the deleted rows
    const relationshipTableData = relationships.filter((item, i) => item['ActionCode'] !== '03');
    const relationship = relationshipTableData[index];
    if (!relationship) return;
    setRelationshipIndexToShow(index);
    setShowRelationshipForm(!showRelationshipForm);
    setRelationshipBeingEdited({
      ...relationship,
    });
  };

  const deleteRelationshipRow = (_data: any, index: number) => {
    const relationships = watch(`ToOperation.results[${indexToShow}].ToRelationship.results`);
    //exclude the deleted rows
    const relationshipTableData = relationships.filter((item, i) => item['ActionCode'] !== '03');
    const relationshipToRemove = relationshipTableData[index];
    if (relationshipToRemove) {
      if (['NEW', '01'].includes(relationshipToRemove['ActionCode'])) {
        relationshipRemove(index);
      } else {
        relationshipToRemove.ActionCode = '03';
        relationshipUpdate(index, relationshipToRemove);
      }
    }
    updateRelationshipTableData();
  };

  const upsertRelationshipRow = async (index: number) => {
    // trigger validation for the new item before adding it to the list
    await trigger();

    // if there are errors, do not add the new item
    const state = getFieldState(`ToOperation.results[${indexToShow}].ToRelationship.results[${index}]`);
    if (state?.error) {
      return;
    }
    const relationship = watch(`ToOperation.results[${indexToShow}].ToRelationship.results[${index}]`);
    relationshipUpdate(index, {
      ...relationship,
      ActionCode: ['NEW', '01'].includes(relationship['ActionCode']) ? '01' : '02',
    });
    updateRelationshipTableData();
    setShowRelationshipForm(() => !showRelationshipForm);
  };

  useEffect(() => {
    updateRelationshipTableData();
  }, [watch, indexToShow]);

  // --- End Manage Relationship Form

  const showOperationForm = !showServiceForm && !showRelationshipForm;
  return (
    <>
      {fields.map((field: any, index: any) => {
        return (
          <div key={index}>
            {indexToShow === index && (
              <div>
                {showOperationForm && (
                  <>
                    <SubBanner title="Activity" />
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Activity Number"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].OperationNo`}
                        control={control}
                        disabled={false}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Short Text"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].ShortText`}
                        control={control}
                        disabled={false}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.ControlKey}
                        codeSetLabelField="ControlKeyDescription"
                        codeSetValueField="ControlKey"
                        fieldLabel="Control key"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].ControlKey`}
                        control={control}
                        required={true}
                        defaultValue=""
                      />
                      <SingleSelectInput
                        items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                        fieldLabel="Work Centre"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].WorkCenter`}
                        control={control}
                        required={false}
                        defaultValue=""
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Work"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].Work`}
                        control={control}
                        disabled={false}
                        required={false}
                      />
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.UomTime}
                        codeSetLabelField="Description"
                        codeSetValueField="Uom"
                        fieldLabel="Unit of Measure"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].WorkUnit`}
                        control={control}
                        required={false}
                        defaultValue=""
                      />
                      <TextInput
                        fieldLabel="Confirmation Number"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].ConfirmationNo`}
                        control={control}
                        disabled={true}
                        required={false}
                      />
                    </div>
                    <SubBanner title="External" />
                    <TextInput
                      fieldLabel="Operation Quantity"
                      fieldPlaceholder=" "
                      name={`ToOperation.results[${index}].Quantity`}
                      control={control}
                      disabled={true}
                      required={false}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.MaterialGroup}
                        codeSetLabelField="MatGrpDescription2"
                        codeSetValueField="MaterialGroup"
                        fieldLabel="Material Group"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].MaterialGroup`}
                        control={control}
                        required={false}
                        defaultValue=""
                      />
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.PurchaseGrp}
                        codeSetLabelField="Description"
                        codeSetValueField="PurchGrp"
                        fieldLabel="Purchasing Group"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].PurchasingGroup`}
                        control={control}
                        required={false}
                        defaultValue=""
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="FW Order"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].PurchaseOrder`}
                        control={control}
                        disabled={false}
                        required={false}
                        codeSet={TypeAheadCodeSet.PurchOrd}
                        top="100"
                        codeSetValueField="PONumber"
                        codeSetLabelField="Description"
                        codeSetFilterField="Description"
                      />
                      <TextInput
                        fieldLabel="FW Item Number"
                        fieldPlaceholder=" "
                        name={`ToOperation.results[${index}].PurchaseOrderitem`}
                        control={control}
                        disabled={false}
                        required={false}
                      />
                    </div>
                    <SubBanner title="Services" />
                    <Table
                      ColumnsHeadersInput={[
                        {
                          accessor: 'ServiceNo',
                          header: 'Service Number',
                        },
                        {
                          accessor: 'Description',
                          header: 'Description',
                        },
                        {
                          accessor: 'Location',
                          header: 'Location',
                        },
                        {
                          accessor: 'Quantity',
                          header: 'Quantity',
                        },
                        {
                          accessor: 'GrossPrice',
                          header: 'Gross Price',
                        },
                        {
                          accessor: 'NetValue',
                          header: 'Net Value',
                        },
                      ]}
                      values={serviceTableData?.filter((item) => item['ActionCode'] !== '03')}
                      editRow={editServiceRow}
                      deleteRow={deleteServiceRow}
                    />
                    <button
                      className="button-secondary w-64 bg-white"
                      type="button"
                      onClick={() => {
                        setShowServiceForm(() => !showServiceForm);
                        serviceAppend({
                          ...serviceDefaultValues,
                          LineNo: getNextLineNo(serviceTableData),
                        });
                        setServicesIndexToShow(() => serviceTableData.length);
                      }}
                    >
                      Add Services
                    </button>
                    <SubBanner title="Relationship" />

                    <Table
                      ColumnsHeadersInput={[
                        {
                          accessor: 'OrderPredecessor',
                          header: 'Predecessor',
                        },
                        {
                          accessor: 'OperationPredecessor',
                          header: 'Predecessor Operation Number',
                        },
                        {
                          accessor: 'OrderSuccessor',
                          header: 'Sucessor',
                        },
                        {
                          accessor: 'OperationSuccessor',
                          header: 'Sucessor Operation Number',
                        },
                        {
                          accessor: 'RelationshipType',
                          header: 'Relationship Type',
                        },
                        {
                          accessor: 'TimeInterval',
                          header: 'Offset',
                        },
                        {
                          accessor: 'TimeUnit',
                          header: 'UoM',
                        },
                      ]}
                      values={relationshipTableData?.filter((item) => item['ActionCode'] !== '03')}
                      editRow={editRelationshipRow}
                      deleteRow={deleteRelationshipRow}
                    />
                    <button
                      className="button-secondary bg-white"
                      type="button"
                      onClick={() => {
                        setShowRelationshipForm(() => !showRelationshipForm);
                        relationshipAppend(relationshipDefaultValues);
                        setRelationshipIndexToShow(() => relationshipTableData.length);
                      }}
                    >
                      Add Relationship
                    </button>
                    <div className="flex justify-start">
                      <button
                        className="button-primary bg-defence-orange-500"
                        type="button"
                        onClick={() => {
                          addActivity();
                        }}
                      >
                        Save
                      </button>
                      <button
                        className="button-secondary bg-white"
                        type="button"
                        onClick={() => {
                          cancelActivity(index, oldData);
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </>
                )}
                {showServiceForm && (
                  <>
                    {serviceFields.map((field, servicesIndex) => {
                      return (
                        <div key={field.id}>
                          <>
                            {servicesIndexToShow === servicesIndex && (
                              <>
                                <SubBanner title="Services" />
                                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                                  <TextInput
                                    fieldLabel="Line Number"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToService.results[${servicesIndex}].LineNo`}
                                    control={control}
                                    disabled={false}
                                    required={true}
                                  />
                                  <InputTypeAhead
                                    fieldLabel="Service Number"
                                    fieldPlaceholder="Start typing"
                                    name={`ToOperation.results[${index}].ToService.results[${servicesIndex}].ServiceNo`}
                                    control={control}
                                    disabled={false}
                                    required={true}
                                    codeSet={TypeAheadCodeSet.ServiceNo}
                                    top="10"
                                    codeSetValueField="ServiceNo"
                                    codeSetLabelField="Description"
                                  />
                                </div>
                                <TextInput
                                  fieldLabel="Description"
                                  fieldPlaceholder=" "
                                  name={`ToOperation.results[${index}].ToService.results[${servicesIndex}].Description`}
                                  control={control}
                                  disabled={false}
                                  required={false}
                                />
                                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                                  <TextInput
                                    fieldLabel="Location"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToService.results[${servicesIndex}].Location`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                  />
                                  <TextInput
                                    fieldLabel="Quantity"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToService.results[${servicesIndex}].Quantity`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                  />
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                                  <TextInput
                                    fieldLabel="Gross Price"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToService.results[${servicesIndex}].GrossPrice`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                  />
                                  <TextInput
                                    fieldLabel="Net Value"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToService.results[${servicesIndex}].NetValue`}
                                    control={control}
                                    disabled={true}
                                    required={false}
                                  />
                                </div>
                                <div className="flex justify-start">
                                  <button
                                    className="button-primary bg-defence-orange-500"
                                    type="button"
                                    onClick={() => {
                                      upsertServiceRow(servicesIndex);
                                    }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="button-secondary bg-white"
                                    type="button"
                                    onClick={() => {
                                      cancelService(servicesIndex);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      );
                    })}
                  </>
                )}
                {showRelationshipForm && (
                  <>
                    {relationshipFields.map((field, relationshipIndex) => {
                      return (
                        <div key={field.id}>
                          <>
                            {relationshipIndexToShow === relationshipIndex && (
                              <>
                                <SubBanner title="Relationship" />
                                <div className="grid md:grid-cols-2 md:gap-8">
                                  <InputTypeAhead
                                    fieldLabel="Predecessor Work Order Number"
                                    fieldPlaceholder="Start typing"
                                    name={`ToOperation.results[${index}].ToRelationship.results[${relationshipIndex}].OrderPredecessor`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                    codeSet={TypeAheadCodeSet.Wo}
                                    top="100"
                                    codeSetValueField="WorkOrder"
                                    defaultValue={relationshipDefaultValues.OrderPredecessor}
                                  />
                                  <TextInput
                                    fieldLabel="Predecessor Operation Number"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToRelationship.results[${relationshipIndex}].OperationPredecessor`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                  />
                                </div>
                                <div className="grid md:grid-cols-2 md:gap-8">
                                  <InputTypeAhead
                                    fieldLabel="Successor Work Order Number"
                                    fieldPlaceholder="Start typing"
                                    name={`ToOperation.results[${index}].ToRelationship.results[${relationshipIndex}].OrderSuccessor`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                    codeSet={TypeAheadCodeSet.Wo}
                                    top="100"
                                    codeSetValueField="WorkOrder"
                                    defaultValue={relationshipDefaultValues.OrderSuccessor}
                                  />
                                  <TextInput
                                    fieldLabel="Sucessor Operation Number"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToRelationship.results[${relationshipIndex}].OperationSuccessor`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                  />
                                </div>
                                <div className="grid md:grid-cols-2 md:gap-8">
                                  <SingleSelectInput
                                    items={[
                                      { code: 'FF', description: 'FF Relationship' },
                                      { code: 'FS', description: 'FS Relationship' },
                                      { code: 'SF', description: 'SF Relationship' },
                                      { code: 'SS', description: 'SS Relationship' },
                                    ]}
                                    fieldLabel="Relation Type"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToRelationship.results[${relationshipIndex}].RelationshipType`}
                                    control={control}
                                    includeCode={false}
                                    disabled={false}
                                    required={false}
                                  />

                                  <TextInput
                                    fieldLabel="Offset"
                                    fieldPlaceholder=" "
                                    name={`ToOperation.results[${index}].ToRelationship.results[${relationshipIndex}].TimeInterval`}
                                    control={control}
                                    disabled={false}
                                    required={false}
                                  />
                                </div>
                                <SingleSelectInputCode
                                  codeSet={TypeAheadCodeSet.UomTime}
                                  codeSetLabelField="Description"
                                  codeSetValueField="Uom"
                                  fieldLabel="Unit of Measure"
                                  fieldPlaceholder=" "
                                  name={`ToOperation.results[${index}].ToRelationship.results[${relationshipIndex}].TimeUnit`}
                                  control={control}
                                  disabled={false}
                                  required={false}
                                />
                                <div className="flex justify-start">
                                  <button
                                    className="button-primary bg-defence-orange-500"
                                    type="button"
                                    onClick={() => {
                                      upsertRelationshipRow(relationshipIndex);
                                    }}
                                  >
                                    Save
                                  </button>
                                  <button
                                    className="button-secondary bg-white"
                                    type="button"
                                    onClick={() => {
                                      cancelRelationship(relationshipIndex);
                                    }}
                                  >
                                    Cancel
                                  </button>
                                </div>
                              </>
                            )}
                          </>
                        </div>
                      );
                    })}
                  </>
                )}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
