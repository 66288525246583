import React, { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SubBanner } from '../../components/SubBanner';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TextInput } from '../../components/form/inputs/textInput';
import { TypeAheadCodeSet } from '../../lib/api/lookup';

export const ServiceForm = ({
  addActivity,
  cancelActivity,
  fields,
  control,
  indexToShow,
}: {
  addActivity: any;
  cancelActivity: any;
  fields: any;
  control: any;
  indexToShow: number;
  metadata: any;
}) => {
  const [oldData, setOldData] = useState<any>();
  const { watch } = useFormContext();

  useEffect(() => {
    const convData = JSON.parse(JSON.stringify(watch(`WOConfirmSrvSet.results[${indexToShow}]`)));
    setOldData(convData);
  }, [indexToShow]);

  return (
    <>
      {fields.map((_field: any, index: any) => {
        return (
          <div key={index}>
            {indexToShow === index && (
              <div>
                <>
                  <SubBanner title="Service Details" />
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <TextInput
                      fieldLabel="Line No"
                      fieldPlaceholder=" "
                      name={`WOConfirmSrvSet.results[${index}].LineNo`}
                      control={control}
                      disabled={true}
                      required={false}
                    />
                    <InputTypeAhead
                      fieldLabel="Service Number"
                      fieldPlaceholder="Start typing"
                      name={`WOConfirmSrvSet.results[${index}].ServiceNo`}
                      control={control}
                      codeSet={TypeAheadCodeSet.ServiceNo}
                      codeSetFilterField="Description"
                      codeSetValueField="ServiceNo"
                      codeSetLabelField="Description"
                      required={true}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <TextInput
                      fieldLabel="Quantity"
                      fieldPlaceholder=" "
                      name={`WOConfirmSrvSet.results[${index}].Quantity`}
                      control={control}
                      required={true}
                    />
                    <TextInput
                      fieldLabel="Gross Price"
                      fieldPlaceholder=" "
                      name={`WOConfirmSrvSet.results[${index}].NetValue`}
                      control={control}
                      required={true}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <DateInput
                      fieldLabel="Start Date and Time"
                      fieldPlaceholder=" "
                      name={`WOConfirmSrvSet.results[${index}].StartDateTime`}
                      control={control}
                      disabled={false}
                      showTime={true}
                      required={true}
                    />
                    <DateInput
                      fieldLabel="End Date and Time"
                      fieldPlaceholder=" "
                      name={`WOConfirmSrvSet.results[${index}].EndDateTime`}
                      control={control}
                      showTime={true}
                      required={true}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <TextInput
                      fieldLabel="Location"
                      fieldPlaceholder=" "
                      name={`WOConfirmSrvSet.results[${index}].Location`}
                      control={control}
                      required={false}
                    />
                    <TextInput
                      fieldLabel="Message"
                      fieldPlaceholder=" "
                      name={`WOConfirmSrvSet.results[${index}].Message`}
                      control={control}
                      required={false}
                    />
                  </div>
                  <div className="flex justify-start">
                    <button
                      className="button-secondary bg-white"
                      type="button"
                      onClick={() => {
                        cancelActivity(index, oldData);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="button-primary bg-defence-orange-500"
                      type="button"
                      onClick={() => {
                        addActivity();
                      }}
                    >
                      Save
                    </button>
                  </div>
                </>
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};
