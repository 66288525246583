import { Button, CardBody, CardFooter, CardHeader, Card as ChakraCard, Heading, Image, Text } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { External_Black as ExternalBlack, External_White as ExternalWhite } from '../icons/Outline';
import { CardLink } from './CardLink';

export interface CardProps {
  header?: string;
  content?: string;
  linkText?: string;
  linkURL?: string;
  image?: string;
  alt?: string;
  isButton?: boolean;
  buttonVariant?: string;
  isExternal?: boolean;
}

export const Card: React.FC<CardProps> = ({
  header,
  content,
  linkText,
  linkURL,
  image,
  alt,
  isButton,
  buttonVariant,
  isExternal,
}: CardProps): JSX.Element => {
  return (
    <ChakraCard
      height="100%"
      padding={0}
      data-testid="sc-card"
      justifyContent="space-between"
      borderRadius="5px"
      boxShadow="none"
    >
      {(header || image) && (
        <>
          {image && (
            <CardHeader p={0}>
              <Image
                w="100%"
                src={image}
                borderTopLeftRadius="inherit"
                borderTopRightRadius="inherit"
                alt={alt ?? ''}
              />
            </CardHeader>
          )}
          {header && (
            <CardHeader px="1.6rem" pt="2.5rem" pb="0">
              <Heading as="h3" size="xs" color="black" fontWeight="medium">
                {header}
              </Heading>
            </CardHeader>
          )}
        </>
      )}

      <CardBody px="1.6rem" maxHeight="none">
        {content && (
          <Text fontWeight="300" fontSize="18px">
            {content}
          </Text>
        )}
      </CardBody>

      {linkText && linkURL && (
        <CardFooter px="1.6rem" pt="0" pb="1.6rem">
          {isButton ? (
            <Button
              w="100%"
              as={RouterLink}
              to={linkURL}
              variant={buttonVariant}
              display="flex"
              alignItems="center"
              gap={2}
              fontWeight="500"
              fontSize="xs"
              borderRadius="2px"
              target={isExternal ? '_blank' : undefined}
              rel={isExternal ? 'noopener noreferrer' : undefined}
            >
              {linkText}
              {isExternal &&
                (buttonVariant === 'secondary' ? <ExternalBlack height="1rem" /> : <ExternalWhite height="1rem" />)}
            </Button>
          ) : (
            <CardLink text={linkText} href={linkURL} />
          )}
        </CardFooter>
      )}
    </ChakraCard>
  );
};

export const IconCard: React.FC<CardProps> = ({
  header,
  content,
  linkText,
  linkURL,
  image,
  alt,
  buttonVariant,
}: CardProps): JSX.Element => {
  return (
    <ChakraCard height="100%" padding="2rem" data-testid="icon-card" boxShadow="none">
      {image && (
        <CardHeader>
          <Image src={require(`../../../../images/${image}`)} data-testid="icon" height="2rem" alt={alt ?? ''} />
        </CardHeader>
      )}
      {(content || header) && (
        <CardBody maxHeight="none">
          <Heading as="h3" fontWeight="medium" fontSize="sm">
            {header}
          </Heading>
          {content && (
            <Text fontWeight="light" color="black">
              {content}
            </Text>
          )}
        </CardBody>
      )}
      {linkText && linkURL && (
        <CardFooter>
          <Button variant={buttonVariant ?? 'secondary'} as={RouterLink} to={linkURL}>
            {linkText}
          </Button>
        </CardFooter>
      )}
    </ChakraCard>
  );
};
