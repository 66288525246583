import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { Banner } from '../../components/Banner';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { TableInputGen } from '../../components/form/inputs/tableInputGen';
import { TextAreaInput } from '../../components/form/inputs/textAreaInput';
import { TextInput } from '../../components/form/inputs/textInput';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { ReadSESResponseDataItem, SESMetadataResponseDataResults } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertData from '../../lib/convertData';
import convertSapMetadata from '../../lib/convertMetadata';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaSes/metaSes.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';

export const UpdateServiceEntrySheet = () => {
  useDocumentTitle(`Display Service Entry Sheet`);

  // const [metadata, setMetadata] = useState<{ [key: string]: any }>({});
  // const [errorMessage, setErrorMessage] = useState('');
  const [singledata, setSingledata] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const refData = useRef(true);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const sesId = useParams();
  useEffect(() => {}, [sesId]);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    if (Object.keys(metadata).length === 0) {
      // getNotifMeta();
      const fetchTableMetadata = async () => {
        if (ref.current) {
          const { data: TableMetadata } = await axiosInstance.get<SESMetadataResponseDataResults>(
            `${getAPIUrl(process.env.REACT_APP_EE_SES_METADATA)}`,
          );

          const formMetaData = convertSapMetadata(TableMetadata);
          dispatch(setCurrentMeta(formMetaData));
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);

  useEffect(() => {
    const fetchSingleSes = async (id: string) => {
      if (refData.current) {
        try {
          setLoading(true);
          let sheetId;
          try {
            sheetId = `ReadServiceEntrySheet('` + Object.values(id)[0] + `')`;
          } catch (e) {
            sheetId = id;
          }
          const respData = await axiosInstance.get<ReadSESResponseDataItem>(
            `${getAPIUrl(process.env.REACT_APP_EE_SES_SING_REQ_LIST)}${sheetId}?$expand=SesLines&$format=json`,
          );
          const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
          setTitleMessage(titleMessage);
          setMessages(messages);
          const sesData = respData.data;
          const singleSesData = convertData(sesData) || {};
          try {
            singleSesData.SesLines.results.map((item) => {
              if (item.LineNo) {
                item.LineNo = item.LineNo.replace(/^0+/, '');
              }
            });
            setSingledata(singleSesData);
          } catch (e) {
            setSingledata(singleSesData);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          const errorResponse = isAxiosError(error) ? error.response : null;
          const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
            (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
          ]);
          setTitleMessage(titleMessage);
          setMessages(messages);
        }
      }
    };
    fetchSingleSes(sesId as never).catch(console.error);
    return () => {
      refData.current = true;
    };
  }, [sesId]);

  const schema = z.object({
    SheetNumber: z.string().optional(),
    Status: z.string().optional(),
    StatusText: z.string().optional(),
    PurchaseOrder: z.string().optional(),
    PurchaseOrderItem: z.string().optional(),
    ShortText: z.string().optional(),
    ExternalNumber: z.string().optional(),
    CommNumber: z.string().optional(),
    ReferenceDocumentNumber: z.string().optional(),
    RunId: z.string().optional(),
    Vendor: z.string().optional(),
    DocumentDate: z.string().optional(),
    BeginDate: z.string().optional(),
    EndDate: z.string().optional(),
    LongText: z.string().optional(),
    SesLines: z.object({
      results: z
        .object({
          LineNo: z.string().optional(),
          Service: z.number().optional(),
          ShortText: z.string().optional(),
          Operation: z.string().optional(),
          MatlGroup: z.string().optional(),
          WorkOrder: z.string().optional(),
          Location: z.string().optional(),
          Quantity: z.string().optional(),
          GrossPrice: z.string().optional(),
          BaseUOM: z.string().optional(),
        })
        .array(),
    }),
  });

  type formSchema = z.infer<typeof schema>;
  const navigate = useNavigate();

  const [defaultValues, setDefaultValues] = useState<formSchema>({
    SheetNumber: '',
    Status: '',
    StatusText: '',
    PurchaseOrder: '',
    PurchaseOrderItem: '',
    ShortText: '',
    ExternalNumber: '',
    CommNumber: '',
    RunId: '',
    ReferenceDocumentNumber: '',
    Vendor: '',
    DocumentDate: undefined,
    BeginDate: undefined,
    EndDate: undefined,
    LongText: '',
    SesLines: {
      results: [],
    },
  });
  useEffect(() => {
    setDefaultValues({
      SheetNumber: singledata.SheetNumber,
      Status: singledata.Status,
      StatusText: singledata.StatusText,
      PurchaseOrder: singledata.PurchaseOrder,
      PurchaseOrderItem: singledata.PurchaseOrderItem,
      ShortText: singledata.ShortText,
      ExternalNumber: singledata.ExternalNumber,
      CommNumber: singledata.CommNumber,
      RunId: singledata.RunId,
      ReferenceDocumentNumber: singledata.ReferenceDocumentNumber,
      Vendor: singledata.Vendor,
      DocumentDate: singledata.DocumentDate,
      BeginDate: singledata.BeginDate,
      EndDate: singledata.EndDate,
      LongText: singledata.LongText,
      SesLines: {
        results: singledata?.SesLines?.results,
      },
    });
  }, [singledata]);

  const methods = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (defaultValues) {
      methods.reset({ ...defaultValues });
    }
  }, [defaultValues, methods.reset]);

  const onSubmit = async (data: formSchema) => {
    try {
      // const { data: responseData } = await serviceRequestList();
      // navigate("/notifications/read/results", {
      //   state: { data: responseData },
      // });
    } catch (error) {
      if (isAxiosError(error)) {
      }
    }
    //navigate("/notifications/read/results", { state: { data: responseData } });
  };
  const [step, setStep] = useState(1);
  // const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    // setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Header Data',
      fields: [
        'SheetNumber',
        'StatusText',
        'PurchaseOrder',
        'PurchaseOrderItem',
        'ShortText',
        'ExternalNumber',
        'CommNumber',
        'RunId',
        'ReferenceDocumentNumber',
        'Vendor',
        'DocumentDate',
        'BeginDate',
        'EndDate',
        'LongText',
      ],
    },
    {
      code: 2,
      name: 'Service Lines',
      fields: [
        'LineNo',
        'Service',
        'ShortText',
        'Operation',
        'MatlGroup',
        'WorkOrder',
        'Location',
        'Quantity',
        'GrossPrice',
        'BaseUOM',
      ],
    },
  ];
  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <>
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <SubMenu title="Service Entry Sheet" selectedIndex="0" />
      <Banner title="Display Service Entry Sheet" description="" bannerImgSrc="" />
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {/* {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))} */}
          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                    ? FormStepperEnum.Complete
                    : FormStepperEnum.Upcoming,
              }))}
            />
          </div>
          <div className="w-full pt-3">
            <SubBanner title={sections[step - 1].name} />
          </div>

          <div className="w-full p-2">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, goToErrors)}>
                {step === 1 && (
                  <div className="mb-8 w-full">
                    <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Sheet Entry Sheet Number"
                          fieldPlaceholder=" "
                          name="SheetNumber"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Status"
                          fieldPlaceholder=" "
                          name="StatusText"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Purchase Order"
                          fieldPlaceholder=" "
                          name="PurchaseOrder"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="PO Item"
                          fieldPlaceholder=" "
                          name="PurchaseOrderItem"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Vendor Number"
                          fieldPlaceholder=" "
                          name="Vendor"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="External Number"
                          fieldPlaceholder=" "
                          name="ExternalNumber"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Service Entry Sheet Description"
                          fieldPlaceholder=" "
                          name="ShortText"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Communication Number"
                          fieldPlaceholder=" "
                          name="CommNumber"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Run ID"
                          fieldPlaceholder=" "
                          name="RunId"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Reference Document Number"
                          fieldPlaceholder=" "
                          name="ReferenceDocumentNumber"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <DateInput
                          fieldLabel="Start Date"
                          fieldPlaceholder=" "
                          name="BeginDate"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <DateInput
                          fieldLabel="End Date"
                          fieldPlaceholder=" "
                          name="EndDate"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                        <DateInput
                          fieldLabel="Document Date"
                          fieldPlaceholder=" "
                          name="DocumentDate"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-1 md:gap-8">
                        <TextAreaInput
                          fieldLabel="Service Entry Sheet Text"
                          fieldPlaceholder=" "
                          name="LongText"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                )}

                {step == 2 && (
                  <div className="mb-8 w-full">
                    <>
                      <TableInputGen
                        readonly={true}
                        scenario="SES"
                        defaultValues={{
                          LineNo: '10',
                          Service: '',
                          ShortText: '',
                          Operation: '',
                          MatlGroup: '',
                          WorkOrder: '',
                          Location: '',
                          Quantity: '',
                          GrossPrice: '',
                          BaseUOM: '',
                        }}
                        keyfield="LineNo"
                        path="SesLines.results"
                        metadata={metadata}
                        ColumnsHeadersInput={[
                          {
                            accessor: 'LineNo',
                            header: 'Line No',
                          },
                          {
                            accessor: 'Service',
                            header: 'Service',
                          },
                          {
                            accessor: 'ShortText',
                            header: 'ShortText',
                          },
                          {
                            accessor: 'MatlGroup',
                            header: 'MatlGroup',
                          },
                          {
                            accessor: 'WorkOrder',
                            header: 'WorkOrder',
                          },
                          {
                            accessor: 'Operation',
                            header: 'Operation',
                          },
                          {
                            accessor: 'Location',
                            header: 'Location',
                          },
                          {
                            accessor: 'Quantity',
                            header: 'Qty',
                          },
                          {
                            accessor: 'GrossPrice',
                            header: 'Gross Price',
                          },
                          {
                            accessor: 'BaseUOM',
                            header: 'UOM',
                          },
                        ]}
                      />
                    </>
                    {/* <div className="grid h-full grid-flow-row  gap-1 md:grid-cols-1 md:gap-4">
                <ConfServiceInput
                  control={control}
                  readOnly={false}
                  metadata={metadata}
                  defaultValues={defaultValues}
                />
              </div> */}
                  </div>
                )}
                <div className="flex w-full justify-end">
                  <div className="flex-end flex">
                    {step === 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          nextStep();
                        }}
                        className="button-primary bg-defence-orange-500"
                      >
                        Next
                      </button>
                    )}
                    {step === 2 && (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            backStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Back
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};
