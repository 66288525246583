import React from "react";
import { createContext, useContext, useEffect, useRef } from "react";
import { Location, useLocation } from "react-router-dom";

const usePrevious = (value: Location) => {
  const ref = useRef<Location>();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
};

interface NavigationContextInfo {
  prevLocation?: Location;
  location?: Location;
}

const NavigationContext = createContext<NavigationContextInfo>({});

export interface NavigationContextProviderProps {
  children: React.ReactNode;
}
export function NavigationContextProvider({
  children,
}: NavigationContextProviderProps) {
  const location = useLocation();
  const prevLocation = usePrevious(location);

  return (
    <NavigationContext.Provider value={{ location, prevLocation }}>
      {children}
    </NavigationContext.Provider>
  );
}

export const useNavigationContext = (): NavigationContextInfo => {
  return useContext(NavigationContext);
};
