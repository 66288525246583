import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { WorkOrderCreateResponse, WorkOrderMetadataResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertSapMetadata from '../../lib/convertMetadata';
import { formatDateToODataDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentMeta } from '../../store/metaOrder/metaOrder.action';
import { selectCurrentMeta } from '../../store/metaOrder/metaOrder.selector';
import { TWorkOrderFormSchema, workOrderFormSchema } from '../orders/WorkOrderFormSchema';
import { WorkOrderForm } from './WorkOrderForm';

const defaultValues: TWorkOrderFormSchema = {
  WorkOrderNo: '',
  OrderType: '',
  RevnrRevision: '',
  PlannerGroupDesc: '',
  PlannerGroup: '',
  FunctionalLocation: '',
  EquipmentId: '',
  Description: '',
  WorkOrderLongText: '',
  SystemStatus: '',
  UserStatus: '',
  DIRSet: {
    results: [
      {
        DocumentNo: '',
        DocumentDesc: '',
      },
    ],
  },
  UserStatusSet: {
    results: [],
  },
  ScheduleStartDateTime: null,
  ScheduleFinishDateTime: null,
  BasicStartDateTime: undefined,
  BasicFinishDateTime: undefined,
  Priority: '',
  MaintenanceType: '',
  PotentialHarm: '',
  MainWorkCentre: '',
  ToOperation: {
    results: [],
  },
  RefWorkOrderDesc: '',
  IntegrityAssessed: '',
  FunctionalityAssessed: '',
  ComplianceIssue: false,
  ComplianceType: '',
  NoncompConsequence: '',
  ValueMoneyConsequence: '',
  IntendedYear: '',
  SafetyImpact: false,
  PotentialHazard: '',
  WHSConsequence: '',
  WHSLikelihood: '',
  RiskLevel: '',
  CustCostCentre: '',
  CustomerWbs: '',
  CustomerAsset: '',
  CustomerSubAsset: '',
  NotificationNo: '',
  RefNotificationNo: '',
  RefWorkOrder: '',
  DefenceGroup: '',
  DefenceGroupName: '',
  FunctionalParentECID: '',
  EstateClassID: '',
  EUPricingID1: '',
  EUPricingID2: '',
  SystemStatusSet: {
    results: [],
  },
  Etag: '',
  Plant: '1000',
  PlanningPlant: '1000',
};

export const CreateWorkOrder = () => {
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const [loading, setLoading] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [workOrderNumber, setWorkOrderNumber] = useState<any>('');
  const [workOrder, setWorkOrder] = useState<TWorkOrderFormSchema>(defaultValues);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  useEffect(() => {
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const { data: TableMetadata } = await axiosInstance.get<WorkOrderMetadataResponse>(
              `${getAPIUrl(process.env.REACT_APP_EE_WO_METADATA)}`,
            );
            const formMetaData = convertSapMetadata(TableMetadata);
            dispatch(setCurrentMeta(formMetaData));
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);

  const uploadSelectedFile = (workorderno: string) => {
    const file = selectedFiles?.[0];
    if (file) {
      setLoading(true);
      const uploadFormData = new FormData();
      uploadFormData.append('file', file, file.name);

      try {
        return axiosInstance
          .post(`${getAPIUrl(process.env.REACT_APP_EE_WO_ATTACHMENT)}`, uploadFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              slug: file.name,
              WorkOrderNo: workorderno,
            },
          })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setTitleMessage('Success');
              setMessages([response.data?.d?.ResultMsg ?? 'File uploaded successfully', response.data?.d?.RequestId]);
            } else {
              const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
                `Work Order(${workorderno}) is created successfully but the file upload failed. Please try again later`,
              ]);
              setTitleMessage('Success');
              setMessages(messages);
            }
            setLoading(false);
            return true;
          })
          .catch((err) => {
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(err?.response, [
              `Work Order(${workorderno}) is created successfully but the file upload failed. Please try again later`,
            ]);
            setTitleMessage('Success');
            setMessages(messages);
            setLoading(false);
            return false;
          });
      } catch (_error) {
        setTitleMessage('Success');
        setMessages([
          `Work Order(${workorderno}) is created successfully but Unable to process the file upload request. Please try again later`,
        ]);
        setLoading(false);
        return false;
      }
    } else {
      return true;
    }
  };

  const submitCreate = async (data: TWorkOrderFormSchema) => {
    setLoading(true);
    const resultData = convertFormDates(data);
    if (resultData.CustomerAsset !== '') {
      resultData.CustCompanyCode = '1000';
    } else {
      resultData.CustCompanyCode = '';
    }
    const payloadData = updatePayloadData(structuredClone(resultData));
    let resultData1 = { d: payloadData };

    try {
      const response = await axiosInstance.post<WorkOrderCreateResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_CREATE)}`,
        resultData1,
      );
      const workorderno = response?.data?.['d']?.['WorkOrderNo'];
      if (selectedFiles.length > 0 && workorderno) {
        const success = await uploadSelectedFile(workorderno);
        if (!success) {
          setWorkOrderNumber(workorderno);
          return;
        }
      }
      setLoading(false);
      if (response.status === 200 || response.status === 201) {
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'WorkOrder created: ' + response.data?.d?.WorkOrderNo,
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
        setWorkOrderNumber(response.data?.d?.WorkOrderNo);
      }
      setWorkOrder(data);
    } catch (err) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
      setWorkOrder(data);
    }
  };

  const convertFormDates = function (data: any) {
    const formValues = { ...data };
    try {
      Object.keys(formValues).forEach((item) => {
        if (typeof formValues[item] === 'object' && formValues[item] instanceof Date === true) {
          if (formValues[item]) {
            formValues[item] = formatDateToODataDate(formValues[item]);
          }
        }
      });
    } catch (err) {
      console.log('errors: ', err);
    }
    return formValues;
  };

  const updatePayloadData = function (data: any) {
    const formValues = { ...data };
    try {
      formValues.ComplianceIssue = formValues.ComplianceIssue ? 'X' : '';
      formValues.SafetyImpact = formValues.SafetyImpact ? 'X' : '';
      formValues.Plant = formValues.PlanningPlant = '1000';
      //update Plant in Operation
      if (formValues.ToOperation.results?.length > 0) {
        for (var i in formValues.ToOperation.results) {
          formValues.ToOperation.results[i].Plant = formValues.Plant;
        }
      }

      // Move UserStatus in SingleDropDown UserStatus to StatusCode in the struture
      // note: since the dropdown has SAP code (E0001 etc) in UserStatus, hence we have to use it to transfer
      formValues.UserStatusSet.results = formValues.UserStatusSet.results || [];
      if (formValues.UserStatusSet.results.length) {
        for (var i in formValues.UserStatusSet.results) {
          formValues.UserStatusSet.results[i].UserStatusCode = formValues.UserStatusSet.results[i].code;
          formValues.UserStatusSet.results[i].UserStatusName = formValues.UserStatusSet.results[i].description;
          formValues.UserStatusSet.results[i].ActiveIndicator = 'X';
          delete formValues.UserStatusSet.results[i].code;
          delete formValues.UserStatusSet.results[i].description;
        }
      }
      if (formValues.UserStatus !== '') {
        formValues.UserStatusSet.results.push({
          UserStatusCode: formValues.UserStatus,
          ActiveIndicator: 'X',
        });
      }
      //prevent blank on decimal
      if (data.ToOperation.results.length) {
        for (var j in data.ToOperation.results) {
          if (data.ToOperation.results[j].Quantity === '') {
            data.ToOperation.results[j].Quantity = '0';
          }
          if (data.ToOperation.results[j].Work === '') {
            data.ToOperation.results[j].Work = '0';
          }
        }
      }
    } catch (err) {
      console.log('errors: ', err);
    }
    return formValues;
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Create Work Order',
      }}
      bannerProps={{
        title: 'Create Work Order',
      }}
      submenuProps={{
        title: 'Work Orders',
        selectedIndex: '0',
      }}
    >
      {loading && <Spinner overlay />}
      {!loading && messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
          navigateLink={workOrderNumber ? `/eeportal/orders/search/results/${workOrderNumber}/update` : ''}
        ></MessageModal>
      )}
      <div>
        <WorkOrderForm
          metadata={metadata}
          updateForm={false}
          onSubmit={submitCreate}
          schema={workOrderFormSchema}
          defaultValues={workOrder}
          onFilesSelected={setSelectedFiles}
        />
      </div>
    </BaseLayout>
  );
};
