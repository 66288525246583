import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialData/profile.json';

export const handleProfile = createSlice({
  name: 'Handling Profile',
  initialState,
  reducers: {
    setProfile: (_state, { payload }) => {
      return payload;
    },
    setGovID: (state, { payload }) => {
      state.myGovIDLink.defenceIdentity = payload;
    },
    setSMSConsent: (state, { payload }) => {
      state.SMSConsent = payload;
    },
    setWebAnalytics: (state, { payload }) => {
      state.AllowAnalyticsTracking = String(payload);
    },
  },
});

export const { setProfile, setGovID, setSMSConsent, setWebAnalytics } = handleProfile.actions;

export default handleProfile.reducer;
