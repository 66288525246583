import { zodResolver } from '@hookform/resolvers/zod';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { MultiSelectInput } from '../../components/form/inputs/multiSelectInput';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TextInput } from '../../components/form/inputs/textInput';
import { LOCAL_STORAGE_KEY_PREFIX, ROUTES } from '../../constants';
import { useNavigationContext, usePersistForm, useSessionStorage } from '../../hooks';
import { NotificationMetadataResponse, SearchNotificationResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertFormData from '../../lib/convertFormData';
import convertSapMetadata from '../../lib/convertMetadata';
import { isDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaNotif/metaNotif.action';
import { selectCurrentMeta } from '../../store/metaNotif/metaNotif.selector';
import { Text } from '@chakra-ui/react';

const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_KEY_PREFIX}-notifications-search`;
export const NotificationSearch = () => {
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const { storedData } = useSessionStorage<FormSchema>(LOCAL_STORAGE_KEY);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const { prevLocation } = useNavigationContext();
  const restoreValues =
    !prevLocation?.pathname ||
    prevLocation?.pathname === ROUTES.NOTIFICATIONS_SEARCH_RESULTS ||
    prevLocation?.pathname === ROUTES.NOTIFICATIONS_SEARCH ||
    prevLocation?.pathname.startsWith(ROUTES.NOTIFICATIONS_SEARCH_RESULTS.split(':')[0]);
  const ref = useRef(false);
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));

    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const response = await axiosInstance.get<NotificationMetadataResponse>(
              `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_METADATA)}`,
            );
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
            setTitleMessage(titleMessage);
            setMessages(messages);
            if (titleMessage !== 'Error' && (response.status === 200 || response.status === 201)) {
              const formMetaData = convertSapMetadata(response.data);
              dispatch(setCurrentMeta(formMetaData));
            }
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
        return () => {
          ref.current = true;
        };
      };
      fetchTableMetadata();
    }
  }, []);

  const schema = z
    .object({
      FunctLocation: z.string().optional(),
      RequestorName: z.string().optional(),
      SortField: z
        .string()
        .max(metadata['SortField'] ? metadata['SortField'].INTERNAL_LENGTH : 0)
        .optional(),
      DefenceGroup: z.string().optional(),
      RequiredEndFrom: z.string().optional(),
      RequiredEndTo: z.string().optional(),
      NotificationDesc: z
        .string()
        .max(metadata['NotificationDesc'] ? metadata['NotificationDesc'].INTERNAL_LENGTH : 0)
        .optional(),
      PlannerGroup: z.string().optional(),
      CreatedBy: z.string().optional(),
      Status: z.string().optional(),
      NotificationNoTo: z.string().optional(),
      EquipmentId: z.string().optional(),
      NotificationNoFrom: z.string().optional(),
      RequiredStartFrom: z.date().optional(),
      RequiredStartTo: z.date().optional(),
      WorkCentre: z.string().optional(),
      NotificationType: z.object({ code: z.string(), description: z.string() }).array().optional(),
      Qmgrp: z.string().optional(),
      CustCostCentre: z.string().optional(),
    })
    .and(
      z.union([
        z.object({
          RequiredStartFrom: z.date().superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
          RequiredStartTo: z.date().superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
          WorkCentre: z.string().superRefine((val, ctx) => {
            if (val === '') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
          NotificationType: z
            .object({ code: z.string(), description: z.string() })
            .array()
            .superRefine((val, ctx) => {
              if (val.length === 0) {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
          Qmgrp: z
            .string()
            .optional()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          NotificationNoFrom: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
      ]),
    );

  type FormSchema = z.infer<typeof schema>;
  const navigate = useNavigate();

  const defaultValues: FormSchema = {
    FunctLocation: '',
    RequestorName: '',
    NotificationType: [],
    SortField: '',
    DefenceGroup: '',
    WorkCentre: '',
    RequiredStartFrom: undefined,
    RequiredStartTo: undefined,
    RequiredEndFrom: '',
    RequiredEndTo: '',
    NotificationDesc: '',
    PlannerGroup: '',
    CreatedBy: '',
    Status: '',
    NotificationNoFrom: '',
    NotificationNoTo: '',
    Qmgrp: '',
    EquipmentId: '',
    CustCostCentre: '',
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    setValue,
    watch,
    trigger,
  } = useForm<FormSchema>({
    defaultValues,
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { clearStoredValues } = usePersistForm<FormSchema>({
    watch,
    localStorageKey: LOCAL_STORAGE_KEY,
  });

  useEffect(() => {
    if (restoreValues && storedData) {
      Object.keys(storedData).forEach((key) => {
        const data = storedData?.[key] as any;
        if (!isNil(data)) {
          // @ts-ignore
          // const isDate = new Date(data) !== 'Invalid Date' && !isNaN(new Date(data));
          const value = isDate(data) ? new Date(data) : data;
          setValue(key as keyof FormSchema, (value as never) || '');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!restoreValues) {
      clearStoredValues();
    }
  }, [restoreValues, clearStoredValues]);

  const onSubmit = async (data: FormSchema) => {
    //const results = convertData(data);
    let formDataQuery = convertFormData(data);
    formDataQuery = formDataQuery.replace('RequiredStart ge', 'RequiredStartFrom ge');
    formDataQuery = formDataQuery.replace('RequiredStart le', 'RequiredStartFrom le');
    //begin of special request if no "to"
    if (!formDataQuery.includes('NotificationNo le') && formDataQuery.includes('NotificationNo ge')) {
      formDataQuery = formDataQuery.replace('NotificationNo ge', 'NotificationNo eq');
    }
    //end of special request if no "to"
    try {
      setLoading(true);
      const responseData = await axiosInstance.get<SearchNotificationResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_SEARCH)}?$filter=${formDataQuery}&$format=json`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
      setTitleMessage(titleMessage);
      setMessages(messages);
      setLoading(false);

      if (titleMessage !== 'Error') {
        navigate(ROUTES.NOTIFICATIONS_SEARCH_RESULTS, {
          state: { data: responseData.data },
        });
      }
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Service Request Details',
      fields: [
        'NotificationNoFrom',
        'NotificationNoTo',
        'RequiredStartFrom',
        'RequiredStartTo',
        'WorkCentre',
        'NotificationType',
        'FunctLocation',
        'FunctLocation',
        'RequestorName',
        'DefenceGroup',
      ],
    },
    {
      code: 2,
      name: 'Additional Details',
      fields: [
        'Status',
        'NotificationDesc',
        'Type',
        'CustCostCentre',
        'CreatedBy',
        'PlannerGroup',
        'ContactRegion',
        'Qmgrp',
        'EquipmentId',
      ],
    },
  ];
  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Search Service Requests',
      }}
      bannerProps={{
        title: 'Search and/or Update Service Request',
      }}
      submenuProps={{
        title: 'Service Requests',
        selectedIndex: '0',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage === 'Error' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                    ? FormStepperEnum.Complete
                    : FormStepperEnum.Upcoming,
              }))}
            />
          </div>

          <div className="w-full pt-3">
            <SubBanner
              title={sections[step - 1].name}
              info="To find the notification you are looking for, please enter the relevant information below and click *submit*. The fields marked with an * are mandatory."
            />
          </div>
          <Text mb="1rem" color="redalert">
            **The search will only cover the last 2 years of data. For more, please use the bulk functionality.
          </Text>
          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit, goToErrors)}>
              {step == 1 && (
                <div className="mb-4 w-full rounded-md bg-white">
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Service Request Number From"
                        fieldPlaceholder=" "
                        name="NotificationNoFrom"
                        control={control}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Service Request Number To"
                        fieldPlaceholder=" "
                        name="NotificationNoTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Request Start Date From"
                        fieldPlaceholder="From Date"
                        name="RequiredStartFrom"
                        control={control}
                        required={true}
                      />
                      <DateInput
                        fieldLabel="Request Start Date To"
                        fieldPlaceholder="To Date"
                        name="RequiredStartTo"
                        control={control}
                        required={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                        fieldLabel="Request Work Centre"
                        fieldPlaceholder=" "
                        name="WorkCentre"
                        control={control}
                        required={true}
                      />

                      <MultiSelectInput
                        items={metadata['NotificationType'] ? JSON.parse(metadata['NotificationType'].FVALUE) : []}
                        fieldLabel="Service Request Type"
                        fieldPlaceholder="Type"
                        name="NotificationType"
                        control={control}
                        required={true}
                        multiple={true}
                        includeCode={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Functional Location"
                        fieldPlaceholder="Start typing"
                        name="FunctLocation"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Floc}
                        top="10"
                        codeSetValueField="Floc"
                      />
                      <TextInput
                        fieldLabel="Sort field / EBI"
                        fieldPlaceholder=" "
                        name="SortField"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Requestor Name"
                        fieldPlaceholder="Start typing"
                        name="RequestorName"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.UserID}
                        top="10"
                        codeSetValueField="UserID"
                        codeSetLabelField="FullName"
                      />

                      <SingleSelectInput
                        items={metadata['DefenceGroup'] ? JSON.parse(metadata['DefenceGroup'].FVALUE) : []}
                        fieldLabel="Defence Group"
                        fieldPlaceholder="Defence Group"
                        name="DefenceGroup"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.CodeGroup}
                        codeSetLabelField="ShortText"
                        codeSetValueField="CodeGroup"
                        codeDepFilterField="NotificationType"
                        fieldLabel="Code Group"
                        fieldPlaceholder=" "
                        name="Qmgrp"
                        control={control}
                        required={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              {step == 2 && (
                <div className="mb-4 w-full rounded-md bg-white">
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.SystemStatus}
                        codeSetLabelField="DisplayValue"
                        codeSetValueField="SystemStatus"
                        fieldLabel="System Status"
                        fieldPlaceholder=" "
                        name="SystemStatus"
                        control={control}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Service Request Description"
                        fieldPlaceholder="Notification Description"
                        name="NotificationDesc"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Customer Cost Center"
                        fieldPlaceholder="Start typing"
                        name="CustCostCentre"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.CostCentre}
                        top="10"
                        codeSetValueField="CostCenter"
                        codeSetLabelField="ShortText"
                      />
                      <InputTypeAhead
                        fieldLabel="Equipment"
                        fieldPlaceholder="Start typing"
                        name="EquipmentId"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Equip}
                        top="10"
                        codeSetValueField="EquipmentId"
                        codeSetLabelField="Description"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Created By"
                        fieldPlaceholder=" "
                        name="CreatedBy"
                        control={control}
                        required={false}
                      />

                      <SingleSelectInput
                        items={metadata['PlannerGroup'] ? JSON.parse(metadata['PlannerGroup'].FVALUE) : []}
                        fieldLabel="Planner Group"
                        fieldPlaceholder="Planner Group"
                        name="PlannerGroup"
                        control={control}
                        required={false}
                      />
                    </div>
                    {/* <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={metadata['ContactRegion'] ? JSON.parse(metadata['ContactRegion'].FVALUE) : []}
                        fieldLabel="State/Region"
                        fieldPlaceholder="State/Region"
                        name="ContactRegion"
                        control={control}
                        required={false}
                      />
                    </div> */}
                  </div>
                </div>
              )}
              <div className="flex w-full justify-end">
                <div className="grid grid-cols-2">
                  {!(step === 1) && (
                    <button
                      type="button"
                      onClick={() => {
                        backStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Back
                    </button>
                  )}
                  {!(step === sections.length) && (
                    <button
                      type="button"
                      onClick={() => {
                        nextStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Next
                    </button>
                  )}
                  <button
                    type="submit"
                    onClick={() => {
                      trigger(['NotificationNoFrom', 'RequiredStartTo', 'RequiredStartFrom', 'WorkCentre']);
                    }}
                    className="button-primary mr-0 bg-defence-orange-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
