import * as z from 'zod';

export const workOrderFormSchema = z.object({
  /* header Data */
  OrderType: z.string().optional(),
  WorkOrderNo: z.string().optional(),
  Priority: z.string().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
      });
    }
  }),
  Description: z.string().max(40).optional(),
  RefWorkOrderDesc: z.string().optional(),
  RefWorkOrder: z.string().optional(),
  WorkOrderLongText: z.string().optional(),
  WorkOrderLongTextDisplay: z.string().optional(),
  SystemStatus: z.string().optional(),
  UserStatus: z.string().optional(),
  UserStatusSet: z.object({
    results: z
      .object({
        ActiveIndicator: z.string().optional(),
        UserStatusCode: z.string().optional(),
        UserStatusDisplayCode: z.string().optional(),
        UserStatusName: z.string().optional(),
        WorkorderNo: z.string().optional(),
        code: z.string().optional(),
        description: z.string().optional(),
      })
      .array(),
  }),
  RevnrRevision: z.string().optional(),
  FunctionalLocation: z.string().optional(),
  EquipmentId: z.string().optional(),
  PlannerGroup: z.string().optional(),
  PlannerGroupDesc: z.string().optional(),
  MainWorkCentre: z.string().optional(),
  ActualCost: z.string().optional(),
  PotentialHarm: z.string().optional(),

  DIRSet: z.object({
    results: z
      .object({
        DocumentNo: z.string().optional(),
        DocumentDesc: z.string().optional(),
      })
      .array()
      .optional(),
  }),
  /* Operations */

  ToOperation: z.object({
    results: z
      .object({
        WorkOrderNo: z.string().optional(),
        ActionCode: z.string().optional(),
        OperationNo: z.string().optional(),
        ShortText: z.string().optional(),
        ControlKey: z
          .string()
          .trim()
          .superRefine((val, ctx) => {
            if (!val) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
              });
            }
          }),
        Plant: z.string().optional(),
        WorkCenter: z.string().optional(),
        Quantity: z.string().optional(),
        MaterialGroup: z.string().optional(),
        PurchasingGroup: z.string().optional(),
        PurchaseOrder: z.string().optional(),
        PurchaseOrderitem: z.string().optional(),
        Work: z.string().optional(),
        WorkUnit: z.string().optional(),
        ToService: z.object({
          results: z
            .object({
              GrossPrice: z.string().optional(),
              ActionCode: z.string().optional(),
              Description: z.string().optional(),
              ExternalServiceNo: z.string().optional(),
              ServiceNo: z
                .string()
                .trim()
                .superRefine((val, ctx) => {
                  if (!val) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                    });
                  }
                }),
              LineNo: z
                .string()
                .trim()
                .superRefine((val, ctx) => {
                  if (!val) {
                    ctx.addIssue({
                      code: z.ZodIssueCode.custom,
                    });
                  }
                }),
              Location: z.string().optional(),
              NetValue: z.string().optional(),
              OperationNo: z.string().optional(),
              PriceUnit: z.string().optional(),
              Quantity: z.string().optional(),
              WorkOrderNo: z.string().optional(),
            })
            .array(),
        }),
        ToRelationship: z.object({
          results: z
            .object({
              OrderPredecessor: z.string().optional(),
              OperationPredecessor: z.string().optional(),
              OrderSuccessor: z.string().optional(),
              OperationSuccessor: z.string().optional(),
              RelationshipType: z.string().optional(),
              TimeInterval: z.string().optional(),
              TimeUnit: z.string().optional(),
              ActionCode: z.string().optional(),
            })
            .array(),
        }),
      })

      .array(),
  }),
  /* Scheduling details */
  ScheduleStartDateTime: z.date().optional().nullable(),
  ScheduleFinishDateTime: z.date().optional().nullable(),
  BasicStartDateTime: z.date().optional().nullable(),
  BasicFinishDateTime: z.date().optional().nullable(),

  /* Additional Details*/
  DefenceGroup: z.string().optional(),
  CustCostCentre: z.string().optional(),
  DefenceGroupName: z.string().optional(),
  CustomerWbs: z.string().optional(),
  CustomerAsset: z.string().optional(),
  CustomerSubAsset: z.string().optional(),
  RefNotificationNo: z.string().optional(),
  NotificationNo: z.string().trim().optional(),
  FunctionalParentECID: z.string().optional(),
  EstateClassID: z.string().optional(),
  EUPricingID1: z.string().optional(),
  EUPricingID2: z.string().optional(),

  IntegrityAssessed: z.string().optional(),
  FunctionalityAssessed: z.string().optional(),
  AssIntegrityDesc: z.string().optional(),
  AssignedFunctionalityDesc: z.string().optional(),
  ComplianceIssue: z.boolean().optional(),
  ComplianceType: z.string().optional(),
  NoncompConsequence: z.string().optional(),
  ValueMoneyConsequence: z.string().optional(),
  NonCompScore: z.string().optional(),

  IntendedYear: z.string().optional(),
  SafetyImpact: z.boolean().optional(),
  PotentialHazard: z.string().optional(),
  WHSConsequence: z.string().optional(),
  WHSLikelihood: z.string().optional(),
  RiskLevel: z.string().optional(),
  DeltaIntegrity: z.string().optional(),
  DeltaFunctionality: z.string().optional(),
  Teco: z.boolean().optional(),
  CancelTeco: z.boolean().optional(),
  CloseWo: z.boolean().optional(),
  SetDelete: z.boolean().optional(),
  ResetDelete: z.boolean().optional(),
  Closed: z.boolean().optional(),
  CategoryWeightingIntegrity: z.string().optional(),
  CategoryWeightingFunctionality: z.string().optional(),
  CategoryWeightingCompliance: z.string().optional(),
  CategoryWeightingValueForMoney: z.string().optional(),
  MaintenanceType: z.string().superRefine((val, ctx) => {
    if (!val) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
      });
    }
  }),
  SystemStatusSet: z.object({
    results: z
      .object({
        ActiveIndicator: z.string().optional(),
        SystemStatusCode: z.string().optional(),
        SystemStatusDisplayCode: z.string().optional(),
        SystemStatusName: z.string().optional(),
        WorkorderNo: z.string().optional(),
      })
      .array(),
  }),
  Etag: z.string().optional(),
  PlanningPlant: z.string().optional(),
  Plant: z.string().optional(),
  CurrentUse: z.string().optional(),
  Criticality: z.string().optional(),
  AssignedIntegrity: z.string().optional(),
  CustCompanyCode: z.string().optional(),
});

export type TWorkOrderFormSchema = z.infer<typeof workOrderFormSchema>;
