import React from "react";
import { ExclamationTriangleIcon } from "@radix-ui/react-icons";

export const ErrorBanner = ({ children }: { children: any }) => {
  return (
    <>
      <div className="flex w-full gap-3 bg-defence-redAlert bg-opacity-50 p-4 text-xl hover:bg-opacity-80 hover:duration-100 hover:ease-in ">
        <ExclamationTriangleIcon width={30} height={30} color="white" />
        {children}
      </div>
    </>
  );
};
