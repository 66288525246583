import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { Banner } from '../../components/Banner';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { LOCAL_STORAGE_KEY_PREFIX, ROUTES } from '../../constants';
import { useNavigationContext, usePersistForm, useSessionStorage } from '../../hooks';
import { MultiSelectInput } from '../../components/form/inputs/multiSelectInput';
import { ReadMultipleWorkOrderConfirmationsResponse, WorkOrderMetadataResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import { TextInput } from '../../components/form/inputs/textInput';
import convertFormData from '../../lib/convertFormData';
import convertSapMetadata from '../../lib/convertMetadata';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaSes/metaSes.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';
import { isDate } from '../../lib/date';

const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_KEY_PREFIX}-confirmations-search`;

export const ConfirmationSearch = () => {
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const { storedData } = useSessionStorage<FormSchema>(LOCAL_STORAGE_KEY);
  const [loading, setLoading] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const { prevLocation } = useNavigationContext();
  const restoreValues =
    !prevLocation?.pathname ||
    prevLocation?.pathname === ROUTES.CONFIRMATIONS_SEARCH ||
    prevLocation?.pathname === ROUTES.CONFIRMATIONS_SEARCH_RESULTS;

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const response = await axiosInstance.get<WorkOrderMetadataResponse>(
              `${getAPIUrl(process.env.REACT_APP_EE_WO_METADATA)}`,
            );
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
            setTitleMessage(titleMessage);
            setMessages(messages);
            if (titleMessage !== 'Error' && (response.status === 200 || response.status === 201)) {
              const formMetaData = convertSapMetadata(response.data);
              dispatch(setCurrentMeta(formMetaData));
            }
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
        return () => {
          ref.current = true;
        };
      };
      fetchTableMetadata();
    }
  }, []);


  const schema = z
    .object({
      OrderIdFrom: z
        .string()
        .max(metadata['WorkOrderNo'] ? metadata['WorkOrderNo'].LENGTH : 12)
        .optional(),
      OrderIdTo: z
        .string()
        .max(metadata['WorkOrderNo'] ? metadata['WorkOrderNo'].LENGTH : 12)
        .optional(),
      ActualStartFrom: z.date().optional(),
      ActualStartTo: z.date().optional(),
      ActualEndFrom: z.date().optional(),
      ActualEndTo: z.date().optional(),
      PostingDateFrom: z.date().optional(),
      PostingDateTo: z.date().optional(),
      ConfCnt: z.string().optional(),
      ConfNoFrom: z.string().optional(),
      ConfNoTo: z.string().optional(),
      CreatedBy: z.string().optional(),
      MaintenancetActivityType: z.string().optional(),
      NoCancellations: z.any().optional(),
      WorkCentre: z.string().optional(),
      OrderStatus: z
        .object({ code: z.string(), description: z.string() })
        .array().optional(),
    })
    .and(
      z.union([
        z.object({
          OrderStatus: z
            .object({ code: z.string(), description: z.string() })
            .array()
            .superRefine((val, ctx) => {
              if (val[0] === undefined || val[0].code === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
          PostingDateFrom: z.date().superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
          PostingDateTo: z.date().superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
        }),
        z.object({
          ConfNoFrom: z
            .string()
            .max(metadata['ConfirmationNo'] ? metadata['ConfirmationNo'].LENGTH : 10)
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
      ]),
    );

  type FormSchema = z.infer<typeof schema>;
  const navigate = useNavigate();

  const defaultValues: FormSchema = {
    ConfNoFrom: '',
    ConfNoTo: '',
    OrderIdFrom: '',
    OrderIdTo: '',
    WorkCentre: '',
    OrderStatus: [],
    NoCancellations: '',
    ActualStartFrom: undefined,
    ActualStartTo: undefined,
    ActualEndFrom: undefined,
    ActualEndTo: undefined,
    PostingDateFrom: undefined,
    PostingDateTo: undefined,
    ConfCnt: '',
    CreatedBy: '',
    MaintenancetActivityType: '',
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { clearStoredValues } = usePersistForm<FormSchema>({
    watch,
    localStorageKey: LOCAL_STORAGE_KEY,
  });

  useEffect(() => {
    if (restoreValues && storedData) {
      Object.keys(storedData).forEach((key) => {
        const data = storedData?.[key] as any;
        if (!isNil(data)) {
          // @ts-ignore
          // const isDate = new Date(data) !== 'Invalid Date' && !isNaN(new Date(data));
          const value = isDate(data) ? new Date(data) : data;
          setValue(key as keyof FormSchema, (value as never) || '');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!restoreValues) {
      clearStoredValues();
    }
  }, [restoreValues, clearStoredValues]);

  const onSubmit = async (data: FormSchema) => {
    let formDataQuery = convertFormData(data);
    formDataQuery = formDataQuery.replace("NoCancellations eq 'No'", 'NoCancellations eq false');
    formDataQuery = formDataQuery.replace("NoCancellations eq 'Yes'", 'NoCancellations eq true');
    if (!formDataQuery.includes('ConfNo le') && formDataQuery.includes('ConfNo ge')) {
      formDataQuery = formDataQuery.replace('ConfNo ge', 'ConfNo eq');
    }
    try {
      setLoading(true);
      const responseData = await axiosInstance.get<ReadMultipleWorkOrderConfirmationsResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_CONF_MULTI_REQ_LIST)}?$filter=(${formDataQuery})&$format=json`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
      setTitleMessage(titleMessage);
      setMessages(messages);
      setLoading(false);
      if (titleMessage !== 'Error') {
        navigate(ROUTES.CONFIRMATIONS_SEARCH_RESULTS, {
          state: { data: responseData.data },
        });
      }
    } catch (error) {
      setLoading(false);
      const errorResponse = isAxiosError(error) ? error.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Confirmation Details',
      fields: [
        'ConfNoFrom',
        'ConfNoTo',
        'OrderStatus',
        'PostingDateFrom',
        'PostingDateTo',
        'OrderIdFrom',
        'OrderIdTo',
        'WorkCentre',
        'NoCancellations',
      ],
    },
    {
      code: 2,
      name: 'Additional Details',
      fields: [
        'ActualStartFrom',
        'ActualStartTo',
        'ActualEndFrom',
        'ActualEndTo',
        'MaintenancetActivityType',
        'ConfCnt',
        'CreatedBy',
      ],
    },
  ];

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <>
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <SubMenu title="Confirmations" selectedIndex="0" />
      <Banner title="Search and/or Display Confirmations" description="" bannerImgSrc="" />
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      The <strong>Mandatory</strong> field has not been completed. Please update and resubmit.
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          {sections.length > 1 && (
            <div className="relative">
              <FormStepper
                onChange={(item: FormStepperItem) => {
                  setStep(item.code);
                }}
                items={sections?.map((section) => ({
                  code: section.code,
                  name: section.name,
                  href: '#',
                  status:
                    step === section.code
                      ? FormStepperEnum.Current
                      : step > section.code
                        ? FormStepperEnum.Complete
                        : FormStepperEnum.Upcoming,
                }))}
              />
            </div>
          )}
          <div className="w-full pt-3">
            <SubBanner
              title={sections[step - 1].name}
              info="To find the confirmations you are looking for, please enter the relevant information below and click *submit*. The fields marked with an * are mandatory."
            />
          </div>
          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit, goToErrors)}>
              {step === 1 && (
                <div className="mb-8 w-full">
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Confirmation Number From"
                        fieldPlaceholder=" "
                        name="ConfNoFrom"
                        control={control}
                      />
                      <TextInput
                        fieldLabel="Confirmation Number To"
                        fieldPlaceholder=" "
                        name="ConfNoTo"
                        control={control}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Order Number From"
                        fieldPlaceholder="Start typing"
                        name="OrderIdFrom"
                        control={control}
                        codeSet={TypeAheadCodeSet.Wo}
                        top="20"
                        codeSetValueField="WorkOrder"
                      />
                      <InputTypeAhead
                        fieldLabel="Order Number To"
                        fieldPlaceholder="Start typing"
                        name="OrderIdTo"
                        control={control}
                        codeSet={TypeAheadCodeSet.Wo}
                        top="20"
                        codeSetValueField="WorkOrder"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <MultiSelectInput
                        items={
                          metadata['OrderStatus']?.FVALUE
                            ? JSON.parse(metadata['OrderStatus'].FVALUE)
                            : [
                              { code: '0', description: 'In Process' },
                              {
                                code: '1',
                                description: 'Completed',
                              },
                            ]
                        }
                        fieldLabel="Order Status"
                        fieldPlaceholder=" "
                        name="OrderStatus"
                        control={control}
                        multiple={true}
                        required={true}
                      />
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.Workcenter}
                        codeSetLabelField="Description"
                        codeSetValueField="WorkCenter"
                        fieldLabel="Work Center"
                        fieldPlaceholder="Please Select Work Center"
                        name="WorkCentre"
                        control={control}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Posting Date From"
                        fieldPlaceholder="From Date"
                        name="PostingDateFrom"
                        control={control}
                        required={true}
                      />
                      <DateInput
                        fieldLabel="Posting Date To"
                        fieldPlaceholder="To Date"
                        name="PostingDateTo"
                        control={control}
                        required={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={[
                          { code: 'No', description: 'No' },
                          {
                            code: 'Yes',
                            description: 'Yes',
                          },
                        ]}
                        includeCode={false}
                        fieldLabel="No Cancels"
                        fieldPlaceholder=" "
                        name="NoCancellations"
                        control={control}
                      />
                    </div>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="mb-4 w-full rounded-md bg-white">
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Actual Start From"
                        fieldPlaceholder="From Date"
                        name="ActualStartFrom"
                        control={control}
                        required={false}
                      />
                      <DateInput
                        fieldLabel="Actual Start To"
                        fieldPlaceholder="To Date"
                        name="ActualStartTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Actual End From"
                        fieldPlaceholder="From Date"
                        name="ActualEndFrom"
                        control={control}
                        required={false}
                      />
                      <DateInput
                        fieldLabel="Actual End To"
                        fieldPlaceholder="To Date"
                        name="ActualEndTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.MaintActType}
                        codeSetLabelField="MaintActTypeDesc"
                        codeSetValueField="MaintActType"
                        fieldLabel="Maintenance Activity Type"
                        fieldPlaceholder=" "
                        name="MaintenanceType"
                        control={control}
                        required={false}
                      />

                      <InputTypeAhead
                        fieldLabel="Created By"
                        fieldPlaceholder="Start typing"
                        name="CreatedBy"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.UserID}
                        codeSetValueField="UserID"
                        codeSetLabelField="FullName"
                        top="20"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex w-full justify-end">
                <div className="flex-end flex">
                  {!(step === 1) && (
                    <button
                      type="button"
                      onClick={() => {
                        backStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Back
                    </button>
                  )}
                  {!(step === sections.length) && (
                    <button
                      type="button"
                      onClick={() => {
                        nextStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Next
                    </button>
                  )}
                  <button type="submit" onClick={() => { }} className="button-primary mr-0 bg-defence-orange-500">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
