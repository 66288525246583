const formatMessagesToDisplay = function (response: any, sMsg?: any[]) {
  let errorMessages: string[];
  // data.error - property message is blank then sap error else display msg
  // data.soaErr - read errorMessage property
  if (response?.status === 200 || response?.status === 201) {
    if (!(response?.data?.error || response?.data?.soaErr )) {
      // error object is not present or is undefined
      if(response?.data?.d?.Message){
        //check for returned success messages
      sMsg = sMsg ? sMsg : [response?.data?.d?.Message];
      }      
      return { title : 'Success' , message : sMsg??[]};
    } else{
      // error object is present in node : error or soaErr
      if(response?.data?.error?.innererror){
        errorMessages = response.data.error.innererror.errordetails.map(e => e.message);
      }else if(response?.data?.soaErr){
        errorMessages = [ response?.data?.soaErr?.errorMessage ?? "Unknown internal server error occured" ];
      }else{
        errorMessages = ['Unknown internal server error occured'];
      }
      return { title : 'Error' , message : errorMessages };
    }                    
  } else {
    return { title : 'Error', message : sMsg ?? ['Unknown internal server error occured'] };
  }
};
export default formatMessagesToDisplay;
