import { request } from '../../../../../helper/useAxios';
import { appConstants } from '../../../../../helper/client/constant';

const data = {
  caseTypeID: 'DoD-SConn-Work-ConsentManagement',
  content: {
    pyRequestType: 'DSAS',
  },
};

export const getAssignments = (caseID) => request(appConstants.API_ASSIGNMENTS, 'get', { id: caseID });
export const captureConsents = (caseID) =>
  request(appConstants.API_ASSIGNMENTS, 'get', { id: caseID + '/actions/CaptureConsents' }).then((res) => {
    return res.data;
  });

export const createCase = () => {
  return new Promise((resolve) => {
    request(appConstants.API_CASES, 'post', {
      data,
    }).then((res) => {
      getAssignments(res.data.nextAssignmentID).then(() => {
        captureConsents(res.data.nextAssignmentID).then((res) => {
          resolve(res);
        });
      });
    });
  });
};
