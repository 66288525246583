import * as z from 'zod';

export const notificationFormSchema = z
  .object({
    //Notification Details
    Etag: z.string().optional(),
    NotificationNo: z.string().optional(),
    NotificationType: z.string().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    // NotificationType: z.string(),
    SystemStatus: z.string().optional(),
    UserStatus: z.string().optional(),
    NotificationDesc: z.string().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    WorkOrder: z.string().optional(),
    ReportedBy: z.string().optional(),

    //Job Details
    // FunctLocation: z.string().superRefine((val, ctx) => {
    //   if (val === "") {
    //     ctx.addIssue({
    //       code: z.ZodIssueCode.custom,
    //     });
    //   }
    // }),
    FunctLocationDesc: z.string().optional(),
    // EquipmentId: z.string().superRefine((val, ctx) => {
    //   if (val === "") {
    //     ctx.addIssue({
    //       code: z.ZodIssueCode.custom,
    //     });
    //   }
    // }),
    EquipmentDesc: z.string().optional(),
    // PlannerGroup: z.string(),
    PlannerGroup: z.string().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    // WorkCentre: z.string(),
    MainWorkCentre: z.string().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    MainWorkCentrePlant: z.string().optional(),
    //Coding: z.string().optional(),
    NotificationLongText: z.string().optional(),
    NotificationLongTextDisplay: z.string().optional(),

    //Additional Data
    NotificationPriority: z.string().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    NotificationPriorityDesc: z.string().optional(),
    RequiredStartDateTime: z.date().optional(),
    RequiredEndDateTime: z.date().optional(),
    MalfunctionStart: z.date().optional(),
    MalfunctionEnd: z.date().optional(),
    DurationUom: z.string().optional(),
    BreakdownIndicator: z.any().optional(),
    CodeGroup: z.string().optional(),
    CodeGroupCoding: z.string().optional(),
    NotifToItem: z.object({
      results: z
        .object({
          NotificationNo: z.string().optional(),
          ItemNo: z.string().optional(),
          CauseText: z.string().optional(),
          Actioncode: z.string().optional(),
          CauseCodeCoding: z.string().optional(),
          CauseCodeGroup: z.string().optional(),
          CauseCodeDesc: z.string().optional(),
          // ItemToCause: z.object({
          //   results: z
          //   .object({NotificationNo: z.string().optional(),})
          //   .array().optional()
          // }).optional(),
        })
        .array()
        .optional(),
    }),
    NotifToDir: z.object({
      results: z
        .object({
          DocumentNo: z.string().optional(),
          DocumentDesc: z.string().optional(),
        })
        .array()
        .optional(),
    }),
    NotifToUserStatus: z
      .object({
        results: z
          .object({
            ActiveIndicator: z.string().optional(),
            DisplayCode: z.string().optional(),
            Name: z.string().optional(),
            NotificationNo: z.string().optional(),
            StatusCode: z.string().optional(),
          })
          .array()
          .optional(),
      })
      .optional(),

    //Customer details
    RequestorName: z.string().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    RequestorTelephone: z.string().optional(),
    RequestorEmail: z.string().optional(),
    RequestorMobile: z.string().optional(),
    DefenceGroup: z.string().superRefine((val, ctx) => {
      if (!val) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    CustomerCostCentre: z.string().optional(),
    CustomerWbs: z.string().optional(),
    CustomerAsset: z.string().optional(),
    CustomerSubAsset: z.string().optional(),

    //site contact
    ContactName: z.string().max(40).optional(),
    ContactHouseNo: z.string().max(10).optional(),
    ContactStreet: z.string().max(60).optional(),
    ContactTelephone: z.string().max(30).optional(),
    ContactFax: z.string().max(30).optional(),
    ContactPostalCode: z.string().max(10).optional(),
    ContactCity: z.string().max(40).optional(),
    ContactRegion: z.string().max(3).optional(),
    ContactCountry: z.string().max(3).optional(),
    InProcess: z.boolean().optional(), //z.string().optional(),
    SetDelete: z.boolean().optional(),
    Reset: z.boolean().optional(),
    Complete: z.boolean().optional(),
    Postpone: z.boolean().optional(),
    CustCompanyCode: z.string().optional(),
  })
  .and(
    z.union([
      z.object({
        FunctLocation: z
          .string()
          .trim()
          .superRefine((val, ctx) => {
            if (!val) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
      }),
      z.object({
        EquipmentNo: z
          .string()
          .trim()
          .superRefine((val, ctx) => {
            if (!val) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
      }),
    ]),
  );

export type TNotificationFormSchema = z.infer<typeof notificationFormSchema>;
