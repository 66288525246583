import React from 'react';
import { ModalHeader, ModalBody, Text, Flex, Heading } from '@chakra-ui/react';
import { Link } from '@chakra-ui/react';
export const DSAS = () => {
  return (
    <>
      <ModalHeader>
        <Heading as="h2" fontSize="lg" fontWeight="medium">
          Defence and Department of Veterans’ Affairs (DVA) Data Sharing and Analytics Solution (DSAS) Consent Form
        </Heading>
        <Text mb="1rem" fontWeight="light">
          Use this section to view and manage your DSAS data sharing consent.
        </Text>
        <Heading as="h3" fontSize="sm" fontWeight="medium">
          What is the Defence and Department of Veterans’ Affairs (DVA) Data Sharing and Analytics Solution (DSAS)
          consent form used for?
        </Heading>
        <Text fontWeight="light" fontSize="regular">
          OFFICIAL
        </Text>
      </ModalHeader>
      <ModalBody as={Flex} gap={3} flexDirection="column" fontWeight="light">
        <Flex gap={2} flexDirection="column">
          <Text fontWeight="medium" m={0}>
            Description
          </Text>
          <Text>
            This form ensures your consent decision is recorded for the Defence and Department of Veterans’ Affairs
            (DVA) Data Sharing and Analytics Solution (DSAS).
          </Text>
          <Text>
            Use this form to provide or withdraw your consent for Defence and the DVA to be able to collect, use and
            disclose your data for the specific purpose of the Data Sharing and Analytics Solution (DSAS).
          </Text>
          <Text>
            The DSAS links Defence data related to health, wellbeing and safety with DVA client data, to provide a
            whole-of-life view of current and former ADF members. The intent is to support optimisation of lifetime
            health, wellbeing and safety outcomes for ADF personnel.
          </Text>
          <Text>
            For more information about the DSAS, visit the{' '}
            <Link
              href="http://drnet.defence.gov.au/People/WHS/Pages/Protecting-Lifetime-Wellbeing-ADF.aspx"
              target="_blank"
            >
              Protecting Lifetime Wellbeing of ADF Personnel intranet page.
            </Link>
          </Text>
        </Flex>
        <Flex gap={2} flexDirection="column">
          <Text fontWeight="medium" m={0}>
            What happens next
          </Text>
          <Text>
            Once you select <b>Submit</b>, your form will be shared with the DSAS Project Team. You will also receive a
            copy of this form for your records. You can update your consent decision at any time by selecting this form
            from the My Data Management section in your <Link href="/serviceconnect/Profile">Profile</Link> page within{' '}
            <Link href="https://internalportal.serviceconnect.defence.gov.au/">ServiceConnect.</Link>
          </Text>
        </Flex>
        <Flex gap={2} flexDirection="column">
          <Text fontWeight="medium">Contacts</Text>
          <Text>Form sponsor: Work Health and Safety (WHS) Branch</Text>
          <Text>Group: DPG</Text>
          <Text>
            Contact details: Phone <Link href="tel:1800 333 362">1800DEFENCE</Link> (1800 333 362, select 2) or email{' '}
            <Link href="mailto:yourcustomer.service@defence.gov.au">yourcustomer.service@defence.gov.au</Link>
          </Text>
        </Flex>
      </ModalBody>
    </>
  );
};
