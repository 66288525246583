import { CrossCircledIcon, DownloadIcon, FileTextIcon, MagnifyingGlassIcon, Pencil2Icon } from '@radix-ui/react-icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseLayout } from '../../components/BaseLayout';
import { LinkCard } from '../../components/LinkCard';
import { ROUTES } from '../../constants';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';

export const WorkOrders = () => {
  useDocumentTitle(`Work Orders`);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 2 }));
  }, []);
  const quickLinkCards = [
    {
      link: ROUTES.WORK_ORDERS_SEARCH,
      description: 'You can find and display Work Order information.',
      label: 'Looking for a Work Order?',
      linkText: 'Search',
      hide: false,
      icon: <MagnifyingGlassIcon width={40} height={40} />,
    },
    {
      link: ROUTES.WORK_ORDERS_CREATE,
      description: 'You can easily create a single Work Order online without having to use the template.',
      label: 'Do you need to create a Work Order?',
      linkText: 'Create',
      hide: false,
      icon: <Pencil2Icon width={40} height={40} />,
    },
    {
      link: ROUTES.WORK_ORDERS_BULK_UPLOAD_DOWNLOAD,
      description:
        'You can access the Bulk Templates, Search and Display as well as Upload/Download Work Order Information.',
      label: 'Need to Upload/Download Work Orders?',
      linkText: 'Bulk Upload/Download',
      icon: <DownloadIcon width={40} height={40} />,
    },
    {
      link: ROUTES.WORK_ORDERS_BULK_PROGRESS_REPORT,
      description: 'You can view the various Bulk Upload/Download submissions that have been made and their status.',
      label: 'Need to view & monitor bulk load progress?',
      linkText: 'Bulk Progress Report',
      icon: <FileTextIcon width={40} height={40} />,
    },
    {
      link: ROUTES.CONFIRMATIONS_SEARCH,
      description: 'You can find and display Confirmation information.',
      label: 'Looking for a Confirmation?',
      linkText: 'Search',
      hide: false,
      icon: <MagnifyingGlassIcon width={40} height={40} />,
    },
    {
      link: ROUTES.CONFIRMATIONS_CREATE,
      description: 'You can easily create a single Confirmation online without having to use the template.',
      label: 'Do you need to create a Confirmation?',
      linkText: 'Create',
      hide: false,
      icon: <Pencil2Icon width={40} height={40} />,
    },
    {
      link: ROUTES.CONFIRMATIONS_CANCEL,
      description: 'You can easily cancel a single Confirmation online.',
      label: 'Do you need to cancel a Confirmation?',
      linkText: 'Cancel',
      hide: false,
      icon: <CrossCircledIcon width={40} height={40} />,
    },
    {
      link: ROUTES.CONFIRMATIONS_BULK_UPLOAD_DOWNLOAD,
      description:
        'You can access the Bulk Templates, Search and Display as well as Upload/Download Confirmation Information.',
      label: 'Need to Upload/Download Confirmation?',
      linkText: 'Bulk Upload/Download',
      icon: <DownloadIcon width={40} height={40} />,
    },
    {
      link: ROUTES.CONFIRMATIONS_BULK_PROGRESS_REPORT,
      description: 'You can view the various Bulk Upload/Download submissions that have been made and their status.',
      label: 'Need to view & monitor bulk load progress of Confirmation?',
      linkText: 'Bulk Progress Report',
      icon: <FileTextIcon width={40} height={40} />,
    },
  ];

  return (
    <BaseLayout
      headProps={{
        title: 'Work Orders',
      }}
      bannerProps={{
        title: 'Work Orders',
      }}
      submenuProps={{
        title: 'Work Orders',
      }}
    >
      <div className=" flex w-full">
        <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
          <p className="w-fit p-4 text-xl"></p>
          <div className="grid grid-cols-1 gap-10 px-2 sm:grid-cols-2 sm:px-4 md:my-2 md:grid-cols-2 md:gap-10 lg:grid-cols-3 xl:grid-cols-3">
            {quickLinkCards.map((card) => {
              return (
                <LinkCard
                  key={card.link}
                  showIcon={true}
                  icon={card.icon}
                  imageSrc=""
                  linkText={card.linkText}
                  link={card.link}
                  description={card.description}
                  label={card.label}
                  hide={card.hide}
                />
              );
            })}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
