/* eslint-disable jsx-a11y/anchor-is-valid */
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { FileUpload } from '../../components/form/inputs/FileUpload';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { RadioInput } from '../../components/form/inputs/radioInput';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { TextInput } from '../../components/form/inputs/textInput';
import { MeasurementDocumentDownloadReSetResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertFormData from '../../lib/convertFormData';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';

export const MeasdocBulkUploadDownload = () => {
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const schema = z
    .object({
      UploadDownload: z.string().optional(),
      MeasuringDocDateFrom: z.date().superRefine((val, ctx) => {
        if (val === undefined) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
          });
        }
      }),
      MeasuringDocDateTo: z.date().optional(),
      MeasuringPointFrom: z.string().optional(),
      MeasuringPointTo: z.string().optional(),
      MeasuringDocumentFrom: z.string().optional(),
      MeasuringDocumentTo: z.string().optional(),
      CodeGrp: z.string().optional(),
      Reversal: z.string().optional(),
      MsmtRdngByUser: z.string().optional(),
    })
    .and(
      z.union([
        z.object({
          FunctionalLocation: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          Equipment: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
      ]),
    );

  type formSchema = z.infer<typeof schema>;

  const defaultValues: formSchema = {
    UploadDownload: 'Upload',
    MeasuringPointFrom: '',
    MeasuringPointTo: '',
    FunctionalLocation: '',
    Equipment: '',
    MeasuringDocDateFrom: undefined as never,
    MeasuringDocDateTo: undefined,
    MeasuringDocumentFrom: '',
    MeasuringDocumentTo: '',
    CodeGrp: '',
    Reversal: '',
    MsmtRdngByUser: '',
  };

  const { handleSubmit, watch, control } = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: formSchema) => {
    if (data.UploadDownload === 'Download') {
      const formDataQuery = convertFormData(data);
      setLoading(true);
      const url = `${getAPIUrl(process.env.REACT_APP_EE_MEASDOC_DOWNLOAD_REQ)}?filter=(${formDataQuery})&$format=json`;
      try {
        const response = await axiosInstance.get<MeasurementDocumentDownloadReSetResponse>(url);
        setLoading(false);
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Request successfully submitted, please check Bulk Progress Report page',
          response.data?.d?.results?.[0]?.RequestId || '',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      } catch (err) {
        setLoading(false);
        const errorResponse = isAxiosError(err) ? err.response : null;
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
          (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    }
  };

  const handleTemplate = async (template: string) => {
    setLoading(true);
    const file = await axiosInstance.get(`${getAPIUrl(process.env.REACT_APP_EE_SHARE_TEMPLATE)}/${template}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(file.data);
    const link = document.createElement('a');
    link.href = url;
    let filename = file.headers['x-filename'];
    if (!filename) {
      filename = file.headers['content-disposition'].split('=')[1] || '';
      filename = filename.replace(/"/g, ''); // trim double
    }
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Bulk Upload Download Measurement Documents',
      }}
      bannerProps={{
        title: 'Bulk Upload/Download',
      }}
      submenuProps={{
        title: 'Measurement Documents',
        selectedIndex: '2',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white py-2">
        <div className="m-auto w-11/12 py-2 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          <form onSubmit={handleSubmit(onSubmit)}>
            {watch('UploadDownload') === 'Upload' && (
              <div className="py-2">
                <a
                  className="cursor-pointer text-x0 text-defence-orange-500 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTemplate(`('MEASUREMENT DOCUMENTS')`)}
                >
                  Measurement Documents template
                </a>
              </div>
            )}
            <div className="mb-4 w-full rounded-md bg-white">
              <div className="grid h-full grid-flow-row grid-cols-1 md:grid-cols-1 md:gap-1">
                <RadioInput
                  fieldLabel=" "
                  options={['Upload', 'Download']}
                  horizontal={true}
                  name="UploadDownload"
                  control={control}
                />
                {watch('UploadDownload') === 'Upload' && (
                  <div>
                    <p className="text-2xl font-bold">File Upload</p>
                    <div className="grid grid-flow-row grid-cols-1 gap-1">
                      <FileUpload
                        fieldLabel=""
                        fileType=""
                        fileMax="20 MB"
                        register=""
                        fieldname=""
                        errorMessage=""
                        validation=""
                        url={getAPIUrl(process.env.REACT_APP_EE_SHARE_UPLOAD)}
                      />
                    </div>
                  </div>
                )}
                {watch('UploadDownload') === 'Download' && (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Functional Location"
                        fieldPlaceholder="Start typing"
                        name="FunctionalLocation"
                        control={control}
                        codeSet={TypeAheadCodeSet.Floc}
                        codeSetFilterField="Description"
                        codeSetValueField="Floc"
                      />
                      <InputTypeAhead
                        fieldLabel="Equipment"
                        fieldPlaceholder="Start typing"
                        name="Equipment"
                        control={control}
                        codeSet={TypeAheadCodeSet.Equip}
                        codeSetFilterField="Description"
                        codeSetValueField="EquipmentId"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Measuring Doc Date From"
                        fieldPlaceholder="From Date"
                        name="MeasuringDocDateFrom"
                        control={control}
                        required={true}
                      />
                      <DateInput
                        fieldLabel="Measuring Doc Date To"
                        fieldPlaceholder="To Date"
                        name="MeasuringDocDateTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Measuring Point From"
                        fieldPlaceholder=" "
                        name="MeasuringPointFrom"
                        control={control}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Measuring Point To"
                        fieldPlaceholder=" "
                        name="MeasuringPointTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Measuring Doc From"
                        fieldPlaceholder=" "
                        name="MeasuringDocumentFrom"
                        control={control}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Measuring Doc To"
                        fieldPlaceholder=" "
                        name="MeasuringDocumentTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={[
                          { code: 'ZPM-FUNC', description: 'Functionality Assessment - Estate Appraisal' },
                          {
                            code: 'ZPM-INTG',
                            description: 'Integrity Assessment - Estate Appraisal',
                          },
                        ]}
                        includeCode={false}
                        fieldLabel="EA Code Group"
                        fieldPlaceholder="Please Select EA Code Group"
                        name="CodeGrp"
                        control={control}
                      />
                      <SingleSelectInput
                        items={[
                          { code: '', description: 'With or Without Reversal Indicator' },
                          {
                            code: 'YES',
                            description: 'With Reversal Indicator',
                          },
                          {
                            code: 'NO',
                            description: 'Without Reversal Indicator',
                          },
                        ]}
                        includeCode={false}
                        fieldLabel="Reversal"
                        fieldPlaceholder="Please Select Reversal Indicator"
                        name="Reversal"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Reading by User"
                        fieldPlaceholder="Start typing"
                        name="MsmtRdngByUser"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.UserID}
                        codeSetFilterField="Name"
                        codeSetValueField="UserID"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {watch('UploadDownload') === 'Download' && (
              <div className="flex w-full justify-end">
                <button
                  type="submit"
                  onClick={() => {
                    //console.log('getValues() ', getValues());
                  }}
                  className="button-primary mr-0 bg-defence-orange-500"
                >
                  Submit
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </BaseLayout>
  );
};
