export const LOCAL_STORAGE_KEY_PREFIX = 'edx';
const logo = require(`./assets/EstateExchangeLogo.png`);
export const ROUTES = {
  HOME: '/eeportal',
  SUPPORT: '/eeportal/support',
  HELP: '/help',
  ABOUT: '/serviceconnect/learnMore',
  COPYRIGHT: 'https://www.defence.gov.au/copyright',
  PRIVACY: 'https://www.defence.gov.au/about/governance/privacy-policy',
  PRIVACY_NOTICE: '/serviceconnect/privacy',
  DISCLAIMER: 'https://www.defence.gov.au/disclaimer',

  // Notifications
  NOTIFICATIONS: '/eeportal/notifications',
  NOTIFICATIONS_SEARCH: '/eeportal/notifications/search',
  NOTIFICATIONS_SEARCH_RESULTS: '/eeportal/notifications/search/results',
  NOTIFICATIONS_CREATE: '/eeportal/notifications/create',
  NOTIFICATIONS_UPDATE: '/eeportal/notifications/search/results/:id/update',
  NOTIFICATIONS_BULK_UPLOAD_DOWNLOAD: '/eeportal/notifications/bulk-download-upload',
  NOTIFICATIONS_BULK_PROGRESS_REPORT: '/eeportal/notifications/bulk-progress-report',

  // Confirmations
  CONFIRMATIONS: '/eeportal/orders/confirmations',
  CONFIRMATIONS_SEARCH: '/eeportal/orders/confirmations/search',
  CONFIRMATIONS_SEARCH_RESULTS: '/eeportal/orders/confirmations/search/results',
  CONFIRMATIONS_CREATE: '/eeportal/orders/confirmations/create',
  CONFIRMATIONS_CANCEL: '/eeportal/orders/confirmations/cancel',
  CONFIRMATIONS_READ: '/eeportal/orders/confirmations/:id/read',
  CONFIRMATIONS_BULK_UPLOAD_DOWNLOAD: '/eeportal/orders/confirmations/bulk-download-upload',
  CONFIRMATIONS_BULK_PROGRESS_REPORT: '/eeportal/orders/confirmations/bulk-progress-report',

  // Work Orders
  WORK_ORDERS: '/eeportal/orders',
  WORK_ORDERS_CREATE: '/eeportal/orders/create',
  WORK_ORDERS_SEARCH: '/eeportal/orders/search',
  WORK_ORDERS_SEARCH_RESULTS: '/eeportal/orders/search/results',
  WORK_ORDERS_UPDATE: '/eeportal/orders/search/results/:id/update',
  WORK_ORDERS_BULK_UPLOAD_DOWNLOAD: '/eeportal/orders/bulk-download-upload',
  WORK_ORDERS_BULK_PROGRESS_REPORT: '/eeportal/orders/bulk-progress-report',

  //RETRIEVAL_PURCHASE_ORDER_DOWNLOAD: '/eeportal/retrieval/purchase-order-download',

  // Service Entry Sheets
  SES: '/eeportal/ses',
  SES_CREATE: '/eeportal/ses/create',
  SES_SEARCH: '/eeportal/ses/search',
  SES_SEARCH_RESULTS: '/eeportal/ses/search/results',
  SES_UPDATE: '/eeportal/ses/search/results/:id/update',
  SES_BULK_UPLOAD_DOWNLOAD: '/eeportal/ses/bulk-download-upload',
  SES_BULK_PROGRESS_REPORT: '/eeportal/ses/bulk-progress-report',

  //Other
  OTHER: '/eeportal/other',
  RETRIEVAL: '/eeportal/other/retrieval',
  RETRIEVAL_SEARCH: '/eeportal/other/retrieval/search',
  RETRIEVAL_LIST: '/eeportal/other/retrieval/search/list',
  RETRIEVAL_LIST_HISTORY: '/eeportal/other/retrieval/search/list/:id/history',
  MEASDOC_BULK_UPLOAD_DOWNLOAD: '/eeportal/other/meas-bulk-download-upload',
  MEASDOC_BULK_PROGRESS_REPORT: '/eeportal/other/meas-bulk-progress-report',
  RFX_BULK_UPLOAD_DOWNLOAD: '/eeportal/other/rfx-bulk-download-upload',
  RFX_BULK_PROGRESS_REPORT: '/eeportal/other/rfx-bulk-progress-report',
  FLOC_BULK_UPLOAD_DOWNLOAD: '/eeportal/other/floc-bulk-download-upload',
  FLOC_BULK_PROGRESS_REPORT: '/eeportal/other/floc-bulk-progress-report',
  SERVICEMASTER_BULK_UPLOAD_DOWNLOAD: '/eeportal/other/servicemaster-bulk-download-upload',
  SERVICEMASTER_BULK_PROGRESS_REPORT: '/eeportal/other/servicemaster-bulk-progress-report',
};

export const eePortalNavProps = {
  router: true,
  logoURL: logo,
  homeURL: ROUTES.HOME,
  altText: 'Australian Government Defence - Service Connect',
  mobileLogoURL: logo,
  searchURL: '/serviceconnect/search',
  placeholder: 'Search',
  navigationItems: [
    { title: 'Home', url: ROUTES.HOME },
    {
      title: 'Service Requests',
      url: ROUTES.NOTIFICATIONS,
    },
    {
      title: 'Work Orders',
      url: ROUTES.WORK_ORDERS,
    },
    { title: 'Service Entry', url: ROUTES.SES },
    // { title: 'Other', url: ROUTES.OTHER },
    { title: 'Logout', url: '/logout' },
  ],
};
