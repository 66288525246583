import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import {
  WorkOrderMetadataResponse,
  WorkOrderRequestListSetResponse,
  WorkOrderUpdateResponse,
} from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertSapMetadata from '../../lib/convertMetadata';
import { formatDateToODataDate, formatODataDateToDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentMeta } from '../../store/metaNotif/metaNotif.action';
import { selectCurrentMeta } from '../../store/metaNotif/metaNotif.selector';
import { WorkOrderForm } from './WorkOrderForm';
import { TWorkOrderFormSchema, workOrderFormSchema } from './WorkOrderFormSchema';

export const UpdateWorkOrder = () => {
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const refMeta = useRef(false);
  const refData = useRef(false);
  const WoNo = useParams();
  const [singledata, setSingledata] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!refMeta.current) {
          const { data: TableMetadata } = await axiosInstance.get<WorkOrderMetadataResponse>(
            `${getAPIUrl(process.env.REACT_APP_EE_WO_METADATA)}`,
          );
          const formMetaData = convertSapMetadata(TableMetadata);
          dispatch(setCurrentMeta(formMetaData));
        }
      };
      fetchTableMetadata();
      return () => {
        refMeta.current = true;
      };
    }
  }, []);

  const fetchSingleWO = async (id: string) => {
    if (!refData.current && id && id !== '') {
      try {
        setLoading(true);
        let WoNo;
        try {
          if (typeof id === 'object') {
            WoNo = `ReadWorkOrder('` + Object.values(id)[0] + `')`;
          } else {
            WoNo = `ReadWorkOrder('` + id + `')`;
          }
        } catch (e) {
          WoNo = id;
        }
        const respData = await axiosInstance.get<WorkOrderRequestListSetResponse>(
          `${getAPIUrl(
            process.env.REACT_APP_EE_WO_READ,
          )}${WoNo}?$expand=ToOperation,ToOperation/ToService,ToOperation/ToRelationship,UserStatusSet,SystemStatusSet,DIRSet`,
        );
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
        setTitleMessage(titleMessage);
        setMessages(messages);
        const singleWoData = respData.data?.d as any;
        try {
          singleWoData.Teco = singleWoData.SystemStatusSet.results.some(
            (item) => item.SystemStatusDisplayCode === 'REL' && item.ActiveIndicator === 'X',
          );
          singleWoData.CancelTeco = singleWoData.SystemStatusSet.results.some(
            (item) => item.SystemStatusDisplayCode === 'TECO' && item.ActiveIndicator === 'X',
          );
          singleWoData.CloseWo = singleWoData.SystemStatusSet.results.some(
            (item) => item.SystemStatusDisplayCode === 'CRTD' && item.ActiveIndicator === 'X',
          );
          singleWoData.SetDelete =
            singleWoData.SystemStatusSet.results.some(
              (item) => item.SystemStatusDisplayCode === 'CLSD' && item.ActiveIndicator === 'X',
            ) && !singleWoData.SystemStatusSet.results.some((item) => item.SystemStatusDisplayCode === 'DLFL');

          singleWoData.ResetDelete = singleWoData.SystemStatusSet.results.some(
            (item) => item.SystemStatusDisplayCode === 'DLFL' && item.ActiveIndicator === 'X',
          );
          singleWoData.Closed = singleWoData.SystemStatusSet.results.some(
            (item) => item.SystemStatusDisplayCode === 'CLSD' && item.ActiveIndicator === 'X',
          );
        } catch (e) { }

        setSingledata(singleWoData);

        setLoading(false);
      } catch (error) {
        setLoading(false);
        const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
          (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    }
  };

  useEffect(() => {
    fetchSingleWO(WoNo as never).catch(console.error);
    return () => {
      refData.current = true;
    };
  }, [WoNo]);

  const [defaultValues, setDefaultValues] = useState<TWorkOrderFormSchema>({
    WorkOrderNo: '',
    OrderType: '',
    RevnrRevision: '',
    PlannerGroupDesc: '',
    FunctionalLocation: '',
    EquipmentId: '',
    Description: '',
    WorkOrderLongText: '',
    WorkOrderLongTextDisplay: '',
    SystemStatus: '',
    UserStatus: '',
    DIRSet: {
      results: [
        {
          DocumentNo: '',
          DocumentDesc: '',
        },
      ],
    },
    UserStatusSet: {
      results: [],
    },
    ScheduleStartDateTime: undefined,
    ScheduleFinishDateTime: undefined,
    BasicStartDateTime: undefined,
    BasicFinishDateTime: undefined,
    Priority: '',
    MainWorkCentre: '',
    ToOperation: {
      results: [],
    },
    RefWorkOrderDesc: '',
    IntegrityAssessed: '',
    FunctionalityAssessed: '',
    AssIntegrityDesc: '',
    AssignedFunctionalityDesc: '',
    ComplianceIssue: false,
    ComplianceType: '',
    NoncompConsequence: '',
    NonCompScore: '',
    ValueMoneyConsequence: '',
    IntendedYear: '',
    SafetyImpact: false,
    PotentialHazard: '',
    WHSConsequence: '',
    WHSLikelihood: '',
    RiskLevel: '',
    CustCostCentre: '',
    CustomerWbs: '',
    CustomerAsset: '',
    CustomerSubAsset: '',
    RefNotificationNo: '',
    NotificationNo: '',
    RefWorkOrder: '',
    DefenceGroup: '',
    DefenceGroupName: '',
    FunctionalParentECID: '',
    EstateClassID: '',
    EUPricingID1: '',
    EUPricingID2: '',
    ActualCost: '',
    PotentialHarm: '',
    CategoryWeightingIntegrity: '',
    CategoryWeightingFunctionality: '',
    CategoryWeightingCompliance: '',
    CategoryWeightingValueForMoney: '',
    DeltaIntegrity: '',
    DeltaFunctionality: '',
    Teco: false,
    CancelTeco: false,
    CloseWo: false,
    SetDelete: false,
    ResetDelete: false,
    Closed: false,
    MaintenanceType: '',
    SystemStatusSet: {
      results: [],
    },
    Etag: '',
    Plant: '',
    CurrentUse: '',
    Criticality: '',
    AssignedIntegrity: '',
  });

  useEffect(() => {
    setDefaultValues({
      /* header Data */
      OrderType: singledata.OrderType,
      WorkOrderNo: singledata.WorkOrderNo,
      Priority: singledata.Priority,
      RefWorkOrderDesc: singledata.RefWorkOrderDesc,
      RefWorkOrder: singledata.RefWorkOrder,
      Description: singledata.Description,
      WorkOrderLongText: '',
      WorkOrderLongTextDisplay: singledata.WorkOrderLongText,
      SystemStatus: singledata.SystemStatus,
      CustCostCentre: singledata.CustCostCentre,
      DIRSet: {
        results: singledata?.DIRSet?.results?.length
          ? singledata?.DIRSet?.results?.map((item: any) => {
            return {
              DocumentNo: item.DocumentNo,
              DocumentDesc: item.DocumentDesc,
            };
          })
          : [],
      },
      UserStatus: singledata?.UserStatusSet?.results?.filter(
        (item) => item.ActiveIndicator === 'X' && item.OrdinalNo !== '00',
      )[0].UserStatusCode,
      UserStatusSet: {
        results: singledata?.UserStatusSet?.results?.some((e: any) => e.OrdinalNo === '00')
          ? singledata?.UserStatusSet?.results?.map((item: any) => {
            return {
              ActiveIndicator: item.ActiveIndicator,
              UserStatusCode: item.UserStatusCode,
              UserStatusDisplayCode: item.UserStatusDisplayCode,
              UserStatusName: item.UserStatusName,
              OrdinalNo: item.WorkorderNo,
              code: item.UserStatusCode,
            };
          })
          : [],
      },
      RevnrRevision: singledata.RevnrRevision,
      FunctionalLocation: singledata.FunctionalLocation,
      EquipmentId: singledata.EquipmentId,
      PlannerGroup: singledata.PlannerGroup,
      PlannerGroupDesc: singledata.PlannerGroupDesc,
      MainWorkCentre: singledata.MainWorkCentre,
      ActualCost: singledata.ActualCost,
      PotentialHarm: singledata.PotentialHarm,

      /* Operations */
      ToOperation: singledata.ToOperation,
      /* Scheduling details */
      ScheduleStartDateTime: formatODataDateToDate(singledata.ScheduleStartDateTime),
      ScheduleFinishDateTime: formatODataDateToDate(singledata.ScheduleFinishDateTime),
      BasicStartDateTime: formatODataDateToDate(singledata.BasicStartDateTime),
      BasicFinishDateTime: formatODataDateToDate(singledata.BasicFinishDateTime),
      /* Additional Details*/
      DefenceGroup: singledata.DefenceGroup,
      DefenceGroupName: singledata.DefenceGroupName,
      CustomerWbs: singledata.CustomerWbs,
      CustomerAsset: singledata.CustomerAsset,
      CustomerSubAsset: singledata.CustomerSubAsset,
      RefNotificationNo: singledata.RefNotificationNo,
      NotificationNo: singledata.NotificationNo,
      FunctionalParentECID: singledata.FunctionalParentECID,
      EstateClassID: singledata.EstateClassID,
      EUPricingID1: singledata.EUPricingID1,
      EUPricingID2: singledata.EUPricingID2,

      IntegrityAssessed: singledata.IntegrityAssessed,
      FunctionalityAssessed: singledata.FunctionalityAssessed,
      AssIntegrityDesc: singledata.AssIntegrityDesc,
      AssignedFunctionalityDesc: singledata.AssignedFunctionalityDesc,
      ComplianceIssue: singledata.ComplianceIssue === '' ? false : true,
      ComplianceType: singledata.ComplianceType,
      NoncompConsequence: singledata.NoncompConsequence,
      NonCompScore: singledata.NonCompScore,
      ValueMoneyConsequence: singledata.ValueMoneyConsequence,

      IntendedYear: singledata.IntendedYear,
      SafetyImpact: singledata.SafetyImpact === 'X' ? true : false,
      PotentialHazard: singledata.PotentialHazard,
      WHSConsequence: singledata.WHSConsequence,
      WHSLikelihood: singledata.WHSLikelihood,
      RiskLevel: singledata.RiskLevel,
      DeltaIntegrity: singledata.DeltaIntegrity,
      DeltaFunctionality: singledata.DeltaFunctionality,
      Teco: singledata.Teco,
      CancelTeco: singledata.CancelTeco,
      CloseWo: singledata.CloseWo,
      SetDelete: singledata.SetDelete,
      ResetDelete: singledata.ResetDelete,
      Closed: singledata.Closed,
      CategoryWeightingIntegrity: singledata.CategoryWeightingIntegrity,
      CategoryWeightingFunctionality: singledata.CategoryWeightingFunctionality,
      CategoryWeightingCompliance: singledata.CategoryWeightingCompliance,
      CategoryWeightingValueForMoney: singledata.CategoryWeightingValueForMoney,
      MaintenanceType: singledata.MaintenanceType,
      SystemStatusSet: {
        results: singledata?.SystemStatusSet?.results?.length
          ? singledata?.SystemStatusSet?.results?.map((item: any) => {
            return {
              ActiveIndicator: item.ActiveIndicator,
              SystemStatusCode: item.SystemStatusCode,
              SystemStatusDisplayCode: item.SystemStatusDisplayCode,
              SystemStatusName: item.SystemStatusName,
              WorkorderNo: item.WorkorderNo,
            };
          })
          : [],
      },
      Etag: singledata.Etag,
      PlanningPlant: singledata.PlanningPlant,
      Plant: singledata.Plant,
      CurrentUse: singledata.CurrentUse,
      Criticality: singledata.Criticality,
      AssignedIntegrity: singledata.AssignedIntegrity,
    });
  }, [singledata]);

  const submitUpdate = async (formValues: any) => {
    const data = { ...formValues };
    setLoading(true);
    setIsSubmitting(true);
    const resultData = convertFormDates(data);
    if (resultData.CustomerAsset !== '') {
      resultData.CustCompanyCode = '1000';
    } else {
      resultData.CustCompanyCode = '';
    }
    const payloadData = updatePayloadData(resultData);
    let resultData1 = { d: payloadData };
    try {
      const response = await axiosInstance.post<WorkOrderUpdateResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_UPDATE)}`,
        resultData1,
      );
      setLoading(false);
      setIsSubmitting(false);
      if (response.status === 200 || response.status === 201) {
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'WorkOrder updated: ' + resultData.WorkOrderNo,
        ]);

        if (titleMessage !== 'Error') {
          await fetchSingleWO(resultData.WorkOrderNo);
        }
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    } catch (err) {
      setLoading(false);
      setIsSubmitting(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const convertFormDates = function (data: any) {
    const formValues = { ...data };
    try {
      Object.keys(formValues).forEach((item) => {
        if (typeof formValues[item] === 'object' && formValues[item] instanceof Date === true) {
          if (formValues[item]) {
            formValues[item] = formatDateToODataDate(formValues[item]);
          }
        }
      });
    } catch (err) {
      console.log('errors: ', err);
    }
    return formValues;
  };

  const updatePayloadData = function (data: any) {
    try {
      //process data to remove flags as they are not needed in backend.
      delete data.Teco;
      delete data.CancelTeco;
      delete data.CloseWo;
      delete data.ResetDelete;
      delete data.Closed;
      delete data.SetDelete;

      delete data.WorkOrderLongTextDisplay;

      data.ComplianceIssue = data.ComplianceIssue ? 'X' : '';
      data.SafetyImpact = data.SafetyImpact ? 'X' : '';

      // Move UserStatus in SingleDropDown UserStatus to StatusCode in the struture
      // note: since the dropdown has SAP code (E0001 etc) in UserStatus, hence we have to use it to transfer
      // if (data.UserStatusSet.results.length) {
      //   data.UserStatusSet.results[0].UserStatusCode = data.UserStatus;
      //   data.UserStatusSet.results[0].ActiveIndicator = 'X';
      //   data.UserStatusSet.results.splice(1);
      // }
      if (data.UserStatusSet.results.length) {
        for (var i in data.UserStatusSet.results) {
          data.UserStatusSet.results[i].UserStatusCode = data.UserStatusSet.results[i].code;
          data.UserStatusSet.results[i].UserStatusName = data.UserStatusSet.results[i].description;
          data.UserStatusSet.results[i].ActiveIndicator = 'X';
          delete data.UserStatusSet.results[i].code;
          delete data.UserStatusSet.results[i].description;
        }
      }
      if (data.UserStatus !== '') {
        data.UserStatusSet.results.push({
          UserStatusCode: data.UserStatus,
          ActiveIndicator: 'X',
        });
      }
      //prevent blank on decimal
      if (data.ToOperation.results.length) {
        for (var j in data.ToOperation.results) {
          if (data.ToOperation.results[j].Quantity === '') {
            data.ToOperation.results[j].Quantity = '0';
          }
          if (data.ToOperation.results[j].Work === '') {
            data.ToOperation.results[j].Work = '0';
          }
          //prevent blank action code in operation, just in case user forgot to click save at the end, but the service/relationship new data is in the buffer already (only for update operation scenario)
          if (data.ToOperation.results[j].ActionCode === '') {
            data.ToOperation.results[j].ActionCode = '02';
          }
        }
      }
    } catch (err) {
      console.log('errors: ', err);
    }
    return data;
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Update Work Order',
      }}
      bannerProps={{
        title: 'Update Work Order',
      }}
      submenuProps={{
        title: 'Work Orders',
        selectedIndex: '0',
      }}
    >
      {loading && <Spinner overlay />}
      {!loading && messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      {(!loading || isSubmitting) && (
        <div>
          <WorkOrderForm
            metadata={metadata}
            updateForm={true}
            onSubmit={submitUpdate}
            schema={workOrderFormSchema}
            defaultValues={defaultValues}
            refreshData={fetchSingleWO}
          />
        </div>
      )}
    </BaseLayout>
  );
};
