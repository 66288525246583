import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
import convertSapData from '../../lib/convertData';
import { useDispatch, useSelector } from 'react-redux';
import { Banner } from '../../components/Banner';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { TablePagination } from '../../components/TablePagination';
import { ROUTES } from '../../constants';
import { useNavigationContext } from '../../hooks';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useSessionStorage } from '../../hooks/useStorage';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';

export const ConfirmationSearchResults = () => {
  useDocumentTitle(`Read Confirmations`);

  const [data, setData] = useState([]);
  const metadata = useSelector(selectCurrentMeta);
  const location = useLocation();
  const { prevLocation } = useNavigationContext();

  const { removeStoredData, storeData, storedData } = useSessionStorage('confirmations');
  const { data: tableData } = location?.state
    ? location.state
    : {
        data: storedData,
      };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    if (tableData) {
      storeData(tableData);
      const sapdata = convertSapData(tableData);
      setData(sapdata);
    } else if (prevLocation?.pathname && prevLocation?.pathname !== ROUTES.CONFIRMATIONS_SEARCH_RESULTS) {
      removeStoredData();
    } else if (storedData) {
      setData(convertSapData(storedData));
    } else {
      setData([]);
    }
  }, [tableData, storeData, removeStoredData, prevLocation, storedData, dispatch]);

  const columnHelper = createColumnHelper<{ [key: string]: string }>();

  const columns = [
    columnHelper.accessor('CreatedTimeStamp', {
      header: () => (metadata['CreatedTimeStamp'] ? metadata['CreatedTimeStamp'].LABEL : 'Created On'),
    }),
    columnHelper.accessor('OrderId', {
      header: () => (metadata['OrderId'] ? metadata['OrderId'].LABEL : 'Order'),
    }),
    columnHelper.accessor('Operation', {
      header: () => (metadata['Operation'] ? metadata['Operation'].LABEL : 'Act'),
    }),
    columnHelper.accessor('ConfNo', {
      header: () => (metadata['ConfNo'] ? metadata['ConfNo'].LABEL : 'Confirmation'),
      cell: (info) => (
        <Link
          to={`/eeportal/orders/confirmations/${info.getValue()}/read?confirmationCounter=${info.row.getValue(
            'ConfCnt',
          )}`}
          className="text flex text-defence-orange-500 underline hover:no-underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('ConfCnt', {
      header: () => (metadata['ConfCnt'] ? metadata['ConfCnt'].LABEL : 'Counter'),
    }),
    columnHelper.accessor('Srvpos', {
      header: () => (metadata['Srvpos'] ? metadata['Srvpos'].LABEL : 'Service'),
    }),
    columnHelper.accessor('Quantity', {
      header: () => (metadata['Quantity'] ? metadata['Quantity'].LABEL : 'Quantity'),
    }),
    columnHelper.accessor('NetValue', {
      header: () => (metadata['NetValue'] ? metadata['NetValue'].LABEL : 'Net Value'),
    }),
    columnHelper.accessor('GrossPrice', {
      header: () => (metadata['GrossPrice'] ? metadata['GrossPrice'].LABEL : 'Gross Price'),
    }),
    columnHelper.accessor('ActWork', {
      header: () => (metadata['ActWork'] ? metadata['ActWork'].LABEL : 'Actual Work'),
    }),
    columnHelper.accessor('UnitWorkIso', {
      header: () => (metadata['UnitWorkIso'] ? metadata['UnitWorkIso'].LABEL : 'Unit Work'),
    }),
    columnHelper.accessor('ConfText', {
      header: () => (metadata['ConfText'] ? metadata['ConfText'].LABEL : 'Confirmation Text'),
    }),
    columnHelper.accessor('Reversed', {
      header: () => (metadata['Reversed'] ? metadata['Reversed'].LABEL : 'Reversed'),
    }),
    columnHelper.accessor('ActualStart', {
      header: () => (metadata['ActualStart'] ? metadata['ActualStart'].LABEL : 'Actual Start'),
    }),
    columnHelper.accessor('ActualEnd', {
      header: () => (metadata['ActualEnd'] ? metadata['ActualEnd'].LABEL : 'Actual End'),
    }),
    columnHelper.accessor('FinConf', {
      header: () => (metadata['FinConf'] ? metadata['FinConf'].LABEL : 'Final Confirmation'),
    }),
    columnHelper.accessor('WorkCentre', {
      header: () => (metadata['WorkCentre'] ? metadata['WorkCentre'].LABEL : 'Work Centre'),
    }),
    columnHelper.accessor('MaintenancetActivityType', {
      header: () =>
        metadata['MaintenancetActivityType'] ? metadata['MaintenancetActivityType'].LABEL : 'Activity Type',
    }),
  ];

  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: isMobile ? { pagination: { pageSize: 15 } } : { pagination: { pageSize: 30 } },
  });

  return (
    <>
      <>
        <SubMenu title="Confirmations" selectedIndex="0" />
        <Banner title="Confirmations Search Results" description="" bannerImgSrc="" />
        <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
          <SubBanner title="Confirmations Search Results" />
          <div className="">
            <div className="flex overflow-x-auto p-2">
              <table className="w-full flex-shrink-0">
                <thead className="border bg-defence-orange-500 text-left font-normal text-white">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th className="border border border-black p-2 text-x0" key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="border text-sm md:text-base">
                  {table.getRowModel().rows.map((row) => (
                    <>
                      <tr key={row.id} className="border even:bg-slate-200">
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="fontweight-light border border-black p-2 text-x0">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}
                </tbody>

                <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.footer, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
            <div className="h-2" />
            <TablePagination tableInstance={table} />
          </div>
        </div>
      </>
    </>
  );
};
