import moment from 'moment';

const convertFormData = function (data: any, mode?: string) {
  let returnFormData: string | '' = '';
  if (mode === 'Create') {
    Object.keys(data).forEach((item) => {
      if (typeof data[item] === 'object' && data[item] instanceof Date === true) {
        if (item.endsWith('From')) {
          returnFormData =
            returnFormData +
            item.slice(0, item.lastIndexOf('From')) +
            ' ge datetime' +
            "'" +
            moment(data[item]).format().split('+')[0] +
            "'" +
            ' and ';
        }
        if (item.endsWith('To')) {
          returnFormData =
            returnFormData +
            item.slice(0, item.lastIndexOf('To')) +
            ' le datetime' +
            "'" +
            moment(data[item]).format().split('+')[0] +
            "'" +
            ' and ';
        }
      } else {
        if (typeof data[item] === 'string' && (item.endsWith('From') || item.endsWith('To'))) {
          if (item.endsWith('From')) {
            returnFormData =
              returnFormData + item.slice(0, item.lastIndexOf('From')) + ' ge ' + "'" + data[item] + "'" + ' and ';
          }
          if (item.endsWith('To')) {
            returnFormData =
              returnFormData + item.slice(0, item.lastIndexOf('To')) + ' le ' + "'" + data[item] + "'" + ' and ';
          }
        } else {
          //Undefined is used for null/blank value date scenario
          if (data[item] === 'Upload' || data[item] === 'Download' || data[item] === undefined) {
            //Do Nothing or Skip; i.e, Do not add to result
          } else {
            //For Multi-Select DropDown Values
            if (typeof data[item] === 'object' && data[item] instanceof Array === true) {
              for (let i = 0; i < data[item].length; i++) {
                let value = data[item][i][Object.keys(data[item][i])[0]];
                if (data[item].length === 1) {
                  returnFormData = returnFormData + item + ' eq ' + "'" + value + "'" + ' and ';
                  break;
                }
                if (data[item].length === i + 1) {
                  returnFormData = returnFormData + item + ' eq ' + "'" + value + "'" + ' )' + ' and ';
                } else {
                  if (i === 0) {
                    returnFormData = returnFormData + '( ' + item + ' eq ' + "'" + value + "'" + ' or ';
                  } else {
                    returnFormData = returnFormData + item + ' eq ' + "'" + value + "'" + ' or ';
                  }
                }
              }
            } else {
              returnFormData = returnFormData + item + ' eq ' + "'" + data[item] + "'" + ' and ';
            }
          }
        }
      }
    });
  } else {
    Object.keys(data).sort().forEach((item) => {
      if (typeof data[item] === 'object' && data[item] instanceof Date === true) {
        if (
          item.endsWith('From') &&
          data[item] !== '' &&
          data[item] !== undefined &&
          data[item.slice(0, item.lastIndexOf('From')) + 'To'] !== undefined
        ) {
          returnFormData =
            returnFormData +
            '(' +
            item.slice(0, item.lastIndexOf('From')) +
            ' ge datetime' +
            "'" +
            moment(data[item]).format().split('+')[0] +
            "'" +
            ' and ';
        } else {
          if (item.endsWith('From') && data[item] !== '' && data[item] !== undefined) {
            returnFormData =
              returnFormData +
              item.slice(0, item.lastIndexOf('From')) +
              ' ge datetime' +
              "'" +
              moment(data[item]).format().split('+')[0] +
              "'" +
              ' and ';
          }
        }
        if (
          item.endsWith('To') &&
          data[item] !== '' &&
          data[item] !== undefined &&
          data[item.slice(0, item.lastIndexOf('To')) + 'From'] !== undefined
        ) {
          returnFormData =
            returnFormData +
            item.slice(0, item.lastIndexOf('To')) +
            ' le datetime' +
            "'" +
            moment(data[item]).format().split('+')[0] +
            "')" +
            ' and ';
        } else {
          if (item.endsWith('To') && data[item] !== '' && data[item] !== undefined) {
            returnFormData =
              returnFormData +
              item.slice(0, item.lastIndexOf('To')) +
              ' le datetime' +
              "'" +
              moment(data[item]).format().split('+')[0] +
              "'" +
              ' and ';
          }
        }
      } else {
        if (typeof data[item] === 'string' && (item.endsWith('From') || item.endsWith('To'))) {
          if (
            item.endsWith('From') &&
            data[item] !== '' &&
            data[item.slice(0, item.lastIndexOf('From')) + 'To'] !== ''
          ) {
            returnFormData =
              returnFormData +
              '(' +
              item.slice(0, item.lastIndexOf('From')) +
              ' ge ' +
              "'" +
              data[item] +
              "'" +
              ' and ';
          } else {
            if (item.endsWith('From') && data[item] !== '') {
              returnFormData =
                returnFormData + item.slice(0, item.lastIndexOf('From')) + ' ge ' + "'" + data[item] + "'" + ' and ';
            }
          }
          if (item.endsWith('To') && data[item] !== '' && data[item.slice(0, item.lastIndexOf('To')) + 'From'] !== '') {
            returnFormData =
              returnFormData + item.slice(0, item.lastIndexOf('To')) + ' le ' + "'" + data[item] + "')" + ' and ';
          } else {
            if (item.endsWith('To') && data[item] !== '') {
              returnFormData =
                returnFormData + item.slice(0, item.lastIndexOf('To')) + ' le ' + "'" + data[item] + "'" + ' and ';
            }
          }
        } else {
          //Undefined is used for null/blank value date scenario
          if (data[item] === 'Upload' || data[item] === 'Download' || data[item] === undefined) {
            //Do Nothing or Skip; i.e, Do not add to result
          } else {
            //For Multi-Select DropDown Values
            if (typeof data[item] === 'object' && data[item] instanceof Array === true) {
              for (let i = 0; i < data[item].length; i++) {
                let value = data[item][i][Object.keys(data[item][i])[0]];
                if (data[item].length === 1) {
                  returnFormData = returnFormData + item + ' eq ' + "'" + value + "'" + ' and ';
                  break;
                }
                if (data[item].length === i + 1) {
                  returnFormData = returnFormData + item + ' eq ' + "'" + value + "'" + ' )' + ' and ';
                } else {
                  if (i === 0) {
                    returnFormData = returnFormData + '( ' + item + ' eq ' + "'" + value + "'" + ' or ';
                  } else {
                    returnFormData = returnFormData + item + ' eq ' + "'" + value + "'" + ' or ';
                  }
                }
              }
            } else {
              if (data[item] !== '' && data[item] !== undefined && data[item] !== null) {
                if (data[item] === true) { // Added on 14-Aug-2024 For Boolean value i.e. 'X'
                  returnFormData = returnFormData + item + ' eq ' + data[item] + ' and ';
                } else {
                  returnFormData = returnFormData + item + ' eq ' + "'" + data[item] + "'" + ' and ';
                }
              }
            }
          }
        }
      }
    });
  }

  if (returnFormData.endsWith(' and ')) {
    returnFormData = returnFormData.slice(0, returnFormData.lastIndexOf(' and'));
  }
  return returnFormData;
};

export default convertFormData;
