import React from 'react';
// import { ErrorBoundary } from "react-error-boundary";
import { Outlet } from 'react-router-dom';
import { NavigationContextProvider } from '../hooks';

export const BaseApp = () => {
  return (
    <NavigationContextProvider>
      <div className="flex min-h-screen flex-col">
        <div className="flex flex-col justify-center bg-defence-light-grey-200">
          <Outlet />
        </div>
      </div>
    </NavigationContextProvider>
  );
};
