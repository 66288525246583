import { FileTextIcon } from '@radix-ui/react-icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseLayout } from '../../components/BaseLayout';
import { LinkCard } from '../../components/LinkCard';
import { ROUTES } from '../../constants';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';

export const Retrieval = () => {
  useDocumentTitle(`Retrieval`);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 2 }));
  }, []);
  const quickLinkCards = [
    {
      link: ROUTES.RETRIEVAL_SEARCH,
      description:
        'List of Notifications, Work Orders, Purchase Orders and Service Entry Sheets, created or changed based on event triggers',
      label: 'Need to view Retrieval List',
      linkText: 'Retrieval List',
      icon: <FileTextIcon width={40} height={40} />,
    },
  ];

  return (
    <BaseLayout
      headProps={{
        title: 'Retrieval',
      }}
      bannerProps={{
        title: 'Retrieval',
      }}
      submenuProps={{
        title: 'Retrieval',
      }}
    >
      <div className=" flex w-full">
        <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
          <p className="w-fit p-4 text-xl"></p>
          <div className="grid grid-cols-1 gap-10 px-2 sm:grid-cols-2 sm:px-4 md:my-2 md:grid-cols-2 md:gap-10 lg:grid-cols-2 xl:grid-cols-2">
            {quickLinkCards.map((card) => {
              return (
                <LinkCard
                  key={card.link}
                  showIcon={true}
                  icon={card.icon}
                  imageSrc=""
                  linkText={card.linkText}
                  link={card.link}
                  description={card.description}
                  label={card.label}
                />
              );
            })}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
