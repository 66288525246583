import React from 'react';
import { FormElement, SelectProps } from '../FormElementType';
import { ChakraStylesConfig, Select, chakraComponents } from 'chakra-react-select';
import { Icon, FormLabel, FormErrorMessage } from '@chakra-ui/react';
import { Chevron_Orange } from '../../icons/Outline';
import { PrimitiveControl } from '../PrimitiveControl';

const styles: ChakraStylesConfig = {
  menu: (provided) => ({ ...provided, zIndex: 100 }),
  control: (provided) => ({ ...provided, _hover: { cursor: 'pointer' } }),
  option: (provided) => ({ ...provided, fontSize: 'regular' }),
  placeholder: (provided) => ({ ...provided, fontWeight: 'light', color: 'black' }),
  noOptionsMessage: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontSize: 'regular',
    marginLeft: '1rem',
    color: 'black',
  }),
};
//Using a ChakraUI wrapper around react-select to create an efficient searchable dropdown
export const SearchableSelect: React.FC<SelectProps & FormElement> = ({
  options,
  desktopWidth,
  mobileWidth,
  onSelect,
  id,
  required,
  isInvalid,
  label,
  defaultValue,
  placeholder,
  errorMessage,
}): JSX.Element => {
  return (
    <PrimitiveControl
      id={id}
      required={required}
      isInvalid={isInvalid}
      desktopWidth={desktopWidth}
      mobileWidth={mobileWidth}
    >
      {label ? <FormLabel>{label}</FormLabel> : <></>}
      <Select
        variant="primary"
        defaultInputValue={defaultValue}
        chakraStyles={styles}
        onChange={(option) => onSelect(option)}
        options={options.map((option) => {
          return { label: option.value, value: option.key };
        })}
        placeholder={placeholder}
        components={{
          DropdownIndicator: (props) => (
            <chakraComponents.DropdownIndicator {...props}>
              <Icon as={Chevron_Orange} />
            </chakraComponents.DropdownIndicator>
          ),
        }}
      />
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </PrimitiveControl>
  );
};
