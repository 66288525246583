import * as Popover from '@radix-ui/react-popover';
import React from 'react';

export const PopoverIcon = ({ content }: { content: string }) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button title="Tooltip" className="border-0 bg-transparent">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="15" height="15" fill="currentColor">
            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
          </svg>
        </button>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content side="top" className="flex w-56 rounded bg-defence-charcoal p-2 text-white">
          <p className="">{content}</p>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};
