import { GLOBALCONFIG_TYPES } from "./globalConfig.types";

const INITIAL_STATE = {
  currentMeta: {selectedIndex: 0},
};

export const globalConfigReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case GLOBALCONFIG_TYPES.SET_CURRENT_META:
      return { ...state, currentMeta: payload };
    default:
      return state;
  }
};
