import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
// import { createTableData } from "../../assets/tableData";
import { ChevronDownIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, LoaderFunction, useLocation } from 'react-router-dom';
// import { serviceRequestTableMetadata } from "../../lib/api/notifications";
import convertSapData from '../../lib/convertData';
// import convertSapMetadata from "../../lib/convertMetadata";
import { useDispatch, useSelector } from 'react-redux';
import { BaseLayout } from '../../components/BaseLayout';
import { SubBanner } from '../../components/SubBanner';
import { TablePagination } from '../../components/TablePagination';
import { ROUTES } from '../../constants';
import { useNavigationContext } from '../../hooks';
import { useSessionStorage } from '../../hooks/useStorage';
import { getServiceRequests } from '../../lib/api/notifications';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { selectCurrentMeta } from '../../store/metaNotif/metaNotif.selector';

export const notificationsLoader = (async () => {
  const res = await getServiceRequests();
  return res.data;
}) satisfies LoaderFunction;

export const NotificationSearchResults = () => {
  const [data, setData] = useState([]);

  const location = useLocation();
  const { data: tableData } = location?.state || {};

  const metadata = useSelector(selectCurrentMeta);
  const { prevLocation } = useNavigationContext();

  const { removeStoredData, storeData, storedData } = useSessionStorage('notifications');

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
  }, [tableData]);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
  }, [tableData]);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
    if (tableData) {
      storeData(tableData);
      const sapdata = convertSapData(tableData);
      setData(sapdata);
    } else if (prevLocation?.pathname && !prevLocation?.pathname.startsWith(ROUTES.NOTIFICATIONS_SEARCH_RESULTS)) {
      removeStoredData();
    } else if (storedData) {
      setData(convertSapData(storedData));
    } else {
      setData([]);
    }
  }, [tableData, storeData, removeStoredData, prevLocation, storedData, dispatch]);

  const columnHelper = createColumnHelper<{ [key: string]: string }>();

  const columns = [
    columnHelper.accessor('NotificationNo', {
      header: () => (metadata['NotificationNo'] ? metadata['NotificationNo'].LABEL : 'Service Request No.'),
      cell: (info) => (
        <Link
          to={`/eeportal/notifications/search/results/${info.getValue()}/update`}
          className="text flex text-defence-orange-500 underline hover:no-underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('NotificationDesc', {
      header: () => (metadata['NotificationDesc'] ? metadata['NotificationDesc'].LABEL : 'Description'),
    }),
    columnHelper.accessor('RequiredStart', {
      header: () => (metadata['RequiredStart'] ? metadata['RequiredStart'].LABEL : 'Required Start'),
    }),
    columnHelper.accessor('RequiredEnd', {
      header: () => (metadata['RequiredEnd'] ? metadata['RequiredEnd'].LABEL : 'Required End'),
    }),
    columnHelper.accessor('RequestorName', {
      header: () => ('Requestor'),
    }),
    columnHelper.accessor('Status', {
      header: () => (metadata['Status'] ? metadata['Status'].LABEL : 'Notification Status'),
    }),
    columnHelper.accessor('FlocDescription', {
      header: () => ('Functional Location'),
    }),
    columnHelper.accessor('EquipmentDesc', {
      header: () => ('Equipment'),
    }),
    columnHelper.accessor('SortField', {
      header: () => (metadata['SortField'] ? metadata['SortField'].LABEL : 'EBI'),
    }),
    columnHelper.accessor('CreatedBy', {
      header: () => 'Created By',
    }),
    columnHelper.accessor('ZzDefGrpName', {
      header: () => ('Defence Group'),
    }),
    columnHelper.accessor('Qmgrp', {
      header: () => ('Code Group'),
    }),
    columnHelper.accessor('PlannerGroupName', {
      header: () => ('Planner Group'),
    }),
    columnHelper.accessor('WorkCentreName', {
      header: () => ('Work Centre'),
    }),
    columnHelper.accessor('CustCostCentre', {
      header: () => ('Cost Centre'),
    }),
  ];

  const columnsMobile = [
    columnHelper.accessor('NotificationNo', {
      header: () => (metadata['NotificationNo'] ? metadata['NotificationNo'].LABEL : ''),
      cell: (info) => (
        <div className="flex" key={info.row.id} onClick={() => info.row.toggleExpanded()}>
          <button>
            {info.row.getIsExpanded() ? (
              <ChevronRightIcon className="h-4 w-4 text-defence-orange-600" />
            ) : (
              <ChevronDownIcon className="h-4 w-4 text-defence-orange-600" />
            )}
          </button>
          {/* ) : (
          "🔵"
        )} */}
          {info.getValue()}
        </div>
      ),
    }),
    columnHelper.accessor('NotificationDesc', {
      header: () => (metadata['NotificationDesc'] ? metadata['NotificationDesc'].LABEL : ''),
      cell: (info) => info.renderValue(),
    }),
    columnHelper.accessor('SortField', {
      header: () => (metadata['SortField'] ? metadata['SortField'].LABEL : ''),
    }),
    columnHelper.accessor('Status', {
      header: () => (metadata['Status'] ? metadata['Status'].LABEL : ''),
    }),
  ];
  const table = useReactTable({
    data,
    columns: columns, //isMobile ? columnsMobile : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: isMobile ? { pagination: { pageSize: 15 } } : { pagination: { pageSize: 30 } },
  });

  const renderSubComponent = (row: any) => {
    if (row) {
      return (
        <div className="grid grid-cols-2 gap-1">
          <div>{metadata['RequiredStart'].LABEL}:</div>
          <div>{row.original.RequiredStart}</div>
          <div>{metadata['RequiredEnd'].LABEL}:</div>
          <div>{row.original.RequiredEnd}</div>
          <div>{metadata['FunctLocation'].LABEL}:</div>
          <div>{row.original.FunctLocation}</div>
          <div>{metadata['ReportedBy'].LABEL}:</div>
          <div>{row.original.ReportedBy}</div>
          <div>{metadata['NotifLongtext'].LABEL}:</div>
          <div>{row.original.NotifLongtext}</div>
          <div>{metadata['DefenceGroup'].LABEL}:</div>
          <div>{row.original.DefenceGroup}</div>
        </div>
      );
    }
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Service Request Search Results',
      }}
      bannerProps={{
        title: 'Service Request Search Results',
      }}
      submenuProps={{
        title: 'Service Requests',
        selectedIndex: '0',
      }}
    >
      <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
        <SubBanner title="Notification Search Results" />
        <div className="">
          <div className="flex overflow-x-auto p-2">
            <table className="w-full flex-shrink-0">
              <thead className="border bg-defence-orange-500 text-left font-normal text-white">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th className="border border border-black p-2 text-x0" key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="border text-sm md:text-base">
                {table.getRowModel().rows.map((row) => (
                  <>
                    <tr key={row.id} className="border even:bg-slate-200">
                      {row.getVisibleCells().map((cell) => (
                        <td key={cell.id} className="fontweight-light border border-black p-2 text-x0">
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() ? (
                      <tr className="even:bg-slate-200">
                        <td colSpan={4} className="break-all">
                          {row.getIsExpanded() ? renderSubComponent(row) : null}
                        </td>
                      </tr>
                    ) : null}
                  </>
                ))}
              </tbody>

              <tfoot>
                {table.getFooterGroups().map((footerGroup) => (
                  <tr key={footerGroup.id}>
                    {footerGroup.headers.map((header) => (
                      <th key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </tfoot>
            </table>
          </div>
          <div className="h-2" />
          <TablePagination tableInstance={table} />
        </div>
      </div>
    </BaseLayout>
  );
};
