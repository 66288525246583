import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";

// Default config options
const defaultOptions = {
  baseURL:  "http://localhost:3001/",
  headers: {
    "Content-Type": "application/json",
  },
};

export interface ApiResponse<T> {
  d: T;
}

/**
 * @module axiosInstance
 * @description This module exports an instance of the axios HTTP client configured with default API settings.
 * @param {Object} defaultOptions - Default API configuration for the axios instance.
 * @param {string} defaultOptions.baseURL - The base URL for API requests.
 * @param {Object} defaultOptions.headers - The default headers to be sent with API requests.
 * @param {string} defaultOptions.headers['Content-Type'] - The default 'Content-Type' header, set to 'application/json'.
 * @returns {AxiosInstance} The configured axios instance.
 */
export const axiosInstance = axios.create(defaultOptions);

const requestHandler = (config: InternalAxiosRequestConfig) => {
  return config;
};

const responseHandler = (response: AxiosResponse) => {
  /* if (response.status === 401) {
      window.location = '/login';
  } */

  return response;
};

const errorHandler = (error: any) => {
  return Promise.reject(error);
};

/**
 * @function
 * @description Request interceptor for the axios instance.
 * This function intercepts outgoing requests and attaches an 'Authorization' header to each one.
 * The 'Authorization' header is set with the current session's JWT token
 * @param {InternalAxiosRequestConfig} config - The configuration object of the request.
 * @returns {InternalAxiosRequestConfig} The configuration object with the 'Authorization' header set.
 */
axiosInstance.interceptors.request.use(requestHandler);

/**
 * @function
 * @description Response interceptor for the axios instance.
 * This function intercepts incoming responses and handles any errors that may occur.
 * @param {Object} config - The configuration object of the request.
 * @returns {Object} The configuration object
 * @returns {Object} The response object.
 */
axiosInstance.interceptors.response.use(responseHandler, errorHandler);

/**
 * @module endpoint
 * @description This module exports a set of functions that make HTTP requests to the API.

 */
const endpoint = {
  get: <T>(url: string, params: AxiosRequestConfig = {}) => {
    return axiosInstance.get<T>(url, { params });
  },
  post: <T>(url: string, params: AxiosRequestConfig = {}) => {
    return axiosInstance.post<T>(url, params);
  },
};

export default endpoint;
