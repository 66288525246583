import { InfoCircledIcon, MagnifyingGlassIcon } from '@radix-ui/react-icons';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useController } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import { TypeAheadCodeSet, lookupCodeSet } from '../../../lib/api/lookup';
import { Spinner } from '../../Spinner';

export interface InputTypeAheadInterface {
  fieldLabel: string;
  items?: any[];
  fieldPlaceholder: string;
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  codeSet?: TypeAheadCodeSet;
  codeSetFilterField?: string;
  codeSetValueField?: string;
  codeSetLabelField?: string;
  top?: any;
  tooltip?: string;
  hideMagnifyingGlass?: boolean;
}

export const InputTypeAhead = ({
  fieldLabel,
  fieldPlaceholder,
  control,
  name,
  required = false,
  disabled = false,
  defaultValue = '',
  codeSet,
  codeSetFilterField = 'Search',
  codeSetValueField,
  codeSetLabelField,
  top = 10,
  tooltip,
  hideMagnifyingGlass = false,
}: InputTypeAheadInterface) => {
  const [suggestions, setSuggestions] = useState<any[]>([]);

  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const [selectedValue, setSelectedValue] = useState('');
  const [hoveredOverTable, setHoveredOverTable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [description, setDescription] = useState('');

  const onChange = async (value: string) => {
    if (value.length > 0 && codeSet) {
      const filter = codeSetFilterField ? `$top=${top}&search=${value}` : undefined;
      setLoading(true);
      try {
        const sapResults = await lookupCodeSet(codeSet, filter);
        const results = convertSapData(sapResults);
        setSuggestions(results);
      } catch (error) {
        setMsgError('Error fetching data from backend');
      }
    } else {
      setSuggestions([]);
      field.onChange('');
      setSelectedValue('');
    }
    setLoading(false);
  };

  const convertSapData = function (data: any) {
    if (data) {
      data.map((row: any) =>
        Object.keys(row).forEach((item) => {
          if (typeof row[item] === 'object') {
            delete row[item];
          } else {
            if (typeof row[item] === 'string' && row[item].includes('/Date(')) {
              const stringForDateFunction = 'return new ' + row[item].replaceAll('/', ''); //Odata return miliseconds for date, need to convert
              const getDate = new Function(stringForDateFunction); //run string as a function to get real date
              row[item] = moment(getDate()).format('DD-MM-YYYY');
            }
          }
        }),
      );

      return data;
    }
  };

  const onTextChange = useMemo(() => {
    return debounce(onChange, 1500);
  }, []);

  const suggestionSelected = (value: any) => {
    setSelectedValue(value);
    setSuggestions([]);
    setHoveredOverTable(false);
  };

  useEffect(() => {
    if (field.value !== '') {
      setSelectedValue(field.value);
    }
  }, [field.value]);

  const renderSuggestions = () => {
    if (suggestions.length === 0) {
      return null;
    }
    return (
      <div className="absolute left-0 mt-20 rounded border border-defence-light-grey-100 bg-white p-2">
        {dynamicTable()}
      </div>
    );
  };
  const dynamicTable = () => {
    // this creates a dynamic table inside the div by capturing the items in suggestion array
    return (
      <ul className="relative p-0">
        <table
          //ref={concernedElement}
          className="max-w-96 block max-h-60 w-full overflow-auto bg-white text-left font-light"
        >
          <thead className="sticky top-0 border-b dark:border-neutral-500">
            <tr>
              {Object.keys(suggestions[0]).map(
                (
                  hdr,
                  i, // loops at column header
                ) => (
                  <th scope="col" className="bg-defence-light-grey-200 px-3 py-3 text-sm" key={i.toString()}>
                    {hdr.toUpperCase()}
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody onMouseOver={() => setHoveredOverTable(true)} onMouseOut={() => setHoveredOverTable(false)}>
            {suggestions.map(
              (
                itm,
                i, // loops at table item level
              ) => (
                <tr
                  key={i}
                  className="cursor-pointer border-b bg-white hover:bg-defence-light-grey-200 dark:border-neutral-500"
                  onClick={() => {
                    const text = codeSetLabelField ? itm[codeSetLabelField] : null;
                    const value = codeSetValueField ? itm[codeSetValueField] : Object.values(itm)[0];
                    setDescription(text);
                    field.onChange(value);
                    suggestionSelected(value);
                  }}
                >
                  {Object.keys(suggestions[0]).map((hdr, j) => (
                    <td className="whitespace-nowrap px-3 py-3 text-sm font-medium" key={i.toString() + j.toString()}>
                      {itm[hdr]}
                    </td>
                  ))}
                </tr>
              ),
            )}
          </tbody>
        </table>
      </ul>
    );
  };

  return (
    <>
      <div className="grid grid-cols-1">
        <label htmlFor="textInput" className="mb-2 mt-2 flex text-x0">
          {fieldLabel} {required && <p className="m-0 inline text-defence-redAlert"> *</p>}
          <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
        </label>

        <label
          className={`inputBase flex content-center overflow-auto rounded-sm border-defence-light-grey-500 text-left ${
            error?.type ? `inputError` : `inputClean`
          }
          ${disabled ? `bg-defence-light-grey-200` : ``}`}
        >
          <input
            className={`focus: w-full border-none font-light outline-none placeholder:text-defence-light-grey-900 
             ${error?.type ? `inputError` : `inputClean`}`}
            onChange={(e) => {
              onTextChange(e.target.value);
              setSelectedValue(e.target.value);
              setDescription('');
            }}
            value={selectedValue + (description ? ' ' + description : '')}
            type="text"
            disabled={disabled}
            name={name}
            onBlur={() => {
              if (!hoveredOverTable) {
                setSuggestions([]);
              }
              if (selectedValue) {
                field.onChange(selectedValue);
                setSelectedValue(selectedValue);
              }
            }}
            placeholder={fieldPlaceholder ? fieldPlaceholder : 'test  '}
          />
          {
            // this is the spinner that shows up when the user is typing
            loading ? (
              <Spinner className="mr-2 h-4 w-4 " />
            ) : (
              !hideMagnifyingGlass && (
                <MagnifyingGlassIcon height={25} width={25} className="mr-2 content-center text-defence-orange-500" />
              )
            )
          }
        </label>

        {suggestions.length > 0 && <div className="absolute z-10 border bg-white">{renderSuggestions()}</div>}

        <div className="h-4 text-defence-redAlert">
          {error && error?.type === 'too_big' && (
            <p className="flex pt-1 text-sm text-defence-redAlert">
              <InfoCircledIcon className="flex pt-1" height={16} width={16} />
              Field Length Error.
            </p>
          )}
          {error && error?.type === 'invalid_type' && (
            <p className="flex pt-1 text-sm text-defence-redAlert">
              <InfoCircledIcon className="flex pt-1" height={16} width={16} />
              This field is not valid.
            </p>
          )}

          {error && error?.type === 'custom' && (
            <p className="flex pt-1 text-sm text-defence-redAlert">
              <InfoCircledIcon className="flex pt-1" height={16} width={16} />
              This field is required.
            </p>
          )}
        </div>
      </div>
    </>
  );
};
