import { getAPIUrl } from '../getAPIUrl';
import { axiosInstance } from '../instance';
import { axiosInstanceWithCache } from '../instance';

export enum TypeAheadCodeSet {
  Asset = 'VH_AssetSet',
  Floc = 'VH_FlocSet',
  Equip = 'VH_EquiSet',
  Wo = 'VH_WorkorderSet',
  OrderType = 'VH_OrderTypeSet',
  Notif = 'VH_NotificationSet',
  NotifType = 'VH_NotificationTypeSet',
  PurchOrd = 'VH_PurchOrdSet',
  CauseCode = 'VH_CauseCodeSet',
  DefenceGrp = 'VH_DefenceGrpSet',
  CostCentre = 'VH_CostcenterSet',
  Wbs = 'VH_WBSSet',
  Vendor = 'VH_VendorSet',
  MeasuringPoint = 'VH_MeasuringPointSet',
  UserID = 'VH_UserIDSet',
  ServiceNo = 'VH_ServiceNoSet',
  MaintActType = 'VH_MaintActivityTypeSet',
  CodeGroup = 'VH_CodeGroupSet',
  Code = 'VH_CodeSet',
  Status = 'VH_StatusSet',
  UserStatus = 'VH_UserStatusSet',
  SystemStatus = 'VH_SystemStatusSet',
  Workcenter = 'VH_WorkcenterSet',
  IntegrityAssessed = 'VH_IntegrityAssessedSet',
  FunctionalityAssessed = 'VH_FunctionalityAssessedSet',
  ComplianceIssue = 'VH_ComplianceIssueSet',
  ComplianceType = 'VH_ComplianceTypeSet',
  NoncompConsequence = 'VH_NoncompConsequenceSet',
  ValueMoneyConsequence = 'VH_ValueMoneyConsequenceSet',
  SafetyImpact = 'VH_SafetyImpactSet',
  WHSConsequence = 'VH_WHSConsequenceSet',
  WHSLikelihood = 'VH_WHSLikelihoodSet',
  MaterialGroup = 'VH_MaterialGroupSet',
  PlantSection = 'VH_PlantSectionSet',
  FlocCat = 'VH_FlocCatSet',
  CauseCodeCoding = 'VH_CauseCodeSet',
  AccountingIndicator = 'VH_AccountingIndicatorSet',
  UoM = 'VH_UomTimeSet',
  ControlKey = 'VH_ControlKeySet',
  PurchaseGrp = 'VH_PurchGrpSet',
  UomTime= 'VH_UomTimeSet',
  Revision='VH_RevisionNumberSet',
}

export const lookupCodeSet = async (code: TypeAheadCodeSet, filter?: string) => {
  let url = `${getAPIUrl(process.env.REACT_APP_EE_LOOKUP)}/${code}`;
  if (filter) {
    url = url.concat(`?${filter}`);
  }
  // add format always
  url = url.concat(`&$format=json`);

  try {
    const res = await axiosInstanceWithCache
      .get<{
        d: {
          results: any[];
        };
      }>(url)
      .catch((_error) => {
        throw new Error('Error fetching data');
      });
    const list = res?.data?.d?.results || [];
    return list.map((item) => {
      const { __metadata, ...rest } = item;
      return rest;
    });
  } catch (error) {
    throw new Error('Error fetching data');
  }
};
