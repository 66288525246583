import { Listbox } from '@headlessui/react';
import { ChevronDownIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import { useController } from 'react-hook-form';

type TSelectInputItem = {
  code: string;
  description: string;
};
type TSelectInput = {
  fieldLabel: string;
  items: TSelectInputItem[];
  fieldPlaceholder: string;
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  includeCode?: boolean;
  tooltip?: string;
};

export const SingleSelectInput = ({
  fieldLabel,
  items,
  fieldPlaceholder,
  name,
  control,
  required = false,
  disabled = false,
  defaultValue,
  includeCode = true,
  tooltip,
}: TSelectInput) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const [selectedItem, setSelectedItem] = useState('');
  const [selectedCode, setSelectedCode] = useState('');
  const [fromChange, setFromChange] = useState(false);

  const getSelectedItem = (code: string) => {
    const index = items.findIndex((item) => item.code === code);
    if (index >= 0) {
      return items[index].description;
    }
    return '';
  };

  const getSelectedItemCode = (code: string) => {
    const index = items.findIndex((item) => item.code === code);
    if (index >= 0) {
      return items[index].code;
    }
    return '';
  };

  useEffect(() => {
    if (field.value) {
      setSelectedCode(field.value);
      const selectedItem = getSelectedItem(field.value);
      if (selectedItem) {
        setSelectedItem(selectedItem);
      }
    }
  }, [field.value]);

  useEffect(() => {
    if (defaultValue && !fromChange) {
      const selectedItem = getSelectedItem(defaultValue);
      if (selectedItem) {
        setSelectedItem(selectedItem);
      }
      const selectedItemCode = getSelectedItemCode(defaultValue);
      if (selectedItemCode) {
        setSelectedCode(selectedItemCode);
      }
    }
  }, [defaultValue, items]);

  const handleChange = (value: string) => {
    const selectedItem = getSelectedItem(value);
    if (selectedItem) {
      setSelectedItem(selectedItem);
    }
    const selectedItemCode = getSelectedItemCode(value);
    if (selectedItemCode) {
      setSelectedCode(selectedItemCode);
    }
    setFromChange(true);
  };
  return (
    <div>
      <label className="mb-2 mt-2 flex text-x0">
        {fieldLabel} {required && <p className="m-0 inline  text-defence-redAlert"> *</p>}
        <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange }, fieldState: { error } }) => {
          return (
            <div className="relative">
              <Listbox
                disabled={disabled}
                value={selectedCode}
                onChange={(e: any) => {
                  handleChange(e?.code);
                  onChange(e?.code);
                }}
              >
                <Listbox.Button
                  className={`flex w-full content-center items-center overflow-auto rounded-md border border-defence-light-grey-500 py-1.5 pl-2 text-left text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:outline-black focus:border focus:border-defence-blueGrey focus:bg-white focus-visible:outline enabled:bg-white disabled:bg-defence-light-grey-200 ${
                    error ? `inputError border-defencered` : `inputClean border-defencemediumgrey`
                  } ${disabled ? `!cursor-default` : `cursor-pointer`}`}
                >
                  {selectedCode ? (
                    <p className="mb-0 w-full text-black">
                      {includeCode ? selectedCode + ' : ' : ''} {selectedItem}
                    </p>
                  ) : (
                    <p className="mb-0 w-full text-defence-black">{fieldPlaceholder}</p>
                  )}
                  <ChevronDownIcon height={30} width={30} className="mx-2 text-defence-orange-500" />
                </Listbox.Button>
                <Listbox.Options
                  className={`!absolute z-10 w-full overflow-y-auto border-2 border-t-0 bg-white shadow-md ${
                    items.length >= 10 ? `h-96` : ``
                  }`}
                >
                  {items.map((item) => (
                    <Listbox.Option
                      key={item.code}
                      value={item}
                      className="cursor-pointer  p-2 ui-active:bg-defence-light-grey-200 "
                    >
                      {includeCode ? item.code + ' : ' : ''}
                      {item.description}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
              <div className="h-4">
                {error && error?.type === 'custom' && (
                  <p className="flex pt-1 text-sm text-defence-redAlert">
                    <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                    This field is required.
                  </p>
                )}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
