import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@radix-ui/react-icons';
import { Table } from '@tanstack/react-table';

export interface TablePaginationProps {
  tableInstance: Table<{
    [key: string]: string;
  }>;
}
export const TablePagination = ({ tableInstance }: TablePaginationProps) => {
  if (!tableInstance || tableInstance?.getPageCount() === 0) return null;

  return (
    <div className="flex items-center justify-center gap-2">
      {tableInstance.getPageCount() > 0 && (
        <>
          <button
            className="p-1"
            onClick={() => tableInstance.previousPage()}
            disabled={!tableInstance.getCanPreviousPage()}
          >
            <ChevronLeftIcon className="mr-2 text-defence-orange-500" width={20} height={20} />
          </button>
          <span className="flex items-center gap-1">
            <div>Page</div>
            <strong>
              {tableInstance.getState().pagination.pageIndex + 1} of {tableInstance.getPageCount()}
            </strong>
          </span>
          <button className="p-1" onClick={() => tableInstance.nextPage()} disabled={!tableInstance.getCanNextPage()}>
            <ChevronRightIcon className="mr-2 text-defence-orange-500" width={20} height={20} />
          </button>
          <span className="flex items-center gap-1">
            | Go to page:
            <input
              type="number"
              defaultValue={tableInstance.getState().pagination.pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                tableInstance.setPageIndex(page);
              }}
              className="w-16 rounded border p-1"
            />
          </span>
        </>
      )}
      {tableInstance.getPageCount() === 0 && <div>No Entries</div>}
    </div>
  );
};
