import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import {
  CreateNotificationRequestBody,
  NotificationMetadataResponse,
  ReadNotificationResponse,
} from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertSapMetadata from '../../lib/convertMetadata';
import { formatDateToODataDate, formatODataDateToDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentMeta } from '../../store/metaNotif/metaNotif.action';
import { selectCurrentMeta } from '../../store/metaNotif/metaNotif.selector';
import { NotificationForm } from './NotificationForm';
import { TNotificationFormSchema, notificationFormSchema } from './NotificationFormSchema';

export const NotificationUpdate = () => {
  const [singledata, setSingledata] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const refMeta = useRef(false);
  const refData = useRef(false);
  const notifId = useParams();
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => { }, [notifId]);

  useEffect(() => {
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!refMeta.current) {
          const { data: TableMetadata } = await axiosInstance.get<NotificationMetadataResponse>(
            `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_METADATA)}`,
          );
          const formMetaData = convertSapMetadata(TableMetadata);
          dispatch(setCurrentMeta(formMetaData));
        }
      };
      fetchTableMetadata();
      return () => {
        refMeta.current = true;
      };
    }
  }, []);

  const fetchSingleNotification = async (id: string) => {
    if (!refData.current) {
      try {
        setLoading(true);
        let notifId;
        try {
          if (typeof id === 'object') {
            notifId = `ReadNotification('` + Object.values(id)[0] + `')`;
          } else {
            notifId = `ReadNotification('` + id + `')`;
          }
        } catch (e) {
          notifId = id;
        }
        const respData = await axiosInstance.get<ReadNotificationResponse>(
          `${getAPIUrl(
            process.env.REACT_APP_EE_NOTIF_SING_REQ_LIST,
          )}${notifId}?$expand=NotifToItem,NotifToUserStatus,NotifToDir,NotifToItem/ItemToCause&$format=json`,
        );
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
        setTitleMessage(titleMessage);
        setMessages(messages);
        const singleNotifData = respData.data?.d as any;
        //const singleNotifData = convertData(notifData) || {};
        try {
          const { InProcess, SetDelete, Reset, Complete } = JSON.parse(singleNotifData?.UiRules);
          singleNotifData.InProcess = InProcess;
          singleNotifData.SetDelete = SetDelete;
          singleNotifData.Reset = Reset;
          singleNotifData.Complete = Complete;
        } catch (e) { }
        try {
          singleNotifData.NotifToItem.results.map((item) => {
            if (item.LineNo) {
              item.LineNo = item.LineNo.replace(/^0+/, '');
            }
          });
          setSingledata(singleNotifData);
        } catch (e) {
          setSingledata(singleNotifData);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
          (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    }
  };

  useEffect(() => {
    fetchSingleNotification(notifId as never).catch(console.error);
    return () => {
      refData.current = true;
    };
  }, [notifId]);

  const onMessageModalClose = () => {
    setMessages([]);
  };

  const [defaultValues, setDefaultValues] = useState<TNotificationFormSchema>({
    Etag: '',
    NotificationNo: '',
    NotificationType: '',
    SystemStatus: '',
    UserStatus: '',
    NotificationDesc: '',
    WorkOrder: '',
    ReportedBy: '',
    FunctLocation: '',
    FunctLocationDesc: '',
    EquipmentNo: '',
    EquipmentDesc: '',
    PlannerGroup: '',
    MainWorkCentre: '',
    MainWorkCentrePlant: '',
    NotificationLongText: '',
    NotificationLongTextDisplay: '',
    ContactName: '',
    ContactHouseNo: '',
    ContactStreet: '',
    ContactTelephone: '',
    ContactFax: '',
    NotificationPriority: '',
    NotificationPriorityDesc: '',
    RequiredStartDateTime: undefined,
    RequiredEndDateTime: undefined,
    MalfunctionStart: undefined,
    MalfunctionEnd: undefined,
    DurationUom: '',
    CodeGroup: '',
    CodeGroupCoding: '',
    NotifToItem: {
      results: [
        {
          NotificationNo: '',
          ItemNo: '',
          CauseText: '',
          CauseCodeCoding: '',
          CauseCodeGroup: '',
          CauseCodeDesc: '',
          Actioncode: '02', // for update
        },
      ],
    },
    NotifToUserStatus: {
      results: [
        {
          ActiveIndicator: '',
          DisplayCode: '',
          Name: '',
          NotificationNo: '',
          StatusCode: '',
        },
      ],
    },
    NotifToDir: {
      results: [
        {
          DocumentNo: '',
          DocumentDesc: '',
        },
      ],
    },
    ContactPostalCode: '',
    ContactCity: '',
    ContactRegion: '',
    ContactCountry: '',
    RequestorName: '',
    RequestorTelephone: '',
    RequestorEmail: '',
    RequestorMobile: '',
    DefenceGroup: '',
    CustomerCostCentre: '',
    CustomerWbs: '',
    CustomerAsset: '',
    CustomerSubAsset: '',
    InProcess: false,
    SetDelete: false,
    Reset: false,
    Complete: false,
    Postpone: false,
    BreakdownIndicator: '',
  });

  useEffect(() => {
    setDefaultValues({
      Etag: singledata.Etag,
      NotificationNo: singledata.NotificationNo,
      NotificationType: singledata.NotificationType,
      SystemStatus: singledata.SystemStatus,
      UserStatus: singledata?.NotifToUserStatus?.results?.filter(
        (item) => item.ActiveIndicator === 'X' && item.DisplayCode === singledata?.UserStatus?.substring(0, 4),
      )[0].StatusCode, //singledata.UserStatus,
      NotificationDesc: singledata.NotificationDesc,
      WorkOrder: singledata.WorkOrder,
      ReportedBy: singledata.ReportedBy,
      FunctLocation: singledata?.FunctLocation,
      FunctLocationDesc: singledata?.FunctLocationDesc,
      EquipmentNo: singledata?.EquipmentNo,
      EquipmentDesc: singledata?.EquipmentDesc,
      PlannerGroup: singledata?.PlannerGroup,
      MainWorkCentre: singledata?.MainWorkCentre,
      MainWorkCentrePlant: singledata?.MainWorkCentrePlant,
      NotificationLongText: '',
      NotificationLongTextDisplay: singledata?.NotificationLongText,
      ContactName: singledata?.ContactName,
      ContactHouseNo: singledata?.ContactHouseNo,
      ContactStreet: singledata?.ContactStreet,
      ContactTelephone: singledata?.ContactTelephone,
      ContactFax: singledata?.ContactFax,
      NotificationPriority: singledata?.NotificationPriority,
      NotificationPriorityDesc: singledata?.NotificationPriorityDesc,
      RequiredStartDateTime: formatODataDateToDate(singledata.RequiredStartDateTime),
      RequiredEndDateTime: formatODataDateToDate(singledata.RequiredEndDateTime),
      MalfunctionStart: formatODataDateToDate(singledata.MalfunctionStart),
      MalfunctionEnd: formatODataDateToDate(singledata.MalfunctionEnd),
      DurationUom: singledata?.DurationUom,
      CodeGroup: singledata?.CodeGroup,
      CodeGroupCoding: singledata?.CodeGroupCoding,
      NotifToItem: {
        results:
          singledata?.NotifToItem?.results.length > 0
            ? singledata?.NotifToItem?.results?.map((item: any, index) => {
              return {
                NotificationNo: item.NotificationNo,
                ItemNo: item.ItemNo,
                CauseText: singledata?.NotifToItem?.results[index]?.ItemToCause?.results[0]?.CauseText,
                CauseCodeCoding: singledata?.NotifToItem?.results[index]?.ItemToCause?.results[0]?.CauseCodeCoding,
                CauseCodeGroup: singledata?.NotifToItem?.results[index]?.ItemToCause?.results[0]?.CauseCodeGroup,
                CauseCodeDesc: singledata?.NotifToItem?.results[index]?.ItemToCause?.results[0]?.CauseCodeDesc,
                Actioncode: '02', // update code
              };
            })
            : [
              {
                NotificationNo: singledata.NotificationNo,
                ItemNo: '',
                CauseText: '',
                CauseCodeCoding: '',
                CauseCodeGroup: 'ZPM-EU',
                CauseCodeDesc: '',
                Actioncode: '01', // for creating new items
              },
            ],
      },
      NotifToUserStatus: {
        results: singledata?.NotifToUserStatus?.results?.length
          ? singledata?.NotifToUserStatus?.results?.map((item: any) => {
            return {
              ActiveIndicator: item.ActiveIndicator,
              DisplayCode: item.DisplayCode,
              Name: item.Name,
              NotificationNo: item.NotificationNo,
              StatusCode: item.StatusCode,
            };
          })
          : [],
      },
      NotifToDir: {
        results: singledata?.NotifToDir?.results?.length
          ? singledata?.NotifToDir?.results?.map((item: any) => {
            return {
              DocumentNo: item.DocumentNo,
              DocumentDesc: item.DocumentDesc,
            };
          })
          : [],
      },
      ContactPostalCode: singledata?.ContactPostalCode,
      ContactCity: singledata?.ContactCity,
      ContactRegion: singledata?.ContactRegion,
      ContactCountry: singledata?.ContactCountry,
      RequestorName: singledata?.RequestorName,
      RequestorTelephone: singledata?.RequestorTelephone,
      RequestorEmail: singledata?.RequestorEmail,
      RequestorMobile: singledata?.RequestorMobile,
      DefenceGroup: singledata?.DefenceGroup,
      CustomerCostCentre: singledata?.CustomerCostCentre,
      CustomerWbs: singledata?.CustomerWbs,
      CustomerAsset: singledata?.CustomerAsset,
      CustomerSubAsset: singledata?.CustomerSubAsset,
      InProcess: singledata?.InProcess,
      SetDelete: singledata?.SetDelete,
      Reset: singledata?.Reset,
      Complete: singledata?.Complete,
      Postpone: singledata?.Postpone,
      BreakdownIndicator: singledata?.BreakdownIndicator ? 'Yes' : 'No',
    });
  }, [singledata]);

  //const onSubmitUpdate = async (data: TNotificationFormSchema) => {
  //console.log("🚀 ~ file: Update.tsx:139 ~ onSubmitUpdate ~ data:", data);
  // try {
  //   const { data: responseData } = await serviceRequestList();
  // } catch (error) {
  // }
  //navigate("/notifications/read/results", { state: { data: responseData } });
  //};

  const onSubmitUpdate = async (data: TNotificationFormSchema) => {
    setLoading(true);
    setIsSubmitting(true);
    const resultData = convertFormDates({ ...data });
    //process data to remove flags as they are not needed in backend.
    delete resultData.InProcess;
    delete resultData.SetDelete;
    delete resultData.Reset;
    delete resultData.Postpone;
    delete resultData.Complete;
    delete resultData.NotificationLongTextDisplay;
    delete resultData.NotifToDir;
    // Move UserStatus in SingleDropDown UserStatus to StatusCode in the struture
    // note: since the dropdown has SAP code (E0001 etc) in UserStatus, hence we have to use it to transfer
    if (resultData.NotifToUserStatus.results.length > 0) {
      resultData.NotifToUserStatus.results[0].StatusCode = resultData.UserStatus;
      resultData.NotifToUserStatus.results[0].ActiveIndicator = 'X';
      resultData.NotifToUserStatus.results.splice(1);
    }
    if (resultData.NotifToItem.results.some((e) => e.ItemNo === '') && resultData.NotifToItem.results.length === 1) {
      resultData.NotifToItem.results = [{}];
    } else {
      resultData.NotifToItem.results.map(
        (item: any) =>
        (item.ItemToCause = {
          results: [
            {
              CauseCodeGroup: 'ZPM-EU',
              ItemNo: item.ItemNo,
              SequentialNo: '0001',
              Actioncode: item.Actioncode,
              CauseText: item.CauseText,
              CauseCodeCoding: item.CauseCodeCoding,
              NotificationNo: item.NotificationNo,
            },
          ],
        }),
      );
    }
    for (var i in resultData.NotifToItem.results) {
      delete resultData.NotifToItem.results[i].CauseCodeGroup;
      delete resultData.NotifToItem.results[i].CauseText;
      delete resultData.NotifToItem.results[i].CauseCodeCoding;
      delete resultData.NotifToItem.results[i].CauseCodeDesc;
    }

    if (resultData.BreakdownIndicator === 'Yes') {
      resultData.BreakdownIndicator = true;
    } else {
      resultData.BreakdownIndicator = false;
    }
    if (resultData.CustomerAsset !== '') {
      resultData.CustCompanyCode = '1000';
    } else {
      resultData.CustCompanyCode = '';
    }
    let resultData1 = { d: resultData };
    try {
      const response = await axiosInstance.post<CreateNotificationRequestBody>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_UPDATE)}`,
        resultData1,
      );
      setLoading(false);
      setIsSubmitting(false);
      if (response.status === 200 || response.status === 201) {
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Notification updated: ' + resultData.NotificationNo,
        ]);
        if (titleMessage !== 'Error') {
          await fetchSingleNotification(notifId as never);
        }
        setTitleMessage(titleMessage);
        setMessages(messages);
        try {
          for (var j in resultData.NotifToItem?.results) {
            resultData.NotifToItem.results[j].CauseCodeGroup =
              resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeGroup;
            resultData.NotifToItem.results[j].CauseText =
              resultData.NotifToItem.results[j].ItemToCause.results[0].CauseText;
            resultData.NotifToItem.results[j].CauseCodeCoding =
              resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeCoding;
          }
        } catch (e) { }
      }
    } catch (err) {
      setLoading(false);
      setIsSubmitting(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
      try {
        for (var j in resultData.NotifToItem?.results) {
          resultData.NotifToItem.results[j].CauseCodeGroup =
            resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeGroup;
          resultData.NotifToItem.results[j].CauseText =
            resultData.NotifToItem.results[j].ItemToCause.results[0].CauseText;
          resultData.NotifToItem.results[j].CauseCodeCoding =
            resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeCoding;
        }
      } catch (e) { }
    }
  };

  const convertFormDates = function (data: any) {
    try {
      Object.keys(data).forEach((item) => {
        if (typeof data[item] === 'object' && data[item] instanceof Date === true) {
          if (data[item]) {
            data[item] = formatDateToODataDate(data[item]);
          }
        }
      });
    } catch (err) {
      console.log('errors: ', err);
    }
    return data;
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Update Service Request',
      }}
      bannerProps={{
        title: 'Update Service Request',
      }}
      submenuProps={{
        title: 'Service Requests',
        selectedIndex: '0',
      }}
    >
      {loading && <Spinner overlay />}
      {!loading && messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      {(!loading || isSubmitting) && (
        <div className="w-full p-2">
          <NotificationForm
            metadata={metadata}
            onSubmit={onSubmitUpdate}
            schema={notificationFormSchema}
            defaultValues={defaultValues}
            updateForm={true}
            refreshData={fetchSingleNotification}
          />
        </div>
      )}
    </BaseLayout>
  );
};
