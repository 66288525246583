import { TrashIcon } from '@radix-ui/react-icons';
import React, { useState } from 'react';
import { Spinner } from '../../../../components/ServiceUI/Core/Spinner/Spinner';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import { axiosInstances } from '../../../lib/api/APICallsUpload';
import formatMessagesToDisplay from '../../../lib/formatMessagesToDisplay';
import { MessageModal } from '../../MessageModal';

export const FileUpload = ({
  fieldLabel,
  fileType,
  fileMax,
  errorMessage,
  required = false,
  disabled = false,
  url,
  accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  tooltip,
  headers = {},
  refreshPage = false,
}: {
  fieldLabel: string;
  fileType: string;
  register: any;
  fileMax: any;
  fieldname: string;
  errorMessage: any | undefined;
  validation: unknown;
  required?: boolean;
  disabled?: boolean;
  url?: string;
  accept?: string;
  tooltip?: string;
  headers?: { [key: string]: string };
  refreshPage?: boolean;
}) => {
  const [selectedFile, setSelectedFile] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const onSelectFile = (event: any) => {
    const aFiles: any[] = [];
    if (selectedFile.length !== 0) {
      selectedFile.map((selectedFileItem) => {
        //aFiles.push(selectedFileItem);
        setSelectedFile(aFiles);
      });
    }
    for (const i in event.target.files) {
      const existFile = aFiles.find((fileItem) => fileItem.name === event.target.files[i].name);
      if (!existFile && event.target.files[i].size) {
        aFiles.push(event.target.files[i]);
      }
    }
    setSelectedFile(aFiles);
  };
  const onFileUpload = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setLoading(true);
    const file = selectedFile[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file, file.name);

      try {
        axiosInstances.api
          .post(url, formData, {
            headers: {
              slug: file.name,
              ...headers,
            },
          })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setTitleMessage('Success');
              setMessages([response.data?.d?.ResultMsg ?? 'File uploaded successfully', response.data?.d?.RequestId]);
            } else {
              const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
                'Error while uploading the file',
              ]);
              setTitleMessage(titleMessage);
              setMessages(messages);
            }
            setLoading(false);
          })
          .catch((err) => {
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(err?.response, [
              (err as Error)?.message ?? 'Unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
            setLoading(false);
          });
      } catch (_error) {
        setTitleMessage('Error');
        setMessages(['Unable to process your request. Please try again later']);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  };

  const onDeleteFile = (file: any) => {
    let aFiles: any[] = [];
    if (selectedFile.length !== 0) {
      selectedFile.map((selectedFileItem) => {
        aFiles.push(selectedFileItem);
      });
    }
    const existFile = aFiles.find((fileItem) => fileItem.name === file.name);
    if (existFile) {
      aFiles = aFiles.filter((aFilesItem) => aFilesItem.name !== file.name);
    }
    setSelectedFile(aFiles);
  };

  const files = selectedFile;
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const onMessageModalClose = () => {
    setMessages([]);
  };
  return (
    <>
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
          refreshPage={refreshPage}
        ></MessageModal>
      )}
      {loading && <Spinner overlay />}
      <label className="block text-defence-black">
        {fieldLabel} {required && <p className="m-0 inline  text-defence-redAlert"> *</p>}
        <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
      </label>
      <div
        className={`relative flex flex-col rounded border border-dashed border-defence-light-grey-100 text-defence-black ${disabled ? `` : `cursor-pointer`
          }`}
      >
        <input
          accept={accept}
          type="file"
          disabled={disabled}
          onChange={onSelectFile}
          style={{ display: 'inline-block', width: '100%' }}
          className={`absolute inset-0 m-0 h-full w-full p-0 opacity-0 outline-none ${disabled ? `` : `cursor-pointer`
            }`}
        />
        <div
          className={`flex flex-col items-center justify-center py-10 text-center ${disabled ? `bg-defence-light-grey-200` : ``
            }`}
        >
          <svg
            className="text-current-50 mr-1 h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <p className="m-0">Drag your file here or click in this area.</p>
          <p className="m-0 text-xs">
            {fileType} up to {fileMax}
          </p>
        </div>
      </div>

      <div>
        {files.map((file: any, index) => (
          <div key={index} className="grid grid-cols-2">
            <div className="px-4 py-2 text-base text-defence-black">{file.name}</div>
            <div>
              <button type="button" disabled={disabled} className="flex px-4 py-2" onClick={() => onDeleteFile(file)}>
                <TrashIcon width={20} height={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {errorMessage && <p className="text-defence-redAlert">{errorMessage}</p>}

      <button
        onClick={onFileUpload}
        hidden={disabled}
        className="focus-visible:outline-offset border border-transparent bg-defence-charcoal p-2 text-x0 text-white shadow-sm hover:border-black hover:bg-defence-dark-grey-500 hover:text-black focus-visible:outline focus-visible:outline focus-visible:outline-defence-orange-500"
      >
        Upload
      </button>
    </>
  );
};
