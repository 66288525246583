import { CalendarIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import { isString } from 'lodash';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import './CustomDatePicker.css';

export interface DateInputProps {
  fieldLabel: string;
  fieldPlaceholder: string;
  name: string;
  control: any;
  required?: boolean;
  disabled?: boolean;
  showTime?: boolean;
  tooltip?: string;
}

export const DateInput = ({
  fieldLabel,
  fieldPlaceholder,
  name,
  control,
  required = false,
  disabled = false,
  showTime = false,
  tooltip,
}: DateInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <>
      <div className="grid grid-cols-1">
        <label htmlFor="textInput" className="mb-2 mt-2 flex rounded text-x0">
          {fieldLabel} {required && <p className="m-0 inline text-defence-redAlert"> *</p>}
          <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
        </label>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
            const selectedValue = isString(value) ? new Date(value) : value;
            return (
              <>
                <label
                  onClick={(e) => isOpen && e.preventDefault()}
                  className={`hidden w-full cursor-pointer rounded-sm border border-defence-light-grey-500 py-2 pl-2 text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:border focus-within:bg-white focus-within:outline focus-within:outline-1 focus-within:outline-black focus:border focus:border-defence-blueGrey sm:text-sm sm:leading-6 md:flex  
                            ${
                              error
                                ? `inputError border-defencered`
                                : `inputClean border-defencemediumgrey ${
                                    disabled ? `!cursor-default` : `cursor-pointer`
                                  }`
                            }
                            ${disabled ? `bg-defence-light-grey-200` : ``}`}
                >
                  <div className="customDatePickerWidth">
                    <DatePicker
                      dateFormat={`dd/MM/yyyy ${showTime === true ? `HH:mm:ss` : ``}`}
                      placeholderText={fieldPlaceholder}
                      className="h-full w-full text-x0 font-light placeholder:text-defence-light-grey-900 focus:outline-none"
                      onChange={onChange}
                      onBlur={onBlur}
                      onCalendarOpen={() => setIsOpen(true)}
                      onCalendarClose={() => setIsOpen(false)}
                      selected={selectedValue}
                      showPopperArrow={false}
                      disabled={disabled}
                      showTimeSelect={showTime}
                      shouldCloseOnSelect={true}
                      strictParsing
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                  <CalendarIcon width={20} height={20} className="mr-2 text-defence-orange-500" />
                </label>
                <label
                  className={`flex w-full cursor-pointer rounded-sm border border-defence-light-grey-500 py-2 pl-2 text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:border focus-within:bg-white focus-within:outline focus-within:outline-1 focus-within:outline-black focus:border focus:border-defence-blueGrey sm:text-sm sm:leading-6 md:hidden
            ${
              error
                ? `inputError border-defencered`
                : `inputClean border-defencemediumgrey  ${disabled ? `!cursor-default` : `cursor-pointer`}`
            }
            ${disabled ? `bg-defence-light-grey-200` : ``}`}
                >
                  <div className="customDatePickerWidth h-full w-full text-defence-black focus:outline-none">
                    <DatePicker
                      dateFormat={`dd/MM/yyyy ${showTime === true ? `HH:mm:ss` : ``}`}
                      placeholderText={fieldPlaceholder}
                      className="h-full w-full text-x0 font-light placeholder:text-defence-light-grey-900 focus:outline-none"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={selectedValue}
                      showPopperArrow={false}
                      disabled={disabled}
                      strictParsing
                      shouldCloseOnSelect={true}
                      showTimeSelect={showTime}
                      withPortal
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>

                  <CalendarIcon className="mr-2 text-defence-orange-500" width={20} height={20} />
                </label>
                <div className="h-4 text-defence-redAlert">
                  {error && error?.type === 'too_big' && (
                    <p className="flex pt-1 text-sm text-defence-redAlert">
                      <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                      The date must before todays date.
                    </p>
                  )}
                  {error && error?.type === 'invalid_type' && (
                    <p className="flex pt-1 text-sm text-defence-redAlert">
                      <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                      This field is required.
                    </p>
                  )}
                  {error && error?.type === 'custom' && (
                    <p className="flex pt-1 text-sm text-defence-redAlert">
                      <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                      This field is required.
                    </p>
                  )}
                </div>
              </>
            );
          }}
        />
      </div>
    </>
  );
};
