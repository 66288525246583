import { configureStore } from '@reduxjs/toolkit';
import eftRequestDetailFormReducer from './reducers/eftRequestDetailFormReducer';
import errorHandling from './reducers/errorHandling';
import isInternal from './reducers/internalCheckReducer';
import profileData from './reducers/profileReducer';
import requestDetailFormReducer from './reducers/requestDetailFormReducer';

import { globalConfigReducer } from './EEPortal/store/globalConfig/globalConfig.reducer';
import { metaConfReducer } from './EEPortal/store/metaConf/metaConf.reducer';
import { metaNotifReducer } from './EEPortal/store/metaNotif/metaNotif.reducer';
import { metaOrderReducer } from './EEPortal/store/metaOrder/metaOrder.reducer';
import { metaSesReducer } from './EEPortal/store/metaSes/metaSes.reducer';

export const store = configureStore({
  reducer: {
    requestForm: requestDetailFormReducer,
    EFTRequestMemberForm: eftRequestDetailFormReducer,
    error: errorHandling,
    isInternal,
    profile: profileData,
    metaNotif: metaNotifReducer,
    metaOrder: metaOrderReducer,
    metaConf: metaConfReducer,
    metaSes: metaSesReducer,
    globalConfig: globalConfigReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
