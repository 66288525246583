import { ChevronLeftIcon, ChevronRightIcon, Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import moment from 'moment';
import React, { useMemo, useState } from 'react';

export const Table = ({
  ColumnsHeadersInput,
  values,
  editRow,
  deleteRow,
  readonly,
  hideDeleteForFirstRow,
}: {
  ColumnsHeadersInput: any[];
  values: any;
  editRow?: any;
  deleteRow?: any;
  readonly?: boolean;
  hideDeleteForFirstRow?: boolean;
}) => {
  const columnHelper = createColumnHelper<{ [key: string]: string }>();
  const [tableData, setTableData] = useState([]);
  useMemo(() => {
    setTableData(values);
  }, [values]);

  const convertODataDateToDate = function (date: any) {
    if (date !== undefined) {
      const date1 = moment(date).format('DD-MMM-YYYY, HH:mm:ss');
      return date1;
    }
  };

  const columns: any[] = ColumnsHeadersInput.map((item) => {
    return columnHelper.accessor(item.accessor, {
      header: () => item.header,
      cell: (info) => (info.getValue() instanceof Date ? convertODataDateToDate(info.getValue()) : info.getValue()),
    });
  });

  if (!readonly) {
    columns.push(
      columnHelper.accessor('edit', {
        header: () => 'Action',
        cell: (info) => (
          <>
            <button
              type="button"
              onClick={() => editRow(info.row.original, info.row.index)}
              className="hover:text-defence-orange-500"
              title="Edit"
            >
              <Pencil1Icon width={25} height={25} />
            </button>
            {deleteRow && (info.row.index !== 0 || !hideDeleteForFirstRow) && (
              <button
                type="button"
                onClick={() => deleteRow(info.row.original, info.row.index)}
                className="hover:text-defence-orange-500"
                title="Delete"
              >
                <TrashIcon width={25} height={25} />
              </button>
            )}
          </>
        ),
      }),
    );
  }
  const table = useReactTable({
    data: tableData,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });
  return (
    <div className="">
      <div className="flex overflow-x-auto">
        <table className="w-full flex-shrink-0">
          <thead className="border bg-defence-orange-500 text-left font-normal text-white">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="border border-black p-2 text-x0" key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="border text-sm md:text-base">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="border even:bg-slate-200">
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id} className="fontweight-light border border-black p-2 text-x0">
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>

          <tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
      </div>
      <div className="h-2" />
      <div className="flex items-center justify-center gap-2">
        {table.getPageCount() > 0 && (
          <>
            <button className="p-1" onClick={() => table.previousPage()} disabled={!table.getCanPreviousPage()}>
              <ChevronLeftIcon className="mr-2 text-defence-orange-500" width={20} height={20} />
            </button>
            <span className="flex items-center gap-1">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
              </strong>
            </span>
            <button className="p-1" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
              <ChevronRightIcon className="mr-2 text-defence-orange-500" width={20} height={20} />
            </button>
          </>
        )}
        {table.getPageCount() === 0 && <div>No Entries</div>}
      </div>
    </div>
  );
};
