import endpoint, { ApiResponse } from "../../endpoint";
import { WorkOrder, WorkOrders } from "./types";

export const formData = () => {
  return endpoint.get("/ZPM_EDX_WORKORDER_SRV/LookupSet");
};

export const serviceRequestList = () => {
  return endpoint.get<ApiResponse<WorkOrders>>("/workorder/readtable");
};

export const serviceRequestSingle = () => {
  return endpoint.get<ApiResponse<WorkOrder>>("/workorder/readsingle");
};

export const serviceRequestTableMetadata = () => {
  return endpoint.get("/workorder/metadata");
};

export const serviceRequestConfList = () => {
  return endpoint.get("/conf/tabledata");
};

export const serviceRequestConfMetadata = () => {
  return endpoint.get("/conf/metadata");
};
//export { formData, serviceRequestList };
