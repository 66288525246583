import axios from 'axios';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { appConstants } from '../../../../helper/client/constant';
import { getApiUrl } from '../../../../helper/getOtherAppURL';

import { useDispatch, useSelector } from 'react-redux';
import { findByProp } from '../../../../helper/findByProp';
import { request } from '../../../../helper/useAxios';
import {
  setCurrentCaseDetails,
  setCurrentServiceType,
  setFormFields,
} from '../../../../reducers/requestDetailFormReducer';
import RequestDetailsForm from '../RequestDetailsForm';
import ServiceTypeDropdown from '../ServiceTypeDropdown';
import './RequestDetail.scss';
import { setShowError } from '../../../../reducers/errorHandling';

const RequestDetail = (props) => {
  const [types, setTypes] = useState();
  const dispatch = useDispatch();

  const [attachmentCategory, setattachmentCategory] = useState({
    selectedCategory: '',
    categories: [],
    categoriesOrig: [],
    selectedCategoryId: '',
    allowedFileExt: appConstants.SR_ALLOWED_FILE_EXT_IMAGE_STRING,
  });

  const [subtypes, setSubtypes] = useState('');
  const [currentType, setCurrentType] = useState('');
  const [currentSubtype, setCurrentSubType] = useState('');
  const [subtypeSelected, setSubtypeSelected] = useState(false);
  const [hideRequestDetail, setHideRequestDetail] = useState(false);
  const [description, setDescription] = useState();
  const [selectedCCCFoundInPega, setselectedCCCFoundInPega] = useState('');
  const [selectedWBSFoundInPega, setselectedWBSFoundInPega] = useState('');

  const hasEEEnabled = useSelector((state) => state.profile.myServices.hasEEEnabled);
  const apiUrl = getApiUrl;

  const changeClassAfterTypesChangeForAE547Request = (selectedServiceType) => {
    if (selectedServiceType !== 'Select') {
      let URL = appConstants.API_SERVICE_TYPE + '/' + props.caseID + `?servicetype=${selectedServiceType}`;
      URL = apiUrl + URL;
      axios.post(URL, {}, { withCredentials: true }).then((res) => {
        dispatch(
          setCurrentCaseDetails({
            currentType: selectedServiceType,
            nextAssignmentID: props.nextAssignmentId,
          }),
        );

        getAllCaseData(selectedServiceType);
      });
    }
  };

  //Determine if SR is MM or SR
  useEffect(() => {
    if (props.isminorReqFlag) {
      dispatch(
        setCurrentServiceType({
          isMMserviceType: true,
        }),
      );
    } else {
      dispatch(
        setCurrentServiceType({
          isMMserviceType: false,
        }),
      );
    }
  }, [props.isminorReqFlag]);

  useEffect(() => {
    let onLoadServiceType = '';
    const value = props.isminorReqFlag ? appConstants.SR_WORK_TYPE : appConstants.SR_SERVICE_TYPE;
    const URL_GET_WORK_TYPE = appConstants.API_REF_DATA + value;
    const final_URL_GET_WORK_TYPE = apiUrl + URL_GET_WORK_TYPE;
    !props.isReview &&
      axios
        .get(final_URL_GET_WORK_TYPE, {
          withCredentials: true,
        })

        .then((response) => {
          onLoadServiceType = response.data.refValues[0].code;
          setTypes(response.data.refValues);
          const URL_ATTACHMENT_CATEGORY = appConstants.API_CASES + props.caseIDOriginal + '/attachment_categories';
          const final_URL_ATTACHMENT_CATEGORY = apiUrl + URL_ATTACHMENT_CATEGORY;
          axios
            .get(final_URL_ATTACHMENT_CATEGORY, {
              withCredentials: true,
            })
            .then((response) => {
              if (!props.isminorReqFlag) {
                if (props.fromview === true) {
                  dispatch(
                    setCurrentCaseDetails({
                      currentType: props.workType,
                      nextAssignmentID: props.nextAssignmentId,
                    }),
                  );

                  getAllCaseData(props.workType);
                } else {
                  changeClassAfterTypesChangeForAE547Request(onLoadServiceType);
                }
              }
              const categoriesWithOutFileType = response.data.attachment_categories.filter(
                (category) =>
                  category.name !== appConstants.ATTACHMENTS_FILE_CATEGORY &&
                  category.name !== appConstants.ATTACHMENTS_JSON_CATEGORY,
              );

              setattachmentCategory((prevState) => {
                return {
                  ...prevState,
                  selectedCategory: response.data.attachment_categories[0].name,
                  selectedCategoryId: response.data.attachment_categories[0].ID,
                  categories: [...categoriesWithOutFileType],
                  categoriesOrig: [...categoriesWithOutFileType],
                  allowedFileExt: getValidFormatStringOnBasisOfCategory(response.data.attachment_categories[0].name),
                };
              });
            });
        })
        .catch((error) => {
          dispatch(setShowError({ hasError: true, error }));
        });
  }, []);

  const getAllCaseData = (selectedServiceType) => {
    setHideRequestDetail(true);
    dispatch(
      setFormFields({
        fieldList: [],
      }),
    );
    const URL = appConstants.API_ASSIGNMENTS + props.nextAssignmentId + '/actions/' + props.actionID;
    request(URL, 'get').then((res) => {
      let fieldList = [];
      let groupObjectforServiceRequest;
      fieldList.push({
        field: findByProp(res.data, 'reference', 'ServiceRequest.RequestTitle'),
      });
      fieldList.push({
        field: findByProp(res.data, 'reference', 'ServiceRequest.RequestRelatesTo'),
      });
      fieldList.push({
        field: findByProp(res.data, 'reference', 'ServiceRequest.RequestRelationDetail'),
      });

      if (!props.isminorReqFlag) {
        groupObjectforServiceRequest = findByProp(res.data, 'viewID', 'StandardServiceRequestReact');
      } else {
        groupObjectforServiceRequest = findByProp(res.data, 'viewID', 'SimpleServiceRequest');
      }

      fieldList = [...fieldList, ...groupObjectforServiceRequest.groups[0].layout.groups];
      dispatch(
        setFormFields({
          fieldList,
        }),
      );

      setHideRequestDetail(false);
    });
  };
  useEffect(() => {
    if (!props.isReview && types && props.fromview === undefined) {
      setCurrentType(types[0]);
    } else if (!props.isReview && types && props.fromview === true) {
      setDescription(props.description);

      // NOTE: WorkType can never blank on create req page so setting the first dropdown value if its blank.
      let validWorkType = props.workType.length > 0 ? props.workType : types[0].code;
      //For older requests that are Access/ RescueFireFight change to Select Option
      if (validWorkType === 'Access' || validWorkType === 'RescueFireFight') {
        validWorkType = 'Select';
      }
      const workTypeItem = types.filter((item) => item.code === validWorkType);
      setCurrentType(workTypeItem[0]);
    }

    // !props.isReview && types && setCurrentType(types[0]);
  }, [types]);

  useEffect(() => {
    if (currentSubtype !== 'Select') {
      let URL =
        appConstants.API_SERVICE_TYPE +
        props.caseID +
        `?servicetype=BuildingMaintenance&worktype=${currentType?.code}&worksubtype=${currentSubtype}`;
      URL = apiUrl + URL;
      currentSubtype &&
        axios.post(URL, {}, { withCredentials: true }).then((res) => {
          getAllCaseData();
        });
    }
  }, [currentType, currentSubtype]);

  useEffect(() => {
    const commonFieldValueforSimple = [
      {
        fieldID: 'WorkType',
        reference: 'ServiceRequest.WorkType',
        value: currentType?.code,
      },
      {
        fieldID: 'WorkSubType',
        reference: 'ServiceRequest.WorkSubType',
        value: currentSubtype,
      },
      {
        fieldID: 'pyDescription',
        reference: 'ServiceRequest.pyDescription',
        value: description,
      },
    ];
    const commonFieldValueforAE547 = [
      {
        fieldID: 'WorkType',
        reference: 'ServiceRequest.WorkType',
        value: currentType?.code,
      },

      {
        fieldID: 'pyDescription',
        reference: 'ServiceRequest.pyDescription',
        value: description,
      },
    ];
    if (props.isminorReqFlag) {
      !props.isReview && props.handleUpdate(commonFieldValueforSimple);
    } else {
      !props.isReview && props.handleUpdate(commonFieldValueforAE547);
    }
  }, [currentType, currentSubtype, description]);

  useEffect(() => {
    if (currentType?.code !== 'Select') {
      if (props.isminorReqFlag) {
        let URL = appConstants.API_WORK_TYPE + `?worktype=${currentType?.code}`;
        URL = apiUrl + URL;
        currentType &&
          axios
            .get(URL, {
              withCredentials: true,
            })
            .then((response) => {
              setSubtypes(response.data.refValues);

              if (props.fromview === undefined) {
                select(response.data.refValues[0].code);
              } else if (props.fromview === true) {
                const workSubTypeItem = response.data.refValues.filter((item) => item.code === props.workSubType);

                const selctedValidSubType =
                  workSubTypeItem.length !== 0 ? workSubTypeItem[0].code : response.data.refValues[0].code;

                select(selctedValidSubType);
              }
            })
            .catch((error) => {
              dispatch(setShowError({ hasError: true, error }));
            });
      }
    }
  }, [currentType]);

  const handleCallBackChangeType = (type) => {
    setCurrentType(type);
    setSubtypeSelected(false);
    // below function to update the attachment category by default if previously waste hazard is selected

    updateattachmentCategoryasPerFlag(false);
    if (!props.isminorReqFlag) {
      changeClassAfterTypesChangeForAE547Request(type.code);
    }
  };

  const handleCallBackError = (validationValue) => {
    //set variable to boolean for error handling
    props.dropdownError(validationValue);
  };

  const getValidFormatStringOnBasisOfCategory = (category) => {
    if (category === appConstants.CATEGORY_IMAGE) {
      return appConstants.SR_ALLOWED_FILE_EXT_IMAGE_STRING;
    } else if (
      category === appConstants.CATEGORY_TYPE_NAME_WASTE_MANIFEST ||
      category === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST
    ) {
      return appConstants.SR_ALLOWED_FILE_EXT_TRANSPORT_WASTE_STRING;
    } else return appConstants.SR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING;
  };
  //check CCC is avaliable in pega (validation)
  const checkCCCAvailInPega = (value) => {
    request(appConstants.API_VALIDATE_CCC_WBS + value, 'get', { id: '/VH_CostcenterSet' })
      .then((response) => {
        const CCCMessage = response.data.isValid
          ? appConstants.COST_CENTER_CODE_FOUND
          : appConstants.COST_CENTER_CODE_NOT_FOUND;
        setselectedCCCFoundInPega(CCCMessage);
      })
      .catch((error) => dispatch(setShowError({ hasError: true, error })));
  };

  //check WBS is avaliable in pega (validation)
  const checkWBSAvailInPega = (value) => {
    if (hasEEEnabled === true) {
      request(appConstants.API_VALIDATE_CCC_WBS + value, 'get', { id: '/VH_WBSSet' })
        .then((response) => {
          const WBSMessage = response.data.isValid ? appConstants.WBS_FOUND : appConstants.WBS_NOT_FOUND;
          setselectedWBSFoundInPega(WBSMessage);
        })
        .catch((error) => dispatch(setShowError({ hasError: true, error })));
    }
  };

  const updateattachmentCategoryasPerFlag = (val) => {
    if (val === 'Yes') {
      setattachmentCategory((prevState) => {
        const filteredArrforWaste = attachmentCategory.categories?.filter((item) => item?.ID === 'WasteManifest');
        return {
          ...prevState,
          selectedCategory: filteredArrforWaste[0]?.name,
          selectedCategoryId: filteredArrforWaste[0]?.ID,
          categories: [...filteredArrforWaste],

          allowedFileExt: getValidFormatStringOnBasisOfCategory(filteredArrforWaste[0]?.name),
        };
      });
    } else {
      setattachmentCategory((prevState) => {
        return {
          ...prevState,
          selectedCategory: prevState?.categoriesOrig[0]?.name,
          selectedCategoryId: prevState?.categoriesOrig[0]?.ID,
          categories: [...prevState?.categoriesOrig],

          allowedFileExt: getValidFormatStringOnBasisOfCategory(prevState?.categoriesOrig[0]?.name),
        };
      });
    }
  };
  const select = (type) => {
    setCurrentSubType(type);
    setSubtypeSelected(true);
  };
  return (
    <>
      {(currentType || props.isReview) && (
        <Container className="bg-defencewhite mt-4">
          <Container>
            <h1>Request information</h1>
            <ServiceTypeDropdown
              isReview={props.isReview}
              isminorReqFlag={props.isminorReqFlag}
              types={types}
              currentType={currentType}
              handleChangeType={handleCallBackChangeType}
              isError={handleCallBackError}
            />
            {currentType?.description !== 'Please select a service type' ? (
              <>
                <Container>
                  {!props.isReview && (
                    <Container className="w-100">
                      <Container className="w-lg-75  bg-defencenavy mb-4 p-4 text-white">
                        <p className="fw-light">{currentType.longDescription}</p>
                      </Container>
                    </Container>
                  )}
                </Container>
                <Form onSubmit={(event) => event.preventDefault()} encType="multipart/form-data">
                  {currentType?.description !== 'Please select a service type' ? (
                    <Container>
                      {props.isminorReqFlag && (
                        <Form.Group controlId="subtypes">
                          <Form.Label className="p-lg-0">What do you need help with?</Form.Label>
                          <br />
                          <Container className="d-flex subTypes mb-4 gap-5 p-1">
                            {!props.isReview ? (
                              subtypes &&
                              subtypes.map((radio, idx) => (
                                <Button
                                  role="radio"
                                  aria-checked={currentSubtype === radio.code}
                                  key={idx}
                                  id={`radio-${idx}`}
                                  type="radio"
                                  name="radio"
                                  value={radio.code}
                                  variant="subtype"
                                  checked={currentType === radio.code}
                                  className={`${currentSubtype === radio.code && 'checked'} d-flex flex-column p-3`}
                                  onClick={(e) => select(e.currentTarget.value)}
                                >
                                  <p className="fw-bold">{radio.description}</p>
                                  <p className="fw-light">{radio.longDescription}</p>
                                </Button>
                              ))
                            ) : (
                              <p className="fw-light">{props.data[1].value}</p>
                            )}
                          </Container>
                        </Form.Group>
                      )}
                    </Container>
                  ) : (
                    <></>
                  )}
                </Form>

                {((!hideRequestDetail && subtypeSelected) || !props.isminorReqFlag) && (
                  <RequestDetailsForm
                    caseID={props.caseID}
                    hazardousChanged={updateattachmentCategoryasPerFlag}
                    caseIDOriginal={props.caseIDOriginal}
                    actionID={props.actionID}
                    currentType={currentType}
                    currentSubtype={currentSubtype}
                    subtypeSelected={subtypeSelected}
                    nextAssignmentId={props.nextAssignmentId}
                    isReview={props.isReview}
                    attachmentCategory={attachmentCategory}
                    isminorReqFlag={props.isminorReqFlag}
                    checkCCCAvail={checkCCCAvailInPega}
                    checkWBSAvail={checkWBSAvailInPega}
                    cccMessageFromPega={selectedCCCFoundInPega}
                    wbsMessageFromPega={selectedWBSFoundInPega}
                  ></RequestDetailsForm>
                )}
              </>
            ) : (
              <></>
            )}
          </Container>
        </Container>
      )}
    </>
  );
};

export default RequestDetail;
