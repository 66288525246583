import { Listbox } from '@headlessui/react';
import { ChevronDownIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import { TypeAheadCodeSet, lookupCodeSet } from '../../../lib/api/lookup';

type TSelectInputItem = {
  code: string;
  description: string;
  name: string;
};
type TSelectInput = {
  fieldLabel: string;
  fieldPlaceholder: string;
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  codeSet: TypeAheadCodeSet;
  codeSetLabelField: string;
  codeSetValueField: string;
  codeSetFilterField?: string;
  codeDepFilterField?: string;
  codeDepFilterVal?: string;
  additionalFilter?: string;
  tooltip?: string;
  top?: any;
  codeDisplayField?: string;
};

export const SingleSelectInputCode = ({
  fieldLabel,
  fieldPlaceholder,
  name,
  control,
  required = false,
  disabled = false,
  defaultValue,
  codeSet,
  codeSetLabelField,
  codeSetValueField,
  codeSetFilterField = 'Search',
  codeDepFilterField,
  codeDepFilterVal,
  additionalFilter,
  tooltip,
  top = 100,
  codeDisplayField,
}: TSelectInput) => {
  const [selectedItem, setSelectedItem] = useState('');
  const [options, setOptions] = useState<TSelectInputItem[]>([]);
  const [msgError, setMsgError] = useState('');
  const [loading, setLoading] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (codeDepFilterVal) {
          let filter = codeDepFilterVal ? `$filter=(${codeDepFilterField} eq '${codeDepFilterVal}')` : undefined;
          if (additionalFilter !== undefined) {
            filter = `${filter} ${additionalFilter}`;
          }
          const sapResults = await lookupCodeSet(codeSet, filter);
          const results = convertSapData(sapResults);
          const data: TSelectInputItem[] = results.map((item) => ({
            description: item[codeSetLabelField],
            code: item[codeSetValueField],
            name: codeDisplayField === undefined ? '' : item[codeDisplayField],
          }));
          setOptions(data);
        } else if (codeSet) {
          const filter = `$top=${top}`;
          const sapResults = await lookupCodeSet(codeSet, filter);
          const results = convertSapData(sapResults);
          const data: TSelectInputItem[] = results.map((item) => ({
            description: item[codeSetLabelField],
            code: item[codeSetValueField],
            name: codeDisplayField === undefined ? '' : item[codeDisplayField],
          }));
          setOptions(data);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setMsgError('Error fetching data from backend');
      }
    })();
  }, [codeSet, codeDepFilterVal]);

  const getSelectedItem = (code: string) => {
    const index = options.findIndex((item) => item.code === code);
    if (index >= 0) {
      return options[index].description;
    }
    return '';
  };

  const convertSapData = function (data: any) {
    if (data) {
      data.map((row: any) =>
        Object.keys(row).forEach((item) => {
          if (typeof row[item] === 'object') {
            delete row[item];
          } else {
            if (typeof row[item] === 'string' && row[item].includes('/Date(')) {
              const stringForDateFunction = 'return new ' + row[item].replaceAll('/', ''); //Odata return miliseconds for date, need to convert
              const getDate = new Function(stringForDateFunction); //run string as a function to get real date
              row[item] = moment(getDate()).format('DD-MM-YYYY');
            }
          }
        }),
      );

      return data;
    }
  };

  useEffect(() => {
    if (defaultValue && options.length) {
      const selectedItem = getSelectedItem(defaultValue);
      if (selectedItem) {
        setSelectedItem(selectedItem);
      }
    }
  }, [defaultValue, options]);

  return (
    <div>
      <label className="mb-2 mt-2 flex text-x0">
        {fieldLabel} {required && <p className="m-0 inline  text-defence-redAlert"> *</p>}
        <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          if (value) {
            const selectedItem = getSelectedItem(value);
            if (selectedItem) {
              setSelectedItem(selectedItem);
            }
          }
          return (
            <div className="relative">
              <Listbox
                disabled={disabled}
                value={selectedItem}
                onChange={(e: any) => {
                  onChange(e?.code);
                }}
              >
                <Listbox.Button
                  className={`flex w-full content-center items-center overflow-auto rounded-md border border-defence-light-grey-500 py-1.5 pl-2 text-left text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:outline-black focus:border focus:border-defence-blueGrey focus:bg-white focus-visible:outline enabled:bg-white disabled:bg-defence-light-grey-200 
                  ${error ? `inputError border-defencered` : `inputClean border-defencemediumgrey`} ${
                    disabled ? `!cursor-default` : `cursor-pointer`
                  }`}
                >
                  {selectedItem ? (
                    <p className="mb-0 w-full text-black">{selectedItem} </p>
                  ) : (
                    <p className="mb-0 w-full text-defence-black">{fieldPlaceholder}</p>
                  )}
                  <ChevronDownIcon height={30} width={30} className="mx-2 text-defence-orange-500" />
                </Listbox.Button>
                <Listbox.Options
                  className={`!absolute z-10 w-full overflow-y-auto border-2 border-t-0 bg-white shadow-md ${
                    options.length >= 10 ? `h-96` : ``
                  }`}
                >
                  {options.map((item) => (
                    <Listbox.Option
                      key={item.code}
                      value={item}
                      className="cursor-pointer  p-2 ui-active:bg-defence-light-grey-200 "
                    >
                      {codeDisplayField === undefined ? item.code + ' : ' : item.name + ' : '}
                      {item.description}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
              <div className="h-4">
                {error && error?.type === 'custom' && (
                  <p className="flex pt-1 text-sm text-defence-redAlert">
                    <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                    This field is required.
                  </p>
                )}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
