import { METASES_TYPES } from "./metaSes.types";

const INITIAL_STATE = {
  currentMeta: {},
};

export const metaSesReducer = (state = INITIAL_STATE, action: any) => {
  const { type, payload } = action;

  switch (type) {
    case METASES_TYPES.SET_CURRENT_META:
      return { ...state, currentMeta: payload };
    default:
      return state;
  }
};
