import React from 'react';
import { Helmet, HelmetProps, HelmetProvider } from 'react-helmet-async';
import { Banner, BannerProps } from './Banner';
import LandingPageBanner from './LandingPageBanner';
import { SubMenu, SubMenuProps } from './SubMenu';

export interface BaseLayoutProps {
  children: React.ReactNode;
  headProps: HelmetProps;
  submenuProps?: SubMenuProps;
  bannerProps?: BannerProps;
  showSubmenu?: boolean;
  showLandingPageBanner?: boolean;
}

export const BaseLayout = ({
  headProps,
  children,
  submenuProps = {
    title: '',
  },
  bannerProps = {
    title: '',
    description: '',
    bannerImgSrc: '',
  },
  showSubmenu = true,
  showLandingPageBanner = false,
}: BaseLayoutProps) => {
  const { title, ...otherHeadProps } = headProps;
  return (
    <>
      <HelmetProvider>
        <Helmet {...otherHeadProps} title={`${title} | EE Portal`} />
      </HelmetProvider>
      {showSubmenu && <SubMenu {...submenuProps} />}
      {showLandingPageBanner ? (
        <LandingPageBanner
          heading={bannerProps.title}
          subheading={bannerProps.description || ''}
          backgroundImgURL={`url(${require(`../assets/headerImage.png`)})`}
          mobileBackgroundImgURL={`url(${require(`../assets/mobileHeaderbg.png`)})`}
        />
      ) : (
        <Banner {...bannerProps} />
      )}
      <div className="flex flex-col justify-center bg-white">{children}</div>
    </>
  );
};
