import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { MessageModal } from '../../components/MessageModal';
import { Banner } from '../../components/Banner';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import { TextAreaInput } from '../../components/form/inputs/textAreaInput';
import { TextInput } from '../../components/form/inputs/textInput';
import { serviceRequestTableMetadata } from '../../lib/api/orders';
import { CancelWorkOrderConfirmationResponse, WorkOrderConfirmationResponse } from '../../lib/api/generated-types';
import convertSapMetadata from '../../lib/convertMetadata';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaOrder/metaOrder.action';
import { selectCurrentMeta } from '../../store/metaOrder/metaOrder.selector';
import { axiosInstance } from '../../lib/api/instance';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { formatDateToODataDate } from '../../lib/date';
import convertData from '../../lib/convertData';

export const ConfirmationCancel = () => {
  const [singledata, setSingledata] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 2 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (ref.current) {
          const { data: TableMetadata } = await serviceRequestTableMetadata();
          const formMetaData = convertSapMetadata(TableMetadata);
          dispatch(setCurrentMeta(formMetaData));
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);

  const schema = z
    .object({
      WorkOrderNo: z.string().optional(),
      ConfirmationText: z.string().optional(),
      SystemStaus: z.string().optional(),
      MainWorkCentre: z.string().optional(),
      ActualStartDateTime: z.string().optional(),
      ActualEndDateTime: z.string().optional(),
      ActualWorkQuantity: z.string().optional(),
      ActualWorkUnitOfMeasure: z.string().optional(),
      AccountingIndicator: z.string().optional(),
      ReversedConfirmationIndicator: z.string().optional(),
      Message: z.string().superRefine((val, ctx) => {
        if (val === '') {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: `super refine triggered.`,
          });
        }
      }),
    })
    .and(
      z.object({
        ConfirmationNo: z
          .string()
          .max(metadata['ConfirmationNo'] ? metadata['ConfirmationNo'].LENGTH : 10)
          .superRefine((val, ctx) => {
            if (val === '') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
        ConfirmationCounter: z
          .string()
          .max(metadata['ConfirmationCounter'] ? metadata['ConfirmationCounter'].LENGTH : 8)
          .superRefine((val, ctx) => {
            if (val === '') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
      }),
    );

  type FormSchema = z.infer<typeof schema>;

  const [defaultValues, setDefaultValues] = useState<FormSchema>({
    WorkOrderNo: '',
    ConfirmationText: '',
    SystemStaus: '',
    MainWorkCentre: '',
    ActualWorkQuantity: '',
    ActualWorkUnitOfMeasure: '',
    AccountingIndicator: '',
    ReversedConfirmationIndicator: '',
    ActualStartDateTime: '',
    ActualEndDateTime: '',
    ConfirmationNo: '',
    ConfirmationCounter: '',
    Message: '',
  });

  useEffect(() => {
    setDefaultValues({
      WorkOrderNo: singledata.WorkOrderNo,
      ConfirmationText: singledata.ConfirmationText,
      SystemStaus: singledata.SystemStaus,
      MainWorkCentre: singledata.MainWorkCentre,
      ActualWorkQuantity: singledata.ActualWorkQuantity,
      ActualWorkUnitOfMeasure: singledata.ActualWorkUnitOfMeasure,
      AccountingIndicator: singledata.AccountingIndicator,
      ReversedConfirmationIndicator: JSON.stringify(singledata.ReversedConfirmationIndicator),
      ActualStartDateTime: singledata.ActualStartDateTime,
      ActualEndDateTime: singledata.ActualEndDateTime,
      ConfirmationNo: singledata.ConfirmationNo,
      ConfirmationCounter: singledata.ConfirmationCounter,
      Message: '',
    });
  }, [singledata]);

  const methods = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (defaultValues) {
      methods.reset({ ...defaultValues });
    }
  }, [defaultValues, methods.reset]);

  const readSingleConf = async () => {
    try {
      setLoading(true);
      const confirmationQuery =
        `(ConfirmationNo='` +
        methods.getValues('ConfirmationNo') +
        `',ConfirmationCounter='` +
        methods.getValues('ConfirmationCounter') +
        `')`;

      const respData = await axiosInstance.get<WorkOrderConfirmationResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_CONF_SING_REQ_LIST)}${confirmationQuery}?$format=json`,
      );

      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMessage(titleMessage);
      setMessages(messages);

      const confData = respData?.data;
      const singleConfData = convertData(confData) || {};
      setSingledata(singleConfData);
      setLoading(false);
      if (titleMessage === 'Success') {
        nextStep();
      }
    } catch (error) {
      setLoading(false);
      const errorResponse = isAxiosError(error) ? error.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const onSubmit = async (data: FormSchema) => {
    setLoading(true);
    setErrorMessages([]);

    const resultData = convertFormDates(data);
    const formDataQuery =
      `ConfNo='` +
      resultData.ConfirmationNo +
      `'&ConfCnt='` +
      resultData.ConfirmationCounter +
      `'&CancelText='` +
      resultData.Message +
      `'`;

    try {
      const response = await axiosInstance.post<CancelWorkOrderConfirmationResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_CONF_CANCEL)}?${formDataQuery}&$format=json`,
        resultData,
      );
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
      setTitleMessage(titleMessage);
      setMessages(messages);

      if ((response.status === 200 || response.status === 201) && response?.data?.d?.ConfNo) {
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Confirmation ' + response?.data?.d?.ConfNo + ' is cancelled.' || '',
        ]);

        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    } catch (err) {
      setLoading(false);
      const errorResponse = isAxiosError(err) ? err.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);

      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const convertFormDates = function (data: any) {
    try {
      Object.keys(data).forEach((item) => {
        if (typeof data[item] === 'object' && data[item] instanceof Date === true) {
          if (data[item]) {
            data[item] = formatDateToODataDate(data[item]);
          }
        }
      });
    } catch (err) {
      console.log('errors: ', err);
    }
    return data;
  };

  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Confirmation Details',
      fields: ['ConfirmationNo', 'ConfirmationCounter'],
    },
    {
      code: 2,
      name: 'Order Details',
      fields: [
        'WorkOrderNo',
        'ConfirmationText',
        'SystemStaus',
        'MainWorkCentre',
        'ActualStartDateTime',
        'ActualEndDateTime',
        'ActualWorkQuantity',
        'ActualWorkUnitOfMeasure',
        'AccountingIndicator',
        'ReversedConfirmationIndicator',
      ],
    },
    {
      code: 3,
      name: 'Cancellation Details',
      fields: ['Message'],
    },
  ];

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <>
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage === 'Error' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      {messages.length > 0 && titleMessage === 'Success' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          navigateLink={`/eeportal/orders/confirmations/${methods.getValues(
            'ConfirmationNo',
          )}/read?confirmationCounter=${methods.getValues('ConfirmationCounter')}`}
        ></MessageModal>
      )}
      <SubMenu title="Confirmations" selectedIndex="0" />
      <Banner title="Cancel Confirmation" description="" bannerImgSrc="" />
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                      ? FormStepperEnum.Complete
                      : FormStepperEnum.Upcoming,
              }))}
            />
          </div>
          <div className="w-full pt-3">
            <SubBanner title={sections[step - 1].name} />
          </div>

          <div className="w-full p-2">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, goToErrors)}>
                {step === 1 && (
                  <div className="mb-8 w-full">
                    <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Confirmation Number"
                          fieldPlaceholder=" "
                          name="ConfirmationNo"
                          control={methods.control}
                          required={true}
                        />
                        <TextInput
                          fieldLabel="Confirmation Count"
                          fieldPlaceholder=" "
                          name="ConfirmationCounter"
                          control={methods.control}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="mb-8 w-full">
                    <div className="grid h-full grid-flow-row grid-cols-1  gap-1 md:grid-cols-1 md:gap-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Work Order Number"
                          fieldPlaceholder=" "
                          name="WorkOrderNo"
                          control={methods.control}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Confirmation Text"
                          fieldPlaceholder=" "
                          name="ConfirmationText"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="System Status"
                          fieldPlaceholder=" "
                          name="SystemStaus"
                          control={methods.control}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Main Work Centre"
                          fieldPlaceholder=" "
                          name="MainWorkCentre"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Work Start Date and Time"
                          fieldPlaceholder=" "
                          name="ActualStartDateTime"
                          control={methods.control}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Work Finish Date and Time"
                          fieldPlaceholder=" "
                          name="ActualEndDateTime"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Actual Work"
                          fieldPlaceholder=" "
                          name="ActualWorkQuantity"
                          control={methods.control}
                          disabled={true}
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.UoM}
                          codeSetLabelField="Description"
                          codeSetValueField="Uom"
                          codeSetFilterField="Description"
                          fieldLabel="UoM"
                          fieldPlaceholder=" "
                          control={methods.control}
                          name="ActualWorkUnitOfMeasure"
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.AccountingIndicator}
                          codeSetLabelField="AccountingIndicatorText"
                          codeSetValueField="AccountingIndicator"
                          codeSetFilterField="AccountingIndicatorText"
                          fieldLabel="Accounting Indicator"
                          fieldPlaceholder=" "
                          control={methods.control}
                          name="AccountingIndicator"
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Reversed"
                          fieldPlaceholder=" "
                          name="ReversedConfirmationIndicator"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="mb-8 w-full">
                    <div className="grid w-full grid-cols-1 grid-cols-1">
                      <TextAreaInput
                        fieldLabel="Reason Text"
                        fieldPlaceholder=""
                        name="Message"
                        control={methods.control}
                        required={true}
                      />
                    </div>
                  </div>
                )}
                <div className="flex w-full justify-end">
                  <div className="flex-end flex">
                    {step === 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          readSingleConf();
                        }}
                        className="button-primary bg-defence-orange-500"
                      >
                        Next
                      </button>
                    )}
                    {step === 2 && (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            backStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            nextStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Next
                        </button>
                      </div>
                    )}
                    {step === 3 && (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            backStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Back
                        </button>
                        <button type="submit" onClick={() => { }} className="button-primary mr-0 bg-defence-orange-500">
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};
