import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, Image, Row } from 'react-bootstrap';
import {
  Box,
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import InfoBox from '../../ServiceUI/Core/InfoBox/InfoBox';
import { InfoCircle } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Select from 'react-select';
import { appConstants } from '../../../helper/client/constant';
import switchboard from '../../../images/switchBoard.svg';
import './LocationPreviewModal.scss';
import { setShowError } from '../../../reducers/errorHandling';
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../../helper/useAxios';
import { SMSConsent } from '../../ServiceUI/LandingPage/SMSConsent/SMSConsent';
import { convertDateToDDMMYYYY } from '../../../helper/convertDateToDDMMYYYY';

const LocationPreviewModal = (props) => {
  const dispatch = useDispatch();
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      This could be your primary working location, place of residence, and/or the Defence site you visit most
      frequently. When you create forms or request services, ServiceConnect will pre-fill with your nominated home base
      location. If your preferred home base is not listed below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );
  const renderWorkingLocationTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your preferred working location is not appearing in the list below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );
  const renderAlternateLocationTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your preferred alternate base is not appearing in the list below, please send this feedback to
      yourcustomer.service@defence.gov.au, and select the Defence base or location nearest to you in the meantime.
    </Tooltip>
  );

  const [show, setShow] = useState(true);
  const [showchangeDropDown, setchangeDropDown] = useState(false);
  const [showLocationDropDown, setLocationDropDown] = useState(false);
  const [showAlternateDropDown, setAlternateDropDown] = useState(false);
  const usersPhone = useSelector((state) => state.profile.phone);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const isPorod = useSelector((state) => state.profile.email[0].address.split('@')[1]?.includes('defence.gov.au'));
  const hasSMSEnabled = useSelector((state) => state.profile.myServices.hasSMSEnabled);
  const [smsSettings, setSMSSettings] = useState({ value: '', isValid: true, isChecked: false });

  const setField = (field, value) => {
    setForm({ ...form, [field]: value });

    if (!!errors[field]) {
      setErrors({ ...errors, [field]: null });
    }
  };

  const addWorkLocationClickHandler = () => {
    setLocationDropDown(true);
  };

  const addAlternateLocationClickHandler = () => {
    setAlternateDropDown(true);
  };

  const changeClickHandler = () => {
    setchangeDropDown(true);
  };

  const [dropDownLocationBaseName, setDropDownLocationBaseName] = useState({
    dropDownArrayBaseName: [],
    selectedValue: appConstants.PROFILE_DEFAULT_BASE,
    selectedAddress: 'Northcott Dr, Campbell ACT 2600',
  });

  const [dropDownWorkingLocName, setdropDownWorkingLocName] = useState({
    dropDownArrayWorkingLocName: [],
    selectedWorkingLocation: '',
  });
  const [dropDownAlternateLocName, setdropDownAlternateLocName] = useState({
    dropDownArrayAlternateLocName: [],
    selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
  });

  const getBaseNameDropDown = () => {
    request(appConstants.API_BASE_LOCATION, 'get')
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);
        setDropDownLocationBaseName((prevState) => {
          return {
            ...prevState,
            dropDownArrayBaseName: res.data.locDetails,
          };
        });

        if (props.alternateBaseName !== '') {
          addAlternateLocationClickHandler();

          form.alternateBaseLocation = props.alternateBaseAddress;

          form.startdatealternatelocation = props.alternateBaseStart;
          form.enddatealternatelocation = props.alternateBaseEnd;

          setdropDownAlternateLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayAlternateLocName: locDetailsBase,
              selectedAlternateBase: props.alternateBaseName,
              startdatealternatelocation: props.alternateBaseStart,
              enddatealternatelocation: props.alternateBaseEnd,
            };
          });
        } else {
          setdropDownAlternateLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayAlternateLocName: locDetailsBase,
              selectedAlternateBase: appConstants.PROFILE_LOCSELECT,
            };
          });
        }
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };

  const getWorkingLocDropDown = () => {
    request(appConstants.API_LOCATION_DETAILS, 'get')
      .then((res) => {
        const locDetailsBase = [{ name: 'Select' }].concat(res.data.locDetails);
        if (props.workBaseName !== '') {
          addWorkLocationClickHandler();
          setdropDownWorkingLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayWorkingLocName: locDetailsBase,
              selectedWorkingLocation: props.workBaseName,
            };
          });
        } else {
          setdropDownWorkingLocName((prevState) => {
            return {
              ...prevState,
              dropDownArrayWorkingLocName: locDetailsBase,
              selectedWorkingLocation: appConstants.PROFILE_LOCSELECT,
            };
          });
        }
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const validateForm = () => {
    const { alternateBaseLocation, startdatealternatelocation, enddatealternatelocation } = form;

    const newErrors = {};

    if (!alternateBaseLocation || alternateBaseLocation === appConstants.PROFILE_LOCSELECT) {
      newErrors.alternateBaseLocation = appConstants.PROFILE_ERROR_ALT_BASE;
    }

    if (alternateBaseLocation !== appConstants.PROFILE_LOCSELECT) {
      let startDate = new Date(startdatealternatelocation);

      let endDate = new Date(enddatealternatelocation);

      if (!startdatealternatelocation || startdatealternatelocation === '') {
        newErrors.startdatealternatelocation = appConstants.PROFILE_ERROR_START_DATE;
      }
      if (!enddatealternatelocation || enddatealternatelocation === '' || endDate < startDate) {
        newErrors.enddatealternatelocation = appConstants.PROFILE_ERROR_END_DATE;
      }
    }
    return newErrors;
  };

  const goToServiceConnectHomePage = () => {};

  //Profile Creation - Update Details Handler
  const updateDetailsHandler = (e) => {
    e.preventDefault();
    const updatePhone = () => {
      const phoneData = {
        phone: [
          { IsPrimary: 'true', Type: 'Work', Number: usersPhone[0]?.number },
          {
            IsPrimary: 'false',
            Type: 'Mobile',
            Number: smsSettings.isChecked ? smsSettings.value : usersPhone[1]?.number,
          },
        ],
      };
      request(appConstants.API_USER_PROFILE_DETAILS, 'put', { data: phoneData });
      request(appConstants.API_USER_PROFILE_DETAILS, 'patch', {
        data: { smsConsent: smsSettings.isChecked, smsConsentDate: convertDateToDDMMYYYY(new Date()) },
      });
    };
    const createError = validateForm();
    if (Object.keys(createError).length > 0) {
      setErrors(createError);
    } else {
    }
    //current environment is DEV/TST/TST2 and higher envs
    if (process.env.REACT_APP_CURRENT !== appConstants.REACT_APP_LOCAL_ENV) {
      let queryString = '?homebasename=' + encodeURIComponent(dropDownLocationBaseName.selectedValue);
      if (
        dropDownAlternateLocName.selectedAlternateBase &&
        dropDownAlternateLocName.selectedAlternateBase !== appConstants.PROFILE_LOCSELECT
      ) {
        queryString =
          queryString +
          '&alternatebasename=' +
          encodeURIComponent(dropDownAlternateLocName.selectedAlternateBase) +
          '&startdate=' +
          form.startdatealternatelocation +
          '&enddate=' +
          form.enddatealternatelocation;
      }
      if (
        dropDownWorkingLocName.selectedWorkingLocation &&
        dropDownWorkingLocName.selectedWorkingLocation !== appConstants.PROFILE_LOCSELECT
      ) {
        queryString =
          queryString + '&workinglocationname=' + encodeURIComponent(dropDownWorkingLocName.selectedWorkingLocation);
      }
      //not local POST call
      request(appConstants.API_LOCATION, 'post', { id: queryString })
        .then((res) => {
          if (res.status === 200) {
            hasSMSEnabled && updatePhone();
            goToServiceConnectHomePage();
            setShow(false);
            props.onComingtoHomePageFromUpdate();
          }
        })
        .catch((error) => {
          dispatch(setShowError({ hasError: true, error }));
        });
    }
    //current environment is LOCAL environment
    else {
      const finalAlternateValue =
        dropDownAlternateLocName.selectedAlternateBase === appConstants.PROFILE_LOCSELECT
          ? ''
          : dropDownAlternateLocName.selectedAlternateBase;
      const finalWorkingLocation =
        dropDownWorkingLocName.selectedWorkingLocation === appConstants.PROFILE_LOCSELECT
          ? ''
          : dropDownWorkingLocName.selectedWorkingLocation;
      const finalStartdatealternatelocation = !!form.startdatealternatelocation ? form.startdatealternatelocation : '';

      const finalEnddatealternatelocation = !!form.enddatealternatelocation ? form.enddatealternatelocation : '';
      //?homebasename=${baseName}&alternatebasename=${altName}&workinglocationname=${workName}`;

      let rest = `?homebasename=${encodeURIComponent(
        dropDownLocationBaseName.selectedValue,
      )}&alternatebasename=${finalAlternateValue}&workinglocationname=${finalWorkingLocation}&startdate=${finalStartdatealternatelocation}&enddate=${finalEnddatealternatelocation}`;

      //local POST call
      request(appConstants.API_LOCATION, 'post', { id: rest })
        .then((res) => {
          hasSMSEnabled && updatePhone();
          goToServiceConnectHomePage();
          setShow(false);
          props.onComingtoHomePageFromUpdate();
        })
        .catch((error) => {
          dispatch(setShowError({ hasError: true, error }));
        });
    }
  };

  const loadLocationDropdowns = () => {
    getBaseNameDropDown();
    getWorkingLocDropDown();
    if (props.baseName !== '') {
      setDropDownLocationBaseName((prevState) => {
        return {
          ...prevState,
          selectedValue: props.baseName,
          selectedAddress: props.baseAddress,
        };
      });
    } else {
      setDropDownLocationBaseName((prevState) => {
        return {
          ...prevState,
          selectedValue: appConstants.PROFILE_DEFAULT_BASE,
        };
      });
    }
  };

  const handleBaseSelect = (text, selectedBaseNameObj) => {
    setDropDownLocationBaseName((prevState) => {
      return {
        ...prevState,
        selectedValue: text,
        selectedAddress: selectedBaseNameObj.baseAddress,
      };
    });
  };
  const handleWorkingLocationSelect = (text, selectedBaseNameObj) => {
    setdropDownWorkingLocName((prevState) => {
      return {
        ...prevState,
        selectedWorkingLocation: text,
      };
    });
  };

  const handleAlternateBaseSelect = (text, selectedBaseNameObj) => {
    form.alternateBaseLocation = text;

    setdropDownAlternateLocName((prevState) => {
      return {
        ...prevState,
        selectedAlternateBase: text,
      };
    });
    if (text === 'Select') {
      form.startdatealternatelocation = '';
      form.enddatealternatelocation = '';
    }
    const createError = validateForm();
    if (Object.keys(createError).length > 0) {
      setErrors(createError);
    }
  };

  useEffect(() => {
    loadLocationDropdowns();
  }, []);

  return (
    <Modal isOpen={show} onClose={() => {}} size="large">
      <ModalOverlay />
      <ModalContent p="2rem" maxH="85%">
        <ModalHeader>
          <Heading as="h2" fontWeight="medium" fontSize="lg">
            {props.title}
          </Heading>
        </ModalHeader>
        <Box overflowX="auto">
          <ModalBody>
            <Text>{props.introDescription}</Text>
            <InfoBox>
              <Image className="switchBoardIcon pb-4" src={switchboard} alt="" />
              <h3 className="subTitleText">{props.reviewLocationTitle}</h3>
              <p className="smallText">{props.description}</p>
              <Container className="pb-3">
                <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                  <span className="d-flex align-items-center w-auto gap-1">
                    {props.homeBaseTitle}
                    <InfoCircle size={15} />
                  </span>
                </OverlayTrigger>

                <p className="pe-lg-3 smallText">{props.homeBaseDescription}</p>

                <Container className="bg-defencecharcoalgrey rounded-2 p-3">
                  <Col className="d-flex desktopflex mobilelocationflex">
                    <Container>
                      <h5 className="mb-0 text-white">{dropDownLocationBaseName.selectedValue}</h5>
                      <p className="smallText mb-0 text-white">{dropDownLocationBaseName.selectedAddress}</p>
                    </Container>

                    <Button
                      data-testid="changeLocation"
                      variant="standard bg-white"
                      className="smallText buttonWidthLocation mobileButton"
                      onClick={changeClickHandler}
                    >
                      {props.homeBaseCtaText}
                    </Button>
                  </Col>

                  {showchangeDropDown && (
                    <Container>
                      <Form.Group>
                        <Form.Label className="profile-other-info-text text-white">
                          Home base address
                          <span className="required-field"></span>
                        </Form.Label>
                        <br></br>
                        <div style={{ display: 'inline-block', width: '100%' }}>
                          <Select
                            style={{
                              borderColor: 'gray',
                              borderWidth: '1px',
                              borderStyle: 'solid',
                            }}
                            value={[
                              {
                                label: dropDownLocationBaseName.selectedValue,
                                value: dropDownLocationBaseName.selectedValue,
                              },
                            ]}
                            onChange={(selection, action) => {
                              handleBaseSelect(selection.label, selection.value);
                            }}
                            options={dropDownLocationBaseName.dropDownArrayBaseName.map((key, text) => {
                              return {
                                value: key,
                                label: key.name,
                              };
                            })}
                          />
                        </div>
                      </Form.Group>
                    </Container>
                  )}
                </Container>
              </Container>
              <Container className="pb-1">
                <Col className="d-flex desktopflex mobilelocationflex pe-lg-3">
                  <Container>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderWorkingLocationTooltip}
                    >
                      <span className="d-flex align-items-center w-auto gap-1">
                        {props.workingLocationtitle}
                        <InfoCircle size={15} />
                      </span>
                    </OverlayTrigger>
                    <p className="pe-lg-3 smallText">{props.workingLocationdescription}</p>
                  </Container>
                  {(dropDownWorkingLocName.selectedWorkingLocation.length === 0 ||
                    dropDownWorkingLocName.selectedWorkingLocation === appConstants.PROFILE_LOCSELECT) && (
                    <Button
                      data-testid="addLocation"
                      variant="standard bg-white"
                      className="smallText buttonWidthLocation mobileButton"
                      onClick={addWorkLocationClickHandler}
                    >
                      {props.workingLocationctaText}
                    </Button>
                  )}
                </Col>

                {showLocationDropDown && (
                  <Container>
                    <Form.Group>
                      <Form.Label className="profile-other-info-text">Working location</Form.Label>
                      <Select
                        style={{
                          borderColor: 'gray',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                        }}
                        value={[
                          {
                            label: dropDownWorkingLocName.selectedWorkingLocation,
                            value: dropDownWorkingLocName.selectedWorkingLocation,
                          },
                        ]}
                        onChange={(selection, action) => {
                          handleWorkingLocationSelect(selection.label, selection.value);
                        }}
                        options={dropDownWorkingLocName.dropDownArrayWorkingLocName.map((key, text) => {
                          return {
                            value: key,
                            label: key.name,
                          };
                        })}
                      />
                    </Form.Group>
                  </Container>
                )}
              </Container>
              <Container className="pb-1">
                <Col className="d-flex desktopflex mobilelocationflex pe-lg-3">
                  <Container>
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderAlternateLocationTooltip}
                    >
                      <span className="d-flex align-items-center w-auto gap-1">
                        {props.alternateBasetitle}
                        <InfoCircle size={15} />
                      </span>
                    </OverlayTrigger>
                    <p className="pe-lg-3 smallText">{props.alternateBasedescription}</p>
                  </Container>
                  {(dropDownAlternateLocName.selectedAlternateBase.length === 0 ||
                    dropDownAlternateLocName.selectedAlternateBase === appConstants.PROFILE_LOCSELECT) && (
                    <Button
                      data-testid="addLocation"
                      variant="standard bg-white"
                      className="smallText buttonWidthLocation mobileButton"
                      onClick={addAlternateLocationClickHandler}
                    >
                      {props.alternateBasectaText}
                    </Button>
                  )}
                </Col>

                {showAlternateDropDown && (
                  <>
                    <Form.Group>
                      <Form.Label className="profile-other-info-text">Alternate Base</Form.Label>
                      <br></br>
                      <div style={{ display: 'inline-block', width: '100%' }}>
                        <Select
                          style={{
                            borderColor: 'gray',
                            borderWidth: '1px',
                            borderStyle: 'solid',
                          }}
                          value={[
                            {
                              label: dropDownAlternateLocName.selectedAlternateBase,
                              value: dropDownAlternateLocName.selectedAlternateBase,
                            },
                          ]}
                          onChange={(selection, action) => {
                            handleAlternateBaseSelect(selection.label, selection.value);
                          }}
                          options={dropDownAlternateLocName.dropDownArrayAlternateLocName.map((key, text) => {
                            return {
                              value: key,
                              label: key.name,
                            };
                          })}
                        />
                      </div>
                      <div className="dropdown-error-msg">{errors.alternateBaseLocation}</div>
                    </Form.Group>
                    <Row>
                      <Col className="mobilelocationflex">
                        <Row>
                          <Col>
                            <Container className="pe-lg-2">
                              <Form.Group>
                                <Form.Label className="profile-other-info-text">Date From</Form.Label>
                                <Form.Control
                                  type="date"
                                  placeholder=""
                                  name="startdatealternatelocation"
                                  value={form.startdatealternatelocation}
                                  onChange={(e) => setField('startdatealternatelocation', e.target.value)}
                                  isInvalid={!!errors.startdatealternatelocation}
                                />
                                <Form.Control.Feedback type="invalid" className="validation-error">
                                  {errors.startdatealternatelocation}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Container>
                          </Col>
                          <Col>
                            <Form.Group>
                              <Form.Label className="profile-other-info-text">Date To</Form.Label>
                              <Form.Control
                                type="date"
                                placeholder=""
                                name="enddatealternatelocation"
                                value={form.enddatealternatelocation}
                                onChange={(e) => setField('enddatealternatelocation', e.target.value)}
                                isInvalid={!!errors.enddatealternatelocation}
                              />
                              <Form.Control.Feedback type="invalid" className="validation-error">
                                {errors.enddatealternatelocation}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </InfoBox>
            {hasSMSEnabled && isPorod && (
              <SMSConsent update={(value, isValid, isChecked) => setSMSSettings({ value, isValid, isChecked })} />
            )}
          </ModalBody>
        </Box>
        <ModalFooter>
          <Button
            data-testid="continueLocation"
            className="float-end continueButton mobileButton"
            variant="primary"
            onClick={updateDetailsHandler}
            disabled={!smsSettings.isValid}
          >
            Continue
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default LocationPreviewModal;
