import { TrashIcon } from '@radix-ui/react-icons';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import { MessageModal } from '../../MessageModal';

export const FileInput = ({
  fieldLabel,
  fileType,
  fileMax,
  errorMessage,
  required = false,
  disabled = false,
  accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  tooltip,
  selectedFiles = [],
  onFilesSelected = () => {},
}: {
  fieldLabel?: string;
  fileType?: string;
  register?: any;
  fileMax?: any;
  errorMessage?: any | undefined;
  required?: boolean;
  disabled?: boolean;
  url?: string;
  accept?: string;
  tooltip?: string;
  selectedFiles?: File[];
  onFilesSelected: (_files: File[]) => void;
}) => {
  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    if (selectedFiles) {
      setFiles(selectedFiles);
    }
  }, [selectedFiles]);
  const onSelectFile = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const fileItems = (event.target as HTMLInputElement).files;
    const fileList: File[] = [];
    for (const i in fileItems) {
      if (!fileItems?.hasOwnProperty(i)) {
        continue;
      }
      const file = fileItems?.[i];
      if (file) {
        fileList.push(file);
      }
    }
    setFiles(fileList);
    if (onFilesSelected) {
      onFilesSelected(fileList);
    }
  };

  const onDeleteFile = (file: File) => {
    const fileList = selectedFiles.filter((selectedFile) => selectedFile.name !== file.name);
    setFiles(fileList);
    onFilesSelected(fileList);
  };

  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const onMessageModalClose = () => {
    setMessages([]);
  };
  return (
    <>
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <label className="block text-defence-black">
        {fieldLabel} {required && <p className="m-0 inline  text-defence-redAlert"> *</p>}
        <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
      </label>
      <div className="relative flex cursor-pointer flex-col rounded border border-dashed border-defence-light-grey-100 text-defence-black">
        <input
          accept={accept}
          type="file"
          disabled={disabled}
          onChange={onSelectFile}
          style={{ display: 'inline-block', width: '100%' }}
          className="absolute inset-0 m-0 h-full w-full cursor-pointer p-0 opacity-0 outline-none"
        />
        <div className="flex flex-col items-center justify-center py-10 text-center">
          <svg
            className="text-current-50 mr-1 h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
          </svg>
          <p className="m-0">Drag your file here or click in this area.</p>
          <p className="m-0 text-xs">
            {fileType} up to {fileMax}
          </p>
        </div>
      </div>

      <div>
        {files.map((file: any, index) => (
          <div key={index} className="grid grid-cols-2">
            <div className="px-4 py-2 text-base text-defence-black">{file.name}</div>
            <div>
              <button type="button" disabled={disabled} className="flex px-4 py-2" onClick={() => onDeleteFile(file)}>
                <TrashIcon width={20} height={20} />
              </button>
            </div>
          </div>
        ))}
      </div>
      {errorMessage && <p className="text-defence-redAlert">{errorMessage}</p>}
    </>
  );
};
