import React, { useEffect, useState } from 'react';
import {
  Link,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  GridItem,
  Input,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { EditButton } from './EditButton';
import { SCForm } from '../../Core/FormElements';
import { FormProvider, useForm } from 'react-hook-form';
import { MyGovIDLink, Profile } from '../../../../types';
import { VerifyEmail, VerifyEmailComplete } from './VerifyEmail';
import { request } from '../../../../helper/useAxios';
import { appConstants } from '../../../../helper/client/constant';
import { useDispatch } from 'react-redux';
import { setProfile } from '../../../../reducers';
import { setGovID } from '../../../../reducers/profileReducer';
import { checkMobileValidity } from '../SMSConsent/Utilities';

interface KeyAndValueProps {
  _key: string;
  title: string;
  value: string;
  editable?: React.ReactNode;
  onUpdate?(e): void;
  isAnalytics?: boolean;
  myGovIDDetails?: MyGovIDLink;
  idp?: string;
  updateProfile?(): void;
}

export const KeyAndValue: React.FC<KeyAndValueProps> = ({
  _key,
  title,
  value,
  editable,
  onUpdate,
  myGovIDDetails,
  idp,
}) => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isVerifyEmailOpen, onToggle: toggleIsVerifyEmailOpen } = useDisclosure();
  const { isOpen: isVerifyEmailComplete, onToggle: toggleIsVerifyEmailComplete } = useDisclosure();
  const methods = useForm();
  const [currentValue, setCurrentValue] = useState(value);
  const [isValid, setIsValid] = useState<Boolean>(true);
  const dispatch = useDispatch();
  const verifyEmail = (email) => {
    const data = {
      defenceIdentity: email,
      externalType: 'mygovid',
    };
    request(appConstants.API_IDENTITYLINK, 'post', { data }).then(() => {
      isVerifyEmailOpen && toggleIsVerifyEmailOpen();
    });
    dispatch(setGovID(email));
    toggleIsVerifyEmailComplete();
  };
  useEffect(() => {
    _key === 'mobile' && setIsValid(checkMobileValidity(currentValue));
  }, [currentValue]);

  return (
    <>
      <GridItem colSpan={1}>
        <Text mb="0.5rem" fontWeight="medium">
          {title}
        </Text>

        {!isOpen ? (
          <>
            {_key === 'defenceEmail' && idp === 'mygovid' ? (
              myGovIDDetails?.defenceIdentity === '' ? (
                <Button variant="primary" onClick={toggleIsVerifyEmailOpen}>
                  Verify your Defence email
                </Button>
              ) : (
                <>
                  <Text noOfLines={1} wordBreak="break-word">
                    {myGovIDDetails?.defenceIdentity}
                  </Text>
                  <Flex gap={3}>
                    <EditButton text="Edit" onClick={toggleIsVerifyEmailOpen} />
                    <Link color="orange.standard" onClick={() => verifyEmail(myGovIDDetails?.defenceIdentity)}>
                      Resend
                    </Link>
                  </Flex>
                </>
              )
            ) : (
              <Text noOfLines={1} wordBreak="break-word">
                {value}
              </Text>
            )}
          </>
        ) : (
          <FormProvider {...methods}>
            <FormControl isInvalid={!isValid} mb="1rem">
              <Input
                w="60%"
                variant="primary"
                value={currentValue}
                onChange={(value) => {
                  setCurrentValue(value.target.value);
                }}
              />
              <FormErrorMessage>Please enter a valid mobile number</FormErrorMessage>
            </FormControl>
          </FormProvider>
        )}

        {editable && !isOpen && <EditButton text="Edit" onClick={onToggle} />}
        {isOpen && (
          <Flex gap={3}>
            <Button
              variant="tertiary"
              onClick={() => {
                setCurrentValue(value);
                onToggle();
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={() => onUpdate && onUpdate(currentValue)} isDisabled={!isValid}>
              Update
            </Button>
          </Flex>
        )}
      </GridItem>
      {_key === 'defenceEmail' && (
        <>
          <VerifyEmail isOpen={isVerifyEmailOpen} verifyEmail={(e) => verifyEmail(e)} close={toggleIsVerifyEmailOpen} />
          <VerifyEmailComplete
            isOpen={isVerifyEmailComplete}
            close={toggleIsVerifyEmailComplete}
            edit={() => {
              toggleIsVerifyEmailOpen();
              toggleIsVerifyEmailComplete();
            }}
          />
        </>
      )}
    </>
  );
};
