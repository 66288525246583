import React, { useEffect, useState } from 'react';
import { Box, Button, Flex, Heading, Text, useDisclosure } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { SCForm } from '../../Core/FormElements';
import { useSelector } from 'react-redux';
import { Profile } from '../../../../types';
import { Chat } from '../../Core/icons/Outline';
import { checkMobileValidity } from './Utilities';

export interface SMSConsentProps {
  update(value, isValid, isChecked): void;
}

export const SMSConsent: React.FC<SMSConsentProps> = ({ update }): JSX.Element => {
  const methods = useForm();

  const mobileNumber = useSelector(
    (state: { profile: Profile }) => state.profile?.phone.find((item) => item.type === 'Mobile')?.number,
  );

  const [isValid, setIsValid] = useState<boolean>(true);
  const [mobileNumberValue, setMobileNumberValue] = useState(mobileNumber);
  const [checkboxValue, setCheckboxValue] = useState(false);

  const { isOpen, onToggle } = useDisclosure();

  const handleAdd = () => {
    onToggle();
  };

  useEffect(() => {
    setMobileNumberValue(mobileNumber);
  }, [mobileNumber]);

  //Checking mobile validity
  useEffect(() => {
    setIsValid(checkMobileValidity(mobileNumberValue));
  }, [mobileNumberValue]);

  useEffect(() => {
    update(mobileNumberValue, !isOpen || isValid, checkboxValue);
  }, [isValid, checkboxValue, isOpen]);

  return (
    <FormProvider {...methods}>
      <Flex gap={3} flexDir="column" mt="1rem" p="2rem" bg="whispergrey">
        <Box>
          <Chat height="5rem" />
        </Box>
        <Heading m={0} as="h3" fontSize="lg" fontWeight="medium">
          Review your preferences
        </Heading>
        <Text fontWeight="light">
          We send SMS notifications when important information is available for you in ServiceConnect. At this stage,
          SMS notifications are only sent when an immediate base notice has been published that requires your attention.
          You will be prompted to update your preferences when SMS notifications become available for other services.
        </Text>
        <Flex flexDir={{ lg: 'row', base: 'column' }} justifyContent="space-between" alignItems="center" gap={3}>
          <Box>
            <Text fontWeight="medium">SMS notifications (optional)</Text>
            <Text fontWeight="light">
              If you would like to receive SMS notifications from ServiceConnect, opt in by adding your mobile phone
              number.
            </Text>
          </Box>
          <Button w={{ lg: 'auto', base: '100%' }} variant="tertiary" onClick={handleAdd}>
            Add
          </Button>
        </Flex>
        {isOpen && (
          <>
            <SCForm.Text
              visible
              label="Mobile phone (work or personal)"
              onChange={(value) => {
                setMobileNumberValue(value);
              }}
              isInvalid={!isValid}
              value={mobileNumberValue ?? ''}
              errorMessage="Please enter a valid mobile number"
              placeholder="Mobile number"
              desktopWidth={7}
              mobileWidth={12}
            />
            <SCForm.Checkbox
              visible
              label="Yes, I would like to receive SMS notifications from ServiceConnect and I understand that these notifications are only available for My Base at this stage."
              onCheckboxChange={(value) => {
                setCheckboxValue(value);
              }}
              value={checkboxValue}
            />
          </>
        )}
      </Flex>
    </FormProvider>
  );
};
