const convertSapMetadata = function (metadata: any) {
  if (metadata.d.results[0]) {
    const convertedMetaData: { [key: string]: string } = {};
    let parsedProperties = [];
    try {
      parsedProperties = JSON.parse(metadata.d.results[0].Metadata).PROPERTIES;
    } catch (e) {
      parsedProperties = metadata?.d?.results[0].Metadata?.PROPERTIES;
    }
    parsedProperties.map(
      (property: any) => (convertedMetaData[property.EXTERNAL_NAME] = property)
    );
    return convertedMetaData;
  }
};
export default convertSapMetadata;
