import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Controller, useController } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import { lookupCodeSet, TypeAheadCodeSet } from '../../../lib/api/lookup';
import { Spinner } from '../../Spinner';

type TSelectInputItem = {
  code: string;
  description: string;
};
type TSelectInput = {
  fieldLabel: string;
  fieldPlaceholder: string;
  control: any;
  name: string;
  top?: number;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  multiple?: boolean;
  tooltip?: string;
  includeCode?: boolean;
  codeSet: TypeAheadCodeSet;
  codeSetLabelField: string;
  codeSetValueField: string;
  codeSetFilters?: { field: string; value: string }[];
};

export const MultiSelectInputCode = ({
  fieldLabel,
  fieldPlaceholder,
  name,
  control,
  required = false,
  disabled = false,
  defaultValue,
  multiple = false,
  tooltip,
  includeCode = false,
  codeSet,
  codeSetLabelField,
  codeSetValueField,
  codeSetFilters,
  top = 100,
}: TSelectInput) => {
  const [options, setOptions] = useState<TSelectInputItem[]>([]);
  const [selectedItems, setSelectedItems] = useState<TSelectInputItem[]>([]);
  const [loading, setLoading] = useState(false);

  const { field } = useController({ name, control });

  useEffect(() => {
    if (field.value) {
      setSelectedItems(field.value);
    }
  }, [field.value]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (codeSetFilters?.length) {
          const filter = codeSetFilters
            .map((filter) => {
              return `${filter.field} eq '${filter.value}'`;
            })
            .join(' and ');
          const filterQuery = filter ? `$filter=(${filter})` : undefined;
          const sapResults = await lookupCodeSet(codeSet, filterQuery);
          const results = convertSapData(sapResults);
          const data: TSelectInputItem[] = results.map((item) => ({
            description: item[codeSetLabelField],
            code: item[codeSetValueField],
          }));
          setOptions(data);
        } else if (codeSet) {
          const filter = `$top=${top}`;
          const sapResults = await lookupCodeSet(codeSet, filter);
          const results = convertSapData(sapResults);
          const data: TSelectInputItem[] = results.map((item) => ({
            description: item[codeSetLabelField],
            code: item[codeSetValueField],
          }));
          setOptions(data);
        }
        setLoading(false);
      } catch (_error) {
        setLoading(false);
      }
    })();
  }, [codeSet, codeSetFilters]);

  useEffect(() => {
    if (defaultValue && options.length) {
      const selectedItem = getSelectedItem(defaultValue);
      if (selectedItem) {
        setSelectedItems([selectedItem]);
      }
    }
  }, [defaultValue, options]);

  const getSelectedItem = (code: string) => {
    const index = options.findIndex((item) => item.code === code);
    if (index >= 0) {
      return options[index];
    }
    return null;
  };

  const convertSapData = function (data: any) {
    if (data) {
      data.map((row: any) =>
        Object.keys(row).forEach((item) => {
          if (typeof row[item] === 'object') {
            delete row[item];
          } else {
            if (typeof row[item] === 'string' && row[item].includes('/Date(')) {
              const stringForDateFunction = 'return new ' + row[item]?.replaceAll('/', ''); //Odata return miliseconds for date, need to convert
              const getDate = new Function(stringForDateFunction); //run string as a function to get real date
              row[item] = moment(getDate()).format('DD-MM-YYYY');
            }
          }
        }),
      );

      return data;
    }
  };

  function isSelected(value: any) {
    return selectedItems.find((item) => item.code === value.code) ? true : false;
  }

  function handleSelect(value: TSelectInputItem, onChange?: (_value: TSelectInputItem[]) => void) {
    if (value) {
      const items = isSelected(value) ? handleDeselect(value) : [...selectedItems, value];
      setSelectedItems(items);
      if (onChange) {
        onChange(items);
      }
    }
  }

  function handleDeselect(value: TSelectInputItem) {
    return selectedItems.filter((item) => item.code !== value.code);
  }

  return (
    <div>
      <label className="mb-2 mt-2 flex text-x0">
        {fieldLabel} {required && <p className="m-0 inline  text-red-500"> *</p>}
        <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange }, fieldState: { error } }) => {
          return (
            <div className="relative">
              <Listbox
                disabled={disabled}
                value={selectedItems}
                multiple={multiple}
                onChange={(e: any) => {
                  handleSelect(e, onChange);
                }}
              >
                <Listbox.Button
                  className={`flex w-full content-center items-center overflow-auto rounded-md border border-defence-light-grey-500 py-1.5 pl-2 text-left text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:outline-black focus:border focus:border-defence-blueGrey focus:bg-white focus-visible:outline enabled:bg-white disabled:bg-defence-light-grey-200 
                    ${error ? `inputError border-defencered` : `inputClean border-defencemediumgrey`} ${disabled ? `!cursor-default` : `cursor-pointer`}`}

                >
                  {selectedItems ? (
                    <p className="mb-0 w-full text-black">
                      {selectedItems
                        .map((item) => {
                          const index = options.findIndex((itemx) => itemx.code === item.code);
                          return options[index]?.description;
                        })
                        .filter((description) => description)
                        .join(', ')}
                    </p>
                  ) : (
                    <p className="mb-0 w-full text-defence-black">{fieldPlaceholder}</p>
                  )}
                  {loading ? (
                    <Spinner className="mr-2 h-4 w-4 " />
                  ) : (
                    <ChevronDownIcon height={30} width={30} className="mx-2 text-defence-orange-500" />
                  )}
                </Listbox.Button>
                <Listbox.Options
                  className={`!absolute z-10 w-full overflow-y-auto border-2 border-t-0 bg-white shadow-md ${options.length >= 10 ? `h-96` : ``
                    }`}
                >
                  {options.map((item, index) => (
                    <div key={index} className="flex w-full hover:bg-defence-light-grey-200">
                      <span className="ml-1 mr-1 mt-2 flex-auto items-center">
                        <CheckIcon
                          width={20}
                          height={20}
                          className={` ${isSelected(item) ? `display text-defence-orange-500` : `display text-transparent`
                            }`}
                        />
                      </span>
                      <Listbox.Option
                        key={item.code}
                        value={item}
                        className="w-full flex-auto cursor-pointer list-none p-2"
                      >
                        {includeCode ? item.code + ' : ' : ''}
                        {item.description}
                      </Listbox.Option>
                    </div>
                  ))}
                </Listbox.Options>
              </Listbox>
              <div className="h-4">
                {error && error?.type === 'custom' && (
                  <p className="flex pt-1 text-sm text-defence-redAlert">
                    <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                    This field is required.
                  </p>
                )}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
