import React from 'react';
import { SubContainer } from '../../Core/Layout';
import { Grid, Heading, Text } from '@chakra-ui/react';
import { PrefTile } from './PrefTile';
import { request } from '../../../../helper/useAxios';
import { appConstants } from '../../../../helper/client/constant';
import { useSelector, useDispatch } from 'react-redux';
import { Profile } from '../../../../types';
import { convertDateToDDMMYYYY } from '../../../../helper/convertDateToDDMMYYYY';
import { setSMSConsent, setWebAnalytics } from '../../../../reducers/profileReducer';

export interface MyPreferencesProps {
  heading: string;
  subHeading: string;
}

export const MyPreferences: React.FC<MyPreferencesProps> = ({ heading, subHeading }): JSX.Element => {
  const isPorod = useSelector((state: { profile: Profile }) =>
    state.profile.email[0].address.split('@')[1]?.includes('defence.gov.au'),
  );
  const hasSMSEnabled = useSelector((state: { profile: Profile }) => state.profile.myServices.hasSMSEnabled);

  const profileData = useSelector((state: { profile: Profile }) => state.profile);
  const dispatch = useDispatch();
  return (
    <SubContainer verticalMargin="1rem">
      <Heading as="h3" fontSize="sm" fontWeight="medium">
        {heading}
      </Heading>
      <Text fontWeight="light" mb="1rem">
        {subHeading}
      </Text>
      <Grid gap={3} gridTemplateColumns="repeat(3, 1fr)">
        {hasSMSEnabled && isPorod && (
          <PrefTile
            title="SMS Notifications"
            description="We use SMS notifications to let you know when important information is available to view in ServiceConnect. You may also receive SMS notifications when action is required from you. These notifications are optional."
            label="Allow SMS notifications"
            value={profileData.SMSConsent}
            onChange={(value) => {
              dispatch(setSMSConsent(value));
              request(appConstants.API_USER_PROFILE_DETAILS, 'patch', {
                data: { smsConsentDate: convertDateToDDMMYYYY(new Date()), smsConsent: value },
              });
            }}
          />
        )}
        <PrefTile
          title="Web analytics"
          description="Defence uses web analytics to better understand how customers use ServiceConnect and identify future improvements. Participation is optional. Read our Web Analytics Collection Statement to learn more."
          label="Allow web analytics"
          value={profileData.AllowAnalyticsTracking === 'true'}
          onChange={(value) => {
            dispatch(setWebAnalytics(value));
            request(appConstants.API_USER_PROFILE_DETAILS, 'patch', {
              data: { allowAnalyticsTracking: String(value) },
            });
          }}
        />
      </Grid>
    </SubContainer>
  );
};
