import moment from 'moment';

const convertSapData = function (data: any) {
  if (data?.d?.results) {
    data.d.results.map((row: any) =>
      Object.keys(row).forEach((item) => {
        if (typeof row[item] === 'object') {
          delete row[item];
        } else {
          if (typeof row[item] === 'string' && row[item].includes('/Date(')) {
            const stringForDateFunction = 'return new ' + row[item].replaceAll('/', ''); //Odata return miliseconds for date, need to convert
            const getDate = new Function(stringForDateFunction); //run string as a function to get real date
            row[item] = moment(getDate()).format('DD-MMM-YYYY, HH:mm:ss');
          }
        }
      }),
    );

    return data.d.results;
  } else if (data?.d) {
    Object.keys(data.d).forEach((item) => {
      if (typeof data.d[item] === 'object' && data.d[item] !== null && !data.d[item].results) {
        delete data.d[item];
      } else {
        if (typeof data.d[item] === 'string' && data.d[item].includes('/Date(')) {
          const stringForDateFunction = 'return new ' + data.d[item].replaceAll('/', ''); //Odata return miliseconds for date, need to convert
          const getDate = new Function(stringForDateFunction); //run string as a function to get real date
          data.d[item] = moment(getDate()).format('DD-MMM-YYYY, HH:mm:ss');
        }
      }
    });
    return data.d;
  } else {
    return {};
  }
};
export default convertSapData;
