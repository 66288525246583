import React from 'react';
import { Breadcrumbs } from '../components/Breadcrumbs';

export interface BannerProps {
  title: string;
  description?: string;
  bannerImgSrc?: string;
  showHello?: boolean;
  showBreadcrumb?: boolean;
}
export const Banner = ({
  title,
  description = '',
  bannerImgSrc = '',
  showHello = false,
  showBreadcrumb = true,
}: BannerProps) => {
  return (
    <div
      style={
        bannerImgSrc
          ? ({
              '--image-url': `url(${require(`../assets/headerImage.png`)})`,
            } as React.CSSProperties)
          : ({
              '--image-url': `url(${require(`../assets/headerSwirls.png`)})`,
            } as React.CSSProperties)
      }
      className={
        bannerImgSrc
          ? 'banner-bg-image-unique flex w-full justify-center bg-defence-orange-500 bg-[image:var(--image-url)]'
          : 'banner-bg-image-general flex w-full justify-center bg-defence-charcoal-light bg-[length:100%_100%] lg:bg-[image:var(--image-url)]'
      }
    >
      <div className="w-full md:w-4/5">
        <div className="p-[4rem]  pr-[8rem]">
          {showHello && (
            <a href="/eeportal" className="inline-block">
              <img
                className="inline-block h-12 w-auto align-middle"
                src={require(`../assets/EX icon.png`)}
                alt="Estate Exchange Logo"
              />
            </a>
          )}
          {showBreadcrumb && <Breadcrumbs />}
          <h1 className="w-fit  text-3xl font-medium text-white sm:text-3xl md:text-3xl lg:text-3xl ">{title}</h1>
          <p className="text-l text-white md:w-4/5">{description}</p>
        </div>
      </div>
    </div>
  );
};
