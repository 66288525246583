import { Button } from '@chakra-ui/react';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

export const LinkCard = ({
  label,
  link,
  description,
  linkText = 'home',
  imageSrc = '',
  icon,
  showIcon,
  insertEnter = false,
  hide = false,
}: {
  label?: string;
  link: string;
  description?: string;
  linkText?: string;
  imageSrc: string;
  icon: React.ReactNode;
  showIcon: boolean;
  insertEnter?: boolean;
  hide?: boolean;
}) => {
  return (
    <>
      <div className="w-full rounded-md border bg-white shadow-sm">
        {!showIcon && <img className="w-full rounded-t-md" src={require(`../assets/${imageSrc}`)} />}
        {showIcon && (
          <div className="card-body flex h-fit min-h-full flex-col p-12">
            {showIcon && <span className="text-defence-orange-500">{icon}</span>}
            {label && description && (
              <div className="h-42 md:h-38 lg:h-38">
                <div className="mt-2 w-full font-bold">
                  <h3 className="text-xl">{label}</h3>
                </div>
                <div className="my-1 mb-3 line-clamp-5 flex overflow-hidden text-ellipsis text-base font-light">
                  {description}
                </div>
              </div>
            )}
            {!hide && (
              <Button
                variant="secondary"
                as={RouterLink}
                to={link}
                width="100%"
                fontWeight={500}
                whiteSpace="pre-wrap"
                className="mt-auto"
              >
                {linkText}
              </Button>
            )}
          </div>
        )}
        {!showIcon && (
          <div className="card-body h-fit border-defence-light-grey-100 p-8">
            {showIcon && <span className="text-defence-orange-500">{icon}</span>}
            {/* <div className="w-full font-bold">
              <h3 className="text-xl">{label}</h3>
            </div>
            <div className="my-1 line-clamp-5 h-24 overflow-hidden text-ellipsis text-xl font-light">
              {description}
            </div> */}
            {!hide && (
              <Button variant="secondary" as={RouterLink} to={link} width="100%" fontWeight={500} whiteSpace="pre-wrap">
                {linkText}
              </Button>
            )}
          </div>
        )}
      </div>
    </>
  );
};
