import { RadioGroup } from '@headlessui/react';
import { InfoCircledIcon } from '@radix-ui/react-icons';
import React from 'react';
import { Controller } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';

export const RadioInput = ({
  fieldLabel,
  options,
  horizontal,
  name,
  control,
  required = false,
  tooltip,
}: {
  fieldLabel: string;
  options: string[];
  horizontal: boolean;
  name: string;
  control: any;
  required?: boolean;
  disabled?: boolean;
  tooltip?: string;
}) => {
  return (
    <div>
      <label className="mb-2 mt-2 flex text-x0">
        {fieldLabel} {required && <p className="inline  text-defence-redAlert"> *</p>}
        <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
      </label>

      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <div>
            <div className={`mt-2 ${horizontal ? 'flex' : ''}`}>
              {/* {options.map((radio_list, index) => (
                <div key={index} className="mt-1 flex items-center gap-x-1">
                  <input
                    disabled={disabled}
                    type="radio"
                    className="h-3 w-4 border-defence-light-grey-100 text-indigo-600 focus:ring-indigo-600"
                    value={radio_list}
                    checked={value === radio_list ? true : false}
                    onChange={(e) => {
                      onChange(e.target.value);
                    }}
                  />
                  <label className="my-1 block pr-4 text-lg leading-6 ">
                    {radio_list}
                  </label>
                </div>
              ))} */}
              <RadioGroup value={value} onChange={onChange} className="flex">
                {options.map((plan) => (
                  <RadioGroup.Option
                    key={plan}
                    value={plan}
                    className={({ active, checked }) =>
                      `${active ? '' : ''}
                  ${checked ? 'text-defence-black' : 'text-defence-black'}
                    relative mr-8 flex cursor-pointer focus:outline-none`
                    }
                  >
                    {({ checked }) => (
                      <>
                        {checked && (
                          <div className="shrink-0 text-defence-black">
                            <CheckIcon className="h-6 w-6" />
                          </div>
                        )}
                        {!checked && (
                          <div className="shrink-0 text-defence-black">
                            <CircleIcon className="h-6 w-6" />
                          </div>
                        )}
                        <div className="ml-2 flex w-full items-center justify-between">
                          <div className="flex items-center">
                            <div className="text-x0">
                              <RadioGroup.Label
                                as="p"
                                className={`font-x0  ${checked ? 'text-defence-black' : 'text-defence-black'}`}
                              >
                                {plan}
                              </RadioGroup.Label>
                              <RadioGroup.Description
                                as="span"
                                className={`inline ${
                                  checked ? 'text-defence-orange-500' : 'text-defence-light-grey-900'
                                }`}
                              ></RadioGroup.Description>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </RadioGroup.Option>
                ))}
              </RadioGroup>
            </div>

            <div className="h-4 text-defence-redAlert">
              {error && error?.type === 'invalid_type' && (
                <p className="flex pt-1 text-x0 text-defence-redAlert">
                  <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                  This field is required.
                </p>
              )}
            </div>
          </div>
        )}
      />
    </div>
  );
};

function CheckIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={10} fill="none" stroke="green" strokeWidth="2" opacity="1" />
      <path d="M7 13l3 3 7-7" stroke="green" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

function CircleIcon(props: any) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <circle cx={12} cy={12} r={10} fill="none" stroke="black" strokeWidth="2" opacity="1" />
    </svg>
  );
}
