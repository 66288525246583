import { Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CheckboxIcon,
  ClipboardCopyIcon,
  EyeOpenIcon,
  MinusCircledIcon,
  Pencil2Icon,
  ResetIcon,
  ResumeIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldErrors, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { DialogModal } from '../../components/Dialog';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { FileInput } from '../../components/form/inputs/FileInput';
import { FileUpload } from '../../components/form/inputs/FileUpload';
import { MultiSelectInputCode } from '../../components/form/inputs/MultiSelectInputCode';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TableInputGen } from '../../components/form/inputs/tableInputGen';
import { TextAreaInput } from '../../components/form/inputs/textAreaInput';
import { TextInput } from '../../components/form/inputs/textInput';
import {
  CloseWorkOrderResponse,
  ReadNotificationResponse,
  WorkOrderCancelTechnicalCompleteResponse,
  WorkOrderResetDeletionFlagResponse,
  WorkOrderSetDeletionFlagResponseData,
  WorkOrderTechnicalCompleteResponse,
  WorOrderUpdatePrioritisationCategoryWeightingsResponse,
} from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import { formatODataDateToDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { TWorkOrderFormSchema } from './WorkOrderFormSchema';

export const WorkOrderForm = ({
  updateForm,
  onSubmit,
  schema,
  defaultValues,
  metadata,
  refreshData,
  onFilesSelected = () => {},
}: {
  updateForm: boolean;
  onSubmit: SubmitHandler<TWorkOrderFormSchema>;
  schema: any;
  defaultValues: any;
  metadata: any;
  refreshData?: (_id: string) => void;
  onFilesSelected?: (_files: File[]) => void;
}) => {
  const [step, setStep] = useState(1);
  const [readOnly, setReadOnly] = useState(updateForm);
  const [editToggle, setEditToggle] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [titleMsg, setTitleMsg] = useState('');
  const [respMsg, setRespMsg] = useState<any[]>([]);
  const [showInput, setShowInput] = useState(false);
  const [showCheckBox, setShowCheckBox] = useState(false);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [disableNotifFields, setDisableNotifFields] = useState(false);

  /* { handleSubmit, control, methods.getValues, watch, methods.reset } */
  const methods = useForm<TWorkOrderFormSchema>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });
  const { watch } = methods;

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files);
    onFilesSelected(files);
  };

  useEffect(() => {
    if (defaultValues) {
      methods.reset({ ...defaultValues });
    }
  }, [defaultValues, methods.reset]);

  const [loading, setLoading] = useState(false);

  const sections = [
    {
      code: 1,
      name: 'Order data',
      info: 'Info 1',
      fields: ['OrderType', 'WorkOrderNo', 'Description', 'NotificationNo', 'Priority'],
    },
    {
      code: 2,
      name: 'Header data',
      info: 'Info 3',
      fields: [
        'SystemStatus',
        'WorkOrderLongText',
        'UserStatus',
        'MaintenanceType',
        'BasicStartDateTime',
        'BasicFinishDateTime',
        'Revision',
        'FunctionalLocation',
        'EquipmentId',
        'PlannerGroup',
        'MainWorkCentre',
      ],
    },
    {
      code: 3,
      fields: [],
      name: 'Operations',
      info: 'Info 3',
    },
    {
      code: 4,
      fields: [],
      name: 'Scheduling details',
      info: 'Info 4',
    },
    {
      code: 5,
      fields: [],
      name: 'Additional Details',
      info: 'Info 5',
    },
    {
      code: 6,
      name: 'File Attachments',
      info: 'Info 6',
    },
  ];

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const handleSubmitForm = async (data: TWorkOrderFormSchema) => {
    setErrorMessages([]);
    await onSubmit(data);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({ section: element.name, code: element.code });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const showTecoDialog = () => {
    setShowDialog(true);
    setDialogTitle('Technically Complete WO');
    setDialogMessage('This will Technically Complete the Work Order. Do you want to proceed?');
    setShowInput(true);
    setShowCheckBox(true);
    setOnDialogOk(() => (dialogInput, dialogCheckBox) => {
      setShowDialog(false);
      fnTeco(defaultValues.WorkOrderNo, dialogInput, dialogCheckBox);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
    });
  };

  const showCancelTecoDialog = () => {
    setShowDialog(true);
    setDialogTitle('Cancel TECO');
    setDialogMessage('This will Cancel Technical Complete (TECO) status of Work Order. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnCancelTeco(defaultValues.WorkOrderNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
      //alert('Cancel clicked');
    });
  };

  const showCloseDialog = () => {
    setShowDialog(true);
    setDialogTitle('Close Work Order');
    setDialogMessage('This will mark the Work Order as not executed .Do you want to proceed?');
    setShowInput(false);
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnCloseWo(defaultValues.WorkOrderNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
      //alert('Cancel clicked');
    });
  };

  const showResetDelDialog = () => {
    setShowDialog(true);
    setDialogTitle('Reset Deletion Flag');
    setDialogMessage('This will reset the Deletion flag on the Work Order. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnResetDelete(defaultValues.WorkOrderNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
      //alert('Cancel clicked');
    });
  };

  const showDeleteDialog = () => {
    setShowDialog(true);
    setDialogTitle('Set Deletion Flag');
    setDialogMessage('This will set the Deletion Flag on the Work Order. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnSetDelete(defaultValues.WorkOrderNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
      //alert('Cancel clicked');
    });
  };

  const showCalcDialog = () => {
    setShowDialog(true);
    setDialogTitle('Calculate Weightings');
    setDialogMessage('This will calculate the Priority Category Weightings. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnCalculate(defaultValues.WorkOrderNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
      //alert('Cancel clicked');
    });
  };

  const [onDialogOk, setOnDialogOk] = useState(() => () => {
    alert('Ok clicked');
  });
  const [onDialogCancel, setOnDialogCancel] = useState(() => () => {
    alert('Cancel clicked');
  });

  const fnTeco = async (id: string, dateTime?: string, flagNotifClose?: string) => {
    try {
      setLoading(true);
      let WoNo;
      try {
        WoNo = `'` + id + `'`;
      } catch (e) {
        WoNo = id;
      }
      let urlParam =
        'WorkOrderNo=' + WoNo + '&CompDateTime=datetime' + `'${dateTime}'` + '&NotifClose=' + `'${flagNotifClose}'`;
      const respData = await axiosInstance.post<WorkOrderTechnicalCompleteResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_TECO)}?${urlParam}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.WorkOrderNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnCancelTeco = async (id: string) => {
    try {
      setLoading(true);
      let WoNo;
      try {
        WoNo = `'` + id + `'`;
      } catch (e) {
        WoNo = id;
      }
      let urlParam = 'WorkOrderNo=' + WoNo;
      const respData = await axiosInstance.post<WorkOrderCancelTechnicalCompleteResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_CANCELTECO)}?${urlParam}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.WorkOrderNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnCloseWo = async (id: string) => {
    try {
      setLoading(true);
      let WoNo;
      try {
        WoNo = `'` + id + `'`;
      } catch (e) {
        WoNo = id;
      }
      let urlParam = 'WorkOrderNo=' + WoNo;
      const respData = await axiosInstance.post<CloseWorkOrderResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_CLOSEWO)}?${urlParam}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.WorkOrderNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnResetDelete = async (id: string) => {
    try {
      setLoading(true);
      let WoNo;
      try {
        WoNo = `'` + id + `'`;
      } catch (e) {
        WoNo = id;
      }
      let urlParam = 'WorkOrderNo=' + WoNo;
      const respData = await axiosInstance.post<WorkOrderResetDeletionFlagResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_RESETDELETE)}?${urlParam}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.WorkOrderNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnSetDelete = async (id: string) => {
    try {
      setLoading(true);
      let WoNo;
      try {
        WoNo = `'` + id + `'`;
      } catch (e) {
        WoNo = id;
      }
      let urlParam = 'WorkOrderNo=' + WoNo;
      const respData = await axiosInstance.post<WorkOrderSetDeletionFlagResponseData>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_SETDELETE)}?${urlParam}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.WorkOrderNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnCalculate = async (id: string) => {
    try {
      setLoading(true);
      let WoNo;
      try {
        WoNo = `'` + id + `'`;
      } catch (e) {
        WoNo = id;
      }

      let urlParam =
        'WorkOrderNo=' +
        WoNo +
        '&OrderType=' +
        `'` +
        defaultValues.OrderType +
        `'` +
        '&ActivityType=' +
        `'` +
        methods.getValues().MaintenanceType +
        `'` +
        '&Floc=' +
        `'` +
        methods.getValues().FunctionalLocation +
        `'` +
        '&Equipment=' +
        `'` +
        methods.getValues().EquipmentId +
        `'` +
        '&ZzAssIntegrity=' +
        `'` +
        methods.getValues().IntegrityAssessed +
        `'` +
        '&ZzAssFunctional=' +
        `'` +
        methods.getValues().FunctionalityAssessed +
        `'` +
        '&ZzComplIssue=' +
        methods.getValues().ComplianceIssue +
        '&ZzImpactNonComp=' +
        `'` +
        methods.getValues().NoncompConsequence +
        `'` +
        '&ZzComplStd=' +
        `'` +
        methods.getValues().ComplianceType +
        `'` +
        '&ZzImpactValMoney=' +
        `'` +
        methods.getValues().ValueMoneyConsequence +
        `'` +
        '&ZzWhsConsequence=' +
        `'` +
        methods.getValues().WHSConsequence +
        `'` +
        '&ZzWhsLikelihood=' +
        `'` +
        methods.getValues().WHSLikelihood +
        `'`;

      const respData = await axiosInstance.post<WorOrderUpdatePrioritisationCategoryWeightingsResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_CALCULATE)}?${urlParam}`,
      );
      let { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);

      methods.setValue('CategoryWeightingCompliance', respData.data?.d?.ZzCwCompliance);
      methods.setValue('CategoryWeightingFunctionality', respData.data?.d?.ZzCwFunctional);
      methods.setValue('CategoryWeightingIntegrity', respData.data?.d?.ZzCwIntegrity);
      methods.setValue('CategoryWeightingValueForMoney', respData.data?.d?.ZzCwValueMoney);

      methods.setValue('CurrentUse', respData.data?.d?.ZzCurrentUse);
      methods.setValue('Criticality', respData.data?.d?.ZzCriticality);
      methods.setValue('AssignedIntegrity', respData.data?.d?.ZzTargIntegrityDesc);
      methods.setValue('AssignedFunctionalityDesc', respData.data?.d?.ZzTargFunctionalDesc);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const onMessageModalClose = async () => {
    setRespMsg([]);
    if (refreshData) {
      await refreshData(defaultValues.WorkOrderNo);
    }
  };

  const operationsDefaultValues = {
    WorkOrderNo: '$00000001',
    WorkCenter: methods.getValues('MainWorkCentre'),
    OperationNo: 0,
    ConfirmationNo: '',
    Work: '0',
    WorkUnit: '',
    ControlKey: '',
    Plant: methods.getValues('PlanningPlant'),
    Quantity: '1',
    MaterialGroup: '',
    PurchasingGroup: '',
    PurchaseOrder: '',
    PurchaseOrderitem: '',
    ActionCode: '',
    ShortText: '',
    ToService: {
      results: [],
    },
    ToRelationship: {
      results: [],
    },
  };

  const showOrderType12456 =
    watch('OrderType') === 'ZM01' ||
    watch('OrderType') === 'ZM02' ||
    watch('OrderType') === 'ZM04' ||
    watch('OrderType') === 'ZM05' ||
    watch('OrderType') === 'ZM06';

  const showOrderType3 = watch('OrderType') === 'ZM03';

  const operationsColumns = [
    {
      accessor: 'OperationNo',
      header: 'Operation Number',
    },
    {
      accessor: 'ShortText',
      header: 'Short Text',
    },
    {
      accessor: 'WorkCenter',
      header: 'Work Centre',
    },
    {
      accessor: 'ControlKey',
      header: 'Control Key',
    },
    {
      accessor: 'Work',
      header: 'Work',
    },
    {
      accessor: 'WorkUnit',
      header: 'UoM',
    },
  ];

  const readNotif = async (id?: string) => {
    try {
      setLoading(true);
      let notifId;
      try {
        id = id?.trim();
        if (typeof id === 'object') {
          notifId = `ReadNotification('` + Object.values(id)[0] + `')`;
        } else {
          notifId = `ReadNotification('` + id + `')`;
        }
      } catch (e) {
        notifId = id;
      }
      const respData = await axiosInstance.get<ReadNotificationResponse>(
        `${getAPIUrl(
          process.env.REACT_APP_EE_NOTIF_SING_REQ_LIST,
        )}${notifId}?$expand=NotifToItem,NotifToUserStatus&$format=json`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      const singleNotifData = respData.data?.d as any;

      try {
        singleNotifData.NotifToItem.results.map((item) => {
          if (item.LineNo) {
            item.LineNo = item.LineNo.replace(/^0+/, '');
          }
        });
        setDataFromNotif(singleNotifData);
      } catch (e) {
        setDataFromNotif(singleNotifData);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const setDataFromNotif = async (data: any) => {
    setDisableNotifFields(true);
    methods.setValue('BasicStartDateTime', formatODataDateToDate(data.RequiredStartDateTime));
    methods.setValue('BasicStartDateTime', formatODataDateToDate(data.RequiredEndDateTime));
    methods.setValue('ScheduleStartDateTime', formatODataDateToDate(data.RequiredStartDateTime));
    methods.setValue('ScheduleFinishDateTime', formatODataDateToDate(data.RequiredEndDateTime));
    methods.setValue('PlannerGroup', data.PlannerGroup);
    methods.setValue('FunctionalLocation', data.FunctLocation);
    methods.setValue('MainWorkCentre', data.MainWorkCentre);
    methods.setValue('Description', data.NotificationDesc);
    methods.setValue('WorkOrderLongText', data.NotificationLongText);
    methods.setValue('EquipmentId', data.EquipmentNo);
    methods.setValue('Priority', data.NotificationPriority);
    methods.setValue('CustCostCentre', data.CustomerCostCentre);
    methods.setValue('RefNotificationNo', data.ReferenceNotification);
    methods.setValue('RefWorkOrder', data.ReferenceWorkOrder);
    methods.setValue('DefenceGroup', data.DefenceGroup);
    methods.setValue('CustomerAsset', data.CustomerAsset);
    methods.setValue('CustomerWbs', data.CustomerWbs);
    methods.setValue('CustomerSubAsset', data.CustomerSubAsset);
  };

  return (
    <div className="flex flex-col justify-center bg-white">
      <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
        {errorMessages.length > 0 &&
          errorMessages.map((message, index) => (
            <div key={index} className="my-4">
              <ErrorBanner>
                <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                  <p>
                    There is an error at <strong>{message.section}</strong>
                  </p>
                </button>
              </ErrorBanner>
            </div>
          ))}

        <div className="relative">
          <FormStepper
            onChange={(item: FormStepperItem) => {
              setStep(item.code);
            }}
            items={sections?.map((section) => ({
              code: section.code,
              name: section.name,
              href: '#',
              status:
                step === section.code
                  ? FormStepperEnum.Current
                  : step > section.code
                  ? FormStepperEnum.Complete
                  : FormStepperEnum.Upcoming,
            }))}
          />
          {respMsg.length > 0 && titleMsg !== '' && (
            <MessageModal
              title={titleMsg}
              messages={respMsg}
              open={!!respMsg.length}
              hidebutton={true}
              onOk={onMessageModalClose}
            ></MessageModal>
          )}
        </div>
        <div className="w-full pt-3">
          {step !== 6 && (
            <Text mb="1rem" color="redalert">
              **Some type ahead fields search are case sensitive.
            </Text>
          )}
          <SubBanner title={sections[step - 1]?.name} />
          {!updateForm && showOrderType12456 && (
            <Text mb="1rem" color="redalert">
              **To update the default values from service request, please use update functionality.
            </Text>
          )}
          {!editToggle && updateForm && (watch('OrderType') === 'ZM03' || watch('OrderType') === 'ZM06') && (
            <div className="grid w-full grid-cols-1 place-items-center gap-2 md:grid-cols-5">
              <button
                type="button"
                onClick={() => {
                  if (!methods.getValues('SystemStatus')?.includes('CLSD')) {
                    setReadOnly(!readOnly);
                  }
                  setEditToggle(!editToggle);
                }}
                className="button-primary bg-defence-orange-700"
                title="Edit/Update From"
              >
                <div className="flex items-center gap-1">
                  <Pencil2Icon width={20} height={20} />
                  Edit
                </div>
              </button>
            </div>
          )}

          {updateForm && editToggle && (
            <div className="grid w-full grid-cols-1 place-items-center gap-2 md:grid-cols-5">
              <button
                type="button"
                onClick={() => {
                  if (!methods.getValues('SystemStatus')?.includes('CLSD')) {
                    setReadOnly(!readOnly);
                  }
                  setEditToggle(!editToggle);
                }}
                className="button-primary bg-defence-orange-700"
                title="Cancel Edit / Update"
              >
                <div className="flex items-center gap-1">
                  <EyeOpenIcon width={20} height={20} />
                  Display
                </div>
              </button>

              {defaultValues.Teco && (
                <div className="w-max">
                  <button
                    type="button"
                    onClick={() => {
                      showTecoDialog();
                    }}
                    className="button-primary bg-defence-orange-100"
                    title="Technically Complete"
                  >
                    <div className="flex items-center gap-1">
                      <CheckboxIcon width={20} height={20} />
                      Technically Complete
                    </div>
                  </button>
                </div>
              )}
              {defaultValues.CancelTeco && (
                <div className="w-fit">
                  <button
                    type="button"
                    onClick={() => {
                      showCancelTecoDialog();
                    }}
                    className="button-primary bg-defence-orange-300"
                    title="Cancel TECO"
                  >
                    <div className="flex items-center gap-1">
                      <ResumeIcon width={20} height={20} />
                      Cancel (TECO)
                    </div>
                  </button>
                </div>
              )}

              {defaultValues.CloseWo && (
                <div className="w-fit">
                  <button
                    type="button"
                    onClick={() => {
                      showCloseDialog();
                    }}
                    className="button-primary bg-defence-orange-500"
                    title="Close WO"
                  >
                    <div className="flex items-center gap-1">
                      <MinusCircledIcon width={20} height={20} />
                      Close WO
                    </div>
                  </button>
                </div>
              )}

              {defaultValues.ResetDelete && (
                <div className="w-fit">
                  <button
                    type="button"
                    onClick={() => {
                      showResetDelDialog();
                    }}
                    className="button-primary bg-defence-orange-500"
                    title="Reset Delete"
                  >
                    <div className="flex items-center gap-1">
                      <ResetIcon width={20} height={20} />
                      Reset Delete
                    </div>
                  </button>
                </div>
              )}
              {defaultValues.SetDelete && (
                <div className="w-fit">
                  <button
                    type="button"
                    onClick={() => {
                      showDeleteDialog();
                    }}
                    className="button-primary bg-defence-orange-500"
                    title="Delete"
                  >
                    <div className="flex items-center gap-1">
                      <TrashIcon width={20} height={20} />
                      Delete
                    </div>
                  </button>
                </div>
              )}

              {showOrderType3 && step == 5 && (
                <div className="w-max">
                  <button
                    type="button"
                    onClick={() => {
                      showCalcDialog();
                    }}
                    className="button-primary bg-defence-orange-100"
                    title="Calculate Priority Category Weightings"
                  >
                    <div className="flex items-center gap-1">
                      <ClipboardCopyIcon width={20} height={20} />
                      Calculate Weightings
                    </div>
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {loading && <Spinner overlay />}
        {showDialog && (
          <div className="flex flex-col justify-center">
            <DialogModal
              title={dialogTitle}
              description={dialogMessage}
              okText="Yes"
              cancelText="No"
              onOk={onDialogOk}
              onCancel={onDialogCancel}
              inputDateTime={showInput}
              inputDateTimeLabel="Completion Date:"
              showCheckBox={showCheckBox}
              checkBoxLabel="Close Notification?"
            ></DialogModal>
          </div>
        )}
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(handleSubmitForm, goToErrors)}>
            <div className="mb-4 min-h-[8em] w-full">
              {step === 1 && (
                <div className="grid h-full grid-flow-row grid-cols-1	gap-1 md:grid-cols-1 md:gap-1">
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    {!updateForm && (
                      <SingleSelectInput
                        items={[
                          { code: 'ZM03', description: 'Estate Appraisal' },
                          { code: 'ZM06', description: 'MISCS Order' },
                        ]}
                        fieldLabel="Work order type"
                        fieldPlaceholder=" "
                        name="OrderType"
                        control={methods.control}
                        required={false}
                        defaultValue=""
                        disabled={updateForm}
                      />
                    )}
                    {updateForm && (
                      <SingleSelectInput
                        items={metadata['OrderType'] ? JSON.parse(metadata['OrderType'].FVALUE) : []}
                        fieldLabel="Work order type"
                        fieldPlaceholder=" "
                        name="OrderType"
                        control={methods.control}
                        required={false}
                        defaultValue=""
                        disabled={updateForm}
                      />
                    )}
                    {updateForm && (
                      <TextInput
                        fieldLabel="Work order number"
                        fieldPlaceholder=" "
                        name="WorkOrderNo"
                        control={methods.control}
                        required={false}
                        disabled={true}
                      />
                    )}

                    <TextInput
                      fieldLabel="Work Order Short Description"
                      fieldPlaceholder=" "
                      name="Description"
                      control={methods.control}
                      disabled={readOnly || disableNotifFields}
                      required={false}
                    />

                    <SingleSelectInput
                      items={metadata['Priority'] ? JSON.parse(metadata['Priority'].FVALUE) : []}
                      fieldLabel="Priority"
                      fieldPlaceholder=" "
                      name="Priority"
                      control={methods.control}
                      required={true}
                      disabled={readOnly || disableNotifFields}
                      defaultValue=""
                    />
                    {/* {!updateForm && (
                      <TextInput
                        fieldLabel="Planning Plant"
                        fieldPlaceholder=" "
                        name="PlanningPlant"
                        control={methods.control}
                        disabled={readOnly}
                        required={true}
                      />)} */}

                    {!updateForm && !readOnly && showOrderType12456 && (
                      <div>
                        <InputTypeAhead
                          fieldLabel="Service Request No"
                          fieldPlaceholder="start typing"
                          name="NotificationNo"
                          control={methods.control}
                          disabled={readOnly}
                          required={true}
                          codeSet={TypeAheadCodeSet.Notif}
                          top="20"
                          codeSetValueField="NotificationNo"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="grid h-full grid-flow-row grid-cols-1	gap-1 md:grid-cols-1 md:gap-1">
                  <TextAreaInput
                    fieldLabel="Add Description Long Text"
                    fieldPlaceholder=""
                    name="WorkOrderLongText"
                    disabled={readOnly || disableNotifFields}
                    control={methods.control}
                  />
                  {updateForm && (
                    <div className="grid grid-cols-1 md:grid-cols-1 md:gap-8">
                      <TextAreaInput
                        fieldLabel="Existing Description Long Text"
                        fieldPlaceholder=""
                        name="WorkOrderLongTextDisplay"
                        control={methods.control}
                        required={false}
                        disabled={true}
                      />
                    </div>
                  )}
                  <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8">
                    <SingleSelectInputCode
                      codeSet={TypeAheadCodeSet.UserStatus}
                      codeSetLabelField="Description"
                      codeSetValueField="UserStatus"
                      codeDepFilterField="StatusProfile"
                      codeDepFilterVal={
                        watch('OrderType') === 'ZM03'
                          ? 'ZEAORDER'
                          : watch('OrderType') === 'ZM06'
                          ? 'ZPMORDRS'
                          : 'ZPMORDER'
                      }
                      codeDisplayField="DisplayName"
                      additionalFilter="and StatusNumber ne '00'"
                      fieldLabel="User Status with Status Number"
                      fieldPlaceholder=" "
                      name="UserStatus"
                      control={methods.control}
                      required={false}
                      defaultValue={defaultValues.UserStatus}
                      disabled={readOnly}
                    />
                    <MultiSelectInputCode
                      codeSet={TypeAheadCodeSet.UserStatus}
                      codeSetLabelField="Description"
                      codeSetValueField="UserStatus"
                      codeSetFilters={
                        watch('OrderType') === 'ZM03'
                          ? [
                              { field: 'StatusProfile', value: 'ZEAORDER' },
                              { field: 'StatusNumber', value: '00' },
                            ]
                          : watch('OrderType') === 'ZM06'
                          ? [
                              { field: 'StatusProfile', value: 'ZPMORDRS' },
                              { field: 'StatusNumber', value: '00' },
                            ]
                          : [
                              { field: 'StatusProfile', value: 'ZPMORDER' },
                              { field: 'StatusNumber', value: '00' },
                            ]
                      }
                      fieldLabel="User Status without Status Number"
                      fieldPlaceholder=" "
                      name="UserStatusSet.results"
                      control={methods.control}
                      required={false}
                      defaultValue={defaultValues.UserStatusSet.results}
                      disabled={readOnly}
                    />

                    <SingleSelectInputCode
                      codeSet={TypeAheadCodeSet.MaintActType}
                      codeSetLabelField="MaintActTypeDesc"
                      codeSetValueField="MaintActType"
                      codeDepFilterField="OrderType"
                      codeDepFilterVal={watch('OrderType')}
                      fieldLabel="PM Act Type"
                      fieldPlaceholder=" "
                      name="MaintenanceType"
                      control={methods.control}
                      required={true}
                      defaultValue={defaultValues.MaintenanceType}
                      disabled={readOnly}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <DateInput
                      fieldLabel="Basic start date and time"
                      fieldPlaceholder=" "
                      name="BasicStartDateTime"
                      showTime={true}
                      disabled={(watch('Priority') === '5' && !readOnly ? false : true) || disableNotifFields}
                      control={methods.control}
                    />
                    <DateInput
                      fieldLabel="Basic end date and time"
                      fieldPlaceholder=" "
                      name="BasicFinishDateTime"
                      showTime={true}
                      disabled={(watch('Priority') === '5' && !readOnly ? false : true) || disableNotifFields}
                      control={methods.control}
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8">
                    <InputTypeAhead
                      fieldLabel="Revision"
                      name="RevnrRevision"
                      fieldPlaceholder="Start typing"
                      control={methods.control}
                      required={false}
                      codeSet={TypeAheadCodeSet.Revision}
                      top="20"
                      codeSetLabelField="Description"
                      codeSetValueField="Revision"
                      disabled={readOnly}
                    />

                    <InputTypeAhead
                      fieldLabel="Functional Location"
                      fieldPlaceholder="Start typing"
                      name="FunctionalLocation"
                      control={methods.control}
                      required={false}
                      codeSet={TypeAheadCodeSet.Floc}
                      top="20"
                      codeSetValueField="Floc"
                      codeSetLabelField="Description"
                      disabled={readOnly || disableNotifFields}
                    />
                    <InputTypeAhead
                      fieldLabel="Equipment"
                      fieldPlaceholder="Start typing"
                      name="EquipmentId"
                      control={methods.control}
                      required={false}
                      disabled={readOnly || disableNotifFields}
                      codeSet={TypeAheadCodeSet.Equip}
                      top="10"
                      codeSetValueField="EquipmentId"
                    />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <SingleSelectInput
                      items={metadata['PlannerGroup'] ? JSON.parse(metadata['PlannerGroup'].FVALUE) : []}
                      fieldLabel="Planner Group"
                      fieldPlaceholder=" "
                      name="PlannerGroup"
                      control={methods.control}
                      required={false}
                      disabled={readOnly || disableNotifFields}
                      defaultValue=""
                    />
                    <SingleSelectInput
                      items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                      fieldLabel="Main Work Centre"
                      fieldPlaceholder=" "
                      name="MainWorkCentre"
                      control={methods.control}
                      disabled={readOnly || disableNotifFields}
                      required={false}
                      defaultValue=""
                    />
                  </div>
                </div>
              )}
              {step === 3 && (
                <div className="grid h-full grid-flow-row grid-cols-1	gap-1 md:grid-cols-1 md:gap-1">
                  <TableInputGen
                    scenario="WO"
                    defaultValues={operationsDefaultValues}
                    readonly={readOnly}
                    keyfield="OperationNo"
                    path="ToOperation.results"
                    metadata={metadata}
                    ColumnsHeadersInput={operationsColumns}
                    hideDeleteForFirstRow={updateForm}
                  />
                </div>
              )}
              {step === 4 && (
                <div className="grid h-full grid-flow-row grid-cols-1	 gap-1 md:grid-cols-1 md:gap-1">
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <DateInput
                      fieldLabel="Scheduled start date and time"
                      fieldPlaceholder=" "
                      name="ScheduleStartDateTime"
                      disabled={true}
                      showTime={true}
                      control={methods.control}
                    />{' '}
                    <DateInput
                      fieldLabel="Scheduled end date and time"
                      fieldPlaceholder=" "
                      name="ScheduleFinishDateTime"
                      disabled={true}
                      showTime={true}
                      control={methods.control}
                    />{' '}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <DateInput
                      fieldLabel="Actual start date and time"
                      fieldPlaceholder=" "
                      name="ActualStartDateTime"
                      disabled={true}
                      showTime={true}
                      control={methods.control}
                    />{' '}
                    <DateInput
                      fieldLabel="Actual end date and time"
                      fieldPlaceholder=" "
                      name="ActualFinishDateTime"
                      disabled={true}
                      showTime={true}
                      control={methods.control}
                    />
                  </div>
                </div>
              )}
              {step === 5 && (
                <div className="grid h-full grid-flow-row grid-cols-1	gap-1 md:grid-cols-1 md:gap-1">
                  <>
                    <InputTypeAhead
                      fieldLabel="Customer cost centre"
                      fieldPlaceholder="start typing"
                      name="CustCostCentre"
                      disabled={readOnly || disableNotifFields}
                      control={methods.control}
                      required={false}
                      defaultValue={defaultValues.CustCostCentre}
                      codeSet={TypeAheadCodeSet.CostCentre}
                      top="10"
                      codeSetLabelField="ShortText"
                      codeSetValueField="CostCenter"
                    />
                    <InputTypeAhead
                      fieldLabel="Customer WBS"
                      fieldPlaceholder="start typing"
                      name="CustomerWbs"
                      disabled={readOnly || disableNotifFields}
                      control={methods.control}
                      required={false}
                      defaultValue={defaultValues.CustomerWbs}
                      codeSet={TypeAheadCodeSet.Wbs}
                      top="10"
                      codeSetValueField="WBS"
                    />
                    <InputTypeAhead
                      fieldLabel="Customer asset"
                      fieldPlaceholder="start typing"
                      name="CustomerAsset"
                      disabled={readOnly || disableNotifFields}
                      control={methods.control}
                      required={false}
                      codeSet={TypeAheadCodeSet.Asset}
                      codeSetValueField="Asset"
                      codeSetLabelField="Description"
                      top="10"
                      defaultValue={defaultValues.CustomerAsset}
                    />
                    <InputTypeAhead
                      fieldLabel="Customer sub-asset"
                      fieldPlaceholder="Start typing"
                      name="CustomerSubAsset"
                      control={methods.control}
                      required={false}
                      disabled={readOnly || disableNotifFields}
                      defaultValue={defaultValues.CustomerSubAsset}
                      codeSet={TypeAheadCodeSet.Asset}
                      codeSetValueField="SubAsset"
                      top="10"
                    />
                  </>

                  {showOrderType12456 && (
                    <>
                      <InputTypeAhead
                        fieldLabel="Reference notification"
                        fieldPlaceholder="start typing"
                        name="RefNotificationNo"
                        control={methods.control}
                        disabled={readOnly || disableNotifFields}
                        required={false}
                        codeSet={TypeAheadCodeSet.Notif}
                        top="20"
                        codeSetValueField="NotificationNo"
                        defaultValue={defaultValues.RefNotificationNo}
                      />
                      <InputTypeAhead
                        items={[]}
                        fieldLabel="Reference work order"
                        fieldPlaceholder="start typing"
                        name="RefWorkOrder"
                        control={methods.control}
                        disabled={readOnly || disableNotifFields}
                        required={false}
                        codeSet={TypeAheadCodeSet.Wo}
                        top="10"
                        codeSetValueField="WorkOrder"
                        defaultValue={defaultValues.RefWorkOrder}
                      />
                      <SingleSelectInput
                        items={metadata['DefenceGroup'] ? JSON.parse(metadata['DefenceGroup'].FVALUE) : []}
                        fieldLabel="Defence Group"
                        fieldPlaceholder=" "
                        name="DefenceGroup"
                        control={methods.control}
                        required={false}
                        defaultValue={defaultValues.DefenceGroup}
                        disabled={readOnly || disableNotifFields}
                        includeCode={false}
                      />
                    </>
                  )}
                  {(watch('OrderType') === 'ZM01' || watch('OrderType') === 'ZM02') && (
                    <>
                      <TextInput
                        fieldLabel="ECID"
                        fieldPlaceholder=" "
                        name="EstateClassID"
                        control={methods.control}
                        disabled={true}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Functional parent ECID"
                        fieldPlaceholder=" "
                        name="FunctionalParentECID"
                        control={methods.control}
                        disabled={true}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="EU Pricing ID1"
                        fieldPlaceholder=" "
                        name="EUPricingID1"
                        control={methods.control}
                        disabled={true}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="EU Pricing ID2"
                        fieldPlaceholder=" "
                        name="EUPricingID2"
                        control={methods.control}
                        disabled={true}
                        required={false}
                      />
                    </>
                  )}
                  {showOrderType3 && (
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Current Use"
                          fieldPlaceholder=" "
                          name="CurrentUse"
                          control={methods.control}
                          disabled={true}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="Criticality"
                          fieldPlaceholder=" "
                          name="Criticality"
                          control={methods.control}
                          disabled={true}
                          required={false}
                        />
                      </div>

                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Integrity assigned"
                          fieldPlaceholder=" "
                          name="AssignedIntegrity"
                          control={methods.control}
                          disabled={true}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="Functionality assigned"
                          fieldPlaceholder=" "
                          name="AssignedFunctionalityDesc"
                          control={methods.control}
                          disabled={true}
                          required={false}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.IntegrityAssessed}
                          codeSetLabelField="Description"
                          codeSetValueField="IntegrityAssessed"
                          fieldLabel="Integrity assessed"
                          fieldPlaceholder=" "
                          name="IntegrityAssessed"
                          control={methods.control}
                          required={false}
                          disabled={readOnly}
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.FunctionalityAssessed}
                          codeSetLabelField="Description"
                          codeSetValueField="FunctionalityAssessed"
                          fieldLabel="Functionality assessed"
                          fieldPlaceholder=" "
                          name="FunctionalityAssessed"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                          defaultValue=""
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.ComplianceIssue}
                          codeSetLabelField="Description"
                          codeSetValueField="ComplianceIssue"
                          fieldLabel="Compliance issue"
                          fieldPlaceholder=" "
                          name="ComplianceIssue"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                          defaultValue=""
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.ComplianceType}
                          codeSetLabelField="Description"
                          codeSetValueField="ComplianceType"
                          fieldLabel="Compliance type"
                          fieldPlaceholder=" "
                          name="ComplianceType"
                          control={methods.control}
                          required={false}
                          disabled={readOnly}
                          defaultValue=""
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.NoncompConsequence}
                          codeSetLabelField="Description"
                          codeSetValueField="NoncompConsequence"
                          fieldLabel="Non-compliance consequence"
                          fieldPlaceholder=" "
                          name="NoncompConsequence"
                          control={methods.control}
                          required={false}
                          disabled={readOnly}
                          defaultValue=""
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.ValueMoneyConsequence}
                          codeSetLabelField="Description"
                          codeSetValueField="ValueMoneyConsequence"
                          fieldLabel="Value for Money consequence"
                          fieldPlaceholder=" "
                          name="ValueMoneyConsequence"
                          control={methods.control}
                          required={false}
                          disabled={readOnly}
                          defaultValue=""
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Delta Integrity"
                          fieldPlaceholder=" "
                          name="DeltaIntegrity"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="Delta Functionality"
                          fieldPlaceholder=" "
                          name="DeltaFunctionality"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-5 md:gap-8">
                        <TextInput
                          fieldLabel="Non-compliance score"
                          fieldPlaceholder=" "
                          name="NoncompScore"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="CW Integrity %"
                          fieldPlaceholder=" "
                          name="CategoryWeightingIntegrity"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="CW Functionality %"
                          fieldPlaceholder=" "
                          name="CategoryWeightingFunctionality"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="CW Compliance %"
                          fieldPlaceholder=" "
                          name="CategoryWeightingCompliance"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="CW value for money %"
                          fieldPlaceholder=" "
                          name="CategoryWeightingValueForMoney"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Intended year"
                          fieldPlaceholder=" "
                          name="IntendedYear"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.SafetyImpact}
                          codeSetLabelField="Description"
                          codeSetValueField="SafetyImpact"
                          fieldLabel="Safety Impact"
                          fieldPlaceholder=" "
                          name="SafetyImpact"
                          control={methods.control}
                          required={false}
                          disabled={readOnly}
                          defaultValue=""
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Potential hazard"
                          fieldPlaceholder=" "
                          name="PotentialHazard"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                        <TextInput
                          fieldLabel="Potential harm"
                          fieldPlaceholder=" "
                          name="PotenialHarm"
                          control={methods.control}
                          disabled={readOnly}
                          required={false}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.WHSConsequence}
                          codeSetLabelField="Description"
                          codeSetValueField="WHSConsequence"
                          fieldLabel="WHS consequence"
                          fieldPlaceholder=" "
                          name="WHSConsequence"
                          control={methods.control}
                          required={false}
                          disabled={readOnly}
                          defaultValue=""
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.WHSLikelihood}
                          codeSetLabelField="Description"
                          codeSetValueField="WHSLikelihood"
                          fieldLabel="WHS likelihood"
                          fieldPlaceholder=" "
                          name="WHSLikelihood"
                          control={methods.control}
                          required={false}
                          disabled={readOnly}
                          defaultValue=""
                        />
                      </div>
                      <TextInput
                        fieldLabel="Risk level"
                        fieldPlaceholder=" "
                        name="RiskLevel"
                        control={methods.control}
                        disabled={readOnly}
                        required={false}
                      />
                    </>
                  )}
                </div>
              )}

              {step === 6 && (
                <div className="mb-8 w-full rounded-md bg-white">
                  <p className="my-2 text-lg font-bold">File Upload</p>
                  <Text mb="1rem" color="redalert">
                    **Upon successful upload, system will refresh the data. And if there's any error, please re-attach
                    the file.
                  </Text>
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                    {!updateForm ? (
                      <FileInput
                        fileType="*" // All file types
                        fileMax="20 MB"
                        selectedFiles={selectedFiles}
                        onFilesSelected={handleFilesSelected}
                        accept="*"
                      />
                    ) : (
                      <>
                        <FileUpload
                          fieldLabel=""
                          fileType="*"
                          fileMax="20 MB"
                          register=""
                          fieldname=""
                          errorMessage=""
                          validation=""
                          disabled={readOnly}
                          url={getAPIUrl(process.env.REACT_APP_EE_WO_ATTACHMENT)}
                          headers={{
                            WorkOrderNo: defaultValues.WorkOrderNo,
                          }}
                          accept="*"
                          refreshPage={true}
                        />
                        {defaultValues?.DIRSet?.results?.length > 0 && (
                          <p className="my-2 text-lg font-bold">Document Uploaded</p>
                        )}
                        {defaultValues?.DIRSet?.results?.map((file: any, index) => (
                          <div key={index} className="grid grid-cols-2">
                            <div className="px-4 py-2 text-base text-defence-black">{file.DocumentNo}</div>
                            <div className="px-4 py-2 text-base text-defence-black">{file.DocumentDesc}</div>
                          </div>
                        ))}
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="flex w-full justify-end">
              {step >= 1 && (
                <div className="flex">
                  {!(step === 1) && (
                    <button
                      type="button"
                      onClick={() => {
                        backStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Back
                    </button>
                  )}
                  {!(step === sections.length) && (
                    <button
                      type="button"
                      onClick={() => {
                        if (
                          step === 1 &&
                          methods.getValues('NotificationNo') !== '' &&
                          showOrderType12456 &&
                          !updateForm
                        ) {
                          const NotifNo = methods.getValues('NotificationNo');
                          readNotif(NotifNo);
                        } else {
                          setDisableNotifFields(false);
                        }
                        nextStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Next
                    </button>
                  )}
                </div>
              )}

              {!readOnly && step === sections.length && (
                <button type="submit" className="button-primary mr-0 bg-defence-orange-500">
                  Submit
                </button>
              )}
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};
