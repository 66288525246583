import React, { useState } from 'react';
import { Field } from '../types';
import { SCForm } from '../../../Core/FormElements';
import { useFormContext } from 'react-hook-form';
import { find18YearsAgo, pegaHeadingToFontSize } from './Utils';
import { Heading, Text } from '@chakra-ui/react';

interface FieldsProps {
  fields: Field[];
}

export const Fields: React.FC<FieldsProps> = ({ fields }) => {
  const data = useFormContext();
  const [values, setValues] = useState(data.getValues());

  const updateValues = () => {
    setValues(data.getValues());
  };

  const EIGHTEEN_YEARS_AGO = find18YearsAgo().toISOString().split('T')[0];

  return (
    <>
      {fields?.map((field) => {
        if (field.control?.type) {
          switch (field.control.type) {
            case 'pxDateTime':
              return (
                <SCForm.DateInput
                  key={field.fieldID}
                  visible
                  label={field.label}
                  onDateBlur={() => {}}
                  type="date"
                  desktopWidth={6}
                  reference={field.fieldID}
                  readOnly={field.disabled}
                  required={true}
                  validation={{
                    max: {
                      value: EIGHTEEN_YEARS_AGO,
                      message: 'You must be 18 years or older to complete this form',
                    },
                    required: 'Response is required',
                  }}
                  maxDate={EIGHTEEN_YEARS_AGO}
                />
              );
            case 'pxTextInput':
              return (
                <SCForm.Text
                  key={field.fieldID}
                  visible
                  label={field.label}
                  onBlur={() => {}}
                  desktopWidth={6}
                  reference={field.reference}
                  readOnly={field.disabled}
                  required={field.required}
                />
              );
            case 'pxRadioButtons':
              return (
                <SCForm.Radio
                  key={field.fieldID}
                  variant="primary"
                  options={[
                    { key: 'true', value: 'Yes' },
                    { key: 'false', value: 'No' },
                  ]}
                  visible
                  label={field.label}
                  value={data.getValues()[field.reference]}
                  onSelectRadio={(value) => {
                    data.setValue(field.reference, value);
                    updateValues();
                  }}
                  reference={field.reference}
                  desktopWidth={12}
                  readOnly={field.disabled}
                  required={field.required}
                  validation={{
                    required: 'Response is required',
                  }}
                />
              );
            case 'pxCheckbox':
              return (
                <SCForm.Checkbox
                  key={field.fieldID}
                  visible
                  label={field.control.label}
                  reference={field.reference}
                  desktopWidth={12}
                  disabled={field.disabled}
                  value={values[field.reference]}
                  onCheckboxChange={(value) => {
                    data.setValue(field.reference, value);
                    updateValues();
                  }}
                  validation={{ required: 'Response is required' }}
                />
              );
            default:
              return null;
          }
        }
        // Looking for headings
        else if (field.control?.format && field.value !== '') {
          return (
            //@ts-ignore because 'as' does not accept a string but it is still valid here
            <Heading {...pegaHeadingToFontSize(field.control.format)} fontWeight="medium">
              {field.value}
            </Heading>
          );
        } else if (field.paragraph) {
          return <Text dangerouslySetInnerHTML={{ __html: field.paragraph.value }} />;
        }
      })}
    </>
  );
};
