// TODO: Current not functioning but will make it work
import axios from 'axios';

export let axiosInstances = {};

/*
 * create axios instance
 */
let instance = axios.create({
  headers: {
    // 'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'Content-Type': 'multipart/form-data',
    slug: 'test.xlsx',
    // 'Accept' : '*/*'
  },
});

/*
 * handle request interceptor
 */
instance.interceptors.request.use(
  (config) => handleConfig(config),
  (error) => Promise.reject(error)
);

/*
 * handle response interceptor
 */
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      logout();
    } else {
      return Promise.reject(error);
    }
  }
);

/*
 * assign correspondance instance
 */
axiosInstances['api'] = instance;

/*
 * logput on getting status 401 from any api
 */
const logout = () => {
  window.location.href =
    'https://devpublic.serviceconnect.defence.gov.au/prweb/PRAuth/ServiceConnectAD';
};

/**
 * handle config for get/post
 */
const handleConfig = (config) => {
  config.withCredentials = true;

  return config;
};
