import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Text,
  Flex,
} from '@chakra-ui/react';
import { useState } from 'react';
import { SCForm } from '../../Core/FormElements';
import content from '../../../../pages/ServiceRequest/Content/LandingPage.json';
import { FormProvider, useForm } from 'react-hook-form';
import InfoBox from '../../Core/InfoBox/InfoBox';
import { Link } from '../../Core/Links/Links';
import Information from '../../Core/icons/Outline/InformationBold';
export interface StartSRProps {
  onClose(): void;
  continue(): void;
  isOpen: boolean;
}

const StartSR = (props: StartSRProps) => {
  const methods = useForm();

  //Set values for radio buttons
  const [selectedServiceRequired, setSelectedServiceRequired] = useState('Yes');
  const [selectedServiceRelated, setSelectedServiceRelated] = useState('Yes');

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        autoFocus={false}
        onClose={() => {
          props.onClose();
          //reset selected values to Yes
          setSelectedServiceRelated('Yes');
          setSelectedServiceRequired('Yes');
        }}
        size="xlarge"
      >
        <ModalOverlay />
        <ModalContent p={{ lg: '2.5rem', base: '1rem' }}>
          <FormProvider {...methods}>
            <ModalBody p={0}>
              <InfoBox>
                <Flex flexDirection={{ lg: 'row', base: 'column' }} gap={3}>
                  <Text fontWeight="medium" fontSize="regular">
                    {content.startSRModal.questionOne}
                  </Text>
                  <SCForm.Radio
                    id="serviceRequired"
                    visible={true}
                    variant="primary"
                    value={selectedServiceRequired}
                    onSelectRadio={(value) => setSelectedServiceRequired(value)}
                    options={[
                      { key: 'Yes', value: 'Yes' },
                      { key: 'No', value: 'No' },
                    ]}
                  />
                </Flex>
                <Text fontWeight="light">{content.startSRModal.contentOne}</Text>
                <Link text={content.startSRModal.phoneNumber} type="phone" href={content.startSRModal.phoneNumber} />
              </InfoBox>
              {/* Question Two Box */}
              <Box p={{ lg: '2rem', base: '1rem' }}>
                <Flex flexDirection={{ lg: 'row', base: 'column' }} gap={3}>
                  <Text fontWeight="medium" fontSize="regular">
                    {content.startSRModal.questionTwo}
                  </Text>
                  <SCForm.Radio
                    id="serviceRelated"
                    visible={true}
                    variant="primary"
                    value={selectedServiceRelated}
                    onSelectRadio={(value) => setSelectedServiceRelated(value)}
                    options={[
                      { key: 'Yes', value: 'Yes' },
                      { key: 'No', value: 'No' },
                    ]}
                  />
                </Flex>
                <Text fontWeight="light">{content.startSRModal.contentTwo}</Text>
              </Box>
            </ModalBody>
            {/* Modal Buttons Continue/Cancel */}
            <ModalFooter
              p={0}
              as={Flex}
              justifyContent="space-between"
              flexDirection={{ lg: 'row', base: 'column-reverse' }}
              gap="3"
            >
              <Button
                variant="secondary"
                w={{ lg: '190px', base: '100%' }}
                onClick={() => {
                  props.onClose();
                  //reset selected values to Yes
                  setSelectedServiceRelated('Yes');
                  setSelectedServiceRequired('Yes');
                }}
              >
                Cancel
              </Button>
              {selectedServiceRequired === 'No' && selectedServiceRelated === 'No' && (
                <Button
                  variant="primary"
                  w={{ lg: '190px', base: '100%' }}
                  onClick={() => {
                    props.continue();
                    //reset selected values to Yes
                    setSelectedServiceRelated('Yes');
                    setSelectedServiceRequired('Yes');
                  }}
                >
                  Continue
                </Button>
              )}
            </ModalFooter>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};

export default StartSR;
