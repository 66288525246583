import isEqual from 'lodash/isEqual';

import React, { useEffect, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { ActivityForm } from '../../../pages/orders/ActivityForm';
import { ServiceForm } from '../../../pages/orders/ServiceForm';
import { ServiceLineForm } from '../../../pages/ses/ServiceLine';
import { Table } from '../../Table';

export const TableInputGen = ({
  ColumnsHeadersInput,
  scenario,
  metadata,
  path, //"ToOperation.results"
  keyfield, // "OperationNo"
  defaultValues,
  readonly,
  onAddActivityChange,
  hideDeleteForFirstRow,
}: {
  ColumnsHeadersInput: any[];
  scenario?: string;
  metadata: any;
  path: string;
  keyfield: string;
  defaultValues: any;
  readonly?: boolean;
  onAddActivityChange?: any;
  hideDeleteForFirstRow?: boolean;
}) => {
  const [addActivity, _setAddActivity] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [oldData, setOldData] = useState<any>();

  const [tableData, setTableData] = useState([]);
  const [newItemIndex, setNewItemIndex] = useState(0);
  const { control, watch, trigger, getFieldState } = useFormContext();

  const { fields, append, remove, update } = useFieldArray({
    name: path,
  });

  // Save old data to be able to cancel changes
  useEffect(() => {
    if (watch(path)) {
      setOldData({ ...watch(path) });
    }
    return () => {
      // Remove new items that were not saved
      const list = watch(path);
      list?.forEach((field, index) => {
        const data = watch(`${path}[${index}]`);
        if (data['ActionCode'] === 'NEW') {
          remove(index);
        }
      });
    };
  }, []);

  const getNextLineNo = (keyField: string) => {
    if (!tableData?.length) {
      return '1';
    }
    const maxLineNo = Math.max(...tableData.map((item) => parseInt(item[keyField], 10))) || 0;
    return `${maxLineNo + 1}`;
  };

  const setAddActivity = (value: boolean) => {
    if (onAddActivityChange) {
      onAddActivityChange(value);
    }
    _setAddActivity(value);
  };

  useEffect(() => {
    setTableData(watch(path));
  }, [watch]);

  const handleAddActivity = async () => {
    const data = watch(path);
    setTableData(data);
    // trigger validation for the new item before adding it to the list
    await trigger();
    // if there are errors, do not add the new item
    const state = getFieldState(`${path}[${newItemIndex}]`);
    if (state?.error) {
      return;
    }

    update(newItemIndex, {
      ...data[newItemIndex],
      ActionCode: ['NEW', '01'].includes(data[newItemIndex]['ActionCode']) ? '01' : '02',
    });
    setAddActivity(!addActivity);
    setNewItemIndex(watch(path).length);
  };

  const activityDefaultValues = defaultValues;
  activityDefaultValues[keyfield] = getNextLineNo(keyfield);

  const addNewActivity = () => {
    setIsEditing(false);
    activityDefaultValues[keyfield] = getNextLineNo(keyfield);
    activityDefaultValues.ActionCode = 'NEW';
    append(activityDefaultValues);
    setNewItemIndex(tableData.length);
    setAddActivity(!addActivity);
  };

  const cancelActivity = (index: number, oldData: any) => {
    const data = watch(`${path}[${index}]`);
    if (data['ActionCode'] === 'NEW') {
      remove(index);
    } else if (!isEqual(oldData, watch(`${path}[${index}]`)) && isEditing) {
      update(index, oldData);
    }
    setIsEditing(false);
    setAddActivity(!addActivity);
    setTableData(watch(path));
  };

  const editRow = (data: any) => {
    const index = tableData.findIndex((item) => item[keyfield] === data[keyfield]);
    setIsEditing(true);
    setNewItemIndex(index);
    setAddActivity(!addActivity);
  };

  const deleteRow = (data: any) => {
    const index = tableData.findIndex((item) => item[keyfield] === data[keyfield]);
    const itemToRemove = watch(`${path}[${index}]`);
    if (['NEW', '01'].includes(data['ActionCode'])) {
      remove(index);
    } else {
      itemToRemove.ActionCode = '03';
      update(index, itemToRemove);
    }
    setTableData(watch(path));
  };

  return (
    <>
      {!addActivity && (
        <div>
          <Table
            ColumnsHeadersInput={ColumnsHeadersInput}
            values={tableData?.filter((item) => item['ActionCode'] !== '03')}
            editRow={editRow}
            deleteRow={deleteRow}
            readonly={readonly}
            hideDeleteForFirstRow={hideDeleteForFirstRow}
          />
          {!readonly && (
            <button
              className="button-secondary w-64 bg-white"
              type="button"
              onClick={() => {
                addNewActivity();
              }}
            >
              Add Activity
            </button>
          )}
        </div>
      )}
      {addActivity && scenario === 'WO' && (
        <div>
          <ActivityForm
            addActivity={handleAddActivity}
            cancelActivity={cancelActivity}
            fields={fields}
            control={control}
            indexToShow={newItemIndex}
            metadata={metadata}
          />
        </div>
      )}
      {addActivity && scenario === 'CONF' && (
        <div>
          <ServiceForm
            addActivity={handleAddActivity}
            cancelActivity={cancelActivity}
            fields={fields}
            control={control}
            indexToShow={newItemIndex}
            metadata={metadata}
          />
        </div>
      )}
      {addActivity && scenario === 'SES' && (
        <div>
          <ServiceLineForm
            addActivity={handleAddActivity}
            cancelActivity={cancelActivity}
            fields={fields}
            control={control}
            indexToShow={newItemIndex}
            metadata={metadata}
          />
        </div>
      )}
    </>
  );
};
