import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import moment from 'moment';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { Banner } from '../../components/Banner';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TableInputGen } from '../../components/form/inputs/tableInputGen';
import { TextAreaInput } from '../../components/form/inputs/textAreaInput';
import { TextInput } from '../../components/form/inputs/textInput';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { WorkOrderConfirmationResponse, WorkOrderMetadataResponseDataResults } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertData from '../../lib/convertData';
import convertSapMetadata from '../../lib/convertMetadata';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaOrder/metaOrder.action';
import { selectCurrentMeta } from '../../store/metaOrder/metaOrder.selector';

export const ConfirmationRead = () => {
  useDocumentTitle(`Read Confirmations`);

  const [singledata, setSingledata] = useState<{ [key: string]: any }>({});
  const [loading, setLoading] = useState(false);
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const refData = useRef(true);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const { id: confirmationNumber } = useParams();
  let [searchParams] = useSearchParams();
  const confirmationCounter = searchParams.get('confirmationCounter');

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (ref.current) {
          const { data: TableMetadata } = await axiosInstance.get<WorkOrderMetadataResponseDataResults>(
            `${getAPIUrl(process.env.REACT_APP_EE_WO_METADATA)}`,
          );

          const formMetaData = convertSapMetadata(TableMetadata);
          dispatch(setCurrentMeta(formMetaData));
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);

  useEffect(() => {
    const fetchSingleConf = async (confNo: string, confCnt: string) => {
      if (refData.current) {
        try {
          setLoading(true);
          const confirmationQuery = `(ConfirmationNo='` + confNo + `',ConfirmationCounter='` + confCnt + `')`;
          const respData = await axiosInstance.get<WorkOrderConfirmationResponse>(
            `${getAPIUrl(
              process.env.REACT_APP_EE_CONF_SING_REQ_LIST,
            )}${confirmationQuery}?$expand=WOConfirmSrvSet&$format=json`,
          );

          const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
          setTitleMessage(titleMessage);
          setMessages(messages);

          const confData = respData?.data;
          const singleConfData = convertData(confData) || {};
          try {
            singleConfData.WOConfirmSrvSet.results.map((item) => {
              if (item.LineNo) {
                item.LineNo = item.LineNo.replace(/^0+/, '');
              }
            });
            setSingledata(singleConfData);
          } catch (e) {
            setSingledata(singleConfData);
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          const errorResponse = isAxiosError(error) ? error.response : null;
          const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
            (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
          ]);
          setTitleMessage(titleMessage);
          setMessages(messages);
        }
      }
    };
    fetchSingleConf(confirmationNumber as never, confirmationCounter as never).catch(console.error);
    return () => {
      refData.current = true;
    };
  }, [confirmationNumber, confirmationCounter]);

  const schema = z.object({
    WorkOrderNo: z.string().optional(),
    ConfirmationText: z.string().optional(),
    SystemStaus: z.string().optional(),
    MainWorkCentre: z.string().optional(),
    ActualStartDateTime: z.date().optional(),
    ActualEndDateTime: z.date().optional(),
    ActualWorkQuantity: z.string().optional(),
    AccountingIndicator: z.string().optional(),
    ConfirmationNo: z.string().optional(),
    ConfirmationCounter: z.string().optional(),
    ReversedConfirmationIndicator: z.string().optional(),
    ActualWorkUnitOfMeasure: z.string().optional(),
    LongText: z.string().optional(),
    WOConfirmSrvSet: z.object({
      results: z
        .object({
          LineNo: z.string().optional(),
          ServiceNo: z.string().optional(),
          Description: z.string().optional(),
          Quantity: z.string().optional(),
          UnitOfMeasure: z.string().optional(),
          NetValue: z.string().optional(),
          GrossPrice: z.string().optional(),
          MaterialGroup: z.string().optional(),
          Currency: z.string().optional(),
          InsertDate: z.date().optional().nullable(),
          StartDateTime: z.date().optional().nullable(),
          EndDateTime: z.date().optional().nullable(),
          UserField2: z.any().optional().nullable(),
          UserField1: z.string().optional(),
          Location: z.string().optional(),
          Message: z.string().optional(),
        })
        .array(),
    }),
  });

  type FormSchema = z.infer<typeof schema>;

  const [defaultValues, setDefaultValues] = useState<FormSchema>({
    WorkOrderNo: '',
    ConfirmationText: '',
    SystemStaus: '',
    MainWorkCentre: '',
    ActualWorkQuantity: '',
    AccountingIndicator: '',
    ActualStartDateTime: undefined,
    ActualEndDateTime: undefined,
    ConfirmationNo: '',
    ConfirmationCounter: '',
    ReversedConfirmationIndicator: '',
    ActualWorkUnitOfMeasure: '',
    LongText: '',
    WOConfirmSrvSet: {
      results: [],
    },
  });

  useEffect(() => {
    setDefaultValues({
      WorkOrderNo: singledata.WorkOrderNo,
      ConfirmationText: singledata.ConfirmationText,
      SystemStaus: singledata.SystemStaus,
      MainWorkCentre: singledata.MainWorkCentre,
      ActualWorkQuantity: singledata.ActualWorkQuantity,
      AccountingIndicator: singledata.AccountingIndicator,
      ActualStartDateTime: singledata.ActualStartDateTime,
      ActualEndDateTime: singledata.ActualEndDateTime,
      ConfirmationNo: singledata.ConfirmationNo,
      ConfirmationCounter: singledata.ConfirmationCounter,
      ReversedConfirmationIndicator: singledata.ReversedConfirmationIndicator,
      ActualWorkUnitOfMeasure: singledata.ActualWorkUnitOfMeasure,
      LongText: singledata.LongText,
      WOConfirmSrvSet: {
        results:
          singledata?.WOConfirmSrvSet?.results.length > 0
            ? singledata?.WOConfirmSrvSet?.results?.map((item: any) => {
                return {
                  ServiceNo: item.ServiceNo,
                  LineNo: item.LineNo,
                  Description: item.Description,
                  Quantity: item.Quantity,
                  UnitOfMeasure: item.UnitOfMeasure,
                  NetValue: item.NetValue,
                  GrossPrice: item.GrossPrice,
                  MaterialGroup: item.MaterialGroup,
                  Currency: item.Currency,
                  InsertDate: convertODataDateToDate(item.InsertDate),
                  StartDateTime: convertODataDateToDate(item.StartDateTime),
                  EndDateTime: convertODataDateToDate(item.EndDateTime),
                  UserField1: item.UserField1,
                  UserField2: item.UserField2,
                  Location: item.Location,
                  Message: item.Message,
                };
              })
            : [],
      },
    });
  }, [singledata]);

  const convertODataDateToDate = function (date: any) {
    if (date !== undefined && date.includes('/Date(')) {
      const stringForDateFunction = 'return new ' + date.replaceAll('/', ''); //Odata return miliseconds for date, need to convert
      const getDate = new Function(stringForDateFunction); //run string as a function to get real date
      date = moment(getDate()).format('DD-MMM-YYYY, HH:mm:ss');
      return date;
    }
  };

  const methods = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    if (defaultValues) {
      methods.reset({ ...defaultValues });
    }
  }, [defaultValues, methods.reset]);

  const onSubmit = async (data: FormSchema) => {
    try {
    } catch (error) {
      if (isAxiosError(error)) {
      }
    }
  };

  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Confirmation Details',
      fields: [
        'ConfirmationNo',
        'ConfirmationCounter',
        'WorkOrderNo',
        'ConfirmationText',
        'SystemStaus',
        'MainWorkCentre',
        'ActualStartDateTime',
        'ActualEndDateTime',
        'ActualWorkQuantity',
        'AccountingIndicator',
        'ReversedConfirmationIndicator',
        'ActualWorkUnitOfMeasure',
        'LongText',
      ],
    },
    {
      code: 2,
      name: 'Service Details',
      fields: [
        'ServiceNo',
        'LineNo',
        'Description',
        'Quantity',
        'UnitOfMeasure',
        'NetValue',
        'GrossPrice',
        'MaterialGroup',
        'Currency',
        'InsertDate',
        'StartDateTime',
        'EndDateTime',
        'UserField1',
        'UserField2',
        'Location',
        'Message',
      ],
    },
  ];

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <>
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <SubMenu title="Read Work Order Confirmation" selectedIndex="0" />
      <Banner title="Display Work Order Confirmation" description="" bannerImgSrc="" />
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                    ? FormStepperEnum.Complete
                    : FormStepperEnum.Upcoming,
              }))}
            />
          </div>
          <div className="w-full pt-3">
            <SubBanner title={sections[step - 1].name} />
          </div>

          <div className="w-full p-2">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, goToErrors)}>
                {step === 1 && (
                  <div className="mb-8 w-full">
                    <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Confirmation Number"
                          fieldPlaceholder=" "
                          name="ConfirmationNo"
                          control={methods.control}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Confirmation Count"
                          fieldPlaceholder=" "
                          name="ConfirmationCounter"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Work Order Number"
                          fieldPlaceholder=" "
                          name="WorkOrderNo"
                          control={methods.control}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Confirmation Text"
                          fieldPlaceholder=" "
                          name="ConfirmationText"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="System Status"
                          fieldPlaceholder=" "
                          name="SystemStaus"
                          control={methods.control}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Main Work Centre"
                          fieldPlaceholder=" "
                          name="MainWorkCentre"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Work Start Date and Time"
                          fieldPlaceholder=" "
                          name="ActualStartDateTime"
                          control={methods.control}
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Work Finish Date and Time"
                          fieldPlaceholder=" "
                          name="ActualEndDateTime"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Actual Work"
                          fieldPlaceholder=" "
                          name="ActualWorkQuantity"
                          control={methods.control}
                          disabled={true}
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.UoM}
                          codeSetLabelField="Description"
                          codeSetValueField="Uom"
                          codeSetFilterField="Description"
                          fieldLabel="UoM"
                          fieldPlaceholder=" "
                          control={methods.control}
                          name="ActualWorkUnitOfMeasure"
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.AccountingIndicator}
                          codeSetLabelField="AccountingIndicatorText"
                          codeSetValueField="AccountingIndicator"
                          codeSetFilterField="AccountingIndicatorText"
                          fieldLabel="Accounting Indicator"
                          fieldPlaceholder=" "
                          control={methods.control}
                          name="AccountingIndicator"
                          disabled={true}
                        />
                        <TextInput
                          fieldLabel="Reversed"
                          fieldPlaceholder=" "
                          name="ReversedConfirmationIndicator"
                          control={methods.control}
                          disabled={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-1 md:gap-8">
                        <TextAreaInput
                          fieldLabel="Cancellation Text"
                          fieldPlaceholder=""
                          name="LongText"
                          control={methods.control}
                          required={false}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="mb-8 w-full">
                    <>
                      <TableInputGen
                        scenario="CONF"
                        readonly={true}
                        defaultValues={{
                          LineNo: '10',
                          ServiceNo: '',
                          Description: '',
                          Quantity: '',
                          UnitOfMeasure: '',
                          NetValue: '',
                          Currency: '',
                          EndDateTime: null,
                          GrossPrice: '',
                          InsertDate: null,
                          MaterialGroup: '',
                          StartDateTime: null,
                          UserField1: '',
                          UserField2: '',
                          Location: '',
                          Message: '',
                        }}
                        keyfield="LineNo"
                        path="WOConfirmSrvSet.results"
                        metadata={metadata}
                        ColumnsHeadersInput={[
                          {
                            accessor: 'ServiceNo',
                            header: 'Service Number',
                          },
                          {
                            accessor: 'Description',
                            header: 'Short Description',
                          },
                          {
                            accessor: 'LineNo',
                            header: 'Line No',
                          },
                          {
                            accessor: 'Quantity',
                            header: 'Qty',
                          },
                          {
                            accessor: 'UnitOfMeasure',
                            header: 'UoM',
                          },
                          {
                            accessor: 'NetValue',
                            header: 'Net Value',
                          },
                          {
                            accessor: 'GrossPrice',
                            header: 'Gross Price',
                          },
                          {
                            accessor: 'MaterialGroup',
                            header: 'Material Group',
                          },
                          {
                            accessor: 'Currency',
                            header: 'Currency',
                          },
                          {
                            accessor: 'InsertDate',
                            header: 'Insert Date',
                          },
                          {
                            accessor: 'StartDateTime',
                            header: 'Start Date and Time',
                          },
                          {
                            accessor: 'EndDateTime',
                            header: 'End Date and Time',
                          },
                          {
                            accessor: 'UserField1',
                            header: 'User Defined Field 1',
                          },
                          {
                            accessor: 'UserField2',
                            header: 'User Defined Field 2',
                          },
                          {
                            accessor: 'Location',
                            header: 'Location',
                          },
                          {
                            accessor: 'Message',
                            header: 'Message',
                          },
                        ]}
                      />
                    </>
                  </div>
                )}
                <div className="flex w-full justify-end">
                  <div className="flex-end flex">
                    {step === 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          nextStep();
                        }}
                        className="button-primary bg-defence-orange-500"
                      >
                        Next
                      </button>
                    )}
                    {step === 2 && (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            backStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Back
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  );
};
