import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { TablePagination } from '../../components/TablePagination';
import { WorkOrderRequestListSetResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertData from '../../lib/convertData';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { selectCurrentMeta } from '../../store/metaOrder/metaOrder.selector';

export const BulkOrderProgressReport = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any[]>([]);
  const metadata = useSelector(selectCurrentMeta);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get<WorkOrderRequestListSetResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_REQUEST_LIST)}?$format=json`,
      );
      setLoading(false);
      convertData(response?.data);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
      setTitleMessage(titleMessage);
      setMessages(messages);
      if (response.status === 200 || response.status === 201) {
        const tableData = response.data.d?.results || [];
        setData(tableData);
      } else {
        const tableData = [];
        setData(tableData);
      }
    } catch (err) {
      setLoading(false);
      const errorResponse = isAxiosError(err) ? err.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const getFile = async (guid: string) => {
    setLoading(true);
    try {
      const file = await axiosInstance.get(`${getAPIUrl(process.env.REACT_APP_EE_WO_DOWNLOAD_FILE)}/${guid}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(file.data);
      const link = document.createElement('a');
      link.href = url;
      let filename = file.headers['x-filename'];
      if (!filename) {
        filename = file.headers['content-disposition'].split('=')[1] || '';
        filename = filename.replace(/"/g, ''); // trim double
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const errorResponse = isAxiosError(err) ? err.response : null;
      let { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      if (titleMessage === 'Error' && errorResponse?.status === 600) {
        messages = [];
        messages.push('Please use FTP functionality to download big file');
      }
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
    getData();
  }, []);

  const columnHelper = createColumnHelper<{ [key: string]: string }>();

  const columns = [
    columnHelper.accessor('RequestId', {
      header: () => (metadata['RequestId'] ? metadata['RequestID'].LABEL : 'Request ID'),
    }),
    columnHelper.accessor('CreatedAt', {
      header: () => (metadata['CreatedAt'] ? metadata['CreatedAt'].LABEL : 'Created At'),
    }),
    columnHelper.accessor('CreatedBy', {
      header: () => (metadata['CreatedBy'] ? metadata['CreatedBy'].LABEL : 'Created By'),
    }),
    columnHelper.accessor('ExpiresAt', {
      header: () => (metadata['ExpiresAt'] ? metadata['ExpiresAt'].LABEL : 'Expires At'),
    }),
    columnHelper.accessor('StatusText', {
      header: () => (metadata['StatusText'] ? metadata['StatusText'].LABEL : 'Status'),
    }),
    columnHelper.accessor('BtcFinishedAt', {
      header: () => (metadata['BtcFinishedAt'] ? metadata['BtcFinishedAt'].LABEL : 'Finished At'),
    }),
    columnHelper.accessor('Filename', {
      header: () => (metadata['Filename'] ? metadata['Filename'].LABEL : 'Filename'),
      cell: (info) => (
        <Link
          onClick={() => getFile(`(guid'` + info.row.getValue('RequestId') + `')`)}
          to="#"
          className="flex text-defence-orange-500 underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('RowCount', {
      header: () => (metadata['RowCount'] ? metadata['RowCount'].LABEL : 'Row Count'),
    }),
  ];

  const columnsMobile = [
    columnHelper.accessor('RequestID', {
      header: () => (metadata['RequestID'] ? metadata['RequestID'].LABEL : 'Request ID'),
    }),
    columnHelper.accessor('CreatedAt', {
      header: () => (metadata['CreatedAt'] ? metadata['CreatedAt'].LABEL : 'Created At'),
    }),
    columnHelper.accessor('CreatedBy', {
      header: () => (metadata['CreatedBy'] ? metadata['CreatedBy'].LABEL : 'Created By'),
    }),
    columnHelper.accessor('ExpiresAt', {
      header: () => (metadata['ExpiresAt'] ? metadata['ExpiresAt'].LABEL : 'Expires At'),
    }),
    columnHelper.accessor('StatusText', {
      header: () => (metadata['StatusText'] ? metadata['StatusText'].LABEL : 'Status'),
    }),
    columnHelper.accessor('BtcFinishedAt', {
      header: () => (metadata['BtcFinishedAt'] ? metadata['BtcFinishedAt'].LABEL : 'Finished At'),
    }),
    columnHelper.accessor('Filename', {
      header: () => (metadata['Filename'] ? metadata['Filename'].LABEL : 'Filename'),
      cell: (info) => (
        <Link
          onClick={() => getFile(`(guid'` + info.row.getValue('RequestId') + `')`)}
          to="#"
          className="flex text-defence-orange-500 underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('RowCount', {
      header: () => (metadata['RowCount'] ? metadata['RowCount'].LABEL : 'Row Count'),
    }),
  ];
  const table = useReactTable({
    data,
    columns: isMobile ? columnsMobile : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: isMobile ? { pagination: { pageSize: 15 } } : { pagination: { pageSize: 30 } },
  });

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Bulk Work Orders Progress Report',
      }}
      bannerProps={{
        title: 'Bulk Progress Report',
      }}
      submenuProps={{
        title: 'Work Orders',
        selectedIndex: '3',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}

      <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
        <SubBanner title="Bulk Work Orders Progress Report" />
        <div className="flex overflow-x-auto">
          <table className="w-full flex-shrink-0">
            <thead className="border bg-defence-orange-500 text-left font-normal text-white">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th className="border border-black p-2 text-x0" key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="border text-sm md:text-base">
              {table.getRowModel().rows.map((row) => (
                <>
                  <tr key={row.id} className="border even:bg-slate-200">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="border border-black p-2 text-x0">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </tbody>

            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <div className="h-2" />
        <TablePagination tableInstance={table} />
      </div>
    </BaseLayout>
  );
};
