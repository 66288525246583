import React from "react";
import * as Dialog from "@radix-ui/react-alert-dialog";
import { useState } from "react";
import { CheckBox } from "./form/inputs/checkBox";

export const DialogModal = ({
  title,
  description,
  okText,
  cancelText,
  onOk,
  onCancel,
  inputDateTime,
  inputDateTimeLabel,
  showCheckBox,
  checkBoxLabel,
}: {
  title: string;
  description: string;
  okText?: string;
  cancelText?: string;
  onOk: (inputValue?, checkBox?) => void;
  onCancel: () => void;
  inputDateTime?: boolean;
  inputDateTimeLabel?: string;
  showCheckBox?: boolean;
  checkBoxLabel?: string;
}) => {

  const getCurrentDateTime = () => {
    const now = new Date();
    const offset = now.getTimezoneOffset();
    const adjustedDate = new Date(now.getTime() - offset * 60 * 1000); // Adjust for timezone offset
    return adjustedDate.toISOString().slice(0, 19); // Format to 'YYYY-MM-DDTHH:MM:SS'
  };
  const [dialogInput, setDialogInput] = useState(getCurrentDateTime());
  const [dialogCheckBox, setDialogCheckBox] = useState('X');
  return (
    <Dialog.Root defaultOpen={true}>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-blackA9 data-[state=open]:animate-overlayShow" />
        <Dialog.Content className="fixed inset-x-0 top-[20%] mx-auto w-4/5 rounded bg-white p-8 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow sm:w-9/12 md:w-3/5 lg:w-2/5">
          <Dialog.Title className="m-0 text-center text-[25px] font-medium text-black">{title}</Dialog.Title>
          <Dialog.Description className="mb-5 mt-[10px] text-[15px] text-center justify-center leading-normal text-black">
            {description}
            {inputDateTime && <div>
              <label htmlFor="input1" className="mb-2 mt-2 flex text-[15px]">{inputDateTimeLabel}</label>
              <input
                id="input1"
                type="datetime-local"
                value={dialogInput}
                className={`flex w-50px items-center rounded-sm border border-defence-light-grey-500 py-1.5 pl-2 text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:outline-black focus:border focus:border-defence-blueGrey focus:bg-white focus-visible:outline disabled:bg-defence-light-grey-200`}
                onChange={(e) => { setDialogInput(e.target.value) }}
                step="1"
              />
            </div>}
            {showCheckBox && <div className="flex gap-5 mt-2">
              <label htmlFor="checkBox" className="text-[15px] place-items-center">{checkBoxLabel}</label>
              <input
                id="checkBox"
                type="checkbox"
                value={dialogCheckBox}
                checked={true}
                onChange={(e) => { setDialogCheckBox(e.target.checked ? 'X' : '') }}
                className="justify-items-start"
              />
            </div>}
          </Dialog.Description>
          {okText && cancelText && (
            <div className="flex justify-center gap-5">
              <Dialog.Cancel asChild>
                <button className="button-primary bg-defence-red-500" onClick={() => onCancel()}>{cancelText}</button>
              </Dialog.Cancel>
              <Dialog.Action asChild>
                <button className="button-secondary" onClick={() => onOk(inputDateTime ? dialogInput : null,
                  showCheckBox ? dialogCheckBox : null)}>{okText}</button>
              </Dialog.Action>
            </div>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};