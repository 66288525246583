import { Box, Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import React from 'react';
import { SubContainer } from '../../Core/Layout';

interface SubCTAProps {
  mainHeading: string;
  subHeading: string;
  buttonVariant: string;
  buttonText: string;
  buttonURL: string;
}

export const SubCTA: React.FC<SubCTAProps> = ({ mainHeading, subHeading, buttonVariant, buttonURL, buttonText }) => {
  return (
    <SubContainer>
      <Flex
        gap={3}
        flexDir={{ lg: 'row', base: 'column' }}
        bg="whispergrey"
        justifyContent="space-between"
        p="1.5rem"
        alignItems="center"
      >
        <Box>
          <Heading as="h3" size="regular" fontWeight="medium">
            {mainHeading}
          </Heading>
          <Text fontWeight="light">{subHeading}</Text>
        </Box>
        <Button variant={buttonVariant} as={Link} href={buttonURL} width={{ lg: 'auto', base: '100%' }}>
          {buttonText}
        </Button>
      </Flex>
    </SubContainer>
  );
};
