import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React from 'react';
// import { createTableData } from "../../assets/tableData";
import { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Link, useLocation } from 'react-router-dom';
// import { serviceRequestTableMetadata } from "../../lib/api/notifications";
import convertSapData from '../../lib/convertData';
// import convertSapMetadata from "../../lib/convertMetadata";
import { useDispatch, useSelector } from 'react-redux';
import { Banner } from '../../components/Banner';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { TablePagination } from '../../components/TablePagination';
import { ROUTES } from '../../constants';
import { useNavigationContext } from '../../hooks';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { useSessionStorage } from '../../hooks/useStorage';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';

export const ServiceEntrySheetSearchResults = () => {
  useDocumentTitle(`Read Service Entry Sheet`);

  const [data, setData] = useState([]);
  const metadata = useSelector(selectCurrentMeta);
  const location = useLocation();
  const { prevLocation } = useNavigationContext();

  const { removeStoredData, storeData, storedData } = useSessionStorage('ses');

  const { data: tableData } = location?.state
    ? location.state
    : {
      data: storedData,
    };
  const dispatch = useDispatch();

  useEffect(() => {
    // serviceRequestTableMetadata().then((response) => {
    //   const tableMetaData = convertSapMetadata(response.data) || {};
    //   setMetadata(tableMetaData);
    // });
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    if (tableData) {
      storeData(tableData);
      const sapdata = convertSapData(tableData);
      setData(sapdata);
    } else if (
      prevLocation?.pathname &&
      prevLocation?.pathname !== ROUTES.SES_SEARCH_RESULTS
    ) {
      removeStoredData();
    } else if (storedData) {
      setData(convertSapData(storedData));
    } else {
      setData([]);
    }
  }, [
    tableData,
    storeData,
    removeStoredData,
    prevLocation,
    storedData,
    dispatch,
  ]);

  //const metadata = convertSapMetadata(tableMetaData) || {};

  const columnHelper = createColumnHelper<{ [key: string]: string }>();

  const columns = [
    columnHelper.accessor('SheetNumber', {
      header: () =>
        metadata['SheetNumber'] ? metadata['SheetNumber'].LABEL : 'Sheet No.',
      cell: (info) => (
        <Link
          to={`/eeportal/ses/search/results/${info.getValue()}/update`}
          className="text flex text-defence-orange-500 underline hover:no-underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('Vendor', {
      header: () => (metadata['Vendor'] ? metadata['Vendor'].LABEL : 'Vendor'),
    }),
    columnHelper.accessor('ExternalNumber', {
      header: () =>
        metadata['ExternalNumber']
          ? metadata['ExternalNumber'].LABEL
          : 'External No',
    }),
    columnHelper.accessor('DocumentDate', {
      header: () =>
        metadata['DocumentDate']
          ? metadata['DocumentDate'].LABEL
          : 'Document Date',
    }),
    columnHelper.accessor('ShortText', {
      header: () =>
        metadata['ShortText'] ? metadata['ShortText'].LABEL : 'Short Text',
    }),
    columnHelper.accessor('BeginDate', {
      header: () =>
        metadata['BeginDate'] ? metadata['BeginDate'].LABEL : 'Begin Date',
    }),
    columnHelper.accessor('EndDate', {
      header: () =>
        metadata['EndDate'] ? metadata['EndDate'].LABEL : 'End Date',
    }),
    columnHelper.accessor('ReportedBy', {
      header: () =>
        metadata['ReportedBy'] ? metadata['ReportedBy'].LABEL : 'Reported By',
    }),
    columnHelper.accessor('PurchaseOrder', {
      header: () =>
        metadata['PurchaseOrder']
          ? metadata['PurchaseOrder'].LABEL
          : 'Purchase Order',
    }),
    columnHelper.accessor('PurchaseOrderItem', {
      header: () =>
        metadata['PurchaseOrderItem']
          ? metadata['PurchaseOrderItem'].LABEL
          : 'PO Item',
    }),
    columnHelper.accessor('Location', {
      header: () =>
        metadata['Location'] ? metadata['Location'].LABEL : 'Location',
    }),
    columnHelper.accessor('CommNumber', {
      header: () =>
        metadata['CommNumber'] ? metadata['CommNumber'].LABEL : 'Comm No',
    }),
    columnHelper.accessor('ReferenceDocumentNumber', {
      header: () =>
        metadata['ReferenceDocumentNumber']
          ? metadata['ReferenceDocumentNumber'].LABEL
          : 'Reference',
    }),
    columnHelper.accessor('RunId', {
      header: () =>
        metadata['RunId'] ? metadata['RunId'].LABEL : 'Run Id',
    }),
  ];

  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: isMobile
      ? { pagination: { pageSize: 15 } }
      : { pagination: { pageSize: 30 } },
  });

  return (
    <>
      <>
        <SubMenu title="Service Entry Sheet" selectedIndex="0" />
        <Banner
          title="Service Entry Sheet Search Results"
          description=""
          bannerImgSrc=""
        />
        <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
          <SubBanner title="Service Entry Sheet Search Results" />
          <div className="">
            <div className="flex overflow-x-auto p-2">
              <table className="w-full flex-shrink-0">
                <thead className="border bg-defence-orange-500 text-left font-normal text-white">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          className="border border border-black p-2 text-x0"
                          key={header.id}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="border text-sm md:text-base">
                  {table.getRowModel().rows.map((row) => (
                    <>
                      <tr key={row.id} className="border even:bg-slate-200">
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="fontweight-light border border-black p-2 text-x0"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}
                </tbody>

                <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.footer,
                              header.getContext()
                            )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
            <div className="h-2" />
            <TablePagination tableInstance={table} />
          </div>
        </div>
      </>
    </>
  );
};
