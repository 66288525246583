import React from 'react';
import { Input, FormLabel, FormErrorMessage, InputGroup } from '@chakra-ui/react';
import { DateProps, FormElement } from '../FormElementType';
import { PrimitiveControl } from '../PrimitiveControl';
import { useFormContext } from 'react-hook-form';

const FormDateInput: React.FC<FormElement & DateProps> = (props: FormElement & DateProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  // Store the id of the input element and a fallback
  const id = props.reference?.replace(/\./g, '') || 'dateinput';

  return (
    <PrimitiveControl
      disabled={props.disabled}
      isInvalid={props.isInvalid ?? !!errors[id]}
      required={props.required}
      desktopWidth={props.desktopWidth}
      mobileWidth={props.mobileWidth}
    >
      <InputGroup flexDirection="column">
        {props.label ? <FormLabel>{props.label}</FormLabel> : <></>}
        <Input
          variant="primary"
          data-testid="input"
          defaultValue={String(props.value)}
          placeholder={props.label}
          min={props.minDate}
          max={props.maxDate}
          type={props.type}
          {...register(id, {
            ...props?.validation,
            onChange: (e) => {
              props.onDateBlur(e);
            },
          })}
        />
        <FormErrorMessage>
          <>{props.errorMessage ?? (errors[id] && errors[id]?.message)}</>
        </FormErrorMessage>
      </InputGroup>
    </PrimitiveControl>
  );
};

export default FormDateInput;
