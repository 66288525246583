/* eslint-disable jsx-a11y/anchor-is-valid */
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Spinner } from '../../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../../components/BaseLayout';
import { MessageModal } from '../../../components/MessageModal';
import { InputTypeAhead } from '../../../components/form/inputs/inputTypeAhead';
import { SingleSelectInput } from '../../../components/form/inputs/singleSelectInput';
import { SingleSelectInputCode } from '../../../components/form/inputs/singleSelectInputCode';
import { MasterDownloadReqSrvSetResponse } from '../../../lib/api/generated-types';
import { getAPIUrl } from '../../../lib/api/getAPIUrl';
import { axiosInstance } from '../../../lib/api/instance';
import { TypeAheadCodeSet } from '../../../lib/api/lookup';
import convertFormData from '../../../lib/convertFormData';
import formatMessagesToDisplay from '../../../lib/formatMessagesToDisplay';

export const ServiceMasterBulkUploadDownload = () => {
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const schema = z.object({
    UploadDownload: z.string().optional(),
    MaterialGroup: z.string().superRefine((val, ctx) => {
      if (val === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    ActivityNumber: z.string().optional(),
    // ServiceCat: z.string().optional(),
    // ValuationClass: z.string().optional(),
    DeletionInd: z.string().optional(),
  });

  type formSchema = z.infer<typeof schema>;

  const defaultValues: formSchema = {
    UploadDownload: 'Download',
    MaterialGroup: '',
    ActivityNumber: '',
    // ServiceCat: '',
    // ValuationClass: '',
    DeletionInd: '',
  };

  const { handleSubmit, watch, control } = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: formSchema) => {
    if (data.UploadDownload === 'Download') {
      const results = convertData(data);
      const formDataQuery = convertFormData(results);
      setLoading(true);
      const url = `${getAPIUrl(process.env.REACT_APP_EE_SRV_DOWNLOAD_REQ)}?filter=(${formDataQuery})&$format=json`;
      try {
        const response = await axiosInstance.get<MasterDownloadReqSrvSetResponse>(url);
        setLoading(false);
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Request successfully submitted, please check Bulk Progress Report page',
          response.data?.d?.results?.[0]?.RequestId || '',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      } catch (err) {
        setLoading(false);
        const errorResponse = isAxiosError(err) ? err.response : null;

        const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
          (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    }
  };

  const convertData = function (data: any) {
    let valInd = JSON.parse(JSON.stringify(data.DeletionInd));
    if (typeof valInd === 'string') {
      if (valInd === 'No') {
        data.DeletionInd = false;
      }
      if (valInd === 'Yes') {
        data.DeletionInd = true;
      }
    }
    return data;
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Bulk Download Service Master',
      }}
      bannerProps={{
        title: 'Bulk Download',
      }}
      submenuProps={{
        title: 'Service Master',
        selectedIndex: '2',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white py-2">
        <div className="m-auto w-11/12 py-2 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 w-full rounded-md bg-white">
              <div className="grid h-full grid-flow-row grid-cols-1 md:grid-cols-1 md:gap-1">
                {watch('UploadDownload') === 'Download' && (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.MaterialGroup}
                        codeSetLabelField="MatGrpDescription1"
                        codeSetValueField="MaterialGroup"
                        codeSetFilterField="MatGrpDescription1"
                        fieldLabel="Material Group"
                        fieldPlaceholder=" "
                        control={control}
                        name="MaterialGroup"
                        required={true}
                        top="100"
                      />
                      <InputTypeAhead
                        fieldLabel="Service"
                        fieldPlaceholder="Start typing"
                        name="ActivityNumber"
                        control={control}
                        codeSet={TypeAheadCodeSet.ServiceNo}
                        codeSetFilterField="Description"
                        codeSetValueField="ServiceNo"
                        codeSetLabelField="Description"
                      />
                    </div>
                    {/* <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Service Category"
                        fieldPlaceholder=" "
                        name="ServiceCat"
                        control={control}
                      />
                      <TextInput
                        fieldLabel="Valuation Class"
                        fieldPlaceholder=" "
                        name="ValuationClass"
                        control={control}
                      />
                    </div> */}
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={[
                          { code: 'No', description: 'No' },
                          {
                            code: 'Yes',
                            description: 'Yes',
                          },
                        ]}
                        defaultValue="No"
                        includeCode={false}
                        fieldLabel="Deletion"
                        fieldPlaceholder=" "
                        name="DeletionInd"
                        control={control}
                        required={false}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {watch('UploadDownload') === 'Download' && (
              <div className="flex w-full justify-end">
                <button
                  type="submit"
                  onClick={() => {
                    //console.log('getValues() ', getValues());
                  }}
                  className="button-primary mr-0 bg-defence-orange-500"
                >
                  Submit
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </BaseLayout>
  );
};
