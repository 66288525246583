import { Heading, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import sanitizeHtml from 'sanitize-html';
import Accordion from '../../components/ServiceUI/Core/Accordion/Accordion';
import Header from '../../components/ServiceUI/Core/Header/Header';
import InfoBox from '../../components/ServiceUI/Core/InfoBox/InfoBox';
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import content from './Content/SupportPage.json';
import { QuestionMarkCircledIcon } from '@radix-ui/react-icons';

export const Support: React.FC<any> = (): JSX.Element => {
  useEffect(() => {
    document.title = 'Support';
  });
  return (
    <>
      <Header {...content.header} />
      <SubContainer verticalMargin="1rem">
        <InfoBox desktopPadding="4rem">
          <Heading as="h2" fontSize="md">
            {content.overview.title}
          </Heading>
          <Text
            dangerouslySetInnerHTML={{
              __html: sanitizeHtml(content.overview.description),
            }}
          />
        </InfoBox>
      </SubContainer>
      {content.FAQs.map((value, index) => (
        <Accordion showExpandCollapseAll {...value} key={index} index={index} />
      ))}
      <SubContainer>
        <Text mb="1rem" color="redalert">
          *Link accessible through the Defence network (Defence Protected Environment) only.
        </Text>
      </SubContainer>
    </>
  );
};
