import { Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { Banner } from '../../components/Banner';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import { MessageModal } from '../../components/MessageModal';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TextInput } from '../../components/form/inputs/textInput';
import { LOCAL_STORAGE_KEY_PREFIX, ROUTES } from '../../constants';
import { useNavigationContext, usePersistForm, useSessionStorage } from '../../hooks';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { WorkOrderMetadataResponse, WorkOrderSearchResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertFormData from '../../lib/convertFormData';
import convertSapMetadata from '../../lib/convertMetadata';
import { isDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaOrder/metaOrder.action';
import { selectCurrentMeta } from '../../store/metaOrder/metaOrder.selector';

const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_KEY_PREFIX}-orders-search`;

export const WorkOrderSearch = () => {
  useDocumentTitle(`Read Work Order`);

  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const { storedData } = useSessionStorage<FormSchema>(LOCAL_STORAGE_KEY);
  const [loading, setLoading] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const { prevLocation } = useNavigationContext();
  const restoreValues =
    !prevLocation?.pathname ||
    prevLocation?.pathname === ROUTES.WORK_ORDERS_SEARCH ||
    prevLocation?.pathname === ROUTES.WORK_ORDERS_SEARCH_RESULTS ||
    prevLocation?.pathname.startsWith(ROUTES.WORK_ORDERS_UPDATE.split(':')[0]);
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 2 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const response = await axiosInstance.get<WorkOrderMetadataResponse>(
              `${getAPIUrl(process.env.REACT_APP_EE_WO_METADATA)}`,
            );
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
            setTitleMessage(titleMessage);
            setMessages(messages);
            if (titleMessage !== 'Error' && (response.status === 200 || response.status === 201)) {
              const formMetaData = convertSapMetadata(response.data);
              dispatch(setCurrentMeta(formMetaData));
            }
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);


  const schema = z
    .object({
      WorkOrderNoTo: z.string().optional(),
      FunctionalLocation: z.string().optional(),
      EquipmentId: z.string().optional(),
      CreatedOnFrom: z.date().optional(),
      CreatedOnTo: z.date().optional(),
      DefenceGroup: z.string().optional(),
      UserStatus: z.string().optional(),
      MaintenanceType: z.string().optional(),
      Description: z.string().optional(),
      LastChangedOnFrom: z.date().optional(),
      LastChangedOnTo: z.date().optional(),
      WorkOrderNoFrom: z.string().optional(),
      OrderType: z.string().optional(),
      BasicStartDateFrom: z.date().optional(),
      BasicStartDateTo: z.date().optional(),
      MainWorkCentre: z.string().optional(),
    })
    .and(
      z.union([
        z.object({
          BasicStartDateFrom: z.date().superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
          BasicStartDateTo: z.date().superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
          MainWorkCentre: z.string().superRefine((val, ctx) => {
            if (val === '') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
          OrderType: z.string().superRefine((val, ctx) => {
            if (val.length === 0) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                message: `super refine triggered.`,
              });
            }
          }),
        }),
        z.object({
          WorkOrderNoFrom: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
      ]),
    );

  type FormSchema = z.infer<typeof schema>;
  const navigate = useNavigate();

  const defaultValues: FormSchema = {
    OrderType: '',
    WorkOrderNoFrom: '',
    WorkOrderNoTo: '',
    FunctionalLocation: '',
    EquipmentId: '',
    MainWorkCentre: '',
    CreatedOnFrom: undefined,
    CreatedOnTo: undefined,
    DefenceGroup: '',
    UserStatus: '',
    BasicStartDateFrom: undefined,
    BasicStartDateTo: undefined,
    MaintenanceType: '',
    LastChangedOnFrom: undefined,
    LastChangedOnTo: undefined,
  };

  const { handleSubmit, control, trigger, watch, setValue } = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { clearStoredValues } = usePersistForm<FormSchema>({
    watch,
    localStorageKey: LOCAL_STORAGE_KEY,
  });

  useEffect(() => {
    if (restoreValues && storedData) {
      Object.keys(storedData).forEach((key) => {
        const data = storedData?.[key] as any;
        if (!isNil(data)) {
          // @ts-ignore
          const value = isDate(data) ? new Date(data) : data;
          setValue(key as keyof FormSchema, (value as never) || '');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!restoreValues) {
      clearStoredValues();
    }
  }, [restoreValues, clearStoredValues]);

  const onSubmit = async (data: FormSchema) => {
    let formDataQuery = convertFormData(data);
    //begin of special request if no "to"
    if (!formDataQuery.includes('WorkOrderNo le') && formDataQuery.includes('WorkOrderNo ge')) {
      formDataQuery = formDataQuery.replace('WorkOrderNo ge', 'WorkOrderNo eq');
    }
    //end of special request if no "to"
    try {
      setLoading(true);
      const responseData = await axiosInstance.get<WorkOrderSearchResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_WO_SEARCH)}?$filter=(${formDataQuery})&$format=json`,
      );

      const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
      setTitleMessage(titleMessage);
      setMessages(messages);
      setLoading(false);

      if (titleMessage !== 'Error') {
        navigate(ROUTES.WORK_ORDERS_SEARCH_RESULTS, {
          state: { data: responseData.data, paramOrderType: watch('OrderType') },
        });
      }
    } catch (error) {
      setLoading(false);
      const errorResponse = isAxiosError(error) ? error.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Work order Details',
      fields: [
        'OrderType',
        'WorkOrderNoFrom',
        'WorkOrderNoTo',
        'FunctionalLocation',
        'EquipmentId',
        'MainWorkCentre',
        'CreatedOnFrom',
        'CreatedOnTo',
        'BasicStartDateFrom',
        'BasicStartDateTo',
      ],
    },
    {
      code: 2,
      name: 'Additional Details',
      fields: ['MaintenanceType', 'DefenceGroup', 'Description', 'LastChangedOnFrom', 'LastChangedOnTo', 'UserStatus'],
    },
  ];
  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <>
      <SubMenu title="Work Orders" selectedIndex="0" />
      <Banner title="Search and/or Update Work Orders" description="" bannerImgSrc="" />
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage === 'Error' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                      ? FormStepperEnum.Complete
                      : FormStepperEnum.Upcoming,
              }))}
            />
          </div>
          <div className="w-full pt-3">
            <SubBanner
              title={sections[step - 1].name}
              info="To find the work order you are looking for, please enter the relevant information below and click *submit*. The fields marked with an * are mandatory."
            />
          </div>
          <Text mb="1rem" color="redalert">
            **The search will only cover the last 2 years of data. For more, please use the bulk functionality.
          </Text>

          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit, goToErrors)}>
              {step === 1 && (
                <div className="mb-4 w-full">
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Order Number from"
                        fieldPlaceholder="Start typing"
                        name="WorkOrderNoFrom"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Wo}
                        top="10"
                        codeSetValueField="WorkOrder"
                      />
                      <InputTypeAhead
                        fieldLabel="Order Number to"
                        fieldPlaceholder="Start typing"
                        name="WorkOrderNoTo"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Wo}
                        top="10"
                        codeSetValueField="WorkOrder"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.OrderType}
                        codeSetLabelField="OrderTypeDescription"
                        codeSetValueField="OrderType"
                        fieldLabel="Order Type"
                        fieldPlaceholder=" "
                        name="OrderType"
                        control={control}
                        required={true}
                      />
                      <SingleSelectInput
                        items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                        fieldLabel="Main Work Centre"
                        fieldPlaceholder=" "
                        name="MainWorkCentre"
                        control={control}
                        required={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Basic Start Date From"
                        fieldPlaceholder="From Date"
                        name="BasicStartDateFrom"
                        control={control}
                        required={true}
                      />
                      <DateInput
                        fieldLabel="Basic Start Date To"
                        fieldPlaceholder="To Date"
                        name="BasicStartDateTo"
                        control={control}
                        required={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Created From"
                        fieldPlaceholder=" "
                        name="CreatedOnFrom"
                        control={control}
                        required={false}
                      />
                      <DateInput
                        fieldLabel="Created To"
                        fieldPlaceholder=" "
                        name="CreatedOnTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <div className="grid grid-cols-1 md:grid-cols-1">
                        <InputTypeAhead
                          fieldLabel="Functional Location"
                          fieldPlaceholder="Start typing"
                          name="FunctionalLocation"
                          control={control}
                          required={false}
                          codeSet={TypeAheadCodeSet.Floc}
                          top="10"
                          codeSetValueField="Floc"
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-1">
                        <InputTypeAhead
                          fieldLabel="Equipment"
                          fieldPlaceholder="Start typing"
                          name="EquipmentId"
                          control={control}
                          required={false}
                          codeSet={TypeAheadCodeSet.Equip}
                          top="10"
                          codeSetValueField="EquipmentId"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {step === 2 && (
                <div className="mb-4 w-full">
                  <div className="grid h-full grid-flow-row grid-cols-1  gap-1 md:grid-cols-1 md:gap-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.MaintActType}
                        codeSetLabelField="MaintActTypeDesc"
                        codeSetValueField="MaintActType"
                        codeDepFilterField="OrderType"
                        codeDepFilterVal={watch('OrderType')}
                        fieldLabel="PM Activity Type"
                        fieldPlaceholder=" "
                        name="MaintenanceType"
                        control={control}
                        required={false}
                        defaultValue={defaultValues.MaintenanceType}
                      />
                      <SingleSelectInput
                        items={metadata['DefenceGroup'] ? JSON.parse(metadata['DefenceGroup'].FVALUE) : []}
                        fieldLabel="Defence Group"
                        fieldPlaceholder=" "
                        name="DefenceGroup"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Description"
                        fieldPlaceholder=" "
                        name="Description"
                        control={control}
                        required={false}
                      />
                      <InputTypeAhead
                        fieldLabel="Last Changed By"
                        fieldPlaceholder="Start typing"
                        name="LastChangedBy"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.UserID}
                        top="10"
                        codeSetValueField="UserID"
                        codeSetLabelField="FullName"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Last Changed from"
                        fieldPlaceholder=" "
                        name="LastChangedOnFrom"
                        control={control}
                        required={false}
                      />
                      <DateInput
                        fieldLabel="Last Changed to"
                        fieldPlaceholder=" "
                        name="LastChangedOnTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.UserStatus}
                        codeSetLabelField="Description"
                        codeSetValueField="UserStatus"
                        codeSetFilterField="DisplayName"
                        fieldLabel="User Status"
                        fieldPlaceholder=" "
                        control={control}
                        name="UserStatus"
                        codeDepFilterField="StatusProfile"
                        codeDepFilterVal="ZEAORDER"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex w-full justify-end">
                <div className="flex-end flex">
                  {!(step === 1) && (
                    <button
                      type="button"
                      onClick={() => {
                        backStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Back
                    </button>
                  )}
                  {!(step === sections.length) && (
                    <button
                      type="button"
                      onClick={() => {
                        nextStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Next
                    </button>
                  )}
                  <button type="submit" className="button-primary mr-0 bg-defence-orange-500">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
