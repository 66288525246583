import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';

import { CreateNotificationRequestBody, NotificationMetadataResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertSapMetadata from '../../lib/convertMetadata';
import { formatDateToODataDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaNotif/metaNotif.action';
import { selectCurrentMeta } from '../../store/metaNotif/metaNotif.selector';
import { NotificationForm } from './NotificationForm';
import { TNotificationFormSchema, notificationFormSchema } from './NotificationFormSchema';

const defaultValues: TNotificationFormSchema = {
  NotificationType: '',
  ReportedBy: '',
  FunctLocation: '',
  FunctLocationDesc: '',
  EquipmentNo: '',
  EquipmentDesc: '',
  PlannerGroup: '',
  MainWorkCentre: '',
  MainWorkCentrePlant: '',
  NotificationDesc: '',
  NotificationLongText: '',
  ContactName: '',
  ContactHouseNo: '',
  ContactStreet: '',
  ContactTelephone: '',
  ContactFax: '',
  NotificationPriority: '',
  NotificationPriorityDesc: '',
  RequiredStartDateTime: undefined,
  RequiredEndDateTime: undefined,
  MalfunctionStart: undefined,
  MalfunctionEnd: undefined,
  DurationUom: 'H',
  CodeGroup: '',
  CodeGroupCoding: '',
  NotifToItem: {
    results: [
      {
        NotificationNo: '',
        ItemNo: '',
        CauseText: '',
        CauseCodeCoding: '',
        CauseCodeGroup: 'ZPM-EU',
        CauseCodeDesc: '',
        Actioncode: '01', // for create
      },
    ],
  },
  NotifToDir: {
    results: [
      {
        DocumentNo: '',
        DocumentDesc: '',
      },
    ],
  },
  ContactPostalCode: '',
  ContactCity: '',
  ContactRegion: '',
  ContactCountry: 'AU',
  RequestorName: '',
  RequestorTelephone: '',
  RequestorEmail: '',
  RequestorMobile: '',
  DefenceGroup: '',
  CustomerCostCentre: '',
  CustomerWbs: '',
  CustomerAsset: '',
  CustomerSubAsset: '',
  BreakdownIndicator: 'No',
};

export const NotificationCreate = () => {
  // const metadata = useContext(MetadataContext);
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(defaultValues);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [notificationNo, setNotificationNo] = useState<any>('');

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const response = await axiosInstance.get<NotificationMetadataResponse>(
              `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_METADATA)}`,
            );
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
            setTitleMessage(titleMessage);
            setMessages(messages);
            if (titleMessage !== 'Error' && (response.status === 200 || response.status === 201)) {
              const formMetaData = convertSapMetadata(response.data);
              dispatch(setCurrentMeta(formMetaData));
            }
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
        return () => {
          ref.current = true;
        };
      };
      fetchTableMetadata();
    }
  }, []);

  const uploadSelectedFile = (notificationNo: string) => {
    const file = selectedFiles?.[0];
    if (file) {
      setLoading(true);
      const uploadFormData = new FormData();
      uploadFormData.append('file', file, file.name);

      try {
        return axiosInstance
          .post(`${getAPIUrl(process.env.REACT_APP_EE_NOTIF_ATTACHMENT)}`, uploadFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              slug: file.name,
              NotifNo: notificationNo,
            },
          })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              setTitleMessage('Success');
              setMessages([response.data?.d?.ResultMsg ?? 'File uploaded successfully', response.data?.d?.RequestId]);
            } else {
              const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
                `Notification(${notificationNo}) is created successfully but the file upload failed. Please try again later`,
              ]);
              setTitleMessage('Success');
              setMessages(messages);
            }
            setLoading(false);
            return true;
          })
          .catch((err) => {
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(err?.response, [
              `Notification(${notificationNo}) is created successfully but the file upload failed. Please try again later`,
            ]);
            setTitleMessage('Success');
            setMessages(messages);
            setLoading(false);
            return false;
          });
      } catch (_error) {
        setTitleMessage('Success');
        setMessages([
          `Notification(${notificationNo}) is created successfully but Unable to process the file upload request. Please try again later`,
        ]);
        setLoading(false);
        return false;
      }
    } else {
      return true;
    }
  };

  const submitCreate = async (data: TNotificationFormSchema) => {
    setLoading(true);
    setMessages([]);
    setFormData(data);
    const resultData = convertFormDates(data) as TNotificationFormSchema;
    if (resultData.MainWorkCentre) {
      resultData.MainWorkCentrePlant = '1000';
    }
    if (resultData.CustomerAsset !== '') {
      resultData.CustCompanyCode = '1000';
    } else {
      resultData.CustCompanyCode = '';
    }
    if (
      resultData.NotifToItem?.results?.some((e) => e.ItemNo === '' || e.ItemNo === undefined) &&
      resultData.NotifToItem?.results?.length === 1
    ) {
      delete (resultData as any).NotifToItem;
    } else {
      resultData.NotifToItem?.results?.map(
        (item: any) =>
        (item.ItemToCause = {
          results: [
            {
              CauseCodeGroup: 'ZPM-EU',
              ItemNo: item.ItemNo,
              SequentialNo: '0001',
              Actioncode: item.Actioncode,
              CauseText: item.CauseText,
              CauseCodeCoding: item.CauseCodeCoding,
              NotificationNo: item.NotificationNo,
            },
          ],
        }),
      );
    }
    for (var i in resultData.NotifToItem?.results) {
      delete resultData.NotifToItem.results[i].CauseCodeGroup;
      delete resultData.NotifToItem.results[i].CauseText;
      delete resultData.NotifToItem.results[i].CauseCodeCoding;
      delete resultData.NotifToItem.results[i].CauseCodeDesc;
    }

    if (resultData.BreakdownIndicator === 'Yes') {
      resultData.BreakdownIndicator = true;
    } else {
      resultData.BreakdownIndicator = false;
    }
    let resultData1 = { d: resultData };

    try {
      const response = await axiosInstance.post<CreateNotificationRequestBody>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_CREATE)}`,
        resultData1,
      );
      const notificationNo = response?.data?.['d']?.['NotificationNo'];
      if (selectedFiles.length > 0 && notificationNo) {
        const success = await uploadSelectedFile(notificationNo);
        if (!success) {
          setNotificationNo(notificationNo);
          return;
        }
      }

      if (response.status === 200 || response.status === 201) {
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Notification created: ' + response?.data?.['d']?.['NotificationNo'],
        ]);
        setTitleMessage(notificationNo ? titleMessage : 'Error');
        setMessages(messages);
        if (titleMessage === 'Success') {
          setNotificationNo(response?.data?.['d']?.['NotificationNo']);
        }
        try {
          for (var j in resultData.NotifToItem?.results) {
            resultData.NotifToItem.results[j].CauseCodeGroup =
              resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeGroup;
            resultData.NotifToItem.results[j].CauseText =
              resultData.NotifToItem.results[j].ItemToCause.results[0].CauseText;
            resultData.NotifToItem.results[j].CauseCodeCoding =
              resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeCoding;
          }
        } catch (e) { }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
      try {
        for (var j in resultData.NotifToItem?.results) {
          resultData.NotifToItem.results[j].CauseCodeGroup =
            resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeGroup;
          resultData.NotifToItem.results[j].CauseText =
            resultData.NotifToItem.results[j].ItemToCause.results[0].CauseText;
          resultData.NotifToItem.results[j].CauseCodeCoding =
            resultData.NotifToItem.results[j].ItemToCause.results[0].CauseCodeCoding;
        }
      } catch (e) { }
    }
  };

  const convertFormDates = function (data: TNotificationFormSchema) {
    const formValues = { ...data };
    try {
      Object.keys(formValues).forEach((item) => {
        if (typeof formValues[item] === 'object' && formValues[item] instanceof Date === true) {
          if (formValues[item]) {
            formValues[item] = formatDateToODataDate(formValues[item]);
          }
        }
      });
    } catch (err) {
      console.log('errors: ', err);
    }
    return formValues;
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Create Service Request',
      }}
      submenuProps={{
        title: 'Service Requests',
        selectedIndex: '1',
      }}
      bannerProps={{
        title: 'Create Service Request',
      }}
    >
      <div className=" w-full">
        {loading && <Spinner overlay />}
        {messages.length > 0 && titleMessage === 'Error' && (
          <MessageModal
            title={titleMessage}
            messages={messages}
            open={!!messages.length}
            hidebutton={true}
            onOk={onMessageModalClose}
          ></MessageModal>
        )}
        {messages.length > 0 && titleMessage === 'Success' && (
          <MessageModal
            title={titleMessage}
            messages={messages}
            open={!!messages.length}
            hidebutton={true}
            onOk={onMessageModalClose}
            navigateLink={`/eeportal/notifications/search/results/${notificationNo}/update`}
          ></MessageModal>
        )}
        {!loading && (
          <NotificationForm
            messages={messages}
            metadata={metadata}
            updateForm={false}
            onSubmit={submitCreate}
            schema={notificationFormSchema}
            defaultValues={formData}
            onFilesSelected={setSelectedFiles}
          />
        )}
      </div>
    </BaseLayout>
  );
};
