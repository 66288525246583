import { Action, createAction } from "../utils";
import { GLOBALCONFIG_TYPES } from "./globalConfig.types";

export type SetCurrentMetaAction = Action<
  typeof GLOBALCONFIG_TYPES.SET_CURRENT_META,
  any
>;

export const setCurrentConfig = (meta: any): SetCurrentMetaAction =>
  createAction(GLOBALCONFIG_TYPES.SET_CURRENT_META, meta);
