import React, { useEffect, useRef, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import './Location.scss';
import SearchDropdown from '../SearchDropdown';
import SearchVesselDropdown from '../SearchVesselDropdown';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { InfoCircle } from 'react-bootstrap-icons';

const Location = (props) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      If your request relates to a vessel, select the Defence location where you would receive the service.
    </Tooltip>
  );

  const renderTooltipImpactedArea = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Include the interior or exterior area, building, level, room, desk etc.
    </Tooltip>
  );

  const nameInputRef = useRef();
  const [name, setName] = useState({
    fieldName: '',
    fieldValue: '',
    fieldId: '',
  });
  const [vesselSelected, setVesselSelected] = useState({
    isSelected: props.VesselValues.selectedValue.length > 0 ? true : false,
    selectedVessel: props.VesselValues.selectedValue,
    showVesselDropDown: props.VesselValues.selectedValue === '' ? false : true,
  });

  const inputChangedHandler = (event) => {
    event.persist();

    setName((prevState) => {
      return {
        ...prevState,
        fieldValue: event.target.value,
      };
    });

    props.onLocationInfoChange(
      props.locationDetail.fieldID,
      props.locationDetail.fieldName,
      nameInputRef.current.value,
      props.locationDetail.reference,
    );
  };

  const changeNameHandler = () => {
    if (props.locationDetail.fieldName === 'Type') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Location type',
        };
      });
    } else if (props.locationDetail.fieldName === 'Name') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Base Name',
        };
      });
    } else if (props.locationDetail.fieldName === 'FullAddress') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Address',
        };
      });
    } else if (props.locationDetail.fieldName === 'Area') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Impacted area',
        };
      });
    } else if (props.locationDetail.fieldName === 'Description') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Location Description',
        };
      });
    } else if (props.locationDetail.fieldID === 'Line1') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Address',
        };
      });
    } else if (props.locationDetail.fieldID === 'SiteOrWharf') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Is this a vessel?',
        };
      });
    } else if (props.locationDetail.fieldID === 'BuildingName') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Building number or name',
        };
      });
    } else if (props.locationDetail.fieldID === 'Level') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Floor level',
        };
      });
    } else if (props.locationDetail.fieldID === 'RoomNumber') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Room number or name',
        };
      });
    } else if (props.locationDetail.fieldID === 'AreaDescription') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: 'Provide a brief description of the room or area',
        };
      });
    } else {
      setName(() => {
        return {
          fieldName: !!props.locationDetail.fieldName ? props.locationDetail.fieldName : props.locationDetail.fieldID,
          fieldValue: props.locationDetail.value,
          fieldId: props.locationDetail.fieldID,
        };
      });
    }
  };
  const changeStateLocationTypeDropDownValue = (dropdownName, text, refCode, id) => {
    if (dropdownName === 'State') {
      props.onStateLocationTypeDroppDownChange('State', text, refCode, id);
    } else if (dropdownName === 'Location') {
      setName((prevState) => {
        return {
          ...prevState,
          fieldName: '',
          fieldValue: '',
          fieldId: '',
        };
      });
      props.onStateLocationTypeDroppDownChange('Location', text, refCode, id);
    }
  };

  const updateStateForVesselSelected = () => {
    setVesselSelected((prevState) => {
      return {
        ...prevState,
        isSelected: !prevState.isSelected,
        showVesselDropDown: !prevState.showVesselDropDown,
      };
    });
  };

  const addVesselClickHandler = (flag) => {
    if (flag === true) {
      props.onAddVesselSelected(true);
      updateStateForVesselSelected();
    } else {
      props.onAddVesselSelected(false);
      updateStateForVesselSelected();
    }
  };

  const handleBaseSelect = (text, selectedBaseNameObj, id) => {
    props.onBaseNameDropDownChange(text, selectedBaseNameObj, id);
  };

  const handleBaseText = (text, id) => {
    props.defenceLocationText(text, id);
  };
  const getRequiredClassNameForImpactedArea = () => {
    if (props.isReview) {
      return 'mb-0';
    } else {
      const selectedWorkType = props.reqInfo.commonFieldsArr.find((item) => {
        if (item.fieldID === 'WorkType') {
          return item.value;
        }
        return null;
      });
      if (!!selectedWorkType && (selectedWorkType.value === 'Catering' || selectedWorkType.value === 'Transport')) {
        return 'mb-0';
      } else {
        return 'mb-0 pb-2 required-field';
      }
    }
  };
  const handleVesselSelect = (value, selectedVesselobj, id) => {
    props.onVesselDropDownChange(value, selectedVesselobj.code, id);
    props.onLocationInfoChange(
      id,
      props.locationDetail.fieldName,
      selectedVesselobj.code,
      props.locationDetail.reference,
    );
  };

  const renderElement = () => {
    const { locationDetail, locationTypeValues, stateValues, isReview } = props;
    const { fieldName, fieldID, isReadOnly, value, error } = locationDetail;
    const { selectedValue, dropDownArrayLocType, selectedDescription } = locationTypeValues;

    if (fieldName === 'Type') {
      // return location type dropdown
      return (
        <>
          <Col className="formField px-0">
            <label htmlFor={fieldID}>{name.fieldName}</label>
            <div className="">
              {isReview ? (
                <p className="fw-light">{selectedValue === 'Other' ? 'Other Location' : 'Defence Location'}</p>
              ) : (
                <Form>
                  {dropDownArrayLocType.map((item, idx) => (
                    <Form.Check
                      inline
                      key={idx}
                      label={item.description}
                      checked={selectedDescription === item.description}
                      type="radio"
                      id={item.fieldId}
                      name={`primaryContact-${idx}`}
                      onClick={(e) =>
                        changeStateLocationTypeDropDownValue('Location', e.target.textContent, item.code, fieldID)
                      }
                    />
                  ))}
                </Form>
              )}
            </div>
          </Col>
        </>
      );
    } else if (fieldName === 'State') {
      // return state dropdown
      return (
        <>
          <br></br>
          <div className="fieldContainer py-2">
            <label className={!isReview && 'required-field pb-2'} htmlFor={fieldID}>
              {name.fieldName}
            </label>
            {isReview ? (
              <p className="fw-light mb-0">{stateValues.selectedDescription}</p>
            ) : (
              <Dropdown>
                <Dropdown.Toggle
                  title="State"
                  variant="dropdown"
                  className="dropdownText fw-light"
                  id="State"
                  disabled={props.isReadonlyLocation}
                >
                  {stateValues.selectedDescription}
                </Dropdown.Toggle>
                <Dropdown.Menu className=" dropDownMenu">
                  {stateValues.dropDownArrayLocState.map((item, index) => (
                    <Dropdown.Item
                      className="fw-light dropdownText"
                      onClick={(e) =>
                        changeStateLocationTypeDropDownValue('State', e.target.textContent, item.code, fieldID)
                      }
                      as="button"
                      key={index}
                    >
                      {item.description}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </div>
        </>
      );
    } else if (fieldName === 'Name') {
      // return Base name dropdown
      return (
        <>
          <br></br>
          <div className="fieldContainer py-2">
            <label className={!isReview && 'required-field'}>Defence Location</label>
            <SearchDropdown
              id={fieldID}
              autoComplete="nope"
              options={props.baseNameValues.dropDownArrayBaseName}
              selectedValue={props.baseNameValues.selectedValue}
              isReview={isReview}
              error={error}
              handleChange={(selection) => {
                handleBaseSelect(selection.name, selection, fieldID);
              }}
              handleChangeText={(text) => {
                handleBaseText(text, fieldID);
              }}
            />
          </div>
        </>
      );
    } else if (fieldName === 'SiteOrWharf') {
      // instantiate vessel dropdown
      const vesselDropDownSection = vesselSelected.isSelected ? (
        <Col>
          <div className="fieldContainer">
            {isReview ? (
              <>
                <label>Vessel Name</label>
                <p className="fw-light">{props.VesselValues.selectedDescription}</p>
              </>
            ) : (
              <SearchVesselDropdown
                label="Vessel Name"
                id={fieldID}
                options={props.VesselValues.dropDownArrayVessel}
                selectedValue={props.VesselValues.selectedDescription}
                isReview={isReview}
                error={error}
                handleChange={(selection) => {
                  handleVesselSelect(selection.description, selection, fieldID);
                }}
              />
            )}
          </div>
        </Col>
      ) : (
        <></>
      );
      return (
        <>
          {!isReview && (
            <>
              <Col className="formField">
                <h3>
                  {name.fieldName}
                  <button
                    title="Tooltip"
                    className="border-0 bg-transparent"
                    ref={target}
                    onClick={() => setShow(!show)}
                  >
                    {!isReview && (
                      <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                        <InfoCircle size={15} />
                      </OverlayTrigger>
                    )}
                  </button>
                </h3>

                <Form>
                  <Form.Check
                    inline
                    label="Yes"
                    type="radio"
                    onClick={() => addVesselClickHandler(true)}
                    value="true"
                    checked={vesselSelected.isSelected === true}
                    disabled={props.isReadonlyOther}
                  />
                  <Form.Check
                    inline
                    label="No"
                    type="radio"
                    onClick={() => addVesselClickHandler(false)}
                    id="inline-radio-2"
                    value="false"
                    checked={vesselSelected.isSelected === false}
                    disabled={props.isReadonlyOther}
                  />
                </Form>
              </Col>
              {vesselSelected.isSelected && <br></br>}
            </>
          )}

          {vesselDropDownSection}
        </>
      );
    } else if (fieldName === 'Area') {
      return (
        <>
          <div className="fieldContainer py-2">
            <label className={!isReview}>
              <p className={getRequiredClassNameForImpactedArea()}>
                {name.fieldName}
                <button title="Tooltip" className="border-0 bg-transparent" ref={target} onClick={() => setShow(!show)}>
                  {!isReview && (
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltipImpactedArea}
                    >
                      <InfoCircle size={15} />
                    </OverlayTrigger>
                  )}
                </button>
              </p>
            </label>
            <input
              ref={nameInputRef}
              id={fieldID}
              type="text"
              value={value}
              readOnly={isReadOnly}
              onChange={inputChangedHandler}
              className={
                isReview
                  ? 'textBoxFieldReview fw-light w-100'
                  : error
                  ? ' textBoxField fw-light w-100 show-error-border'
                  : 'textBoxField fw-light w-100'
              }
            />
          </div>
          {/* only display br for Other Type */}
          {locationTypeValues.selectedValue === 'Other' ? <br></br> : <></>}
        </>
      );
    } else if (fieldName === 'FullAddress') {
      return (
        <div className="fieldContainer py-2">
          <label className={!isReview}>
            <p className={getRequiredClassNameForImpactedArea()}>
              {name.fieldName}
              <button title="Tooltip" className="border-0 bg-transparent" ref={target} onClick={() => setShow(!show)}>
                {!isReview && (
                  <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltipImpactedArea}>
                    <InfoCircle size={15} />
                  </OverlayTrigger>
                )}
              </button>
            </p>
          </label>
          <input
            ref={nameInputRef}
            id={fieldID}
            type="text"
            value={props.baseNameValues.selectedValue && value}
            readOnly={props.baseNameValues.selectedValue}
            onChange={inputChangedHandler}
            className={
              isReview
                ? 'textBoxFieldReview fw-light w-100'
                : error
                ? ' textBoxField fw-light w-100 show-error-border'
                : 'textBoxField fw-light w-100'
            }
          />
        </div>
      );
    } else if (fieldName === 'BuildingName') {
      return (
        <>
          <Col className="formField px-0">
            <div>
              {!isReview && <h3 className="pt-4">Building details</h3>}
              <label className={!isReview && 'pb-2'} htmlFor={fieldID}>
                {name.fieldName}
              </label>
              {isReview ? (
                <p className="fw-light">{value}</p>
              ) : (
                <input
                  ref={nameInputRef}
                  id={fieldID}
                  type="text"
                  value={value}
                  readOnly={isReadOnly}
                  onChange={inputChangedHandler}
                  className={
                    isReview
                      ? 'textBoxFieldReview fw-light w-100'
                      : error
                      ? ' textBoxField fw-light w-100 show-error-border'
                      : 'textBoxField fw-light w-100'
                  }
                />
              )}
            </div>
          </Col>
          <br></br>
        </>
      );
    } else if (fieldName === 'Level') {
      return (
        <>
          <Col className="formField px-0">
            {!isReview && <h3 className={!isReview && 'pt-4'}>Floor details</h3>}
            <div>
              <label className={!isReview && 'pb-2'} htmlFor={fieldID}>
                {name.fieldName}
              </label>
              {isReview ? (
                <p className="fw-light">{value}</p>
              ) : (
                <input
                  ref={nameInputRef}
                  id={fieldID}
                  type="text"
                  value={value}
                  readOnly={isReadOnly}
                  onChange={inputChangedHandler}
                  className={
                    isReview
                      ? 'textBoxFieldReview fw-light w-100'
                      : error
                      ? ' textBoxField fw-light w-100 show-error-border'
                      : 'textBoxField fw-light w-100'
                  }
                />
              )}
            </div>
          </Col>
        </>
      );
    } else if (fieldName === 'AreaDescription') {
      return (
        <>
          <Col className="formField px-0">
            <div>
              <label className={!isReview && 'pb-2'} htmlFor={fieldID}>
                {name.fieldName}
              </label>
              {isReview ? (
                <p className="fw-light">{value}</p>
              ) : (
                <textarea
                  ref={nameInputRef}
                  id={fieldID}
                  type="text"
                  value={value}
                  readOnly={isReadOnly}
                  onChange={inputChangedHandler}
                  className={
                    isReview
                      ? 'textBoxFieldReview fw-light w-100'
                      : error
                      ? ' textBoxField fw-light w-100 show-error-border'
                      : 'textBoxField fw-light w-100'
                  }
                />
              )}
            </div>
          </Col>
        </>
      );
    } else {
      // return textbox fields
      return (
        <Col className="formField d-flex flex-column justify-content-end px-0">
          <div>
            <label className={!isReview && 'pb-2'} htmlFor={fieldID}>
              {name.fieldName}
            </label>
            {isReview ? (
              <p className="fw-light">{value}</p>
            ) : (
              <input
                ref={nameInputRef}
                id={fieldID}
                type="text"
                value={value}
                readOnly={isReadOnly}
                onChange={inputChangedHandler}
                className={
                  isReview
                    ? 'textBoxFieldReview fw-light w-100'
                    : error
                    ? ' textBoxField fw-light w-100 show-error-border'
                    : 'textBoxField fw-light w-100'
                }
              />
            )}
          </div>
        </Col>
      );
    }
  };
  useEffect(() => {
    setName(() => {
      return {
        fieldName: '',
        fieldValue: '',
        fieldId: '',
      };
    });

    changeNameHandler();
  }, [props]);

  return <>{renderElement()}</>;
};

export default Location;
