import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TableInputGen } from '../../components/form/inputs/tableInputGen';
import { TextInput } from '../../components/form/inputs/textInput';
import { CreateWorkOrderConfirmationResponse, WorkOrderMetadataResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertSapMetadata from '../../lib/convertMetadata';
import { formatDateToODataDate } from '../../lib/date';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaSes/metaSes.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';

export const ConfirmationCreate = () => {
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [confirmationNumber, setConfirmationNumber] = useState<any>('');
  const [confirmationCounter, setConfirmationCounter] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [isAddActivityOpen, setAddActivityOpen] = useState<boolean>(false);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (!ref.current) {
          setLoading(true);
          try {
            const { data: TableMetadata } = await axiosInstance.get<WorkOrderMetadataResponse>(
              `${getAPIUrl(process.env.REACT_APP_EE_WO_METADATA)}`,
            );
            const formMetaData = convertSapMetadata(TableMetadata);
            dispatch(setCurrentMeta(formMetaData));
            setLoading(false);
          } catch (err) {
            setLoading(false);
            const errorResponse = isAxiosError(err) ? err.response : null;

            const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
              (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
            ]);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);

  const schema = z
    .object({
      ActualWorkQuantity: z
        .string()
        .max(metadata['ActualWorkQuantity'] ? metadata['ActualWorkQuantity'].LENGTH : 7)
        .optional(),
      FinalConfirmation: z.any().optional(),
      NoRemainingWork: z.any().optional(),
      AccountingIndicator: z.string().optional(),
      ActualWorkUnitOfMeasure: z.string().optional(),
      MainWorkCentrePlant: z.string().optional(),
      ConfirmationText: z
        .string()
        .max(metadata['ConfirmationText'] ? metadata['ConfirmationText'].LENGTH : 40)
        .optional(),
      WOConfirmSrvSet: z.object({
        results: z
          .object({
            LineNo: z.string().optional(),
            ServiceNo: z
              .string()
              .max(metadata['ServiceNo'] ? metadata['ServiceNo'].LENGTH : 18)
              .superRefine((val, ctx) => {
                if (val === '') {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                  });
                }
              }),
            Description: z
              .string()
              .max(metadata['Description'] ? metadata['Description'].LENGTH : 40)
              .optional(),
            Quantity: z
              .string()
              .max(metadata['Quantity'] ? metadata['Quantity'].LENGTH : 13)
              .superRefine((val, ctx) => {
                if (val === '') {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                  });
                }
              }),
            UnitOfMeasure: z.string().optional(),
            NetValue: z
              .string()
              .max(metadata['NetValue'] ? metadata['NetValue'].LENGTH : 12)
              .superRefine((val, ctx) => {
                if (val === '') {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                  });
                }
              }),
            GrossPrice: z
              .string()
              .max(metadata['GrossPrice'] ? metadata['GrossPrice'].LENGTH : 12)
              .optional(),
            MaterialGroup: z.string().optional(),
            Currency: z
              .string()
              .max(metadata['Currency'] ? metadata['Currency'].LENGTH : 5)
              .optional(),
            InsertDate: z.date().optional().nullable(),
            StartDateTime: z
              .date()
              .optional()
              .nullable()
              .superRefine((val, ctx) => {
                if (val === null) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                  });
                }
              }),
            EndDateTime: z
              .date()
              .optional()
              .nullable()
              .superRefine((val, ctx) => {
                if (val === null) {
                  ctx.addIssue({
                    code: z.ZodIssueCode.custom,
                  });
                }
              }),
            UserField2: z.any().optional().nullable(),
            UserField1: z
              .string()
              .max(metadata['UserField1'] ? metadata['UserField1'].LENGTH : 10)
              .optional(),
            Location: z
              .string()
              .max(metadata['Location'] ? metadata['Location'].LENGTH : 40)
              .optional(),
            Message: z
              .string()
              .max(metadata['Message'] ? metadata['Message'].LENGTH : 10)
              .optional(),
          })
          .array(),
      }),
    })
    .and(
      z.object({
        ActualStartDateTime: z
          .date()
          .optional()
          .nullable()
          .superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
              });
            }
          }),
        ActualEndDateTime: z
          .date()
          .optional()
          .nullable()
          .superRefine((val, ctx) => {
            if (val === undefined) {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
              });
            }
          }),
        WorkOrderNo: z
          .string()
          .max(metadata['WorkOrderNo'] ? metadata['WorkOrderNo'].LENGTH : 12)
          .superRefine((val, ctx) => {
            if (val === '') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
              });
            }
          }),
        OperationNo: z
          .string()
          .max(metadata['OperationNo'] ? metadata['OperationNo'].LENGTH : 4)
          .superRefine((val, ctx) => {
            if (val === '') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
              });
            }
          }),
        MainWorkCentre: z
          .string()
          .max(metadata['MainWorkCentre'] ? metadata['MainWorkCentre'].LENGTH : 8)
          .superRefine((val, ctx) => {
            if (val === '') {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
              });
            }
          }),
      }),
    );

  type formSchema = z.infer<typeof schema>;

  const defaultValues: formSchema = {
    WorkOrderNo: '',
    OperationNo: '',
    MainWorkCentre: '',
    MainWorkCentrePlant: '',
    ActualWorkQuantity: '',
    FinalConfirmation: '',
    NoRemainingWork: '',
    AccountingIndicator: '',
    ActualStartDateTime: undefined,
    ActualEndDateTime: undefined,
    ActualWorkUnitOfMeasure: '',
    ConfirmationText: '',
    WOConfirmSrvSet: {
      results: [],
    },
  };

  const methods = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: formSchema) => {
    setLoading(true);
    setErrorMessages([]);

    const resultData = convertFormDates(data);
    const payloadData = updatePayloadData(resultData);
    let resultData1 = { d: payloadData }; //For Boolean Params

    try {
      const response = await axiosInstance.post<CreateWorkOrderConfirmationResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_CONF_CREATE)}`,
        resultData1,
      );
      setLoading(false);

      const { title: titleMessage, message: messages } = formatMessagesToDisplay(response);
      setTitleMessage(titleMessage);
      setMessages(messages);

      if ((response.status === 200 || response.status === 201) && response?.data?.d?.ConfirmationNo) {
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Confirmation Created. Confirmation Number: ',
          response?.data?.d?.ConfirmationNo || '',
        ]);

        setTitleMessage(titleMessage);
        setMessages(messages);

        if (titleMessage === 'Success') {
          setConfirmationNumber(response?.data?.d?.ConfirmationNo);
          setConfirmationCounter(response?.data?.d?.ConfirmationCounter);
        }
      }
    } catch (err) {
      setLoading(false);
      const errorResponse = isAxiosError(err) ? err.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);

      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const convertFormDates = function (data: any) {
    try {
      //For Confirmation Header
      Object.keys(data).forEach((item) => {
        if (typeof data[item] === 'object' && data[item] instanceof Date === true) {
          if (data[item]) {
            data[item] = formatDateToODataDate(data[item]);
          }
        }
      });
      //For Confirmation Service Line Items
      for (var i in data.WOConfirmSrvSet?.results) {
        const itemData = data.WOConfirmSrvSet.results[i];
        Object.keys(itemData).forEach((item1) => {
          if (typeof itemData[item1] === 'object' && itemData[item1] instanceof Date === true) {
            if (itemData[item1]) {
              itemData[item1] = formatDateToODataDate(itemData[item1]);
            }
          }
        });
      }
    } catch (err) {
      console.log('errors: ', err);
    }
    return data;
  };

  const updatePayloadData = function (data: any) {
    try {
      if (data.FinalConfirmation === 'Partial') {
        data.FinalConfirmation = '';
      } else {
        data.FinalConfirmation = 'X';
      }

      if (data.NoRemainingWork === 'No') {
        data.NoRemainingWork = false;
      } else {
        data.NoRemainingWork = true;
      }

      if (data.MainWorkCentre) {
        data.MainWorkCentrePlant = '1000';
      }

      if (data.ActualWorkQuantity === '' || data.ActualWorkQuantity === undefined) {
        delete data.ActualWorkQuantity;
      }

      for (var i in data.WOConfirmSrvSet?.results) {
        if (data.WOConfirmSrvSet.results[i].Quantity === '' || data.WOConfirmSrvSet.results[i].Quantity === undefined) {
          delete data.WOConfirmSrvSet.results[i].Quantity;
        }
        if (data.WOConfirmSrvSet.results[i].NetValue === '' || data.WOConfirmSrvSet.results[i].NetValue === undefined) {
          delete data.WOConfirmSrvSet.results[i].NetValue;
        }
        if (
          data.WOConfirmSrvSet.results[i].GrossPrice === '' ||
          data.WOConfirmSrvSet.results[i].GrossPrice === undefined
        ) {
          delete data.WOConfirmSrvSet.results[i].GrossPrice;
        }
        if (
          data.WOConfirmSrvSet.results[i].InsertDate === '' ||
          data.WOConfirmSrvSet.results[i].InsertDate === null ||
          data.WOConfirmSrvSet.results[i].InsertDate === undefined
        ) {
          delete data.WOConfirmSrvSet.results[i].InsertDate;
        }
        if (
          data.WOConfirmSrvSet.results[i].StartDateTime === '' ||
          data.WOConfirmSrvSet.results[i].StartDateTime === null ||
          data.WOConfirmSrvSet.results[i].StartDateTime === undefined
        ) {
          delete data.WOConfirmSrvSet.results[i].StartDateTime;
        }
        if (
          data.WOConfirmSrvSet.results[i].EndDateTime === '' ||
          data.WOConfirmSrvSet.results[i].EndDateTime === null ||
          data.WOConfirmSrvSet.results[i].EndDateTime === undefined
        ) {
          delete data.WOConfirmSrvSet.results[i].EndDateTime;
        }
        if (
          data.WOConfirmSrvSet.results[i].UserField2 === '' ||
          data.WOConfirmSrvSet.results[i].UserField2 === undefined
        ) {
          delete data.WOConfirmSrvSet.results[i].UserField2;
        }
      }
    } catch (err) {
      console.log('errors: ', err);
    }
    return data;
  };

  const [step, setStep] = useState(1);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) {
      // clear errors if there are none to display
      setErrorMessages([]);
      return;
    }
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code === selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Confirmation Details Header',
      fields: ['WorkOrderNo', 'OperationNo', 'MainWorkCentre'],
    },
    {
      code: 2,
      name: 'Confirmation Details',
      fields: [
        'ActualWorkQuantity',
        'FinalConfirmation',
        'NoRemainingWork',
        'AccountingIndicator',
        'ActualStartDateTime',
        'ActualEndDateTime',
        'ActualWorkUnitOfMeasure',
        'ConfirmationText',
      ],
    },
    {
      code: 3,
      name: 'Service Details',
      fields: [
        'ServiceNo',
        'LineNo',
        'Description',
        'Quantity',
        'UnitOfMeasure',
        'NetValue',
        'GrossPrice',
        'MaterialGroup',
        'Currency',
        'InsertDate',
        'StartDateTime',
        'EndDateTime',
        'UserField1',
        'UserField2',
        'Location',
        'Message',
      ],
    },
  ];

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Create Work Order Confirmation',
      }}
      bannerProps={{
        title: 'Create Confirmation',
      }}
      submenuProps={{
        title: 'Confirmations',
        selectedIndex: '2',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage === 'Error' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      {messages.length > 0 && titleMessage === 'Success' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={true}
          hidebutton={true}
          navigateLink={`/eeportal/orders/confirmations/${confirmationNumber}/read?confirmationCounter=${confirmationCounter}`}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                    ? FormStepperEnum.Complete
                    : FormStepperEnum.Upcoming,
              }))}
            />
          </div>
          <div className="w-full pt-3">
            <SubBanner
              title={sections[step - 1].name}
              info="To create a Confirmation, please enter the relevant information below, then select the Next button. The fields marked with an * are mandatory."
            />
          </div>
          <div className="w-full p-2">
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(onSubmit, goToErrors)}>
                {step === 1 && (
                  <div className="mb-8 w-full">
                    <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <InputTypeAhead
                          fieldLabel="Order Number"
                          fieldPlaceholder="Start typing"
                          name="WorkOrderNo"
                          control={methods.control}
                          required={true}
                          codeSet={TypeAheadCodeSet.Wo}
                          top="20"
                          codeSetValueField="WorkOrder"
                        />
                        <TextInput
                          fieldLabel="Operation Number"
                          fieldPlaceholder=" "
                          name="OperationNo"
                          control={methods.control}
                          required={true}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 2 && (
                  <div className="mb-8 w-full">
                    <div className="grid h-full grid-flow-row grid-cols-1  gap-1 md:grid-cols-1 md:gap-4">
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInput
                          items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                          fieldLabel="Main Work Center"
                          fieldPlaceholder="Please Select Main Work Center"
                          name="MainWorkCentre"
                          control={methods.control}
                          required={true}
                        />
                        <SingleSelectInput
                          items={[
                            { code: 'Partial', description: 'Partial Confirmation' },
                            { code: 'Final', description: 'Final Confirmation' },
                          ]}
                          fieldLabel="Final Confirmation"
                          fieldPlaceholder=" "
                          name="FinalConfirmation"
                          control={methods.control}
                          includeCode={false}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInput
                          items={[
                            { code: 'No', description: 'No' },
                            {
                              code: 'Yes',
                              description: 'Yes',
                            },
                          ]}
                          includeCode={false}
                          fieldLabel="No Remaining Work"
                          fieldPlaceholder=" "
                          name="NoRemainingWork"
                          control={methods.control}
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.AccountingIndicator}
                          codeSetLabelField="AccountingIndicatorText"
                          codeSetValueField="AccountingIndicator"
                          codeSetFilterField="AccountingIndicatorText"
                          fieldLabel="Accounting Indicator"
                          fieldPlaceholder=" "
                          control={methods.control}
                          name="AccountingIndicator"
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <DateInput
                          fieldLabel="Work Start Date and Time"
                          fieldPlaceholder=" "
                          name="ActualStartDateTime"
                          control={methods.control}
                          showTime={true}
                          required={true}
                        />
                        <DateInput
                          fieldLabel="Work End Date and Time"
                          fieldPlaceholder=" "
                          name="ActualEndDateTime"
                          control={methods.control}
                          showTime={true}
                          required={true}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Actual Work Quantity"
                          fieldPlaceholder=" "
                          name="ActualWorkQuantity"
                          control={methods.control}
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.UoM}
                          codeSetLabelField="Description"
                          codeSetValueField="Uom"
                          codeSetFilterField="Description"
                          fieldLabel="UoM"
                          fieldPlaceholder=" "
                          control={methods.control}
                          name="ActualWorkUnitOfMeasure"
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Confirmation Text"
                          fieldPlaceholder=" "
                          name="ConfirmationText"
                          control={methods.control}
                        />
                      </div>
                    </div>
                  </div>
                )}
                {step === 3 && (
                  <div className="mb-8 w-full">
                    <>
                      <TableInputGen
                        scenario="CONF"
                        defaultValues={{
                          LineNo: '10',
                          ServiceNo: '',
                          Description: '',
                          Quantity: '',
                          NetValue: '',
                          Currency: '',
                          EndDateTime: null,
                          GrossPrice: '',
                          InsertDate: null,
                          MaterialGroup: '',
                          StartDateTime: null,
                          UserField1: '',
                          UserField2: '',
                          Location: '',
                          Message: '',
                        }}
                        keyfield="LineNo"
                        path="WOConfirmSrvSet.results"
                        metadata={metadata}
                        onAddActivityChange={setAddActivityOpen}
                        ColumnsHeadersInput={[
                          {
                            accessor: 'ServiceNo',
                            header: 'Service Number',
                          },
                          {
                            accessor: 'LineNo',
                            header: 'Line No',
                          },
                          {
                            accessor: 'Quantity',
                            header: 'Qty',
                          },
                          {
                            accessor: 'NetValue',
                            header: 'Net Value',
                          },
                          {
                            accessor: 'MaterialGroup',
                            header: 'Material Group',
                          },
                          {
                            accessor: 'StartDateTime',
                            header: 'Start Date and Time',
                          },
                          {
                            accessor: 'EndDateTime',
                            header: 'End Date and Time',
                          },
                          {
                            accessor: 'Location',
                            header: 'Location',
                          },
                          {
                            accessor: 'Message',
                            header: 'Message',
                          },
                        ]}
                      />
                    </>
                  </div>
                )}
                <div className="flex w-full justify-end">
                  <div className="flex-end flex">
                    {step === 1 && (
                      <button
                        type="button"
                        onClick={() => {
                          nextStep();
                        }}
                        className="button-primary bg-defence-orange-500"
                      >
                        Next
                      </button>
                    )}
                    {step === 2 && (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            backStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Back
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            nextStep();
                          }}
                          className="button-primary bg-defence-orange-500"
                        >
                          Next
                        </button>
                      </div>
                    )}
                    {step === 3 && !isAddActivityOpen && (
                      <div className="flex">
                        <button
                          type="button"
                          onClick={() => {
                            backStep();
                          }}
                          className="button-secondary bg-white"
                        >
                          Back
                        </button>
                        <button type="submit" onClick={() => {}} className="button-primary mr-0 bg-defence-orange-500">
                          Submit
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
