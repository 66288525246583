import React from 'react';
import { GenericComplete } from './GenericComplete';
import Header from '../../../components/ServiceUI/Core/Header/Header';
import content from './content/DSAS.json';

export const DSASCompletePage = () => {
  return (
    <>
      <Header {...content.header} bg="orange.standard" />
      <GenericComplete {...content.complete} />
    </>
  );
};
