//Checking that the input is only made up of digits
const isOnlyNumbers = new RegExp(/^\d+$/);
//Checking that the input is only made up of digits, except for the first character which may be a '+'
const isOnlyNumbersAndPlus = new RegExp(/^\+\d+$/);

export const checkMobileValidity = (number): boolean => {
  //Removing all spaces
  const formattedMobile = number?.replaceAll(' ', '');
  //Global validity - is the input blank?
  const isNotBlank = number !== '';

  // ** Testing local numbers ** //

  //Only accepting digits
  const containsLettersOrSpecialChars = isOnlyNumbers.test(formattedMobile);
  //Checking number without spaces is 10 digits
  const is10Digits = formattedMobile?.length === 10;
  //Checking if the number starts with '04'
  const is04Number = formattedMobile?.slice(0, 2) === '04';
  //Evaluating all into one bool
  const isLocalAndValid = containsLettersOrSpecialChars && is10Digits && is04Number;

  // ** Testing international numbers ** //

  //Only accepting digits AND a '+' as the first character
  const containsNumbersOrNoPlus = isOnlyNumbersAndPlus.test(formattedMobile);
  //Checking number without spaces is 12 digits
  const is12Digits = formattedMobile?.length === 12;
  //Checking if the number starts with '+61'
  const is61Number = formattedMobile?.slice(0, 3) === '+61';
  //Evaluating all into one bool
  const isInternationalAndValid = containsNumbersOrNoPlus && is12Digits && is61Number;
  //Returning true if either international or local validations are true, AND the number is not blank
  return isNotBlank && (isInternationalAndValid || isLocalAndValid);
};
