import { Flex, FormControl, GridItem, Text } from '@chakra-ui/react';
import React from 'react';
import { Checkbox, FormLabel } from '@chakra-ui/react';
import { Checkbox as CheckboxIcon } from '../../Core/icons/Outline';
export interface PrefTileProps {
  title: string;
  description: string;
  label: string;
  value: boolean;
  onChange(value): void;
}

export const PrefTile: React.FC<PrefTileProps> = ({ title, description, label, onChange, value }): JSX.Element => {
  return (
    <Flex
      as={GridItem}
      flexDir="column"
      p="1rem"
      bg="verylightblue"
      colSpan={{ lg: 1, base: 3 }}
      justifyContent="space-between"
    >
      <Text fontWeight="medium" mb="1rem">
        {title}
      </Text>
      <Text fontWeight="light" mb="1rem">
        {description}
      </Text>
      <FormControl display="flex" gap={{ lg: 1, base: 3 }} alignItems="center">
        <Checkbox
          variant="main"
          onChange={(value) => {
            onChange(value.target.checked);
          }}
          icon={value ? <CheckboxIcon height="1rem" /> : <></>}
          isChecked={value}
        />
        <FormLabel>{label}</FormLabel>
      </FormControl>
    </Flex>
  );
};
