import { Listbox } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';
import { useController } from 'react-hook-form';

type TSelectInputItem = {
  code: string;
  description: string;
};
type TSelectInput = {
  fieldLabel: string;
  items: TSelectInputItem[];
  fieldPlaceholder: string;
  control: any;
  name: string;
  required?: boolean;
  disabled?: boolean;
  defaultValue?: string;
  multiple?: boolean;
  tooltip?: string;
  includeCode?: boolean;
};

export const MultiSelectInput = ({
  fieldLabel,
  items,
  fieldPlaceholder,
  name,
  control,
  required = false,
  disabled = false,
  defaultValue,
  multiple = false,
  tooltip,
  includeCode = false,
}: TSelectInput) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  const [selectedItem, setSelectedItem] = useState<any[]>([]);

  useEffect(() => {
    if (field.value) {
      setSelectedItem(field.value);
    }
  }, [field.value]);

  useEffect(() => {
    if (defaultValue !== '') {
      const index = items.findIndex((item) => item.code === defaultValue);
      if (index >= 0) {
        const aDes: string[] = [];
        aDes.push(items[index].code);
        setSelectedItem(aDes);
      }
    }
  }, [defaultValue]);

  function isSelected(value: any) {
    return selectedItem.find((item) => item.code === value.code) ? true : false;
  }

  function handleSelect(value: any = [], onChange?: (value: TSelectInputItem[]) => void) {
    const newValue = handleDeselect(value);
    setSelectedItem(newValue);
    if (onChange) {
      onChange(newValue);
    }
  }

  function handleDeselect(value: any) {
    const newArr = value;
    let h = 0;
    let i = 0;
    let j = 0;
    for (h = 0; h < value.length; h++) {
      const curItem = value[h];
      let foundCount = 0;
      // search array for item
      for (i = 0; i < value.length; i++) {
        if (value[i].code === value[h].code) foundCount++;
      }
      if (foundCount > 1) {
        // remove repeated item from new array
        for (j = 0; j < value.length; j++) {
          if (newArr[j].code === curItem.code) {
            newArr.splice(j, 1);
            j--;
          }
        }
      }
    }
    return newArr;
  }

  return (
    <div>
      <label className="mb-2 mt-2 flex text-x0">
        {fieldLabel} {required && <p className="m-0 inline  text-red-500"> *</p>}
        <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
      </label>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange }, fieldState: { error } }) => {
          return (
            <div className="relative">
              <Listbox
                disabled={disabled}
                value={selectedItem}
                multiple={multiple}
                onChange={(e: any) => {
                  handleSelect(e, onChange);
                }}
              >
                <Listbox.Button
                  className={`flex w-full content-center items-center overflow-auto rounded-md border border-defence-light-grey-500 bg-white py-1.5 pl-2 text-left text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:outline-black focus:border focus:border-defence-blueGrey focus:bg-white focus-visible:outline disabled:bg-defence-light-grey-200 ${error ? `inputError border-defencered` : `inputClean border-defencemediumgrey`
                    }`}
                >
                  {selectedItem ? (
                    <p className="mb-0 w-full text-black">
                      {selectedItem
                        .map((item) => {
                          const index = items.findIndex((itemx) => itemx.code === item.code);
                          return items[index]?.description;
                        })
                        .join(', ')}
                    </p>
                  ) : (
                    <p className="mb-0 w-full text-defence-black">{fieldPlaceholder}</p>
                  )}
                  <ChevronDownIcon height={30} width={30} className="mx-2 text-defence-orange-500" />
                </Listbox.Button>
                <Listbox.Options
                  className={`!absolute z-10 w-full overflow-y-auto border-2 border-t-0 bg-white shadow-md ${items.length >= 10 ? `h-96` : ``
                    }`}
                >
                  {items.map((item, index) => (
                    <div key={index} className="flex w-full hover:bg-defence-light-grey-200">
                      <span className="ml-1 mr-1 mt-2 flex-auto items-center">
                        <CheckIcon
                          width={20}
                          height={20}
                          className={` ${isSelected(item) ? `display text-defence-orange-500` : `display text-transparent`
                            }`}
                        />
                      </span>
                      <Listbox.Option
                        key={item.code}
                        value={item}
                        className="w-full flex-auto cursor-pointer list-none p-2"
                      >
                        {includeCode ? item.code + ' : ' : ''}
                        {item.description}
                      </Listbox.Option>
                    </div>
                  ))}
                </Listbox.Options>
              </Listbox>
              <div className="h-4">
                {error && error?.type === 'custom' && (
                  <p className="flex pt-1 text-sm text-defence-redAlert">
                    <InfoCircledIcon className="flex pt-1" height={16} width={16} />
                    This field is required.
                  </p>
                )}
              </div>
            </div>
          );
        }}
      />
    </div>
  );
};
