import * as Dialog from '@radix-ui/react-alert-dialog';
import { ExclamationTriangleIcon, InfoCircledIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const MessageModal = ({
  title,
  messages,
  open,
  hidebutton,
  navigateLink = '',
  onOk,
  refreshPage = false,
}: {
  title: string;
  messages: any[];
  open: boolean;
  hidebutton: boolean;
  navigateLink?: string;
  onOk?: () => void;
  refreshPage?: boolean;
}) => {
  const navigate = useNavigate();
  const goNavigate = () => {
    if (onOk) {
      onOk();
    }
    if (refreshPage) {
      navigate(0);
    }
    if (navigateLink) {
      navigate(navigateLink);
    }
  };

  return (
    <Dialog.Root defaultOpen={open} open={open}>
      <Dialog.Trigger asChild>
        <button
          className={`flex w-full gap-3 bg-defence-redAlert bg-opacity-50 p-4 text-xl hover:bg-opacity-80 hover:duration-100 hover:ease-in ${
            hidebutton === true ? `hidden` : ``
          }`}
        >
          <ExclamationTriangleIcon className="pt-1" height={30} width={30} />
          {title} ({messages.length})
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 bg-blackA9 data-[state=open]:animate-overlayShow" />
        <Dialog.Content className="fixed inset-x-0 top-[20%] mx-auto max-h-[75%] w-4/5 overflow-y-scroll rounded bg-white p-8 shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow sm:w-9/12 md:w-3/5 lg:w-2/5">
          <Dialog.Title className="m-0 mb-2 text-left text-3xl font-medium text-defence-charcoal">
            <InfoCircledIcon className="pb-2" height={40} width={40} />
            {title}
          </Dialog.Title>
          <Dialog.Description className="mb-2">
            {Array.isArray(messages)
              ? messages.filter((message) => !!message).map((message: any, index) => <p key={index}>{message}</p>)
              : messages}
          </Dialog.Description>
          <div className="flex justify-start gap-[25px]">
            <Dialog.Action asChild>
              <button className="button-primary bg-defence-orange-500" onClick={() => goNavigate()}>
                OK
              </button>
            </Dialog.Action>
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
