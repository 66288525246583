export const convertDateToDDMMYYYY = (date: Date) => {
  let day = date.getDate().toLocaleString('en-AU', {
    minimumIntegerDigits: 2,
  });
  let month = (date.getMonth() + 1).toLocaleString('en-AU', {
    minimumIntegerDigits: 2,
  });
  let year = date.getFullYear();
  return String(day) + month + String(year);
};
