import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { isNil } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { Banner } from '../../components/Banner';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { TextInput } from '../../components/form/inputs/textInput';
import { LOCAL_STORAGE_KEY_PREFIX, ROUTES } from '../../constants';
import { useNavigationContext, usePersistForm, useSessionStorage } from '../../hooks';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { ReadSESResponse, SESMetadataResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertFormData from '../../lib/convertFormData';
import convertSapMetadata from '../../lib/convertMetadata';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { setCurrentMeta } from '../../store/metaSes/metaSes.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';
import { Text } from '@chakra-ui/react';

const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_KEY_PREFIX}-ses-search`;

export const ServiceEntrySheetSearch = () => {
  useDocumentTitle(`Read Service Entry Sheet`);

  const [errorMessage, setErrorMessage] = useState('');
  const metadata = useSelector(selectCurrentMeta);
  const dispatch = useDispatch();
  const ref = useRef(false);
  const { storedData } = useSessionStorage<FormSchema>(LOCAL_STORAGE_KEY);
  const [loading, setLoading] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const { prevLocation } = useNavigationContext();
  const restoreValues =
    !prevLocation?.pathname ||
    prevLocation?.pathname === ROUTES.SES_SEARCH ||
    prevLocation?.pathname === ROUTES.SES_SEARCH_RESULTS ||
    prevLocation?.pathname.startsWith(ROUTES.SES_UPDATE.split(':')[0]);
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    if (Object.keys(metadata).length === 0) {
      const fetchTableMetadata = async () => {
        if (ref.current) {
          setLoading(true);
          const TableMetadata = await axiosInstance.get<SESMetadataResponse>(
            `${getAPIUrl(process.env.REACT_APP_EE_SES_METADATA)}`,
          );
          const { title: titleMessage, message: messages } = formatMessagesToDisplay(TableMetadata);
          setTitleMessage(titleMessage);
          setMessages(messages);
          setLoading(false);

          const formMetaData = convertSapMetadata({ data: TableMetadata });
          dispatch(setCurrentMeta(formMetaData));
        }
      };
      fetchTableMetadata();
      return () => {
        ref.current = true;
      };
    }
  }, []);

  const schema = z.object({
    PurchaseOrder: z.string().superRefine((val, ctx) => {
      if (val === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    DocumentDateFrom: z.date().superRefine((val, ctx) => {
      if (val === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    DocumentDateTo: z.date().superRefine((val, ctx) => {
      if (val === undefined) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
    Vendor: z.string().optional(),
    SheetNumberFrom: z.string().optional(),
    SheetNumberTo: z.string().optional(),
    ReferenceDocumentNumber: z.string().optional(),
    CommunicationNumber: z.string().optional(),
    RunId: z.string().optional(),
    CreatedBy: z.string().optional(),
    Status: z.string().superRefine((val, ctx) => {
      if (val === '') {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
        });
      }
    }),
  });

  type FormSchema = z.infer<typeof schema>;
  const navigate = useNavigate();

  const defaultValues: FormSchema = {
    PurchaseOrder: '',
    DocumentDateFrom: undefined as never,
    DocumentDateTo: undefined as never,
    Vendor: '',
    SheetNumberFrom: '',
    SheetNumberTo: '',
    ReferenceDocumentNumber: '',
    CommunicationNumber: '',
    RunId: '',
    CreatedBy: '',
    Status: '',
  };

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
    watch,
    setValue,
  } = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { clearStoredValues } = usePersistForm<FormSchema>({
    watch,
    localStorageKey: LOCAL_STORAGE_KEY,
  });

  useEffect(() => {
    if (restoreValues && storedData) {
      Object.keys(storedData).forEach((key) => {
        if (!isNil((storedData as never)[key])) {
          const value = key?.includes('Date') ? new Date((storedData as never)[key]) : (storedData as never)[key];
          setValue(key as keyof FormSchema, value || '');
        }
      });
    }
  }, []);

  useEffect(() => {
    if (!restoreValues) {
      clearStoredValues();
    }
  }, [restoreValues, clearStoredValues]);

  const onSubmit = async (data: FormSchema) => {
    const results = convertData(data);
    const formDataQuery = convertFormData(results);
    try {
      setLoading(true);
      const responseData = await axiosInstance.get<ReadSESResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_SES_MULTI_REQ_LIST)}?$filter=(${formDataQuery})&$format=json`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
      setTitleMessage(titleMessage);
      setMessages(messages);
      setLoading(false);

      if (titleMessage !== 'Error') {
        navigate(ROUTES.SES_SEARCH_RESULTS, {
          state: { data: responseData.data },
        });
      }
    } catch (error) {
      setLoading(false);
      const errorResponse = isAxiosError(error) ? error.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const convertData = function (data: any) {
    let valInd = JSON.parse(JSON.stringify(data.Status));
    let fname = 'Status';
    if (typeof valInd === 'string') {
      if (valInd === 'Blocked') {
        data[fname] = 'B';
      }
      if (valInd === 'Accepted') {
        data[fname] = 'A';
      }
      if (valInd === 'Rejected') {
        data[fname] = 'R';
      }
      if (valInd === 'Created') {
        data[fname] = 'C';
      }
      if (valInd === 'All') {
        delete data[fname];
      }
    }
    return data;
  };

  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Service Entry Sheet Details',
      fields: [
        'PurchaseOrder',
        'DocumentDateFrom',
        'DocumentDateTo',
        'Vendor',
        'SheetNumberFrom',
        'SheetNumberTo',
        'ReferenceDocumentNumber',
        'CommunicationNumber',
        'RunId',
        'CreatedBy',
        'Status',
      ],
    },
  ];

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <>
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <SubMenu title="Service Entry Sheets" selectedIndex="0" />
      <Banner title="Search and/or Display Service Entry Sheet" description="" bannerImgSrc="" />
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      The <strong>Mandatory</strong> field has not been completed. Please update and resubmit.
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          {sections.length > 1 && (
            <div className="relative">
              <FormStepper
                onChange={(item: FormStepperItem) => {
                  setStep(item.code);
                }}
                items={sections?.map((section) => ({
                  code: section.code,
                  name: section.name,
                  href: '#',
                  status:
                    step === section.code
                      ? FormStepperEnum.Current
                      : step > section.code
                      ? FormStepperEnum.Complete
                      : FormStepperEnum.Upcoming,
                }))}
              />
            </div>
          )}
          <div className="w-full pt-3">
            <SubBanner
              title={sections[step - 1].name}
              info="To find the Service Entry Sheet you are looking for, please enter the relevant information below, then select the Submit button. The fields marked with an * are mandatory."
            />
          </div>
          <Text mb="1rem" color="redalert">
            **Only Service Entry Sheets that are tied to your vendor will be displayed.
          </Text>
          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit, goToErrors)}>
              {step === 1 && (
                <div className="mb-4 w-full">
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Vendor"
                        fieldPlaceholder="Start typing"
                        name="Vendor"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Vendor}
                        codeSetValueField="Vendor"
                        codeSetLabelField="VendorName"
                        top="20"
                      />
                      <TextInput
                        fieldLabel="Purchase Order"
                        fieldPlaceholder=" "
                        name="PurchaseOrder"
                        control={control}
                        required={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Document Date From"
                        fieldPlaceholder=" "
                        name="DocumentDateFrom"
                        control={control}
                        required={true}
                      />
                      <DateInput
                        fieldLabel="Document Date To"
                        fieldPlaceholder=" "
                        name="DocumentDateTo"
                        control={control}
                        required={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Sheet Number From"
                        fieldPlaceholder=" "
                        name="SheetNumberFrom"
                        control={control}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Sheet Number To"
                        fieldPlaceholder=" "
                        name="SheetNumberTo"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Reference"
                        fieldPlaceholder=" "
                        name="ReferenceDocumentNumber"
                        control={control}
                        required={false}
                      />
                      <TextInput
                        fieldLabel="Communication Number"
                        fieldPlaceholder=" "
                        name="CommunicationNumber"
                        control={control}
                        required={false}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Run ID"
                        fieldPlaceholder=" "
                        name="RunId"
                        control={control}
                        required={false}
                      />
                      <InputTypeAhead
                        fieldLabel="Created By"
                        fieldPlaceholder="Start typing"
                        name="CreatedBy"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.UserID}
                        codeSetValueField="UserID"
                        codeSetLabelField="FullName"
                        top="20"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={[
                          { code: 'All', description: 'All' },
                          { code: 'Created', description: 'Created' },
                          { code: 'Blocked', description: 'Blocked' },
                          { code: 'Accepted', description: 'Accepted' },
                          { code: 'Rejected', description: 'Rejected' },
                        ]}
                        fieldLabel="Status"
                        fieldPlaceholder=" "
                        name="Status"
                        control={control}
                        required={true}
                        includeCode={false}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="flex w-full justify-end">
                <div className="flex-end flex">
                  {!(step === 1) && (
                    <button
                      type="button"
                      onClick={() => {
                        backStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Back
                    </button>
                  )}
                  {!(step === sections.length) && (
                    <button
                      type="button"
                      onClick={() => {
                        nextStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Next
                    </button>
                  )}
                  <button
                    type="submit"
                    onClick={() => {
                      //trigger(["OrderType"]);
                    }}
                    className="button-primary mr-0 bg-defence-orange-500"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
