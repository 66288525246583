import { PlusIcon, TrashIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useFieldArray } from 'react-hook-form';
import { TypeAheadCodeSet } from '../../../lib/api/lookup';
import { SingleSelectInputCode } from './singleSelectInputCode';
import { TextInput } from './textInput';

export const ItemBreakdownInput = ({ control, readOnly, updateForm }) => {
  const { fields, append, update, remove } = useFieldArray({
    name: 'NotifToItem.results',
    control,
  });

  const removeItem = (index) => {
    if (fields[index]['NotificationNo']) {
      update(index, { ...fields[index], Actioncode: '03' });
    } else {
      remove(index);
    }
  };

  const fieldsToDisplay = fields.filter((field) => field['Actioncode'] !== '03');
  return (
    <>
      <div className="w-full pt-3">
        <h1 className="w-max text-2xl font-bold">Item Description</h1>
      </div>
      <div className="">
        {fieldsToDisplay.map((field, index) => {
          return (
            <div
              key={field.id}
              className="my-4 grid grid-cols-1 gap-8 rounded bg-defence-light-blue-grey-200 p-4 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-7"
            >
              <div className="lg:col-span-1">
                <TextInput
                  fieldLabel="Item No."
                  fieldPlaceholder=" "
                  name={`NotifToItem.results[${index}].ItemNo`}
                  control={control}
                  disabled={readOnly}
                />
              </div>
              <div className="lg:col-span-3">
                <SingleSelectInputCode
                  codeSet={TypeAheadCodeSet.CauseCodeCoding}
                  codeSetLabelField="ShortText"
                  codeSetValueField="CauseCode"
                  codeDepFilterField="CodeGroup"
                  codeDepFilterVal="ZPM-EU"
                  fieldLabel="Cause Code"
                  fieldPlaceholder=" "
                  name={`NotifToItem.results[${index}].CauseCodeCoding`}
                  control={control}
                  required={false}
                  defaultValue={`NotifToItem.results[${index}].CauseCodeCoding`}
                  disabled={readOnly}
                />
              </div>
              <div className="lg:col-span-2">
                <TextInput
                  fieldLabel="Cause Text"
                  fieldPlaceholder=" "
                  name={`NotifToItem.results[${index}].CauseText`}
                  control={control}
                  disabled={readOnly}
                />
              </div>

              <div className="lg:col-span-1">
                <div className="flex h-full w-full place-content-center ">
                  {fieldsToDisplay.length - 1 === index && (
                    <button
                      type="button"
                      onClick={() =>
                        append({
                          NotificationNo: field['NotificationNo'],
                          ItemNo: '',
                          CauseText: '',
                          CauseCodeCoding: '',
                          CauseCodeGroup: 'ZPM-EU',
                          CauseCodeDesc: '',
                          Actioncode: '01', // create new
                        })
                      }
                      disabled={readOnly}
                    >
                      <PlusIcon width={25} height={25} />
                    </button>
                  )}

                  <button
                    type="button"
                    // className="hidden"
                    className={` ${index === 0 || readOnly || updateForm ? `hidden` : `display`}`}
                    disabled={readOnly}
                    onClick={() => {
                      removeItem(index);
                    }}
                  >
                    <TrashIcon width={25} height={25} />
                  </button>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
