/* eslint-disable jsx-a11y/anchor-is-valid */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { FileUpload } from '../../components/form/inputs/FileUpload';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';

export const RfxBulkUploadDownload = () => {
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const schema = z.object({
    UploadDownload: z.string().optional(),
  });

  type formSchema = z.infer<typeof schema>;

  const defaultValues: formSchema = {
    UploadDownload: 'Upload',
  };

  const { handleSubmit, watch, control } = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: formSchema) => {};

  const handleTemplate = async (template: string) => {
    setLoading(true);
    const file = await axiosInstance.get(`${getAPIUrl(process.env.REACT_APP_EE_SHARE_TEMPLATE)}/${template}`, {
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(file.data);
    const link = document.createElement('a');
    link.href = url;
    let filename = file.headers['x-filename'];
    if (!filename) {
      filename = file.headers['content-disposition'].split('=')[1] || '';
      filename = filename.replace(/"/g, ''); // trim double
    }
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    setLoading(false);
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Bulk Upload RFX',
      }}
      bannerProps={{
        title: 'Bulk Upload',
      }}
      submenuProps={{
        title: 'RFX',
        selectedIndex: '2',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white py-2">
        <div className="m-auto w-11/12 py-2 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          <form onSubmit={handleSubmit(onSubmit)}>
            {watch('UploadDownload') === 'Upload' && (
              <div className="py-2">
                <a
                  className="cursor-pointer text-x0 text-defence-orange-500 underline hover:no-underline"
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => handleTemplate(`('RFX RESPONSE IN')`)}
                >
                  RFX template
                </a>
              </div>
            )}
            <div className="mb-4 w-full rounded-md bg-white">
              <div className="grid h-full grid-flow-row grid-cols-1 md:grid-cols-1 md:gap-1">
                {watch('UploadDownload') === 'Upload' && (
                  <div>
                    <p className="text-2xl font-bold">File Upload</p>
                    <div className="grid grid-flow-row grid-cols-1 gap-1">
                      <FileUpload
                        fieldLabel=""
                        fileType=""
                        fileMax="20 MB"
                        register=""
                        fieldname=""
                        errorMessage=""
                        validation=""
                        url={getAPIUrl(process.env.REACT_APP_EE_SHARE_UPLOAD)}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </div>
      </div>
    </BaseLayout>
  );
};
