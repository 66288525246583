import { useEffect } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";
import { useSessionStorage } from "./useStorage";

export const usePersistForm = <T extends FieldValues>({
  watch,
  localStorageKey,
}: {
  watch: UseFormWatch<T>;
  localStorageKey: string;
}) => {
  const { removeStoredData, storeData } = useSessionStorage<T>(localStorageKey);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      storeData(
        (storedData) =>
          ({
            ...storedData,
            [name as string]: value[name as any] || "",
          } as any)
      );
    });
    return () => subscription.unsubscribe();
  }, [watch, storeData]);

  return {
    clearStoredValues: removeStoredData,
  };
};
