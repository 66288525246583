import { DownloadIcon, FileTextIcon, MagnifyingGlassIcon, Pencil2Icon } from '@radix-ui/react-icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseLayout } from '../../components/BaseLayout';
import { LinkCard } from '../../components/LinkCard';
import { ROUTES } from '../../constants';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';

export const ServiceEntrySheet = () => {
  useDocumentTitle(`Service Entry Sheet`);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
  }, []);
  const quickLinkCards = [
    {
      link: ROUTES.SES_SEARCH,
      description: 'You can find and display Service Entry Sheets information.',
      label: 'Looking for a Service Entry Sheet?',
      linkText: 'Search',
      icon: <MagnifyingGlassIcon width={40} height={40} />,
    },
    {
      link: ROUTES.SES_CREATE,
      description: 'You can easily create a single SES online without having to use the template.',
      label: 'Do you need to create a Service Entry Sheet?',
      linkText: 'Create',
      icon: <Pencil2Icon width={40} height={40} />,
    },
    {
      link: ROUTES.SES_BULK_UPLOAD_DOWNLOAD,
      description:
        'You can access the Bulk Templates, Search and Display as well as Upload/Download Service Entry Sheets Information.',
      label: 'Need to Upload/Download Service Entry Sheets?',
      linkText: 'Bulk Upload/Download',
      icon: <DownloadIcon width={40} height={40} />,
    },
    {
      link: ROUTES.SES_BULK_PROGRESS_REPORT,
      description: 'You can view the various Bulk Upload/Download submissions that have been made and their status.',
      label: 'Need to view & monitor bulk load progress?',
      linkText: 'Bulk Progress Report',
      icon: <FileTextIcon width={40} height={40} />,
      insertEnter: true,
    },
  ];

  return (
    <BaseLayout
      headProps={{
        title: 'Service Entry Sheets',
      }}
      bannerProps={{
        title: 'Service Entry Sheets',
      }}
      submenuProps={{
        title: 'Service Entry Sheets',
      }}
    >
      <div className=" flex w-full">
        <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
          <p className="w-fit p-4 text-xl"></p>
          <div className="grid grid-cols-1 gap-10 px-2 sm:grid-cols-2 sm:px-4 md:my-2 md:grid-cols-2 md:gap-10 lg:grid-cols-2 xl:grid-cols-2">
            {quickLinkCards.map((card) => {
              return (
                <LinkCard
                  key={card.link}
                  showIcon={true}
                  icon={card.icon}
                  imageSrc=""
                  linkText={card.linkText}
                  link={card.link}
                  description={card.description}
                  label={card.label}
                  insertEnter={card.insertEnter}
                />
              );
            })}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
