import React, { useState, useEffect } from 'react';
import content from './Content/LandingPage.json';
import { Box, Grid, GridItem } from '@chakra-ui/react';
import { getBaseURL, getFormsURL } from '../../helper/getOtherAppURL';
import { CommonFeedback } from '../../components/common/CommonFeedback/commonFeedback';
import { request } from '../../helper/useAxios';
import { appConstants } from '../../helper/client/constant';
import { setShowError } from '../../reducers';
import { useDispatch } from 'react-redux';

import Header from '../../components/ServiceUI/Core/Header/Header';
import { SubContainer } from '../../components/ServiceUI/Core/Layout/SubContainer/SubContainer';
import { IconCard } from '../../components/ServiceUI/Core/Card/Card';
import SubHeader from '../../components/ServiceUI/Core/SubHeader/SubHeader';
import { StartForm } from '../../components/ServiceUI/Core/StartForm/StartForm';
import StartSR from '../../components/ServiceUI/ServiceRequest/StartSR/StartSR';
import SRTypeSelector from '../../components/ServiceUI/ServiceRequest/StartSR/SRTypeSelector';
import { SRDashBoard } from '../../components/ServiceUI/ServiceRequest/SRDashboard/SRDashboard';
import { SR } from '../../components/ServiceUI/ServiceRequest/types';

export const SRLandingPage: React.FC<any> = ({ myServices }) => {
  const [displayStartSR, setDisplayStartSR] = useState(false);
  const [displayTypeSelector, setDisplayTypeSelector] = useState(false);
  const [srItems, setSRItems] = useState<SR[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    sessionStorage.clear();
    document.title = 'My Service Request';
    request(appConstants.API_RECENT_REQUEST, 'get')
      .then((data) => data && setSRItems(data.data.pxResults))
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  }, []);

  const createSRCaseFunc = () => {
    setDisplayStartSR(true); //Display StartSR Modal
  };

  return (
    <>
      <Box bg="whispergrey">
        <Header {...content.header} />
        <StartForm {...content.startform} createCase={createSRCaseFunc} />
        <StartSR
          isOpen={displayStartSR}
          onClose={() => setDisplayStartSR(false)}
          continue={() => {
            setDisplayStartSR(false);
            setDisplayTypeSelector(true);
          }}
        />

        <SRTypeSelector
          modalProps={content.typeofSR}
          isOpen={displayTypeSelector}
          onClose={() => setDisplayTypeSelector(false)}
        />
        <SubHeader bg="lightblue" color="black" {...content.subheader} />
        <SubContainer verticalMargin="1rem">
          <SRDashBoard {...content.sr_dashboard} srs={srItems} />
          <Grid mb="3.5rem" gridTemplateColumns="repeat(2,1fr)" gap={{ lg: '2rem', base: '1rem' }}>
            {content.cards.map((i, idx) => (
              <GridItem colSpan={{ lg: 1, base: 2 }} key={idx}>
                <IconCard
                  {...i}
                  linkURL={i.isAEM ? (i.linkText === 'Visit My Base' ? getBaseURL() : getFormsURL()) : i.linkURL}
                />
              </GridItem>
            ))}
          </Grid>
        </SubContainer>
        <CommonFeedback
          title="Please tell us what you need from My Service Request"
          selectedService="My Service Request"
          myServices={myServices}
        />
      </Box>
    </>
  );
};
