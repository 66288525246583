import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Dropdown, Form, Modal, Row } from 'react-bootstrap';
import { appConstants } from '../../../../helper/client/constant';
import { Spinner } from '../../../ServiceUI/Core/Spinner/Spinner';
import './FormAttachContent.scss';
import {
  setFieldValue,
  setAttachmentmessage,
  setUploadAttachments,
} from '../../../../reducers/requestDetailFormReducer';

import { InfoCircle } from 'react-bootstrap-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { setShowError } from '../../../../reducers/errorHandling';
import { request } from '../../../../helper/useAxios';

import SRFilesUploaded from '../SRFilesUploaded';
import { useDispatch } from 'react-redux';

const FormInputAttachContent = ({
  isReview,
  attachmentCategory,
  caseIDOriginal,
  currentType,
  fromview,
  required,
  visible,
  reference,
  formData,
}) => {
  const initialStatefileUploadValiditywithInfo = {
    isInValid: false,
    fileInfo: [],
  };
  const dispatch = useDispatch();
  const [showSpinnerApiIsInProgress, setShowSpinnerApiIsInProgress] = useState(false);
  const [fileUploadValiditywithInfo, setfileUploadValiditywithInfo] = useState({
    ...initialStatefileUploadValiditywithInfo,
  });
  const [DownloadFileUrl, setDownloadFileUrl] = useState({
    fileName: '',
    url: '',
  });
  const [uploadedFile, setUploadedFile] = useState({ selectedFile: [] });
  const [uploadedFileInPega, setUploadedFileInPega] = useState({
    selectedFile: [],
  });
  const [attachmentCategoryInForm, setattachmentCategoryInForm] = useState({
    selectedCategory: attachmentCategory.selectedCategory,
    selectedCategoryID: attachmentCategory.selectedCategoryId,
    categories: attachmentCategory.categories,
    allowedFileExt: appConstants.SR_ALLOWED_FILE_EXT_IMAGE_STRING,
  });

  const [showSecurityModal, setSecuritytModal] = useState(false);
  const addUpload = () => {
    setSecuritytModal(true);
  };
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {appConstants.ATTACHMENTS_FILESIZE_TOOLTIP}
    </Tooltip>
  );
  const [allFiles, setAllFiles] = useState([]);

  const [savedTotalFileSize, setSavedTotalFileSize] = useState(0);
  const [totalFileSize, setTotalFileSize] = useState(0);
  useEffect(() => {
    let total = 0;
    for (let index = 0; index < allFiles.length; index++) {
      total = total + allFiles[index][0].size;
    }
    setTotalFileSize(total);
  }, [allFiles]);

  useEffect(() => {
    if (fromview || isReview) {
      getALLSavedFiles();
    }
  }, [fromview, isReview]);

  useEffect(() => {
    if (
      attachmentCategory.selectedCategory === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST ||
      attachmentCategory.selectedCategory === appConstants.CATEGORY_TYPE_NAME_WASTE_MANIFEST
    ) {
      getTemplateInBase64fromAPI(attachmentCategory.selectedCategory);
    }
    //e.preventDefault();

    setattachmentCategoryInForm((prevState) => {
      return {
        ...prevState,
        selectedCategory: attachmentCategory.selectedCategory,
        selectedCategoryID: attachmentCategory.selectedCategoryId,
        allowedFileExt: getValidFormatStringOnBasisOfCategory(attachmentCategory.selectedCategory),
        categories: attachmentCategory.categories,
      };
    });
  }, [attachmentCategory]);

  useEffect(() => {
    if (currentType.code === 'Transport' || currentType.code === 'Waste') {
      dispatch(setAttachmentmessage(currentType.code));
    }
  }, [currentType]);

  useEffect(() => {
    //send confirmation of true to reducer that is length greater than 0
    if (uploadedFile.selectedFile.length > 0) {
      dispatch(setUploadAttachments(true));
    } else {
      dispatch(setUploadAttachments(false));
    }
  }, [uploadedFile]);

  const deleteAttachment = (file) => {
    setAllFiles(allFiles.filter((item) => item.name === file.name));
    const fileFoundinSavedArray = uploadedFileInPega.selectedFile.filter((obj) => obj.name === file.name);
    if (fileFoundinSavedArray.length > 0) {
      request(appConstants.API_ATTACHMENTS_FILE + file.attachmentLinkID, 'delete')
        .then((res) => {
          getALLSavedFiles();
          document.getElementById('file-upload').value = '';
        })
        .catch((error) => {
          dispatch(setShowError({ hasError: true, error }));
        });
    } else {
      setUploadedFile((prevState) => {
        return {
          ...prevState,
          selectedFile: uploadedFile.selectedFile.filter((stateFile) => stateFile.name !== file.name),
        };
      });
      document.getElementById('file-upload').value = '';
    }
  };
  const getTemplateInBase64fromAPI = (categoryTypeName) => {
    const fileName =
      categoryTypeName === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST
        ? appConstants.TEMPLATE_NAME_TRANSPORT_MANIFEST
        : appConstants.TEMPLATE_NAME_WASTE_MANIFEST;
    const filePath =
      categoryTypeName === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST
        ? appConstants.FILE_PATH_TRANSPORT_MANIFEST
        : appConstants.FILE_PATH_TRANSPORT_WASTE;

    const repositoryName =
      categoryTypeName === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST
        ? appConstants.REPOSITORY_NAME_TRANSPORT_MANIFEST
        : appConstants.REPOSITORY_NAME_WASTE_MANIFEST;
    request(appConstants.API_RESPONSE_TYPE + repositoryName + '/' + filePath, 'get')
      .then((res) => {
        setDownloadFileUrl((prevState) => {
          return {
            ...prevState,
            fileName: fileName,
            url: appConstants.CONVERSION_BASE64__FILE_DEFAULT_URL + res.data,
          };
        });
      })
      .catch((error) => {
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const getValidFormatStringOnBasisOfCategory = (category) => {
    if (category === appConstants.CATEGORY_IMAGE) {
      return appConstants.SR_ALLOWED_FILE_EXT_IMAGE_STRING;
    } else if (
      category === appConstants.CATEGORY_TYPE_NAME_WASTE_MANIFEST ||
      category === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST
    ) {
      return appConstants.SR_ALLOWED_FILE_EXT_TRANSPORT_WASTE_STRING;
    } else return appConstants.SR_ALLOWED_FILE_EXT_SUPPORTING_DOC_STRING;
  };
  const changeCategory = (e, selectedCategorydropdownObj) => {
    if (
      selectedCategorydropdownObj.name === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST ||
      selectedCategorydropdownObj.name === appConstants.CATEGORY_TYPE_NAME_WASTE_MANIFEST
    ) {
      getTemplateInBase64fromAPI(selectedCategorydropdownObj.name);
    }
    e.preventDefault();

    setattachmentCategoryInForm((prevState) => {
      return {
        ...prevState,

        selectedCategory: selectedCategorydropdownObj.name,
        selectedCategoryID: selectedCategorydropdownObj.ID,
        allowedFileExt: getValidFormatStringOnBasisOfCategory(selectedCategorydropdownObj.name),
      };
    });
  };
  const getUpdatedFilesFromPega = (responseFromPega) => {
    const filteredArrayNonCorrespondese = responseFromPega.attachments.filter(
      (file) => file.category !== appConstants.ATTACHMENT_TYPE_CORRESPONDENCE,
    );

    const fileInfoArray = filteredArrayNonCorrespondese.map((file) => {
      return {
        name: file.name, //changed this from fileName to name
        attachmentLinkID: file.ID,
      };
    });

    dispatch(
      setFieldValue({
        reference,
        value: fileInfoArray.length > 0 ? 'contains file' : '',
        formData,
        required: currentType.code === 'Waste' ? true : false,
        visible: true,
        error: fileInfoArray.length === 0 && currentType.code === 'Waste' ? true : false,
      }),
    );
    setUploadedFileInPega((prevState) => {
      return {
        ...prevState,
        selectedFile: [...fileInfoArray],
      };
    });
  };
  const getALLSavedFiles = () => {
    setShowSpinnerApiIsInProgress(true);
    request(appConstants.API_CASES + caseIDOriginal + '/attachments', 'get')
      .then((res) => {
        setShowSpinnerApiIsInProgress(false);
        if (!!res.data.attachments) {
          getUpdatedFilesFromPega(res.data);
        } else {
          const noFiles = [];
          dispatch(
            setFieldValue({
              reference,
              value: noFiles.length > 0 ? 'contains file' : '',
              formData,
              required: currentType.code === 'Waste' ? true : false,
              visible: true,
              error: noFiles.length === 0 && currentType.code === 'Waste' ? true : false,
            }),
          );
          setUploadedFileInPega((prevState) => {
            return {
              ...prevState,
              selectedFile: [],
            };
          });
        }

        setUploadedFile((prevState) => {
          return {
            ...prevState,
            selectedFile: [],
          };
        });

        //Get totalSize of all saved files for validation
        let totalSize = 0;
        res?.data?.attachments?.forEach((attachment) => {
          if (attachment.type === 'FILE') {
            totalSize += attachment.fileSizeinbytes;
          }
        });
        setSavedTotalFileSize(totalSize);
      })
      .catch((error) => {
        setShowSpinnerApiIsInProgress(false);
        dispatch(setShowError({ hasError: true, error }));
      });
  };
  const apiCallsForUpload = (data, caseId, fileInfo) => {
    setShowSpinnerApiIsInProgress(true);

    return new Promise((resolve, reject) => {
      request(appConstants.API_ATTACHMENT_UPLOAD, 'post', { data })
        .then((res) => {
          const attachmentData = [
            {
              type: appConstants.SR_ATTACHMENT_TYPE,
              category: attachmentCategoryInForm.selectedCategoryID,
              name: fileInfo.name,
              ID: res.data.ID,
            },
          ];
          const data = {
            attachments: attachmentData,
          };
          request(appConstants.API_CASES + caseId + '/attachments', 'post', { data })
            .then((res) => {
              // Add the get call to get the updated list of uploaded file with new Name start
              getALLSavedFiles();
              resolve('all done');
              // Ends here
            })
            .catch((error) => {
              setShowSpinnerApiIsInProgress(false);
              dispatch(setShowError({ hasError: true, error }));
            });
        })
        .catch((error) => {
          setShowSpinnerApiIsInProgress(false);
          dispatch(setShowError({ hasError: true, error }));
        });
    });
  };
  const getValidFormatOnBasisOfCategory = () => {
    if (attachmentCategoryInForm.selectedCategory === appConstants.CATEGORY_IMAGE) {
      return appConstants.SR_ALLOWED_FILE_EXT_IMAGE;
    } else if (
      attachmentCategoryInForm.selectedCategory === appConstants.CATEGORY_TYPE_NAME_WASTE_MANIFEST ||
      attachmentCategoryInForm.selectedCategory === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST
    ) {
      return appConstants.SR_ALLOWED_FILE_EXT_TRANSPORT_WASTE;
    } else {
      return appConstants.SR_ALLOWED_FILE_EXT_SUPPORTING_DOC;
    }
  };
  const resetErrorArray = () => {
    setfileUploadValiditywithInfo((prevState) => {
      return {
        isInValid: false,
        fileInfo: [],
      };
    });
  };
  const containsSpecialChars = (fileName) => {
    const specialChars = appConstants.ATTACHMENTS_SPECIAL_CHARACTERS_STRING;

    const result = specialChars.split('').some((specialChar) => {
      if (fileName.includes(specialChar)) {
        return true;
      }

      return false;
    });

    return result;
  };

  const checkFileSizeValidityBeforeUpload = (bodyFormData, fileInfo) => {
    let count = 0;
    uploadedFile.selectedFile.forEach(async (file) => {
      count = count + 1;
      if (count === uploadedFile.selectedFile.length) {
        if (totalFileSize.toFixed(2) > appConstants.SR_FILE_SIZE_MAX_LIMIT * (1024 * 1024)) {
          const fileDataExtremeSize = {
            fileName: file.name,
            errorMessage: appConstants.SR_FILE_SIZE_EXCEED_MSG,
            reason: appConstants.SR_FILE_ERROR_TYPE_SIZE,
          };
          setfileUploadValiditywithInfo((prevState) => {
            return {
              ...prevState,
              isInValid: true,
              fileInfo: [{ ...fileDataExtremeSize }],
            };
          });
        } else {
          await apiCallsForUpload(bodyFormData, caseIDOriginal, fileInfo);
        }
      }
    });
  };

  const checkFileValidity = (files) => {
    resetErrorArray();
    const sumOfFileSize = files.reduce((total, file) => total + file.size, 0);
    const sumWithSavedFiles = sumOfFileSize + savedTotalFileSize;
    // eslint-disable-next-line no-array-constructor
    const validExtsAllowed = getValidFormatOnBasisOfCategory();

    let count = 0;
    files.map((file) => {
      const FileNameWithOutExtension = file.name.substring(0, file.name.lastIndexOf('.')) || file.name;
      const fileExtFromFileType = file.type.substring(file.type.indexOf('/') + 1);
      count = count + 1;

      if (
        count === files.length &&
        (totalFileSize + file.size).toFixed(2) > appConstants.SR_FILE_SIZE_MAX_LIMIT * (1024 * 1024)
      ) {
        const fileDataExtremeSize = {
          fileName: file.name,
          errorMessage: appConstants.SR_FILE_SIZE_EXCEED_MSG,
          reason: appConstants.SR_FILE_ERROR_TYPE_SIZE,
        };
        setfileUploadValiditywithInfo((prevState) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [{ ...fileDataExtremeSize }],
          };
        });
      } else if (
        count !== files.length &&
        totalFileSize.toFixed(2) > appConstants.SR_FILE_SIZE_MAX_LIMIT * (1024 * 1024)
      ) {
        const fileDataExtremeSize = {
          fileName: file.name,
          errorMessage: appConstants.SR_FILE_SIZE_EXCEED_MSG,
          reason: appConstants.SR_FILE_ERROR_TYPE_SIZE,
        };
        setfileUploadValiditywithInfo((prevState) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [{ ...fileDataExtremeSize }],
          };
        });
      } else if (validExtsAllowed.indexOf(fileExtFromFileType) < 0) {
        const fileDataInvalidFormat = {
          fileName: file.name,
          errorMessage: file.name + appConstants.SR_FILE_SIZE_INVALID_MSG,
          reason: appConstants.SR_FILE_ERROR_TYPE_FORMAT,
        };

        setfileUploadValiditywithInfo((prevState) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, fileDataInvalidFormat],
          };
        });
      } else if (FileNameWithOutExtension.length > 48) {
        const fileDataInvalidFormat = {
          fileName: file.name,
          errorMessage: appConstants.SR_THE_ATTACHMENTS + file.name + appConstants.SR_FILE_NAME_INVALID_MSG,
          reason: appConstants.SR_FILE_ERROR_NAME_FORMAT,
        };

        setfileUploadValiditywithInfo((prevState) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, fileDataInvalidFormat],
          };
        });
      } else if (containsSpecialChars(FileNameWithOutExtension)) {
        const fileDataInvalidFormat = {
          fileName: file.name,
          errorMessage:
            appConstants.SR_THE_ATTACHMENTS + file.name + appConstants.SR_FILE_NAME_SPECIAL_CHARS_INVALID_NAME_MSG,
          reason: appConstants.SR_FILE_ERROR_NAME_SPECIAL_CHARS_FORMAT,
        };
        setfileUploadValiditywithInfo((prevState) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, fileDataInvalidFormat],
          };
        });
      } else if (sumWithSavedFiles > appConstants.SR_FILE_SIZE_MAX_LIMIT * (1024 * 1024)) {
        // Check if combined total size of files does not exceed limit
        const fileDataExtremeSize = {
          fileName: file.name,
          errorMessage: appConstants.SR_FILE_SIZE_EXCEED_MSG,
          reason: appConstants.SR_FILE_ERROR_TYPE_SIZE,
        };
        setfileUploadValiditywithInfo((prevState) => {
          return {
            ...prevState,
            isInValid: true,
            fileInfo: [{ ...fileDataExtremeSize }],
          };
        });
      } else {
        const validFileData = {
          fileName: file.name,
          errorMessage: '',
          reason: '',
        };
        setfileUploadValiditywithInfo((prevState) => {
          return {
            ...prevState,
            isInValid: false,
            fileInfo: [...fileUploadValiditywithInfo.fileInfo, validFileData],
          };
        });

        setUploadedFile((prevState) => {
          const newArray = [...prevState.selectedFile, file];
          return {
            ...prevState,
            selectedFile: newArray,
          };
        });
      }
    });
  };
  //const downloadSavedFile = (file) => {};
  const onFileChange = async (event) => {
    const file = Array.from(event.target.files);
    let combinedFileSize = 0;
    file.forEach((item) => (combinedFileSize += item.size));
    if (combinedFileSize + totalFileSize > appConstants.SR_FILE_SIZE_MAX_LIMIT * (1024 * 1024)) {
      const fileDataExtremeSize = {
        fileName: file.name,
        errorMessage: appConstants.SR_FILE_SIZE_EXCEED_MSG,
        reason: appConstants.SR_FILE_ERROR_TYPE_SIZE,
      };
      setfileUploadValiditywithInfo((prevState) => {
        return {
          ...prevState,
          isInValid: true,
          fileInfo: [{ ...fileDataExtremeSize }],
        };
      });
      return;
    } else {
      if (totalFileSize + file[0].size < appConstants.SR_FILE_SIZE_MAX_LIMIT * (1024 * 1024)) {
        setAllFiles([...allFiles, file]);
      }
      checkFileValidity(file);
    }
  };

  const onFileUpload = (event) => {
    // Create an object of formData
    setSecuritytModal(false);
    const bodyFormData = new FormData();

    for (let item = 0; item < uploadedFile.selectedFile.length; item++) {
      bodyFormData.delete(appConstants.SR_ATTACHMENT_FORM_DATA_KEY);
      bodyFormData.append(
        appConstants.SR_ATTACHMENT_FORM_DATA_KEY,
        uploadedFile.selectedFile[item],
        uploadedFile.selectedFile[item].name,
      );
      checkFileSizeValidityBeforeUpload(bodyFormData, uploadedFile.selectedFile[item]);
    }
  };

  return (
    //Modal for security modal
    <>
      {showSecurityModal && (
        <Modal
          show={showSecurityModal}
          animation={false}
          dialogClassName="modal-lg modalPadding px-lg-0 py-4"
          contentClassName="p-lg-4 modal-75w"
          backdrop="static"
          className=""
        >
          <Modal.Title className="px-lg-5 pt-lg-5 modalTitle px-2">Security Disclaimer</Modal.Title>

          <Modal.Body className="px-lg-5 px-3">
            <Container>
              <p>
                You must acknowledge and confirm the statement below to use
                <b> My Service Request.</b>
              </p>
              <p>
                I confirm that I have complied with&nbsp;
                <a href="http://drnet/AssociateSecretary/Security-Vetting-Services/Security-Policy/Pages/Defence-Security-Principles-Framework.aspx">
                  Defence Security Policy Framework
                </a>
                (DSPF) requirements relating to photography (particularly
                <em> Control 17.1 and Control 22.1</em>), including:
              </p>

              <ul>
                <li>
                  <p>Not capturing sensitive or classified information in an image;</p>

                  <p>
                    <b>Note:</b> Appropriate care should be taken to ensure that sensitive information is not captured
                    in the background of an image. This may include visible documents or computer screens displaying
                    sensitive information. For more information regarding information classification, refer to
                    <em> DSPF Control 10.1.</em>
                  </p>
                </li>
                <li>
                  <p>
                    Adhering to any localised Security Standing Orders (SSOs) relating to photography that may apply.
                  </p>

                  <p>
                    <b>Note:</b> Some Defence sites and/or specific areas may prohibit photography via localised SSOs.
                    When in doubt, please confirm with your Security Officer prior to taking photographs on your device.
                  </p>
                </li>
                <li>
                  <p>
                    Not using personal mobile devices in areas where Portable Electronic Devices (PEDs) are prohibited;
                  </p>
                </li>

                <li>
                  <p>Securely deleting data from a personal device once it is no longer required;</p>
                </li>

                <li>
                  Immediately reporting any sensitive information that has been captured on a personal device by
                  submitting a&nbsp;
                  <a href="https://formsportal.dpe.protected.mil.au/content/forms-portal/content/searchResult.html?search=XP188">
                    Security Incident Report (XP188);
                  </a>
                </li>
              </ul>
            </Container>
          </Modal.Body>

          <Container className="px-5 pb-6 pt-4">
            <Button
              data-testid="agreeButton"
              className="mobileButton rounded-0 reLoginButton float-end"
              variant="primary"
              onClick={onFileUpload}
            >
              Continue
            </Button>
          </Container>
        </Modal>
      )}
      <>
        <Form onSubmit={(event) => event.preventDefault()} enctype="multipart/form-data">
          <Form.Group controlId="supportingEvidence" className="w-75 mb-4">
            <Form.Label>
              Supporting evidence &nbsp;
              <OverlayTrigger placement="top" delay={{ show: 250, hide: 400 }} overlay={renderTooltip}>
                <InfoCircle size={15} className="d-inline" />
              </OverlayTrigger>
            </Form.Label>

            <p className="fw-light">
              Include supporting documents and/or photos that show damage, fault, or risk if you have them.
            </p>
            <Form.Label>Attachment Category</Form.Label>
            {!isReview ? (
              <>
                <Dropdown className="w-50 upload-item">
                  <Dropdown.Toggle variant="dropdown" className="dropdownText fw-light">
                    {attachmentCategoryInForm.selectedCategory}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="dropDownMenu">
                    {attachmentCategoryInForm.categories.map((item, key) => (
                      <Dropdown.Item
                        key={key}
                        className="fw-light dropDownItem dropdownText"
                        as="button"
                        onClick={(e) => changeCategory(e, item)}
                      >
                        {item.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <label for="file-upload" role="button" className="fileUpload">
                  Add attachment
                </label>
              </>
            ) : (
              <p className="fw-light"> {attachmentCategoryInForm.selectedCategory}</p>
            )}

            {((!isReview &&
              attachmentCategoryInForm.selectedCategory === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST) ||
              attachmentCategoryInForm.selectedCategory === appConstants.CATEGORY_TYPE_NAME_WASTE_MANIFEST) && (
              <>
                <a
                  className="req-detail-default-padding-download"
                  href={DownloadFileUrl.url}
                  download={DownloadFileUrl.fileName}
                >
                  {' '}
                  Click here for {attachmentCategoryInForm.selectedCategory.toLowerCase()} template
                </a>
              </>
            )}
            {showSpinnerApiIsInProgress && <Spinner />}
            {!isReview ? (
              <>
                <p className="file-uploadInfo">
                  <span className="file-upload-note">Note:</span> Please upload document(s) only in
                  {attachmentCategoryInForm.allowedFileExt} formats.The total upload size of all files must not exceed
                  12MB.
                </p>
                <input id="file-upload" type="file" name="content" onChange={onFileChange} multiple />
                {uploadedFile.selectedFile.length > 0 && (
                  <Button
                    variant="primary"
                    className="mobileButton"
                    onClick={addUpload}
                    // onClick={onFileUpload}
                    disabled={uploadedFile.selectedFile.length === 0}
                  >
                    Upload
                  </Button>
                )}
                {uploadedFile.selectedFile.length > 0 && (
                  <SRFilesUploaded
                    uploadedFile={uploadedFile.selectedFile}
                    header="List of files to be uploaded:"
                    ctaCallback={(item) => deleteAttachment(item)}
                    ctaText="Delete"
                  ></SRFilesUploaded>
                )}

                {uploadedFileInPega.selectedFile.length > 0 && (
                  <SRFilesUploaded
                    uploadedFile={uploadedFileInPega.selectedFile}
                    header="Uploaded files:"
                    ctaCallback={(item) => deleteAttachment(item)}
                    ctaText="Delete"
                  ></SRFilesUploaded>
                )}
                {(attachmentCategoryInForm.selectedCategory === appConstants.CATEGORY_TYPE_NAME_TRANSPORT_MANIFEST ||
                  attachmentCategoryInForm.selectedCategory === appConstants.CATEGORY_TYPE_NAME_WASTE_MANIFEST) &&
                  uploadedFileInPega.selectedFile.length === 0 && (
                    <div className="error-msg-attachment">
                      <span className="align-center-error-attachment"></span>
                      <span className="align-center-error-message-attachment">
                        File is mandatory for {attachmentCategoryInForm.selectedCategory}
                      </span>
                    </div>
                  )}
                {fileUploadValiditywithInfo.isInValid &&
                  fileUploadValiditywithInfo.fileInfo.map((item, index) => {
                    if (item.errorMessage.length > 0) {
                      return (
                        <div className="error-msg-attachment" key={index + 'error-msg-upload'}>
                          <span className="align-center-error-attachment"></span>
                          <span className="align-center-error-message-attachment">{item.errorMessage}</span>
                        </div>
                      );
                    }
                  })}
              </>
            ) : (
              <>
                {' '}
                {uploadedFileInPega.selectedFile.length > 0 && (
                  <Card className="file-container-box">
                    <Card.Header>Uploaded files:</Card.Header>
                    <Card.Body className="file-container-card">
                      {uploadedFileInPega.selectedFile.map((item, index) => {
                        return (
                          <Row key={index + 'uploadedfile'} className="req-detail-default-padding">
                            <Col sm={10} className="file-name-left-req-detail">
                              {item.name}
                            </Col>
                            <Col sm={2} className="file-name-center-req-detail">
                              <Button
                                variant="dark"
                                className="mobileButton"
                                //onClick={() => downloadSavedFile(item)}
                                size="sm"
                              >
                                Download
                              </Button>
                            </Col>
                          </Row>
                        );
                      })}
                    </Card.Body>
                  </Card>
                )}
              </>
            )}
          </Form.Group>
        </Form>
      </>
    </>
  );
};

export default FormInputAttachContent;
