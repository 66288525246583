// TODO: Current not functioning but will make it work
import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';

function genHexString(len: number) {
  const hex = '0123456789ABCDEF';
  let output = '';
  for (let i = 0; i < len; ++i) {
    output += hex.charAt(Math.floor(Math.random() * hex.length));
  }
  return output;
}
/*
 * create axios instance
 */
export const axiosInstance = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
});

/*
 * handle request interceptor
 */
axiosInstance.interceptors.request.use(
  (config) => handleConfig(config),
  (error) => Promise.reject(error),
);

/*
 * handle response interceptor
 */
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      logout();
    } else {
      return Promise.reject(error);
    }
  },
);

/**
 * create axios instance with cache interceptor
 */
export const axiosInstanceWithCache = setupCache(
  axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  }),
);

axiosInstanceWithCache.interceptors.request.use(
  (config) => handleConfig(config),
  (error) => Promise.reject(error),
);

axiosInstanceWithCache.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      logout();
    } else {
      return Promise.reject(error);
    }
  },
);

/*
 * logput on getting status 401 from any api
 */
const logout = () => {
  window.location.href = 'https://devpublic.serviceconnect.defence.gov.au/prweb/PRAuth/ServiceConnectAD';
};

/**
 * handle config for get/post
 */
const handleConfig = (config) => {
  config.headers['messageid'] = genHexString(32);
  config.withCredentials = true;

  return config;
};
