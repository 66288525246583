import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { TablePagination } from '../../components/TablePagination';
import { RetrievalObjDetailsHistoryResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertData from '../../lib/convertData';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';

export const RetrievalListHistory = () => {
  const histId = useParams();
  const [data, setData] = useState<any[]>([]);
  const metadata = useSelector(selectCurrentMeta);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [histId]);
  useEffect(() => {
    const fetchHistoryDtls = async (id: string) => {
      try {
        setLoading(true);
        let changeID;
        try {
          changeID = `RetrieveObjectDetailsandHistory(guid'` + Object.values(id)[0] + `')`;
        } catch (e) {
          changeID = id;
        }
        const respData = await axiosInstance.get<RetrievalObjDetailsHistoryResponse>(
          `${getAPIUrl(process.env.REACT_APP_EE_RETRIEVAL_SINGLE)}/${changeID}`,
        );
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
        setTitleMessage(titleMessage);
        setMessages(messages);
        try {
          if (respData.data) {
            const histData = convertData(respData.data) || [];
            setData(histData);
          } else {
            setData([]);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(null, ['No Records Found']);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        } catch (e) {
          setLoading(false);
          setData([]);
          const errorResponse = isAxiosError(e) ? e.response : null;
          const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
            (e as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
          ]);
          setTitleMessage(titleMessage);
          setMessages(messages);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const errorResponse = isAxiosError(error) ? error.response : null;
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
          (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    };
    fetchHistoryDtls(histId as never).catch(console.error);
  }, [histId]);

  const columnHelper = createColumnHelper<{ [key: string]: string }>();

  const columns = [
    columnHelper.accessor('ChangeId', {
      header: () => (metadata['ChangeId'] ? metadata['ChangeId'].LABEL : 'Change ID'),
    }),
    // columnHelper.accessor('HistoryId', {
    //   header: () => (metadata['HistoryId'] ? metadata['HistoryId'].LABEL : 'History ID'),
    // }),
    columnHelper.accessor('EventId', {
      header: () => (metadata['EventId'] ? metadata['EventId'].LABEL : 'Event ID'),
    }),
    columnHelper.accessor('EventName', {
      header: () => (metadata['EventName'] ? metadata['EventName'].LABEL : 'Event Name'),
    }),
    columnHelper.accessor('EventTriggeredBy', {
      header: () => (metadata['EventTriggeredBy'] ? metadata['EventTriggeredBy'].LABEL : 'Event Triggered By'),
    }),
    columnHelper.accessor('EventTriggeredAt', {
      header: () => (metadata['EventTriggeredAt'] ? metadata['EventTriggeredAt'].LABEL : 'Event Triggered At'),
    }),
  ];

  const columnsMobile = [
    columnHelper.accessor('ChangeId', {
      header: () => (metadata['ChangeId'] ? metadata['ChangeId'].LABEL : 'Change ID'),
    }),
    // columnHelper.accessor('HistoryId', {
    //   header: () => (metadata['HistoryId'] ? metadata['HistoryId'].LABEL : 'History ID'),
    // }),
    columnHelper.accessor('EventId', {
      header: () => (metadata['EventId'] ? metadata['EventId'].LABEL : 'Event ID'),
    }),
    columnHelper.accessor('EventName', {
      header: () => (metadata['EventName'] ? metadata['EventName'].LABEL : 'Event Name'),
    }),
    columnHelper.accessor('EventTriggeredAt', {
      header: () => (metadata['EventTriggeredAt'] ? metadata['EventTriggeredAt'].LABEL : 'Event Triggered At'),
    }),
    columnHelper.accessor('EventTriggeredBy', {
      header: () => (metadata['EventTriggeredBy'] ? metadata['EventTriggeredBy'].LABEL : 'Event Triggered By'),
    }),
  ];

  const table = useReactTable({
    data,
    columns: isMobile ? columnsMobile : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: isMobile ? { pagination: { pageSize: 15 } } : { pagination: { pageSize: 30 } },
  });

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Retrieval List History',
      }}
      bannerProps={{
        title: 'Retrieval List History',
      }}
      submenuProps={{
        title: 'Retrieval List History',
        selectedIndex: '3',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
        <SubBanner title="Retrieval List History" />
        <div className="flex overflow-x-auto">
          <table className="w-full flex-shrink-0">
            <thead className="border bg-defence-orange-500 text-left font-normal text-white">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th className="border border-black p-2 text-x0" key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="border text-sm md:text-base">
              {table.getRowModel().rows.map((row) => (
                <>
                  <tr key={row.id} className="border even:bg-slate-200">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="border border-black p-2 text-x0">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </tbody>

            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <div className="h-2" />
        <TablePagination tableInstance={table} />
      </div>
    </BaseLayout>
  );
};
