import { appConstants } from '../../../../helper/client/constant';
import { request } from '../../../../helper/useAxios';
import { Phone } from '../../../../types';

export const updatePhoneNumber = (currentPhone: Phone[], type: 'phone' | 'mobile', number: string) => {
  const data = {
    phone: [
      {
        IsPrimary: 'true',
        Type: 'Work',
        Number: type === 'phone' ? number : currentPhone.find((number) => number.type === 'Work')?.number,
      },
      {
        IsPrimary: 'false',
        Type: 'Mobile',
        Number: type === 'mobile' ? number : currentPhone.find((number) => number.type === 'Mobile')?.number,
      },
    ],
  };
  return request(appConstants.API_USER_PROFILE_DETAILS, 'put', { data });
};

export const updateAnalytics = (value: string) => {
  const data = { allowAnalyticsTracking: value };
  return request(appConstants.API_USER_PROFILE_DETAILS, 'put', { data });
};

export const updateLocation = (currentPhone: Phone[], type, base, startdate?, enddate?) => {
  // This API requires phone data to be sent alongside baseName.
  // Simply providing the phone array from personnel isn't sufficient
  // It must be sent with the keys in PascalCase
  // i.e. isPrimary : "true" => IsPrimary: "true"
  let phone = currentPhone.map((item) => {
    return Object.keys(item).reduce((acc, k) => {
      acc[k[0].toUpperCase() + k.slice(1)] = item[k];
      return acc;
    }, {});
  });
  console.log(startdate, enddate);
  const data = {
    phone,
    [type]: base,
    startdate,
    enddate,
  };

  return request(appConstants.API_USER_PROFILE_DETAILS, 'put', { data });
};
