import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import { isNil } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FieldErrors, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as z from 'zod';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { Banner } from '../../components/Banner';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { MultiSelectInput } from '../../components/form/inputs/multiSelectInput';
import { LOCAL_STORAGE_KEY_PREFIX, ROUTES } from '../../constants';
import { useNavigationContext, usePersistForm, useSessionStorage } from '../../hooks';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import { RetrievalObjDetailsHistoryResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import convertFormData from '../../lib/convertFormData';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { isDate } from '../../lib/date';

const LOCAL_STORAGE_KEY = `${LOCAL_STORAGE_KEY_PREFIX}-retrieval-search`;

export const RetrievalSearch = () => {
  useDocumentTitle(`Retrieval Search`);
  const dispatch = useDispatch();
  const { storedData } = useSessionStorage<FormSchema>(LOCAL_STORAGE_KEY);
  const [loading, setLoading] = useState(false);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);

  const { prevLocation } = useNavigationContext();
  const restoreValues =
    !prevLocation?.pathname ||
    prevLocation?.pathname === ROUTES.RETRIEVAL_SEARCH ||
    prevLocation?.pathname === ROUTES.RETRIEVAL_LIST ||
    prevLocation?.pathname.startsWith(ROUTES.RETRIEVAL_LIST_HISTORY);

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
  }, []);

  const schema = z.object({
    ObjectType: z.object({ code: z.string(), description: z.string() }).array().optional(),
    IndustryPartnerId: z.string().optional(),
    ReadStatus: z.object({ code: z.string(), description: z.string() }).array().optional(),
    AddedAtFrom: z.date().optional(),
    AddedAtTo: z.date().optional(),
  });

  type FormSchema = z.infer<typeof schema>;
  const navigate = useNavigate();

  const defaultValues: FormSchema = {
    ObjectType: [],
    IndustryPartnerId: '',
    ReadStatus: [],
    AddedAtFrom: undefined,
    AddedAtTo: undefined,
  };

  const { handleSubmit, control, watch, setValue } = useForm<FormSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const { clearStoredValues } = usePersistForm<FormSchema>({
    watch,
    localStorageKey: LOCAL_STORAGE_KEY,
  });

  useEffect(() => {
    if (!restoreValues) {
      clearStoredValues();
    }
  }, [restoreValues, clearStoredValues]);

  useEffect(() => {
    if (restoreValues && storedData) {
      Object.keys(storedData).forEach((key) => {
        const data = storedData?.[key] as any;
        if (!isNil(data)) {
          // @ts-ignore
          // const isDate = new Date(data) !== 'Invalid Date' && !isNaN(new Date(data));
          const value = isDate(data) ? new Date(data) : data;
          setValue(key as keyof FormSchema, (value as never) || '');
        }
      });
    }
  }, []);

  const onSubmit = async (data: FormSchema) => {
    const results = data;
    const formDataQuery = convertFormData(results);
    try {
      setLoading(true);
      if (formDataQuery) {
        const responseData = await axiosInstance.get<RetrievalObjDetailsHistoryResponse>(
          `${getAPIUrl(process.env.REACT_APP_EE_RETRIEVAL_LIST)}?$filter=(${formDataQuery})&$format=json`,
        );
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
        setTitleMessage(titleMessage);
        setMessages(messages);
        setLoading(false);
        if (titleMessage !== 'Error') {
          const oData = { data: responseData.data, filter: formDataQuery };
          navigate(ROUTES.RETRIEVAL_LIST, {
            state: { data: oData },
          });
        }
      } else {
        const responseData = await axiosInstance.get<RetrievalObjDetailsHistoryResponse>(
          `${getAPIUrl(process.env.REACT_APP_EE_RETRIEVAL_LIST)}?$format=json`,
        );
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
        setTitleMessage(titleMessage);
        setMessages(messages);
        setLoading(false);
        if (titleMessage !== 'Error') {
          const oData = { data: responseData.data };
          navigate(ROUTES.RETRIEVAL_LIST, {
            state: { data: oData },
          });
        }
      }
    } catch (error) {
      setLoading(false);
      const errorResponse = isAxiosError(error) ? error.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({
            section: element.name,
            code: element.code,
          });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code === selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const sections = [
    {
      code: 1,
      name: 'Search Retrieval',
      fields: ['ObjectType', 'IndustryPartnerId', 'ReadStatus', 'AddedAtFrom', 'AddedAtTo'],
    },
  ];

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <>
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <SubMenu title="Retrieval" selectedIndex="0" />
      <Banner title="Search Retrieval List" description="" bannerImgSrc="" />
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      The <strong>Indicator</strong> field has not been completed. Please update and resubmit.
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}
          {sections.length > 1 && (
            <div className="relative">
              <FormStepper
                onChange={(item: FormStepperItem) => {
                  setStep(item.code);
                }}
                items={sections?.map((section) => ({
                  code: section.code,
                  name: section.name,
                  href: '#',
                  status:
                    step === section.code
                      ? FormStepperEnum.Current
                      : step > section.code
                      ? FormStepperEnum.Complete
                      : FormStepperEnum.Upcoming,
                }))}
              />
            </div>
          )}

          <div className="w-full pt-3">
            <SubBanner title={sections[step - 1].name} />
          </div>
          <div className="w-full">
            <form onSubmit={handleSubmit(onSubmit, goToErrors)}>
              {step === 1 && (
                <div className="mb-4 w-full">
                  <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-1">
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Vendor"
                        fieldPlaceholder="Start typing"
                        name="IndustryPartnerId"
                        control={control}
                        required={false}
                        codeSet={TypeAheadCodeSet.Vendor}
                        codeSetValueField="Vendor"
                        codeSetLabelField="VendorName"
                        top="20"
                      />
                      <MultiSelectInput
                        items={[
                          { code: 'NOTIFICATION', description: 'Notification' },
                          {
                            code: 'WORK ORDER',
                            description: 'Work Order',
                          },
                          {
                            code: 'PURCHASE ORDER',
                            description: 'Purchase Order',
                          },
                          {
                            code: 'SES',
                            description: 'Service Entry Sheets',
                          },
                        ]}
                        fieldLabel="Object Type"
                        fieldPlaceholder=" "
                        name="ObjectType"
                        control={control}
                        required={false}
                        multiple={true}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <DateInput
                        fieldLabel="Date Added From"
                        fieldPlaceholder=" "
                        name="AddedAtFrom"
                        showTime={false}
                        disabled={false}
                        control={control}
                      />
                      <DateInput
                        fieldLabel="Date Added To"
                        fieldPlaceholder=" "
                        name="AddedAtTo"
                        showTime={false}
                        disabled={false}
                        control={control}
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <MultiSelectInput
                        items={[
                          { code: '', description: 'Outstanding' },
                          {
                            code: 'R',
                            description: 'Retrieved',
                          },
                          {
                            code: 'V',
                            description: 'Viewed',
                          },
                          {
                            code: 'D',
                            description: 'Deadline Exceeded',
                          },
                        ]}
                        fieldLabel="Status"
                        fieldPlaceholder=" "
                        name="ReadStatus"
                        control={control}
                        required={false}
                        multiple={true}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="flex w-full justify-end">
                <div className="flex-end flex">
                  {!(step === 1) && (
                    <button
                      type="button"
                      onClick={() => {
                        backStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Back
                    </button>
                  )}
                  {!(step === sections.length) && (
                    <button
                      type="button"
                      onClick={() => {
                        nextStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Next
                    </button>
                  )}
                  <button type="submit" onClick={() => {}} className="button-primary mr-0 bg-defence-orange-500">
                    Submit
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
