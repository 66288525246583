import { Dropdown, Form, Col } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import './SearchDropdown.scss';

const SearchDropdown = (props) => {
  const options = props.options;
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');
  const [error, setError] = useState(false);

  const filterFunc = (e) => {
    return e.name.toLowerCase().includes(value.toLowerCase());
  };
  const handleClick = (e) => {
    setValue(e.name);
    setError(false); // Reset error on valid selection
    props.handleChange(e);
    props.handleChangeText(e.name);
  };
  const filteredForms = value ? options.filter((option) => filterFunc(option)) : options;

  const handleChange = (e) => {
    setValue(e);
    props.handleChangeText(e); //change of text send to parent
    if (e.length === 0) {
      setError(true);
    } else {
      setError(false);
    }
  };

  // useEffect(() => {
  //   setValue(props.selectedValue);
  // }, [props]);

  useEffect(() => {
    setValue(props.selectedValue);
  }, [props.selectedValue]);

  return (
    <Col className="formField ps-0">
      {props.isReview ? (
        <p className="fw-light">{value}</p>
      ) : (
        <Dropdown autoComplete="nope">
          <Dropdown.Toggle
            id={props.id}
            role="combobox"
            data-bs-auto-close="outside"
            className={`dropdown dropdownText fw-light m-0 ${props.error && 'fieldContainer show-error-border'} ${
              error && 'show-error-border'
            }`}
            disabled={props.isReview}
            as={Form.Control}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
          ></Dropdown.Toggle>
          <Dropdown.Menu
            onBlur={() => {
              setOpen(false);
            }}
            className="w-100 dropdownHeight"
          >
            {filteredForms.map((key, idx) => {
              return (
                <Dropdown.Item
                  className="fw-light dropdownText"
                  id={`d-item-${idx}`}
                  key={idx}
                  value={key}
                  onClick={() => handleClick(key)}
                >
                  {key.name}
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </Col>
  );
};

export default SearchDropdown;
