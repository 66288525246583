import React, { lazy, useEffect, useState } from 'react';
import { Container, ThemeProvider } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

//helper and reducer functions
import { appConstants } from './helper/client/constant';
import { getAemUrl } from './helper/getOtherAppURL';
import { getUserType } from './helper/getUserType';
import { handleConsent, initializeMatomo } from './helper/matomoAnalyticsHelper';
import { redirectSSOCurrentURL } from './helper/redirectSSOCurrentURL';
import { request } from './helper/useAxios';
import { setShowError } from './reducers/errorHandling';
import { setNotificationsRead, setInternal, setProfile } from './reducers';

//SR imports
import CreateRequest from './components/MyServiceRequest/CreateRequest/CreateRequest';
import ReviewRequest from './components/MyServiceRequest/CreateRequest/ReviewRequest';
import ThankyouPage from './components/MyServiceRequest/CreateRequest/ThankyouPage';
import EditServiceRequestCreate from './components/MyServiceRequest/EditServiceRequest/EditServiceRequestCreate';
import EditServiceRequestReview from './components/MyServiceRequest/EditServiceRequest/EditServiceRequestReview';
import ViewServiceRequest from './components/MyServiceRequest/ViewServiceRequest/ViewServiceRequest';
import { SRHelpPage, SRLandingPage, SRLayout } from './pages/ServiceRequest';

//Content imports
import content from './pages/Core/All';

//Core imports
import { Footer } from './components/common/Footer/Footer';
import { MainNav } from './components/common/MainNav/MainNav';
import SessionTimeout from './components/LandingPage/SessionTimeout/SessionTimeout';
import { ErrorHandler } from './components/ServiceUI/Core/ErrorHandler/ErrorHandler';
import Heartbeat from './components/ServiceUI/LandingPage/HeartBeat/HeartBeat';
import { HomePage, LearnMorePage, NotificationsPage, OurServicesPage, ProfilePage, SearchPage } from './pages/Core';
import Logout from './pages/Core/Logout';
import Privacy from './pages/Core/Privacy';

//Profile creation imports
import VerifyIdentityLink from './components//ProfileSetUp/Auth-Profile/VerifyIdentityLink';
import ProfileModal from './components/ProfileSetUp/Auth-Profile/AuthProfileModal';
import LocationPreviewModalProps from './components/ProfileSetUp/LocationPreviewModal/__mocks__/default.json';
import LocationPreviewModal from './components/ProfileSetUp/LocationPreviewModal/LocationPreviewModal';
import PrivacyModal from './components/ProfileSetUp/PrivacyModal/PrivacyModal';

//EFT imports
import {
  ApplicantDocsReadyToDownload,
  HelpPage,
  LandingPage,
  Layout,
  NewRequestCompletePage,
  NewRequestPage,
  NewRequestReviewPage,
  NewRequestViewPage,
  ProvideDocumentsCompletePage,
  ProvideDocumentsPage,
} from './pages/EFT';
import { DSAS } from './pages/Consents/Forms/DSAS';
import { DSASCompletePage } from './pages/Consents/CompletePages/DSASCompletePage';

//EEPortal imports
import { BaseApp } from './EEPortal/components/BaseApp';
import { ROUTES as EEPORTAL_ROUTES } from './EEPortal/constants';
import { Home } from './EEPortal/pages/Home';
import {
  NotificationBulkProgressReport,
  NotificationBulkUploadDownload,
  NotificationCreate,
  Notifications,
  NotificationSearch,
  NotificationSearchResults,
  NotificationUpdate,
} from './EEPortal/pages/notifications';
import { Support as EEPortalSupportPage } from './EEPortal/pages/SupportPage';

import {
  BulkConfProgressReport,
  BulkConfUploadDownload,
  BulkOrderProgressReport,
  BulkOrderUploadDownload,
  Confirmation,
  ConfirmationCancel,
  ConfirmationCreate,
  ConfirmationRead,
  ConfirmationSearch,
  ConfirmationSearchResults,
  CreateWorkOrder,
  UpdateWorkOrder,
  WorkOrders,
  WorkOrderSearch,
  WorkOrderSearchResults,
} from './EEPortal/pages/orders';

import {
  BulkSesProgressReport,
  BulkSesUploadDownload,
  CreateServiceEntrySheet,
  ServiceEntrySheet,
  ServiceEntrySheetSearch,
  ServiceEntrySheetSearchResults,
  UpdateServiceEntrySheet,
} from './EEPortal/pages/ses';

import { Retrieval, RetrievalList, RetrievalListHistory, RetrievalSearch } from './EEPortal/pages/retrieval';

import {
  EEOthers,
  FlocBulkProgressReport,
  FlocBulkUploadDownload,
  MeasdocBulkProgressReport,
  MeasdocBulkUploadDownload,
  RfxBulkProgressReport,
  RfxBulkUploadDownload,
  ServiceMasterBulkProgressReport,
  ServiceMasterBulkUploadDownload,
} from './EEPortal/pages/eeother';

// Lazy Imports
const SupportPage = lazy(() => import('./pages/Core/SupportPage'));

const SCRoutes = (props: { cookieFlag; setCameFromProfile; onloadData; updatePersonalData }) => {
  const { cookieFlag, setCameFromProfile, onloadData, updatePersonalData } = props;
  const isInternal = useSelector((state: { isInternal: boolean }) => state.isInternal);
  const hasPMKeyS = !!onloadData.profileData.pmKeyS;
  const hasLinkedDefenceEmail = !!onloadData?.profileData?.myGovIDLink?.defenceIdentity;
  const showEEPortal = hasLinkedDefenceEmail || hasPMKeyS || isInternal;
  return (
    <Routes>
      <Route path="/" element={onloadData.isProfileLoaded ? <HomePage {...onloadData.profileData} /> : <></>} />
      {/* CORE ROUTES */}
      <Route path="/serviceconnect">
        <Route index element={onloadData.isProfileLoaded ? <HomePage {...onloadData.profileData} /> : <></>} />

        <Route
          path="all-services"
          element={onloadData.isProfileLoaded ? <OurServicesPage {...onloadData.profileData} /> : <></>}
        />
        <Route path="notifications" element={<NotificationsPage />} />
        <Route
          path="Profile"
          element={
            onloadData.isProfileLoaded ? (
              <ProfilePage profileData={onloadData.profileData} onComingtoHomePageFromUpdate={updatePersonalData} />
            ) : (
              <></>
            )
          }
        />
        <Route path="support" element={<SupportPage />} />
        <Route path="search" element={<SearchPage />} />
        <Route path="learnMore" element={<LearnMorePage />} />
        <Route path="privacy" element={<Privacy />} />
      </Route>
      <Route path="logout" element={<Logout />} />

      {/* SR ROUTES */}
      <Route path="/my-service-request" element={<SRLayout />}>
        <Route index element={<SRLandingPage myServices={{ ...onloadData.profileData.myServices }} />} />
        <Route path="new-service-request" element={<CreateRequest />} />
        <Route path="review-request/:id" element={<ReviewRequest />} />
        <Route path="view-service-request/:id" element={<ViewServiceRequest />} />
        <Route path="edit-service-request-create/:id" element={<EditServiceRequestCreate />} />
        <Route path="edit-service-request-review/:id" element={<EditServiceRequestReview />} />
        <Route path="help-and-faqs" element={<SRHelpPage myServices={{ ...onloadData.profileData.myServices }} />} />
        <Route path="req-completed" element={<ThankyouPage />} />
      </Route>

      {/* PROFILE CREATION ROUTES */}
      <Route
        path="/profile/"
        element={<ProfileModal isCookieSet={cookieFlag} onComingtoHomePage={() => setCameFromProfile(true)} />}
      />
      <Route
        path="/locationpreview"
        element={
          <LocationPreviewModal {...LocationPreviewModalProps} onComingtoHomePageFromUpdate={updatePersonalData} />
        }
      />
      <Route path="/verifyidentitylink/:code" element={<VerifyIdentityLink />} />
      {/* PERSONAL INFORMATION REQUEST ROUTES*/}
      <Route path="/personal-information-request" element={<Layout />}>
        <Route index element={<LandingPage myServices={{ ...onloadData.profileData.myServices }} />} />
        <Route path="new" element={<NewRequestPage />} />
        <Route path="new/:id" element={<NewRequestPage />} />
        <Route path="review/:id" element={<NewRequestReviewPage />} />
        <Route path="complete/:id" element={<NewRequestCompletePage />} />
        <Route path="view/" element={<NewRequestViewPage />} />
        <Route path="doc-ready/:id" element={<ApplicantDocsReadyToDownload />} />
        <Route path="provide-documents">
          <Route path=":id" element={<ProvideDocumentsPage />} />
          <Route path="complete/:id" element={<ProvideDocumentsCompletePage />} />
        </Route>
        <Route path="help-and-faqs" element={<HelpPage myServices={{ ...onloadData.profileData.myServices }} />} />
      </Route>
      {/* CONSENT MANAGEMENT ROUTES*/}
      <Route path="/new-consent">
        <Route path="DSAS" element={<DSAS profileData={onloadData.profileData} />} />
        <Route path="DSAS/complete" element={<DSASCompletePage />} />
      </Route>
      {/* <!-- start of EE portal route --> */}

      <Route
        path={EEPORTAL_ROUTES.HOME}
        element={
          onloadData.isProfileLoaded ? showEEPortal ? <BaseApp /> : <Navigate replace to="/serviceconnect" /> : <></>
        }
      >
        <Route index element={<Home />} />
        <Route path={EEPORTAL_ROUTES.NOTIFICATIONS} element={<Notifications />} />
        <Route path={EEPORTAL_ROUTES.NOTIFICATIONS_SEARCH} element={<NotificationSearch />} />
        <Route path={EEPORTAL_ROUTES.NOTIFICATIONS_SEARCH_RESULTS} element={<NotificationSearchResults />} />
        <Route path={EEPORTAL_ROUTES.NOTIFICATIONS_UPDATE} element={<NotificationUpdate />} />
        <Route path={EEPORTAL_ROUTES.NOTIFICATIONS_CREATE} element={<NotificationCreate />} />
        <Route path={EEPORTAL_ROUTES.NOTIFICATIONS_BULK_UPLOAD_DOWNLOAD} element={<NotificationBulkUploadDownload />} />
        <Route path={EEPORTAL_ROUTES.NOTIFICATIONS_BULK_PROGRESS_REPORT} element={<NotificationBulkProgressReport />} />
        <Route path={EEPORTAL_ROUTES.WORK_ORDERS} element={<WorkOrders />} />
        <Route path={EEPORTAL_ROUTES.WORK_ORDERS_SEARCH} element={<WorkOrderSearch />} />
        <Route path={EEPORTAL_ROUTES.WORK_ORDERS_SEARCH_RESULTS} element={<WorkOrderSearchResults />} />
        <Route path={EEPORTAL_ROUTES.WORK_ORDERS_UPDATE} element={<UpdateWorkOrder />} />
        <Route path={EEPORTAL_ROUTES.WORK_ORDERS_CREATE} element={<CreateWorkOrder />} />
        <Route path={EEPORTAL_ROUTES.WORK_ORDERS_BULK_UPLOAD_DOWNLOAD} element={<BulkOrderUploadDownload />} />
        <Route path={EEPORTAL_ROUTES.WORK_ORDERS_BULK_PROGRESS_REPORT} element={<BulkOrderProgressReport />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS} element={<Confirmation />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS_SEARCH} element={<ConfirmationSearch />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS_SEARCH_RESULTS} element={<ConfirmationSearchResults />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS_CREATE} element={<ConfirmationCreate />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS_CANCEL} element={<ConfirmationCancel />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS_READ} element={<ConfirmationRead />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS_BULK_UPLOAD_DOWNLOAD} element={<BulkConfUploadDownload />} />
        <Route path={EEPORTAL_ROUTES.CONFIRMATIONS_BULK_PROGRESS_REPORT} element={<BulkConfProgressReport />} />
        <Route path={EEPORTAL_ROUTES.SES} element={<ServiceEntrySheet />} />
        <Route path={EEPORTAL_ROUTES.SES_SEARCH} element={<ServiceEntrySheetSearch />} />
        <Route path={EEPORTAL_ROUTES.SES_SEARCH_RESULTS} element={<ServiceEntrySheetSearchResults />} />
        <Route path={EEPORTAL_ROUTES.SES_CREATE} element={<CreateServiceEntrySheet />} />
        <Route path={EEPORTAL_ROUTES.SES_BULK_UPLOAD_DOWNLOAD} element={<BulkSesUploadDownload />} />
        <Route path={EEPORTAL_ROUTES.SES_BULK_PROGRESS_REPORT} element={<BulkSesProgressReport />} />
        <Route path={EEPORTAL_ROUTES.SES_UPDATE} element={<UpdateServiceEntrySheet />} />

        <Route path={EEPORTAL_ROUTES.OTHER} element={<EEOthers />} />
        <Route path={EEPORTAL_ROUTES.RETRIEVAL} element={<Retrieval />} />
        <Route path={EEPORTAL_ROUTES.RETRIEVAL_SEARCH} element={<RetrievalSearch />} />
        <Route path={EEPORTAL_ROUTES.RETRIEVAL_LIST} element={<RetrievalList />} />
        <Route path={EEPORTAL_ROUTES.RETRIEVAL_LIST_HISTORY} element={<RetrievalListHistory />} />
        <Route path={EEPORTAL_ROUTES.MEASDOC_BULK_UPLOAD_DOWNLOAD} element={<MeasdocBulkUploadDownload />} />
        <Route path={EEPORTAL_ROUTES.MEASDOC_BULK_PROGRESS_REPORT} element={<MeasdocBulkProgressReport />} />
        <Route path={EEPORTAL_ROUTES.RFX_BULK_PROGRESS_REPORT} element={<RfxBulkProgressReport />} />
        <Route path={EEPORTAL_ROUTES.RFX_BULK_UPLOAD_DOWNLOAD} element={<RfxBulkUploadDownload />} />
        <Route path={EEPORTAL_ROUTES.FLOC_BULK_PROGRESS_REPORT} element={<FlocBulkProgressReport />} />
        <Route path={EEPORTAL_ROUTES.FLOC_BULK_UPLOAD_DOWNLOAD} element={<FlocBulkUploadDownload />} />
        <Route
          path={EEPORTAL_ROUTES.SERVICEMASTER_BULK_PROGRESS_REPORT}
          element={<ServiceMasterBulkProgressReport />}
        />
        <Route
          path={EEPORTAL_ROUTES.SERVICEMASTER_BULK_UPLOAD_DOWNLOAD}
          element={<ServiceMasterBulkUploadDownload />}
        />
        <Route path={EEPORTAL_ROUTES.SUPPORT} element={<EEPortalSupportPage />} />
      </Route>
    </Routes>
  );
};

const App = () => {
  let location = useLocation();
  const dispatch = useDispatch();

  const [isAuthorised, setisAuthorised] = useState(false);
  const [cameFromProfile, setcameFromProfile] = useState(false);
  const [consentGiven, setConsentGiven] = useState<boolean>();
  const [cookieFlag, setCookieFlag] = useState(false);

  const [onloadData, setOnLoadData] = useState<any>({
    isProfileLoaded: false,
    profileData: { pmKeyS: '' },
    baseDetails: { baseName: '', baseAddress: '' },
    workBaseDetails: { baseName: '', baseAddress: '' },
    alternateBaseDetails: { baseName: '', baseAddress: '', start: '', end: '' },
    isShowLocationModal: false,
    isprofileUpdated: null,
    isShowPrivacyModel: false,
  });

  const updateOnLoadStateDetails = (res) => {
    const { data } = res;
    const {
      location,
      workingLocation,
      alternateBaseLocation,
      AllowAnalyticsTracking,
      isLocationConfirmed,
      smsConsent,
      email,
      myServices,
    } = data;
    dispatch(setProfile(data));

    const isPorod = email[0].address.split('@')[1]?.includes('defence.gov.au');

    setOnLoadData((prevState) => ({
      ...prevState,
      isProfileLoaded: true,
      profileData: data,
      baseDetails: {
        baseName: location?.name || '',
        baseAddress: location?.fullAddress || '',
      },
      workBaseDetails: {
        baseName: workingLocation?.name || '',
        baseAddress: workingLocation?.fullAddress || '',
      },
      alternateBaseDetails: {
        baseName: alternateBaseLocation?.name || '',
        baseAddress: alternateBaseLocation?.fullAddress || '',
        start: alternateBaseLocation?.alternateLocStartDate || '',
        end: alternateBaseLocation?.alternateLocEndDate || '',
      },
      isShowLocationModal:
        (AllowAnalyticsTracking !== '' && !isLocationConfirmed) ||
        (myServices?.hasSMSEnabled && isPorod && smsConsent === null),
      isShowPrivacyModel: AllowAnalyticsTracking === '',
    }));
  };

  const axiosGet = async (url) => {
    try {
      const response = await request(url, 'get');
      return response;
    } catch (error) {
      throw error;
    }
  };

  const renderAuthToken = async () => {
    try {
      // Check if ENV is local and set dummy cookie
      if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV) {
        await axiosGet('/setdummyCookie');
      }
      // Call Heart Beat API and get user profile details
      await axiosGet(appConstants.API_HEART_BEAT);
      setisAuthorised(true);
      const res = await axiosGet(appConstants.API_USER_PROFILE_DETAILS);
      updateOnLoadStateDetails(res);
      // If AllowAnalyticsTracking has a value, set it to consentGiven
      if (res.data.AllowAnalyticsTracking !== undefined) {
        setConsentGiven(res.data.AllowAnalyticsTracking);
      }
    } catch (error: any) {
      error.response = error.response || { response: { data: [] } };
      if (error.response.status === 403 || error.response.status === 401) {
        setisAuthorised(false);
        redirectSSOCurrentURL();
      } else {
        dispatch(setShowError({ hasError: true, error }));
      }
    }
  };

  const renderAuthTokenProfileSetup = async () => {
    try {
      if (process.env.REACT_APP_CURRENT === appConstants.REACT_APP_LOCAL_ENV) {
        await axiosGet('/profile/setdummyCookieProfileSetup');
      }
      await axiosGet(appConstants.API_HEART_BEAT_DOD);
      setisAuthorised(true);
      setCookieFlag(true);
    } catch (error: any) {
      error.response = error.response || { response: { data: [] } };
      if (error.response.status === 403 || error.response.status === 401) {
        setisAuthorised(false);
        redirectSSOCurrentURL();
      } else {
        dispatch(setShowError({ hasError: true, error }));
      }
    }
  };
  const fetchDataAndUpdateState = async () => {
    try {
      const response = await axiosGet(appConstants.API_USER_PROFILE_DETAILS);
      updateOnLoadStateDetails(response);
    } catch (error: any) {
      dispatch(setShowError({ hasError: true, error }));
    }
  };

  const GetUpdatedUserProfile = async () => {
    try {
      const res = await axiosGet(appConstants.API_USER_PROFILE_DETAILS);
      setOnLoadData((prevState) => {
        return {
          ...prevState,
          isprofileUpdated: false,
          profileData: res.data,
        };
      });
    } catch (error: any) {
      dispatch(setShowError({ hasError: true, error }));
    }
  };

  // After GetUpdatedUserProfile is called check if AllowAnalyticsTracking has changed and if so update consentGiven
  useEffect(() => {
    if (onloadData.profileData.AllowAnalyticsTracking !== undefined) {
      setConsentGiven(onloadData.profileData.AllowAnalyticsTracking);
    }
  }, [onloadData.profileData.AllowAnalyticsTracking]);

  const updatePersonalData = () => {
    setOnLoadData((prevState) => {
      return {
        ...prevState,
        isprofileUpdated: true,
      };
    });
  };

  const getNotificationCount = async () => {
    let total: number = 0;
    await request(appConstants.API_NOTIFICATION_LIST, 'get', { id: '/count' })
      .then((res) => (total += res.data.notificationCount))
      .catch(() => {
        return;
      });
    await request(`${getAemUrl}${appConstants.API_MYACTIONS}`, 'get', { isAEM: true })
      .then((res) => (total += res.data.formsToReview.length))
      .catch(() => {
        return;
      });
    dispatch(setNotificationsRead(total));
  };

  useEffect(() => {
    if (location.pathname === appConstants.SR_PROFILE) {
      renderAuthTokenProfileSetup();
    } else {
      if (location.pathname !== appConstants.SR_VERIFY_LINK) {
        renderAuthToken();
      }
    }
  }, [cameFromProfile]);

  useEffect(() => {
    initializeMatomo();
  }, []);

  useEffect(() => {
    handleConsent(consentGiven);
  }, [consentGiven]);

  useEffect(() => {
    isAuthorised === true && !(location.pathname === appConstants.SR_PROFILE) && getNotificationCount();
    const isInternal = getUserType();
    dispatch(setInternal(isInternal));
  }, [isAuthorised]);

  useEffect(() => {
    // Scroll to id on page load if there is a hash is in the url
    // Otherwise scroll to the top of the window on new pages
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) elem.scrollIntoView({ behavior: 'smooth' });
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);

  useEffect(() => {
    if (onloadData.isprofileUpdated !== null && onloadData.isprofileUpdated) {
      GetUpdatedUserProfile();
    }
  }, [onloadData.isprofileUpdated]);

  return (
    <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
      <ErrorHandler />
      {onloadData.isShowPrivacyModel && (
        // @ts-ignore
        <PrivacyModal onPrivacyFlagUpdated={fetchDataAndUpdateState} />
      )}
      {onloadData.isShowLocationModal && (
        <LocationPreviewModal
          {...LocationPreviewModalProps}
          onComingtoHomePageFromUpdate={() => {
            updatePersonalData();
            fetchDataAndUpdateState();
          }}
          baseName={onloadData.baseDetails.baseName}
          baseAddress={onloadData.baseDetails.baseAddress}
          alternateBaseName={onloadData.alternateBaseDetails.baseName}
          alternateBaseAddress={onloadData.alternateBaseDetails.baseAddress}
          alternateBaseStart={onloadData.alternateBaseDetails.start}
          alternateBaseEnd={onloadData.alternateBaseDetails.end}
          workBaseName={onloadData.workBaseDetails.baseName}
          workBaseAddress={onloadData.workBaseDetails.baseAddress}
        />
      )}
      <SessionTimeout idleTime={720} timeUntilRedirect={180} />
      <Heartbeat interval={600000} /> {/* Heartbeat every 10 minutes */}
      {location.pathname !== appConstants.SR_VERIFY_LINK &&
        !location.pathname.includes(EEPORTAL_ROUTES.HOME) &&
        isAuthorised && <MainNav {...content.MainNav} />}
      {location.pathname !== appConstants.SR_VERIFY_LINK &&
        location.pathname.includes(EEPORTAL_ROUTES.HOME) &&
        isAuthorised && <MainNav {...content.EEPortalMainNav} />}
      <Container className="content">
        {isAuthorised && (
          <SCRoutes
            cookieFlag={cookieFlag}
            setCameFromProfile={setcameFromProfile}
            onloadData={onloadData}
            updatePersonalData={updatePersonalData}
          />
        )}
      </Container>
      {location.pathname !== appConstants.SR_VERIFY_LINK &&
        !location.pathname.includes(EEPORTAL_ROUTES.HOME) &&
        isAuthorised && <Footer {...content.Footer} myServices={{ ...onloadData.profileData.myServices }} />}
      {location.pathname !== appConstants.SR_VERIFY_LINK &&
        location.pathname.includes(EEPORTAL_ROUTES.HOME) &&
        isAuthorised && <Footer {...content.EEPortalFooter} myServices={{ ...onloadData.profileData.myServices }} />}
    </ThemeProvider>
  );
};

export default App;
