import { InfoCircledIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useController } from 'react-hook-form';
import { Tooltip } from '../../../../components/ServiceUI/Core/Tooltip/Tooltip';

export const TextAreaInput = ({
  fieldLabel,
  fieldPlaceholder,
  name,
  control,
  required = false,
  disabled = false,
  // defaultValue,
  tooltip,
}: {
  fieldLabel: string;
  fieldPlaceholder: string;
  name: string;
  control: any;
  required?: boolean;
  disabled?: boolean;
  // defaultValue?: string;
  tooltip?: string;
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, control });
  return (
    <>
      <div className="grid grid-cols-1">
        <label htmlFor="textInput" className="mb-2 mt-2 flex text-x0">
          {fieldLabel} {required && <p className="m-0 inline  text-defence-redAlert"> *</p>}
          <div className="ml-1 mt-1">{tooltip && <Tooltip content={tooltip} placement="top" />}</div>
        </label>
        <textarea
          disabled={disabled}
          className={`inputTextArea flex w-full items-center rounded-sm border border-defence-light-grey-500 py-1.5 pl-2 text-x0 font-light text-defence-black transition-all duration-200 ease-in placeholder:text-defence-light-grey-900 focus-within:outline-black focus:border focus:border-defence-blueGrey focus:bg-white focus-visible:outline disabled:bg-defence-light-grey-200  
            ${error ? `inputError border-defencered` : `inputClean border-defencemediumgrey`}`}
          placeholder={fieldPlaceholder ? fieldPlaceholder : ' '}
          {...field}
          // onChange={(event) => {
          //   field.onChange(event.target.value === '' ? undefined : event.target.value);
          // }}
          // value={defaultValue}
        />
        <div className="h-4 text-defence-redAlert">
          {error && error?.type === 'too_big' && (
            <p className="flex pt-1 text-sm text-defence-redAlert">
              <InfoCircledIcon className="flex pt-1" height={16} width={16} />
              Field Length Error.
            </p>
          )}
          {error && error?.type === 'invalid_type' && (
            <p className="flex pt-1 text-sm text-defence-redAlert">
              <InfoCircledIcon className="flex pt-1" height={16} width={16} />
              This field is required.
            </p>
          )}
        </div>
      </div>
    </>
  );
};
