import { ExternalLinkIcon } from '@radix-ui/react-icons';
import React from 'react';
import { BaseLayout } from '../components/BaseLayout';
import { LinkCard } from '../components/LinkCard';
// import { ComingSoonBanner } from "../components/ComingSoonBanner";
// import { FeedbackBanner } from "../components/FeedbackBanner";
import { SubContainer } from '../../components/ServiceUI/Core/Layout';
import { ROUTES } from '../constants';
// import { setCurrentConfig } from "../store/globalConfig/globalConfig.action";

export const Home = () => {
  const quickLinkCards = [
    {
      link: ROUTES.NOTIFICATIONS,
      description: 'Search / Create a service request',
      label: 'Service Requests',
      imageSrc: 'notifications.png',
    },
    {
      link: ROUTES.WORK_ORDERS,
      description: 'Create a new service or maintenance request',
      label: 'Work Orders',
      imageSrc: 'workorders.png',
    },
    {
      link: ROUTES.SES,
      description: 'Search or Create a service entry sheet',
      label: 'Service Entry Sheets',
      imageSrc: 'womenatwork.png',
    },
    {
      link: ROUTES.OTHER,
      description: 'Additional services',
      label: 'Additional Services',
      imageSrc: 'panel_my_base.png',
    },
  ];
  return (
    <BaseLayout
      headProps={{
        title: 'Welcome to Estate Exchange',
      }}
      bannerProps={{
        title: 'Welcome to Estate Exchange',
      }}
      showLandingPageBanner={true}
      showSubmenu={false}
    >
      {/* <ComingSoonBanner
        visible={false}
        title="More services and features are coming soon"
        description="EstateExchange provides a simple, seamless way to access digital services across Defence, currently offering online access to forms, base information and base service requests. New services and features will continue to be built into ServiceConnect over time."
        url="www.google.com"
      /> */}
      <SubContainer>
        <p className="w-fit p-4 px-2 text-xl"></p>
        <div className="grid grid-cols-1 gap-2 gap-2 px-2 sm:grid-cols-2 sm:gap-4 sm:px-4 md:my-4 md:grid-cols-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
          {quickLinkCards.map((card) => {
            return (
              <LinkCard
                key={card.link}
                showIcon={false}
                icon={<ExternalLinkIcon />}
                imageSrc={card.imageSrc}
                linkText={card.label}
                link={card.link}
                description={card.description}
                label={card.label}
              />
            );
          })}
        </div>
      </SubContainer>
      {/* <FeedbackBanner
        visible={false}
        title="Please tell us what you need from Estate Exchange"
        description=" ServiceConnect
        Through ServiceConnect, Defence aims to make accessing services and information simple and seamless. Please let us know if we are meeting this goal and what we can do to improve your experience. If you require immediate assistance, have further enquiries, or are experiencing technical issues please contact 1800DEFENCE (1800 333 362)"
        url="www.google.com"
      /> */}
    </BaseLayout>
  );
};
