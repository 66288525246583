import React from 'react';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../constants';
import { SubMenuDropDown } from './SubMenuDropdown';

export interface SubMenuProps {
  title: string;
  selectedIndex?: string;
}

export const SubMenu = ({ title = '', selectedIndex }: SubMenuProps) => {
  const notifications = [
    {
      link: ROUTES.NOTIFICATIONS_SEARCH,
      description: 'Search or display an existing service or maintenance request.',
      label: 'Search',
    },
    {
      link: ROUTES.NOTIFICATIONS_CREATE,
      description: 'Create a new service or maintenance request.',
      label: 'Create',
    },
    {
      link: ROUTES.NOTIFICATIONS_BULK_UPLOAD_DOWNLOAD,
      description: 'Service Requests',
      label: 'Bulk Upload/Download',
    },
    {
      link: ROUTES.NOTIFICATIONS_BULK_PROGRESS_REPORT,
      description: 'Service Requests',
      label: 'Bulk Progress Report',
    },
  ];

  const orders = [
    {
      link: ROUTES.WORK_ORDERS_SEARCH,
      description: 'Search or update an existing work order.',
      label: 'Search',
    },
    {
      link: ROUTES.WORK_ORDERS_CREATE,
      description: 'Create a new work order.',
      label: 'Create',
    },
    {
      link: ROUTES.WORK_ORDERS_BULK_UPLOAD_DOWNLOAD,
      description: 'Work Orders',
      label: 'Bulk Upload/Download',
    },
    {
      link: ROUTES.WORK_ORDERS_BULK_PROGRESS_REPORT,
      description: 'Work Orders',
      label: 'Bulk Progress Report',
    },
    {
      link: ROUTES.CONFIRMATIONS,
      description: 'Confirmations',
      label: 'Confirmations',
    },
  ];

  const serviceEntry = [
    {
      link: ROUTES.SES_SEARCH,
      description: 'Search or display an existing service entry sheet.',
      label: 'Search',
    },
    {
      link: ROUTES.SES_CREATE,
      description: 'Create a new service entry sheet.',
      label: 'Create',
    },
    {
      link: ROUTES.SES_BULK_UPLOAD_DOWNLOAD,
      description: 'Service Entry Sheets',
      label: 'Bulk Upload/Download',
    },
    {
      link: ROUTES.SES_BULK_PROGRESS_REPORT,
      description: 'Service Entry Sheets',
      label: 'Bulk Progress Report',
    },
  ];

  const additionalServices = [
    {
      link: ROUTES.RETRIEVAL_SEARCH,
      description: 'Search Retrieval',
      label: 'Retrieval List',
    },
    {
      link: ROUTES.MEASDOC_BULK_UPLOAD_DOWNLOAD,
      key: 'meas-bulk-download-upload',
      label: 'Measurement Document Bulk Upload Download',
    },
    {
      link: ROUTES.MEASDOC_BULK_PROGRESS_REPORT,
      key: 'meas-bulk-progress-report',
      label: 'Measurement Document Bulk Progress Report',
    },
    {
      link: ROUTES.RFX_BULK_UPLOAD_DOWNLOAD,
      key: 'rfx-bulk-download-upload',
      label: 'RFX Bulk Upload',
    },
    {
      link: ROUTES.RFX_BULK_PROGRESS_REPORT,
      key: 'rfx-bulk-progress-report',
      label: 'RFX Bulk Progress Report',
    },
    {
      link: ROUTES.FLOC_BULK_UPLOAD_DOWNLOAD,
      key: 'floc-bulk-download-upload',
      label: 'Functional Location Bulk Download',
    },
    {
      link: ROUTES.FLOC_BULK_PROGRESS_REPORT,
      key: 'floc-bulk-progress-report',
      label: 'Functional Location Bulk Progress Report',
    },
    {
      link: ROUTES.SERVICEMASTER_BULK_UPLOAD_DOWNLOAD,
      key: 'servicemaster-bulk-download-upload',
      label: 'Service Master? Bulk Download',
    },
    {
      link: ROUTES.SERVICEMASTER_BULK_PROGRESS_REPORT,
      key: 'servicemaster-bulk-progress-report',
      label: 'Service Master? Bulk Progress Report',
    },
  ];

  let list_menu: any[] = [];
  let forceSubMenuDropDown = false;
  switch (title) {
    case 'Service Requests':
      list_menu = notifications;
      break;
    case 'Work Orders':
      list_menu = orders;
      break;
    case 'Service Entry Sheets':
      list_menu = serviceEntry;
      break;
    case 'Additional Services':
      list_menu = additionalServices;
      forceSubMenuDropDown = true;
      break;
  }

  return (
    <div>
      <nav className="flex bg-white ">
        <div className="m-auto w-full sm:w-full md:w-11/12 lg:w-10/12 xl:w-9/12">
          <div className="hidden w-full grid-cols-6 place-content-center lg:grid">
            <div className="justify-left flex h-20 w-56 flex-row content-center items-center text-2xl font-medium">
              {title}
            </div>
            <div className="col-span-5 flex justify-end">
              {forceSubMenuDropDown ? (
                <SubMenuDropDown menu={list_menu} selectedIndex={selectedIndex} />
              ) : (
                list_menu.map((menu: any, index: number) => {
                  return (
                    <NavLink
                      key={index}
                      to={menu.link}
                      className={({ isActive }) =>
                        `flex h-20 flex-row content-center items-center justify-center px-5 text-x0 ${
                          isActive ? `bg-defence-charcoal-light font-bold text-white` : `font-light`
                        }`
                      }
                    >
                      {menu.label}
                    </NavLink>
                  );
                })
              )}
            </div>
          </div>

          <div className="grid w-full grid-cols-2 place-content-center lg:hidden">
            <div className="flex h-16 flex-row content-center items-center justify-center text-2xl font-bold">
              {title}
            </div>
            <div className="flex justify-end px-8">
              <SubMenuDropDown menu={list_menu} selectedIndex={selectedIndex} />
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};
