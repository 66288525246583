import React from 'react';
import { Link } from '@chakra-ui/react';
import Edit from '../../Core/icons/Outline/Edit';
export interface EditButtonProps {
  text: string;
  onClick(): void;
}

export const EditButton: React.FC<EditButtonProps> = (props: EditButtonProps) => {
  return (
    <Link
      role="button"
      color="orange.standard"
      onClick={props.onClick}
      display="flex"
      alignItems="center"
      gap={1}
      w="min-content"
    >
      {props.text} <Edit height="16" />
    </Link>
  );
};
