import React, { useEffect, useState } from 'react';
import { Text, GridItem, Box, Flex, Heading, Button } from '@chakra-ui/react';
import { KeyAndValue } from './KeyAndValue';
import { EditButton } from './EditButton';
import { Tooltip } from '../../Core/Tooltip/Tooltip';
import { SCForm } from '../../Core/FormElements';
import { Option } from '../../Core/FormElements/FormElementType';
import { updateLocation } from './EditRequests';
import { useSelector } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';

interface LocationSectionProps {
  _key: string;
  heading: string;
  state: string;
  name: string;
  fullAddress: string;
  editMode: boolean;
  edit(): void;
  cancel(): void;
  update(): void;
  editConfig: { heading: string; subHeading; tooltip };
  dropdownItems: Option[];
}

export const LocationSection: React.FC<LocationSectionProps> = ({
  _key,
  heading,
  state,
  name,
  fullAddress,
  editMode,
  edit,
  cancel,
  editConfig,
  dropdownItems,
  update,
}) => {
  const [dropdownValue, setDropdownValue] = useState<string>(name);
  const currentPhone = useSelector((state: { profile }) => state.profile.phone);
  const altBaseDates = useSelector((state: { profile }) => [
    state.profile.alternateLocStartDate,
    state.profile.alternateLocEndDate,
  ]);
  const [isInvalid, setIsInvalid] = useState<boolean>(true);
  const [startdate, setStartDate] = useState(altBaseDates[0]);
  const [enddate, setEndDate] = useState(altBaseDates[1]);

  useEffect(() => {
    setIsInvalid(startdate > enddate);
  }, [startdate, enddate, dropdownValue]);

  const handleUpdate = async (type) => {
    await updateLocation(currentPhone, type, dropdownValue === 'None' ? '' : dropdownValue, startdate, enddate);
    update();
    cancel();
  };
  const methods = useForm();
  return (
    <>
      <Flex as={GridItem} gap={1} colSpan={{ lg: 3, base: 1 }}>
        <Text fontWeight="medium" m={0}>
          {heading}
        </Text>
        <EditButton text="Edit" onClick={edit} />
      </Flex>
      <KeyAndValue _key="state" title="State" value={state} />
      <KeyAndValue _key="baseName" title="Base name" value={name} />
      <KeyAndValue _key="baseAddress" title="Base address" value={fullAddress} />
      {editMode && (
        <FormProvider {...methods}>
          <Box as={GridItem} colSpan={3} width="100%" bg="whispergrey" p="1rem">
            <Flex alignItems="center" gap={1} mb="0.5rem">
              <Heading as="h3" fontSize="sm" fontWeight="medium" m={0}>
                {editConfig.heading}
              </Heading>
              <Tooltip content={editConfig.tooltip} placement="right" />
            </Flex>
            <Text fontSize="xs" mb="1rem">
              {editConfig.subHeading}
            </Text>
            <SCForm.SearchableSelect
              visible
              onSelect={(value) => setDropdownValue(value.label)}
              options={_key === 'homeBase' ? dropdownItems : [{ key: '', value: 'None' }, ...dropdownItems]}
              desktopWidth={6}
              value={dropdownValue}
              placeholder={name}
            />
            {_key === 'alternateBaseName' && (
              <Flex flexDir={{ lg: 'row', base: 'column' }}>
                <SCForm.DateInput
                  reference="startdate"
                  visible
                  desktopWidth={6}
                  mobileWidth={12}
                  value={startdate !== '1970-01-01' ? startdate : ''}
                  onDateBlur={(e) => {
                    setStartDate(e.target.value);
                  }}
                  type="date"
                  isInvalid={startdate > enddate}
                  errorMessage="Start date must not be after the end date"
                />
                <SCForm.DateInput
                  reference="enddate"
                  visible
                  desktopWidth={6}
                  mobileWidth={12}
                  value={enddate !== '1970-01-01' ? enddate : ''}
                  onDateBlur={(e) => {
                    setEndDate(e.target.value);
                  }}
                  type="date"
                  isInvalid={startdate > enddate}
                  errorMessage="Start date must not be after the end date"
                />
              </Flex>
            )}
            <Flex gap={1}>
              <Button variant="tertiary" onClick={cancel}>
                Cancel
              </Button>
              <Button variant="primary" onClick={() => handleUpdate(_key)} isDisabled={isInvalid}>
                Update
              </Button>
            </Flex>
          </Box>
        </FormProvider>
      )}
    </>
  );
};
