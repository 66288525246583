import { DownloadIcon, MagnifyingGlassIcon, ReloadIcon } from '@radix-ui/react-icons';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../components/BaseLayout';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { TablePagination } from '../../components/TablePagination';
import { ROUTES } from '../../constants';
import { useNavigationContext } from '../../hooks';
import { useSessionStorage } from '../../hooks/useStorage';
import { RetrievalObjDetailsHistoryResponse } from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import convertSapData from '../../lib/convertData';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { selectCurrentMeta } from '../../store/metaSes/metaSes.selector';

export const RetrievalList = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState<any[]>([]);
  const metadata = useSelector(selectCurrentMeta);
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { prevLocation } = useNavigationContext();
  const { removeStoredData, storeData, storedData } = useSessionStorage('retrievallist');
  const { storeData: serFilter, storedData: storedFilter } = useSessionStorage('retrievallist-filter');
  let { data: tableData } = location?.state?.data
    ? location.state?.data
    : {
        data: storedData,
      };
  const { filter: formDataQuery } = location?.state?.data || {
    filter: storedFilter,
  };

  useEffect(() => {
    if (storedFilter !== formDataQuery) {
      serFilter(formDataQuery);
    }
  });
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 3 }));
    try {
      if (refresh) {
        tableData = storedData;
        setRefresh(false);
      }
      if (tableData) {
        storeData(tableData);
        if (tableData.d.results.length > 0) {
          setData(convertSapData(tableData));
        } else {
          setData([]);
          const { title: titleMessage, message: messages } = formatMessagesToDisplay(null, ['No Records Found']);
          setTitleMessage(titleMessage);
          setMessages(messages);
        }
      } else if (prevLocation?.pathname && prevLocation?.pathname !== ROUTES.RETRIEVAL_LIST) {
        removeStoredData();
      } else if (storedData) {
        setData(convertSapData(storedData));
      } else {
        setData([]);
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(null, ['No Records Found']);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    } catch (e) {
      setLoading(false);
      setData([]);
      const errorResponse = isAxiosError(e) ? e.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (e as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  }, [tableData, storeData, removeStoredData, prevLocation, storedData, dispatch]);

  const getFile = async (PurchaseOrder) => {
    setLoading(true);
    const PoNum = `('` + PurchaseOrder + `')`;
    try {
      const file = await axiosInstance.get(`${getAPIUrl(process.env.REACT_APP_EE_PO_DOWNLOAD_FILE)}/${PoNum}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(file.data);
      const link = document.createElement('a');
      link.href = url;
      let filename = file.headers['x-filename'];
      if (!filename) {
        filename = file.headers['content-disposition'].split('=')[1] || '';
        filename = filename.replace(/"/g, ''); // trim double
      }
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      const errorResponse = isAxiosError(err) ? err.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const onRefresh = async () => {
    try {
      setLoading(true);
      if (formDataQuery) {
        const responseData = await axiosInstance.get<RetrievalObjDetailsHistoryResponse>(
          `${getAPIUrl(process.env.REACT_APP_EE_RETRIEVAL_LIST)}?$filter=(${formDataQuery})&$format=json`,
        );
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
        setTitleMessage(titleMessage);
        setMessages(messages);
        setLoading(false);
        if (titleMessage !== 'Error') {
          setRefresh(true);
          const tableData = responseData.data;
          if (tableData) {
            storeData(tableData);
            setData(convertSapData(tableData));
          } else {
            setData([]);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(null, ['No Records Found']);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
      } else {
        const responseData = await axiosInstance.get<RetrievalObjDetailsHistoryResponse>(
          `${getAPIUrl(process.env.REACT_APP_EE_RETRIEVAL_LIST)}?$format=json`,
        );
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(responseData);
        setTitleMessage(titleMessage);
        setMessages(messages);
        setLoading(false);
        if (titleMessage !== 'Error') {
          setRefresh(true);
          const tableData = responseData.data;
          if (tableData) {
            storeData(tableData);
            setData(convertSapData(tableData));
          } else {
            setData([]);
            const { title: titleMessage, message: messages } = formatMessagesToDisplay(null, ['No Records Found']);
            setTitleMessage(titleMessage);
            setMessages(messages);
          }
        }
      }
    } catch (error) {
      setLoading(false);
      const errorResponse = isAxiosError(error) ? error.response : null;
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMessage(titleMessage);
      setMessages(messages);
    }
  };

  const columnHelper = createColumnHelper<{ [key: string]: string }>();

  const columnsIp = [
    columnHelper.accessor('ChangeId', {
      header: () => (metadata['ChangeId'] ? metadata['ChangeId'].LABEL : 'Change ID'),
      cell: (info) => (
        <Link
          to={`/eeportal/other/retrieval/search/list/${info.getValue()}/history`}
          className="text flex text-defence-orange-500 underline hover:no-underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('ObjectType', {
      header: () => (metadata['ObjectType'] ? metadata['ObjectType'].LABEL : 'Object Type'),
    }),
    columnHelper.accessor('ObjectId', {
      header: () => (metadata['ObjectId'] ? metadata['ObjectId'].LABEL : 'Object Id'),
      cell: (info) => (
        <>
          {info.row.getValue('ObjectType') === 'SES' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <MagnifyingGlassIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to={`/eeportal/ses/search/results/${info.getValue()}/update`}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
          {info.row.getValue('ObjectType') === 'PURCHASE ORDER' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <DownloadIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to="#"
                  onClick={() => getFile(info.row.getValue('ObjectId'))}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
          {info.row.getValue('ObjectType') === 'NOTIFICATION' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <MagnifyingGlassIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to={`/eeportal/notifications/search/results/${info.getValue()}/update`}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
          {info.row.getValue('ObjectType') === 'WORK ORDER' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <MagnifyingGlassIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to={`/eeportal/orders/search/results/${info.getValue()}/update`}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
        </>
      ),
    }),
    columnHelper.accessor('AddedAt', {
      header: () => (metadata['AddedAt'] ? metadata['AddedAt'].LABEL : 'Added At'),
    }),
    columnHelper.accessor('AddedBy', {
      header: () => (metadata['AddedBy'] ? metadata['AddedBy'].LABEL : 'Added By'),
    }),
    columnHelper.accessor('ExpiresAt', {
      header: () => (metadata['ExpiresAt'] ? metadata['ExpiresAt'].LABEL : 'Expires At'),
    }),
    columnHelper.accessor('ReadStatusText', {
      header: () => (metadata['ReadStatusText'] ? metadata['ReadStatusText'].LABEL : 'Status'),
    }),
  ];

  const columns = [
    columnHelper.accessor('ChangeId', {
      header: () => (metadata['ChangeId'] ? metadata['ChangeId'].LABEL : 'Change ID'),
      cell: (info) => (
        <Link
          to={`/eeportal/other/retrieval/search/list/${info.getValue()}/history`}
          className="text flex text-defence-orange-500 underline hover:no-underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('IndustryPartnerId', {
      header: () => (metadata['IndustryPartnerId'] ? metadata['IndustryPartnerId'].LABEL : 'Vendor ID'),
    }),
    columnHelper.accessor('IndustryPartnerName', {
      header: () => (metadata['IndustryPartnerName'] ? metadata['IndustryPartnerName'].LABEL : 'Vendor Name'),
    }),
    columnHelper.accessor('ObjectType', {
      header: () => (metadata['ObjectType'] ? metadata['ObjectType'].LABEL : 'Object Type'),
    }),
    columnHelper.accessor('ObjectId', {
      header: () => (metadata['ObjectId'] ? metadata['ObjectId'].LABEL : 'Object Id'),
      cell: (info) => (
        <>
          {info.row.getValue('ObjectType') === 'SES' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <MagnifyingGlassIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to={`/eeportal/ses/search/results/${info.getValue()}/update`}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
          {info.row.getValue('ObjectType') === 'PURCHASE ORDER' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <DownloadIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to="#"
                  onClick={() => getFile(info.row.getValue('ObjectId'))}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
          {info.row.getValue('ObjectType') === 'NOTIFICATION' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <MagnifyingGlassIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to={`/eeportal/notifications/search/results/${info.getValue()}/update`}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
          {info.row.getValue('ObjectType') === 'WORK ORDER' && (
            <div className="flex">
              <div className="mt-1 w-8">
                <MagnifyingGlassIcon width={20} height={20} className="text-defence-orange-500" />
              </div>
              <div>
                <Link
                  to={`/eeportal/orders/search/results/${info.getValue()}/update`}
                  className="text flex text-defence-orange-500 underline hover:no-underline"
                >
                  {info.getValue()}
                </Link>
              </div>
            </div>
          )}
        </>
      ),
    }),
    columnHelper.accessor('AddedAt', {
      header: () => (metadata['AddedAt'] ? metadata['AddedAt'].LABEL : 'Added At'),
    }),
    columnHelper.accessor('AddedBy', {
      header: () => (metadata['AddedBy'] ? metadata['AddedBy'].LABEL : 'Added By'),
    }),
    columnHelper.accessor('ExpiresAt', {
      header: () => (metadata['ExpiresAt'] ? metadata['ExpiresAt'].LABEL : 'Expires At'),
    }),
    columnHelper.accessor('ReadStatusText', {
      header: () => (metadata['ReadStatusText'] ? metadata['ReadStatusText'].LABEL : 'Status'),
    }),
    columnHelper.accessor('FirstRetrievedBy', {
      header: () => (metadata['FirstRetrievedBy'] ? metadata['FirstRetrievedBy'].LABEL : 'First Retrieved By'),
    }),
    columnHelper.accessor('FirstRetrievedAt', {
      header: () => (metadata['FirstRetrievedAt'] ? metadata['FirstRetrievedAt'].LABEL : 'First Retrieved At'),
    }),
    columnHelper.accessor('FirstViewedBy', {
      header: () => (metadata['FirstViewedBy'] ? metadata['FirstViewedBy'].LABEL : 'First Viewed By'),
    }),
    columnHelper.accessor('FirstViewedAt', {
      header: () => (metadata['FirstViewedAt'] ? metadata['FirstViewedAt'].LABEL : 'First Viewed At'),
    }),
    columnHelper.accessor('DeadlineExceededAt', {
      header: () => (metadata['DeadlineExceededAt'] ? metadata['DeadlineExceededAt'].LABEL : 'Deadline Exceeded At'),
    }),
  ];
  let isIp = false;
  if (tableData.d.results.length > 0) {
    if (tableData.d.results[0].IpFlag !== '') {
      isIp = true;
    }
  }
  const table = useReactTable({
    data,
    columns: isIp ? columnsIp : columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Retrieval List',
      }}
      bannerProps={{
        title: 'Retrieval List',
      }}
      submenuProps={{
        title: 'Retrieval',
        selectedIndex: '3',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
        <div className="flex w-full">
          <SubBanner title="Retrieval List" />
          <button
            type="button"
            onClick={() => onRefresh()}
            className="m-2 mr-0 flex h-[44px] w-fit rounded-sm border border-defence-orange-500 bg-white p-2 px-4 text-x0 text-defence-orange-500 outline-transparent hover:border-defence-orange-500 hover:bg-defence-orange-600 focus:ring-1 focus:ring-defence-orange-800"
          >
            <ReloadIcon className="m-[5px]" />
            Refresh
          </button>
        </div>
        <div className="flex overflow-x-auto">
          <table className="w-full flex-shrink-0">
            <thead className="border bg-defence-orange-500 text-left font-normal text-white">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th className="border border-black p-2 text-x0" key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="border text-sm md:text-base">
              {table.getRowModel().rows.map((row) => (
                <>
                  <tr key={row.id} className="border even:bg-slate-200">
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id} className="border border-black p-2 text-x0">
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                </>
              ))}
            </tbody>

            <tfoot>
              {table.getFooterGroups().map((footerGroup) => (
                <tr key={footerGroup.id}>
                  {footerGroup.headers.map((header) => (
                    <th key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.footer, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </table>
        </div>
        <div className="h-2" />
        <TablePagination tableInstance={table} />
      </div>
    </BaseLayout>
  );
};
