import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const SubMenuDropDown = ({ menu, selectedIndex }: { menu: any[]; selectedIndex?: string }) => {
  const navigate = useNavigate();
  let selected = -1;
  if (selectedIndex !== '') {
    selected = Number(selectedIndex);
  }
  return (
    <div>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <button aria-label="Customise options" className="">
            <ChevronDownIcon className="h-12 w-12 text-defence-orange-500" />
          </button>
        </DropdownMenu.Trigger>
        <DropdownMenu.Portal>
          <DropdownMenu.Content className="flex h-full w-screen flex-wrap divide-y border-b border-defence-charcoal bg-white">
            {menu.map((menuItem: any, index: number) => {
              return (
                <DropdownMenu.Item
                  key={menuItem.link}
                  className={`flex w-full cursor-pointer flex-wrap p-4 text-x0 hover:bg-defence-light-grey-200 ${
                    index === selected
                      ? `bg-defence-charcoal-light font-medium text-white hover:bg-defence-charcoal-light`
                      : `font-light`
                  }`}
                  onClick={() => {
                    navigate(menuItem.link);
                  }}
                >
                  {menuItem.label}
                </DropdownMenu.Item>
              );
            })}
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};
