import React from 'react';
import { PopoverIcon } from './PopoverIcon';

export const SubBanner = ({ title, info = '' }: { title: string; info?: string }) => {
  return (
    <div className="justify-left mb-2 flex w-full md:my-4">
      <div className={`grid ${info ? `grid-cols-2 ` : `grid-cols-1`}`}>
        <h1 className="w-max text-2xl font-medium">{title}</h1>
        {info && (
          <div className="ml-2 flex">
            <PopoverIcon content={info} />
          </div>
        )}
      </div>
    </div>
  );
};
