import React from 'react';
import FormDateInput from './FormDateInput/FormDateInput';
import {
  ButtonProps,
  CheckboxProps,
  DateProps,
  FilePickerProps,
  FormElement,
  MultiProps,
  RadioProps,
  SelectProps,
  SimpleTextProps,
  TextProps,
} from './FormElementType';
import { FormFilePicker } from './FormFilePicker/FormFilePicker';
import { FormMenu } from './FormMenu/FormMenu';
import Select from './FormSelect/FormSelect';
import TextArea from './FormTextAreaInput/FormTextAreaInput';
import TextAreaSimple from './FormTextAreaInputSimple/FormTextAreaInputSimple';
import Text from './FormTextInput/FormTextInput';
import FormCTA from './FormsCTA/FormsCTA';
import Checkbox from './FormsCheckbox/FormsCheckbox';
import { Multi } from './FormsMulti/FormsMulti';
import Radio from './FormsRadio/FormsRadio';
import { SearchableSelect } from './SearchableSelect/SearchableSelect';

//Creating type for the "Form" component
interface elements {
  Checkbox: React.FC<FormElement & CheckboxProps>;
  Radio: React.FC<FormElement & RadioProps>;
  Text: React.FC<FormElement & TextProps>;
  TextArea: React.FC<FormElement & TextProps>;
  TextAreaSimple: React.FC<FormElement & SimpleTextProps>;
  SearchableSelect: React.FC<FormElement & SelectProps>;
  Select: React.FC<FormElement & SelectProps>;
  DateInput: React.FC<FormElement & DateProps>;
  Button: React.FC<FormElement & ButtonProps>;
  Menu: React.FC<FormElement & SelectProps>;
  Multi: React.FC<FormElement & MultiProps>;
  FilePicker: React.FC<FormElement & FilePickerProps>;
}
type FormType = React.FunctionComponent & elements;

//Form component
const Form: FormType = ({ ...children }): JSX.Element => <>{children}</>;

//Exporting components under one main component ("Form") using dot notation
Form.Checkbox = Checkbox;
Form.Radio = Radio;
Form.Text = Text;
Form.TextArea = TextArea;
Form.TextAreaSimple = TextAreaSimple; /// DONT DELETE ITS FOR IAU USER
Form.Select = Select;
Form.DateInput = FormDateInput;
Form.Button = FormCTA;
Form.Menu = FormMenu;
Form.Multi = Multi;
Form.FilePicker = FormFilePicker;
Form.SearchableSelect = SearchableSelect;

export default Form;
