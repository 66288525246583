import { useCallback, useEffect, useState } from "react";

export function useLocalStorage<T>(
  key: string,
  defaultValue?: never | (() => never)
) {
  return useStorage<T>(key, defaultValue, window.localStorage);
}
export function useSessionStorage<T>(
  key: string,
  defaultValue?: never | (() => never)
) {
  return useStorage<T>(key, defaultValue, window.sessionStorage);
}
function useStorage<T>(
  key: string,
  defaultValue?: never | (() => never),
  storageObject: Storage = window.sessionStorage
) {
  const [value, setValue] = useState<T | undefined>(() => {
    const jsonValue = storageObject.getItem(key);
    if (jsonValue != null) return JSON.parse(jsonValue);
    if (typeof defaultValue === "function") {
      return defaultValue();
    } else {
      return defaultValue;
    }
  });

  useEffect(() => {
    if (value === undefined) return storageObject.removeItem(key);
    storageObject.setItem(key, JSON.stringify(value));
  }, [key, value, storageObject]);

  const remove = useCallback(() => {
    setValue(undefined);
  }, []);

  return { storedData: value, storeData: setValue, removeStoredData: remove };
}
