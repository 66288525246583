/* eslint-disable jsx-a11y/anchor-is-valid */
import { zodResolver } from '@hookform/resolvers/zod';
import { isAxiosError } from 'axios';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as z from 'zod';
import { Spinner } from '../../../../components/ServiceUI/Core/Spinner/Spinner';
import { BaseLayout } from '../../../components/BaseLayout';
import { MessageModal } from '../../../components/MessageModal';
import { InputTypeAhead } from '../../../components/form/inputs/inputTypeAhead';
import { SingleSelectInputCode } from '../../../components/form/inputs/singleSelectInputCode';
import { TextInput } from '../../../components/form/inputs/textInput';
import { MasterDataDownloadReqFlocSetResponse } from '../../../lib/api/generated-types';
import { getAPIUrl } from '../../../lib/api/getAPIUrl';
import { axiosInstance } from '../../../lib/api/instance';
import { TypeAheadCodeSet } from '../../../lib/api/lookup';
import convertFormData from '../../../lib/convertFormData';
import formatMessagesToDisplay from '../../../lib/formatMessagesToDisplay';

export const FlocBulkUploadDownload = () => {
  const [titleMessage, setTitleMessage] = useState('');
  const [messages, setMessages] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  const schema = z
    .object({
      UploadDownload: z.string().optional(),
      EBI: z.string().optional(),
      FuncLoc: z.string().optional(),
      FuncLocCat: z.string().optional(),
      SuperiorFuncLoc: z.string().optional(),
      PlantSection: z.string().optional(),
      UserStatusIn: z.string().optional(),
      UserStatusEx: z.string().optional(),
    })
    .and(
      z.union([
        z.object({
          EBI: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          FuncLoc: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          FuncLocCat: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          SuperiorFuncLoc: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          PlantSection: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          UserStatusIn: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
        z.object({
          UserStatusEx: z
            .string()
            .trim()
            .superRefine((val, ctx) => {
              if (val === '') {
                ctx.addIssue({
                  code: z.ZodIssueCode.custom,
                  message: `super refine triggered.`,
                });
              }
            }),
        }),
      ]),
    );

  type formSchema = z.infer<typeof schema>;

  const defaultValues: formSchema = {
    UploadDownload: 'Download',
    EBI: '',
    FuncLoc: '',
    FuncLocCat: '',
    SuperiorFuncLoc: '',
    PlantSection: '',
    UserStatusIn: '',
    UserStatusEx: '',
  };

  const { handleSubmit, watch, control } = useForm<formSchema>({
    resolver: zodResolver(schema),
    defaultValues: defaultValues,
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onSubmit = async (data: formSchema) => {
    if (data.UploadDownload === 'Download') {
      const formDataQuery = convertFormData(data);
      setLoading(true);
      const url = `${getAPIUrl(process.env.REACT_APP_EE_FLOC_DOWNLOAD_REQ)}?filter=(${formDataQuery})&$format=json`;
      try {
        const response = await axiosInstance.get<MasterDataDownloadReqFlocSetResponse>(url);
        setLoading(false);
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(response, [
          'Request successfully submitted, please check Bulk Progress Report page',
          response.data?.d?.results?.[0]?.RequestId || '',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      } catch (err) {
        setLoading(false);
        const errorResponse = isAxiosError(err) ? err.response : null;
        const { title: titleMessage, message: messages } = formatMessagesToDisplay(errorResponse, [
          (err as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
        ]);
        setTitleMessage(titleMessage);
        setMessages(messages);
      }
    }
  };

  const onMessageModalClose = () => {
    setMessages([]);
  };

  return (
    <BaseLayout
      headProps={{
        title: 'Bulk Download Functional Location',
      }}
      bannerProps={{
        title: 'Bulk Download',
      }}
      submenuProps={{
        title: 'Functional Location',
        selectedIndex: '2',
      }}
    >
      {loading && <Spinner overlay />}
      {messages.length > 0 && titleMessage !== '' && (
        <MessageModal
          title={titleMessage}
          messages={messages}
          open={!!messages.length}
          hidebutton={true}
          onOk={onMessageModalClose}
        ></MessageModal>
      )}
      <div className="flex flex-col justify-center bg-white py-2">
        <div className="m-auto w-11/12 py-2 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4 w-full rounded-md bg-white">
              <div className="grid h-full grid-flow-row grid-cols-1 md:grid-cols-1 md:gap-1">
                {watch('UploadDownload') === 'Download' && (
                  <>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <TextInput
                        fieldLabel="Estate Business Identifier"
                        fieldPlaceholder=" "
                        name="EBI"
                        control={control}
                      />
                      <InputTypeAhead
                        fieldLabel="Functional Location"
                        fieldPlaceholder="Start typing"
                        name="FuncLoc"
                        control={control}
                        codeSet={TypeAheadCodeSet.Floc}
                        codeSetFilterField="Description"
                        codeSetValueField="Floc"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.FlocCat}
                        codeSetLabelField="Description"
                        codeSetValueField="FlocCategory"
                        codeSetFilterField="Description"
                        fieldLabel="Functional Location Category"
                        fieldPlaceholder=" "
                        control={control}
                        name="FuncLocCat"
                      />
                      <InputTypeAhead
                        fieldLabel="Superior Functional Location"
                        fieldPlaceholder="Start typing"
                        name="SuperiorFuncLoc"
                        control={control}
                        codeSet={TypeAheadCodeSet.Floc}
                        codeSetFilterField="Description"
                        codeSetValueField="Floc"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.UserStatus}
                        codeSetLabelField="Description"
                        codeSetValueField="UserStatus"
                        codeSetFilterField="DisplayName"
                        fieldLabel="User Status Inclusive"
                        fieldPlaceholder=" "
                        control={control}
                        name="UserStatusIn"
                        codeDepFilterField="StatusProfile"
                        codeDepFilterVal="ZTECHOBJ"
                      />
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.UserStatus}
                        codeSetLabelField="Description"
                        codeSetValueField="UserStatus"
                        codeSetFilterField="DisplayName"
                        fieldLabel="User Status Exclusive"
                        fieldPlaceholder=" "
                        control={control}
                        name="UserStatusEx"
                        codeDepFilterField="StatusProfile"
                        codeDepFilterVal="ZTECHOBJ"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInputCode
                        codeSet={TypeAheadCodeSet.PlantSection}
                        codeSetLabelField="Description"
                        codeSetValueField="PlantSection"
                        codeSetFilterField="Description"
                        fieldLabel="Plant Section"
                        fieldPlaceholder=" "
                        control={control}
                        name="PlantSection"
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {watch('UploadDownload') === 'Download' && (
              <div className="flex w-full justify-end">
                <button
                  type="submit"
                  onClick={() => {
                    //console.log('getValues() ', getValues());
                  }}
                  className="button-primary mr-0 bg-defence-orange-500"
                >
                  Submit
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </BaseLayout>
  );
};
