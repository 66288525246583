import { Button, Flex, Link } from '@chakra-ui/react';
import React from 'react';

interface ActionButtonsProps {
  isSubmitting: boolean;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({ isSubmitting }) => {
  return (
    <Flex justifyContent="space-between">
      <Button variant="tertiary" as={Link} href="/serviceconnect/Profile">
        Cancel
      </Button>
      <Button variant="primary" type="submit" isLoading={isSubmitting} loadingText="Submitting...">
        Submit
      </Button>
    </Flex>
  );
};
