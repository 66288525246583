import { DownloadIcon, FileTextIcon } from '@radix-ui/react-icons';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { BaseLayout } from '../../components/BaseLayout';
import { LinkCard } from '../../components/LinkCard';
import { ROUTES } from '../../constants';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';

export const EEOthers = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 1 }));
  }, []);
  const quickLinkCards = [
    {
      link: ROUTES.RETRIEVAL,
      description:
        'List of Notifications, Work Orders, Purchase Orders and Service Entry Sheets, created or changed based on event triggers',
      label: 'Need to view Retrieval List?',
      linkText: 'Retrieval',
      icon: <FileTextIcon width={40} height={40} />,
    },
    {
      link: ROUTES.MEASDOC_BULK_UPLOAD_DOWNLOAD,
      description:
        'You can access the Bulk Templates, Search and Display as well as Upload/Download Measurement Document Information.',
      label: 'Need to Upload/Download Measurement Document?',
      linkText: 'Bulk Upload/Download',
      icon: <DownloadIcon width={40} height={40} />,
    },
    {
      link: ROUTES.MEASDOC_BULK_PROGRESS_REPORT,
      description:
        'You can view the Measurement Document Bulk Upload/Download submissions that have been made and their status.',
      label: 'Need to view & monitor bulk progress of Measurement Document?',
      linkText: 'Bulk Progress Report',
      icon: <FileTextIcon width={40} height={40} />,
    },
    {
      link: ROUTES.RFX_BULK_UPLOAD_DOWNLOAD,
      description: 'You can access the Bulk Templates as well as Upload RFX Information.',
      label: 'Need to Upload RFX?',
      linkText: 'Bulk Upload',
      icon: <DownloadIcon width={40} height={40} />,
    },
    {
      link: ROUTES.RFX_BULK_PROGRESS_REPORT,
      description: 'You can view the RFX Bulk Upload submissions that have been made and their status.',
      label: 'Need to view & monitor bulk progress of RFX?',
      linkText: 'Bulk Progress Report',
      icon: <FileTextIcon width={40} height={40} />,
    },
    {
      link: ROUTES.FLOC_BULK_UPLOAD_DOWNLOAD,
      description: 'You can access the Download Functional Location Information.',
      label: 'Need to Download Functional Location?',
      linkText: 'Bulk Download',
      icon: <DownloadIcon width={40} height={40} />,
    },
    {
      link: ROUTES.FLOC_BULK_PROGRESS_REPORT,
      description:
        'You can view the Functional Location Bulk Download submissions that have been made and their status.',
      label: 'Need to view & monitor bulk progress of Functional Location?',
      linkText: 'Bulk Progress Report',
      icon: <FileTextIcon width={40} height={40} />,
    },
    {
      link: ROUTES.SERVICEMASTER_BULK_UPLOAD_DOWNLOAD,
      description: 'You can access the Download Service Master Information.',
      label: 'Need to Download Service Master?',
      linkText: 'Bulk Download',
      icon: <DownloadIcon width={40} height={40} />,
    },
    {
      link: ROUTES.SERVICEMASTER_BULK_PROGRESS_REPORT,
      description: 'You can view the Service Master Bulk Download submissions that have been made and their status.',
      label: 'Need to view & monitor bulk progress of Service Master?',
      linkText: 'Bulk Progress Report',
      icon: <FileTextIcon width={40} height={40} />,
    },
  ];

  return (
    <BaseLayout
      headProps={{
        title: 'Additional Services',
      }}
      bannerProps={{
        title: 'Additional Services',
      }}
      submenuProps={{
        title: 'Additional Services',
      }}
    >
      <div className=" flex w-full">
        <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
          <p className="w-fit p-4 text-xl"></p>
          <div className="grid grid-cols-1 gap-10 px-2 sm:grid-cols-2 sm:px-4 md:my-2 md:grid-cols-2 md:gap-10 lg:grid-cols-3 xl:grid-cols-3">
            {quickLinkCards.map((card) => {
              return (
                <LinkCard
                  key={card.link}
                  showIcon={true}
                  icon={card.icon}
                  imageSrc=""
                  linkText={card.linkText}
                  link={card.link}
                  description={card.description}
                  label={card.label}
                />
              );
            })}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};
