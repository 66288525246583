import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Banner } from '../../components/Banner';
import { SubBanner } from '../../components/SubBanner';
import { SubMenu } from '../../components/SubMenu';
import useDocumentTitle from '../../hooks/useDocumentTitle';
import convertSapData from '../../lib/convertData';
import { setCurrentConfig } from '../../store/globalConfig/globalConfig.action';
import { selectCurrentMeta } from '../../store/metaOrder/metaOrder.selector';
import getOrderColumn from './ReadTableVariant';

import { TablePagination } from '../../components/TablePagination';
import { ROUTES } from '../../constants';
import { useNavigationContext, useSessionStorage } from '../../hooks';
import { WorkOrders } from '../../lib/api/orders';

interface WorkReadTableState {
  data: WorkOrders;
  paramOrderType: string;
}

export const WorkOrderSearchResults = () => {
  useDocumentTitle(`Read Work Orders`);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [orderType, setOrderType] = useState<string>('');
  const metadata = useSelector(selectCurrentMeta);
  const location = useLocation();
  const { prevLocation } = useNavigationContext();

  const { removeStoredData, storeData, storedData } = useSessionStorage<WorkReadTableState>('orders');

  const { data: tableData, paramOrderType } = location?.state
    ? (location.state as WorkReadTableState)
    : {
      data: storedData?.data,
      paramOrderType: storedData?.paramOrderType as string,
    };

  useEffect(() => {
    dispatch(setCurrentConfig({ selectedIndex: 2 }));
    if (tableData) {
      storeData({
        data: tableData,
        paramOrderType,
      });
      const sapdata = convertSapData(tableData);
      setData(sapdata);
      setOrderType(paramOrderType);
    } else if (
      prevLocation?.pathname &&
      prevLocation?.pathname !== ROUTES.WORK_ORDERS_SEARCH_RESULTS &&
      prevLocation?.pathname !== ROUTES.WORK_ORDERS_UPDATE
    ) {
      removeStoredData();
    } else if (storedData) {
      setData(convertSapData(storedData));
    } else {
      setData([]);
    }
  }, [tableData]);

  const columnHelper = createColumnHelper<{ [key: string]: string }>();

  // // use function to get columns based on orderType
  // const columns = getOrderColumn(metadata, orderType);
  // // put the link based on orderType, cant do it in ts file
  // let linkColumn = 0;
  // switch (orderType) {
  //   case 'ZM01':
  //     linkColumn = 2;
  //     break;
  //   case 'ZM02':
  //     linkColumn = 1;
  //     break;
  //   case 'ZM03':
  //     linkColumn = 1;
  //     break;
  //   case 'ZM04':
  //     linkColumn = 2;
  //     break;
  //   case 'ZM05':
  //     linkColumn = 0;
  //     break;
  //   case 'ZM06':
  //     linkColumn = 1;
  //     break;
  // }
  // columns[linkColumn] = columnHelper.accessor('WorkOrderNo', {
  //   header: () => (metadata['WorkOrderNo'] ? metadata['WorkOrderNo'].LABEL : 'Work Order Number'),
  //   cell: (info) => (
  //     <Link
  //       to={`/eeportal/orders/search/results/${info.getValue()}/update`}
  //       className="text flex text-defence-orange-500 underline hover:no-underline"
  //     >
  //       {info.getValue()}
  //     </Link>
  //   ),
  // });


  const columns = [
    columnHelper.accessor('WorkOrderNo', {
      header: () => (metadata['WorkOrderNo'] ? metadata['WorkOrderNo'].LABEL : 'Work Order Number'),
      cell: (info) => (
        <Link
          to={`/eeportal/orders/search/results/${info.getValue()}/update`}
          className="text flex text-defence-orange-500 underline hover:no-underline"
        >
          {info.getValue()}
        </Link>
      ),
    }),
    columnHelper.accessor('Description', {
      header: () => (metadata['Description'] ? metadata['Description'].LABEL : 'Description'),
    }),
    columnHelper.accessor('BasicStartDate', {
      header: () => (metadata['BasicStartDate'] ? metadata['BasicStartDate'].LABEL : 'Basic Start Date'),
    }),
    columnHelper.accessor('BasicEndDate', {
      header: () => (metadata['BasicEndDate'] ? metadata['BasicEndDate'].LABEL : 'Basic End Date'),
    }),
    columnHelper.accessor('MainWorkCenterDesc', {
      header: () => ('Work Centre'),
    }),
    columnHelper.accessor('SystemStatus', {
      header: () => ('System Status'),
    }),
    columnHelper.accessor('FunctionalLocationDesc', {
      header: () => ('Functional Location'),
    }),
    columnHelper.accessor('EquipmentDesc', {
      header: () => ('Equipment'),
    }),
    columnHelper.accessor('SortField', {
      header: () => ('EBI'),
    }),
    columnHelper.accessor('CreatedBy', {
      header: () => 'Created By',
    }),
    columnHelper.accessor('CreatedOn', {
      header: () => 'Created On',
    }),
    columnHelper.accessor('LastChangedBy', {
      header: () => 'Changed By',
    }),
    columnHelper.accessor('LastChangedOn', {
      header: () => 'Changed On',
    }),
    columnHelper.accessor('DefenceGroupName', {
      header: () => ('Defence Group'),
    }),
    columnHelper.accessor('EstCost', {
      header: () => ('Estimate Cost'),
    }),
    columnHelper.accessor('RevisionDesc', {
      header: () => ('Revision'),
    }),
    columnHelper.accessor('MaintenanceTypeDesc', {
      header: () => ('Maintenance Type'),
    }),
    columnHelper.accessor('NotificationNo', {
      header: () => ('Service Request Number'),
    }),
    columnHelper.accessor('Priokx', {
      header: () => ('Priority'),
    }),
  ];

  const table = useReactTable({
    data,
    columns: columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: isMobile ? { pagination: { pageSize: 15 } } : { pagination: { pageSize: 30 } },
  });

  return (
    <>
      <>
        <SubMenu title="Work Orders" selectedIndex="0" />
        <Banner title="Work Order Search Results" description="" bannerImgSrc="" />
        <div className="m-auto w-11/12 pb-8 sm:w-11/12 md:w-11/12 lg:w-10/12 xl:w-9/12">
          <SubBanner title="Work Order Search Results" />
          <div className="p-2">
            <div className="flex overflow-x-auto p-2">
              <table className="w-full flex-shrink-0">
                <thead className="border bg-defence-orange-500 text-left font-normal text-white">
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th className="border border border-black p-2 text-x0" key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.header, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody className="border text-sm md:text-base">
                  {table.getRowModel().rows.map((row) => (
                    <>
                      <tr key={row.id} className="border even:bg-slate-200">
                        {row.getVisibleCells().map((cell) => (
                          <td key={cell.id} className="border border-black p-2 text-x0">
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </td>
                        ))}
                      </tr>
                    </>
                  ))}
                </tbody>

                <tfoot>
                  {table.getFooterGroups().map((footerGroup) => (
                    <tr key={footerGroup.id}>
                      {footerGroup.headers.map((header) => (
                        <th key={header.id}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(header.column.columnDef.footer, header.getContext())}
                        </th>
                      ))}
                    </tr>
                  ))}
                </tfoot>
              </table>
            </div>
            <div className="h-2" />
            <TablePagination tableInstance={table as any} />
          </div>
        </div>
      </>
    </>
  );
};
