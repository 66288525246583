import moment, { Moment } from 'moment';

export const formatODataDate = (odataDate: string): Moment | null => {
  if (!odataDate) {
    return null;
  }
  const timestamp = odataDate.match(/\/Date\((\d+)\)\//)?.[1];
  if (!timestamp) {
    return null;
  }
  return moment(parseInt(timestamp, 10));
};

export const formatODataDateToDate = (odataDate: string): Date | undefined => {
  const dateObj = formatODataDate(odataDate);
  return dateObj?.toDate();
};

export const formatDateToODataDate = (date: Date): string => {
  if (!date) {
    return '';
  }
  const timestamp = date.getTime();
  return `/Date(${timestamp})/`;
};

/**
 * Check if the value is a date, if it is not a number and it is a valid date
 * @param val  The value to check
 * @returns boolean
 */
export const isDate = (val: any) => {
  if (!isNaN(val)) {
    return false;
  }
  // @ts-ignore
  return new Date(val) !== 'Invalid Date' && !isNaN(new Date(val));
};
