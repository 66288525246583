import { Text } from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect, useMemo, useState } from 'react';
import { FieldErrors, SubmitHandler, useForm } from 'react-hook-form';
import { Spinner } from '../../../components/ServiceUI/Core/Spinner/Spinner';
import { DialogModal } from '../../components/Dialog';
import { MessageModal } from '../../components/MessageModal';
import { SubBanner } from '../../components/SubBanner';
import { ErrorBanner } from '../../components/form/ErrorBanner';
import FormStepper, { FormStepperEnum, FormStepperItem } from '../../components/form/Stepper/Stepper';
import { DateInput } from '../../components/form/inputs/DatePicker';
import { FileUpload } from '../../components/form/inputs/FileUpload';
import { InputTypeAhead } from '../../components/form/inputs/inputTypeAhead';
import { ItemBreakdownInput } from '../../components/form/inputs/itemBreakdownInput';
import { SingleSelectInput } from '../../components/form/inputs/singleSelectInput';
import { SingleSelectInputCode } from '../../components/form/inputs/singleSelectInputCode';
import { TextAreaInput } from '../../components/form/inputs/textAreaInput';
import { TextInput } from '../../components/form/inputs/textInput';
import {
  CompleteNotificationResponse,
  InProcessNotificationResponse,
  ResetNotificationDeletionFlagResponse,
  SetNotificationDeletionFlagResponse,
} from '../../lib/api/generated-types';
import { getAPIUrl } from '../../lib/api/getAPIUrl';
import { axiosInstance } from '../../lib/api/instance';
import { TypeAheadCodeSet } from '../../lib/api/lookup';
import formatMessagesToDisplay from '../../lib/formatMessagesToDisplay';
import { TNotificationFormSchema } from './NotificationFormSchema';

import {
  BookmarkIcon,
  CheckboxIcon,
  EyeOpenIcon,
  Pencil2Icon,
  ResetIcon,
  ResumeIcon,
  TrashIcon,
} from '@radix-ui/react-icons';
import { FileInput } from '../../components/form/inputs/FileInput';

export const NotificationForm = ({
  updateForm,
  onSubmit,
  schema,
  defaultValues,
  metadata,
  refreshData,
  onFilesSelected = () => {},
}: {
  updateForm: boolean;
  onSubmit: SubmitHandler<TNotificationFormSchema>;
  onFilesSelected?: (_files: File[]) => void;
  schema: any;
  defaultValues: any;
  metadata: any;
  titleMessage?: string;
  messages?: any[];
  refreshData?: (_id: string) => void;
}) => {
  const [readOnly, setReadOnly] = useState(updateForm);
  const [step, setStep] = useState(1);
  const [errorMessages, setErrorMessages] = useState<any[]>([]);
  const [isComplete, setIsComplete] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');
  const [titleMsg, setTitleMsg] = useState('');
  const [respMsg, setRespMsg] = useState<any[]>([]);
  const [showDate, setShowDate] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);

  const { handleSubmit, control, watch, reset } = useForm<TNotificationFormSchema>({
    resolver: zodResolver(schema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: useMemo(() => {
      return defaultValues;
    }, [defaultValues]),
  });

  const handleFilesSelected = (files: File[]) => {
    setSelectedFiles(files);
    onFilesSelected(files);
  };

  const [loading, setLoading] = useState(false);

  const sections = [
    {
      code: 1,
      name: 'Service Request Details',
      info: 'Info 1',
      fields: [
        'NotificationNo',
        'NotificationType',
        'SystemStatus',
        'UserStatus',
        'NotificationDesc',
        'WorkOrder',
        'ReportedBy',
      ],
    },
    {
      code: 2,
      fields: [
        'FunctLocation',
        'FunctLocationDesc',
        'EquipmentNo',
        'EquipmentDesc',
        'PlannerGroup',
        'MainWorkCentre',
        'MainWorkCentrePlant',
        'CodeGroup',
        'NotificationLongText',
        'NotificationLongTextDisplay',
      ],
      name: 'Job Details',
      info: 'Info 2',
    },
    {
      code: 3,
      fields: [
        'NotificationPriority',
        'RequiredStartDateTime',
        'RequiredEndDateTime',
        'MalfunctionStart',
        'MalfunctionEnd',
        'DurationUom',
        'BreakdownIndicator',
      ],
      name: 'Additional Data',
      info: 'Info 3',
    },
    {
      code: 4,
      fields: [
        'RequestorName',
        'RequestorTelephone',
        'RequestorEmail',
        'RequestorMobile',
        'DefenceGroup',
        'CustomerCostCentre',
        'CustomerWbs',
        'CustomerAsset',
        'CustomerSubAsset',
      ],
      name: 'Customer Details',
      info: 'Info 5',
    },
    {
      code: 5,
      fields: [
        'ContactName',
        'ContactHouseNo',
        'ContactStreet',
        'ContactTelephone',
        'ContactFax',
        'ContactPostalCode',
        'ContactCity',
        'ContactRegion',
        'ContactCountry',
      ],
      name: 'Site Contact Details',
      info: 'Info 4',
    },
    {
      code: 6,
      name: 'File Attachments',
      info: 'Info 6',
    },
  ];

  const handleSubmitForm = async (data: TNotificationFormSchema) => {
    setErrorMessages([]);
    await onSubmit(data);
  };

  const backStep = () => {
    if (step === 1) return;
    setStep(step - 1);
  };

  const nextStep = () => {
    if (step === sections.length) return;
    setStep(step + 1);
  };

  const goToErrors = (errors: FieldErrors) => {
    if (!errors) return;
    const tempErrors: { section: string; code: number }[] = [];
    sections.forEach((element) => {
      element.fields?.forEach((field: string) => {
        if (errors[field]) {
          tempErrors.push({ section: element.name, code: element.code });
        }
      });
    });

    const results = tempErrors.filter(
      (item, index) => index === tempErrors.findIndex((selfItem) => item.code == selfItem.code),
    );
    setErrorMessages([...results]);
  };

  const [onDialogOk, setOnDialogOk] = useState(() => () => {
    alert('Ok clicked');
  });
  const [onDialogCancel, setOnDialogCancel] = useState(() => () => {
    alert('Cancel clicked');
  });

  const showPostponeDialog = () => {
    setShowDialog(true);
    setDialogTitle('Postpone Notification');
    setDialogMessage('This will Postpone the Notification. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnPostpone(defaultValues.NotificationNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
      alert('Cancel clicked');
    });
  };

  const showDelDialog = () => {
    setShowDialog(true);
    setDialogTitle('Delete Notification');
    setDialogMessage('This will set Delete flag to the Notification. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnDeleteNotif(defaultValues.NotificationNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
    });
  };

  const showResetDelDialog = () => {
    setShowDialog(true);
    setDialogTitle('Reset Delete Flag for Notification');
    setDialogMessage('This will reset the Delete flag on the Notification. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnResetDelete(defaultValues.NotificationNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
    });
  };
  const showCompleteDialog = () => {
    setShowDialog(true);
    setDialogTitle('Complete Notification');
    setDialogMessage('This will Complete the Notification. Do you want to proceed?');
    setShowDate(true);
    setOnDialogOk(() => (dialogInput) => {
      setShowDialog(false);
      fnComplete(defaultValues.NotificationNo, dialogInput);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
      setShowDate(false);
    });
  };

  const showInProcessDialog = () => {
    setShowDialog(true);
    setDialogTitle('In Process Notification');
    setDialogMessage('This will set the Notification In Process. Do you want to proceed?');
    setOnDialogOk(() => () => {
      setShowDialog(false);
      fnInProcess(defaultValues.NotificationNo);
    });
    setOnDialogCancel(() => () => {
      setShowDialog(false);
    });
  };

  const fnResetDelete = async (id: string) => {
    try {
      setLoading(true);
      let notifId;
      try {
        notifId = `'` + id + `'`;
      } catch (e) {
        notifId = id;
      }
      const respData = await axiosInstance.post<ResetNotificationDeletionFlagResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_RESET_DEL)}${notifId}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);

      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.NotificationNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnDeleteNotif = async (id: string) => {
    try {
      setLoading(true);
      let notifId;
      try {
        notifId = `'` + id + `'`;
      } catch (e) {
        notifId = id;
      }
      const respData = await axiosInstance.post<SetNotificationDeletionFlagResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_SET_DEL)}${notifId}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);

      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.NotificationNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnPostpone = async (id: string) => {
    try {
      setLoading(true);
      let notifId;
      try {
        notifId = `'` + id + `'`;
      } catch (e) {
        notifId = id;
      }
      const respData = await axiosInstance.post<SetNotificationDeletionFlagResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_SET_DEL)}${notifId}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.NotificationNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnComplete = async (id: string, dateTime: string) => {
    try {
      setLoading(true);
      let notifId;
      try {
        notifId = `'` + id + `'`;
      } catch (e) {
        notifId = id;
      }
      let urlParam = notifId + '/datetime' + `'${dateTime}'`;
      const respData = await axiosInstance.post<CompleteNotificationResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_COMPLETE_NOTIF)}${urlParam}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.NotificationNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const fnInProcess = async (id: string) => {
    try {
      setLoading(true);
      let notifId;
      try {
        notifId = `'` + id + `'`;
      } catch (e) {
        notifId = id;
      }
      const respData = await axiosInstance.post<InProcessNotificationResponse>(
        `${getAPIUrl(process.env.REACT_APP_EE_NOTIF_IN_PROCESS)}${notifId}`,
      );
      const { title: titleMessage, message: messages } = formatMessagesToDisplay(respData);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
      if (refreshData && messages.length === 0 && titleMsg === '') {
        await refreshData(defaultValues.NotificationNo);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const { title: titleMessage, message: messages } = formatMessagesToDisplay({}, [
        (error as Error)?.message ?? 'Sorry unable to process your request. Please try again later',
      ]);
      setTitleMsg(titleMessage);
      setRespMsg(messages);
    }
  };

  const onMessageModalClose = async () => {
    setRespMsg([]);
    if (refreshData) {
      await refreshData(defaultValues.NotificationNo);
    }
  };

  useEffect(() => {
    if (defaultValues) {
      reset({ ...defaultValues });
    }
  }, [defaultValues, reset]);

  return (
    <>
      <div className="flex flex-col justify-center bg-white">
        <div className="m-auto w-11/12 py-8 sm:w-11/12 sm:px-2 md:w-11/12 md:px-4 lg:w-10/12 lg:px-8 xl:w-9/12 xl:px-16">
          {errorMessages.length > 0 &&
            errorMessages.map((message, index) => (
              <div key={index} className="my-4">
                <ErrorBanner>
                  <button onClick={() => setStep(message.code)} className="flex w-full text-left opacity-100">
                    <p>
                      There is an error at <strong>{message.section}</strong>
                    </p>
                  </button>
                </ErrorBanner>
              </div>
            ))}

          <div className="relative">
            <FormStepper
              onChange={(item: FormStepperItem) => {
                setStep(item.code);
              }}
              items={sections?.map((section) => ({
                code: section.code,
                name: section.name,
                href: '#',
                status:
                  step === section.code
                    ? FormStepperEnum.Current
                    : step > section.code
                    ? FormStepperEnum.Complete
                    : FormStepperEnum.Upcoming,
              }))}
            />
            {respMsg.length > 0 && titleMsg !== '' && (
              <MessageModal
                title={titleMsg}
                messages={respMsg}
                open={!!respMsg.length}
                hidebutton={true}
                onOk={onMessageModalClose}
              ></MessageModal>
            )}
          </div>
          <div className="w-full pt-3">
            <SubBanner title={sections[step - 1].name} />
            {step !== 6 && (
              <Text mb="1rem" color="redalert">
                **Some type ahead fields search are case sensitive.
              </Text>
            )}
            {updateForm && readOnly && watch('NotificationType') === 'Z6' && (
              <div className="grid w-full grid-cols-1 place-items-center gap-2 md:grid-cols-5">
                <div className="w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setReadOnly(!readOnly);
                    }}
                    className="button-primary bg-defence-orange-700"
                    title="Edit/Update From"
                  >
                    <div className="flex items-center gap-1">
                      <Pencil2Icon width={20} height={20} />
                      Edit
                    </div>
                  </button>
                </div>
              </div>
            )}
            {updateForm && !readOnly && (
              <div className="grid w-full grid-cols-1 place-items-center gap-2 md:grid-cols-5">
                <div className="w-fit">
                  <button
                    type="button"
                    onClick={() => {
                      setReadOnly(!readOnly);
                    }}
                    className="button-primary bg-defence-orange-700"
                    title="Cancel Edit / Update"
                  >
                    <div className="flex items-center gap-1">
                      <EyeOpenIcon width={20} height={20} />
                      Display
                    </div>
                  </button>
                </div>
                {defaultValues.InProcess && watch('SystemStatus') !== 'OSNO' && (
                  <div className="w-max">
                    <button
                      type="button"
                      onClick={() => {
                        showInProcessDialog();
                      }}
                      className="button-primary bg-defence-orange-100"
                      title="Put In Process"
                    >
                      <div className="flex items-center gap-1">
                        <ResumeIcon width={20} height={20} />
                        Put in Process
                      </div>
                    </button>
                  </div>
                )}
                {defaultValues.Postpone && (
                  <div className="w-fit">
                    <button
                      type="button"
                      onClick={() => {
                        showPostponeDialog();
                      }}
                      className="button-primary bg-defence-orange-300"
                      title="Postpone"
                    >
                      <div className="flex items-center gap-1">
                        <BookmarkIcon width={20} height={20} />
                        Postpone
                      </div>
                    </button>
                  </div>
                )}

                {defaultValues.SetDelete && (
                  <div className="w-fit">
                    <button
                      type="button"
                      onClick={() => {
                        showDelDialog();
                      }}
                      className="button-primary bg-defence-orange-500"
                      title="Set Deletion Flag"
                    >
                      <div className="flex items-center gap-1">
                        <TrashIcon width={20} height={20} />
                        Delete
                      </div>
                    </button>
                  </div>
                )}

                {defaultValues.Reset && (
                  <div className="w-fit">
                    <button
                      type="button"
                      onClick={() => {
                        showResetDelDialog();
                      }}
                      className="button-primary bg-defence-orange-500"
                      title="Reset Deletion Flag"
                    >
                      <div className="flex items-center gap-1">
                        <ResetIcon width={20} height={20} />
                        Undelete
                      </div>
                    </button>
                  </div>
                )}
                {!isComplete && defaultValues.Complete && (
                  <div className="w-fit">
                    <button
                      type="button"
                      onClick={() => {
                        showCompleteDialog();
                      }}
                      className="button-primary bg-defence-orange-500"
                      title="Complete"
                    >
                      <div className="flex items-center gap-1">
                        <CheckboxIcon width={20} height={20} />
                        Close
                      </div>
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>

          {showDialog && (
            <div className="flex flex-col justify-center">
              <DialogModal
                title={dialogTitle}
                description={dialogMessage}
                okText="Yes"
                cancelText="No"
                onOk={onDialogOk}
                onCancel={onDialogCancel}
                inputDateTime={showDate}
                inputDateTimeLabel="Completion Date:"
              ></DialogModal>
            </div>
          )}
          {loading && <Spinner overlay />}

          <form onSubmit={handleSubmit(handleSubmitForm, goToErrors)}>
            {step === 1 && (
              <div className="mb-4 w-full rounded-md bg-white">
                <div className="grid h-full grid-flow-row grid-cols-1	gap-1 md:grid-cols-1 md:gap-1">
                  {updateForm && (
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Service Request No."
                          fieldPlaceholder=" "
                          name="NotificationNo"
                          control={control}
                          required={false}
                          disabled={true}
                        />
                        <SingleSelectInput
                          items={metadata['NotificationType'] ? JSON.parse(metadata['NotificationType'].FVALUE) : []}
                          fieldLabel="Service Request Type"
                          fieldPlaceholder=" "
                          name="NotificationType"
                          control={control}
                          required={true}
                          defaultValue={defaultValues.NotificationType}
                          disabled={true}
                          includeCode={false}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="System Status"
                          fieldPlaceholder=" "
                          name="SystemStatus"
                          control={control}
                          required={false}
                          disabled={true}
                        />
                        <SingleSelectInputCode
                          codeSet={TypeAheadCodeSet.UserStatus}
                          codeSetLabelField="Description"
                          codeSetValueField="UserStatus"
                          codeDisplayField="DisplayName"
                          fieldLabel="User Status"
                          fieldPlaceholder=" "
                          name="UserStatus"
                          control={control}
                          required={false}
                          defaultValue={defaultValues.UserStatus}
                          disabled={readOnly}
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Description"
                          fieldPlaceholder=" "
                          name="NotificationDesc"
                          control={control}
                          required={true}
                          disabled={readOnly}
                        />
                        <TextInput
                          fieldLabel="Work Order Number"
                          fieldPlaceholder=" "
                          name="WorkOrder"
                          control={control}
                          required={false}
                          disabled={true}
                        />
                      </div>
                    </>
                  )}
                  {!updateForm && (
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <SingleSelectInput
                          items={[{ code: 'Z6', description: 'MISCS Request' }]}
                          fieldLabel="Service Request Type"
                          fieldPlaceholder=" "
                          name="NotificationType"
                          control={control}
                          required={true}
                          defaultValue={defaultValues.NotificationType}
                          disabled={readOnly}
                          includeCode={true}
                        />
                        {/* <SingleSelectInput
                          items={metadata['NotificationType'] ? JSON.parse(metadata['NotificationType'].FVALUE) : []}
                          fieldLabel="Service Request Type"
                          fieldPlaceholder=" "
                          name="NotificationType"
                          control={control}
                          required={true}
                          defaultValue={defaultValues.NotificationType}
                          disabled={readOnly}
                          includeCode={true}
                        /> */}
                        <InputTypeAhead
                          fieldLabel="Reported By"
                          fieldPlaceholder="Start typing"
                          name="ReportedBy"
                          control={control}
                          required={!schema._def.left.shape.ReportedBy.isOptional()}
                          disabled={readOnly}
                          codeSet={TypeAheadCodeSet.UserID}
                          codeSetValueField="UserID"
                          codeSetLabelField="FullName"
                          top="10"
                        />
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                        <TextInput
                          fieldLabel="Description"
                          fieldPlaceholder=" "
                          name="NotificationDesc"
                          control={control}
                          required={true}
                          disabled={readOnly}
                        />
                        <TextInput
                          fieldLabel="Work Order Number"
                          fieldPlaceholder=" "
                          name="WorkOrder"
                          control={control}
                          required={false}
                          disabled={true}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            )}
            {step === 2 && (
              <div className="mb-4 w-full rounded-md bg-white">
                <div className="grid h-full grid-flow-row grid-cols-1  gap-1 md:grid-cols-1 md:gap-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <InputTypeAhead
                      fieldLabel="Functional Location"
                      fieldPlaceholder="Start typing"
                      name="FunctLocation"
                      control={control}
                      required={true}
                      disabled={readOnly}
                      defaultValue={defaultValues.FunctLocation}
                      codeSet={TypeAheadCodeSet.Floc}
                      top="10"
                      codeSetValueField="Floc"
                      codeSetLabelField="Description"
                    />
                    <InputTypeAhead
                      fieldLabel="Equipment"
                      fieldPlaceholder="Start typing"
                      name="EquipmentNo"
                      control={control}
                      required={false}
                      disabled={readOnly}
                      defaultValue={defaultValues.EquipmentNo}
                      codeSet={TypeAheadCodeSet.Equip}
                      top="10"
                      codeSetValueField="EquipmentId"
                      codeSetLabelField="Description"
                    />

                    <SingleSelectInput
                      items={metadata['PlannerGroup'] ? JSON.parse(metadata['PlannerGroup'].FVALUE) : []}
                      fieldLabel="Planner Group"
                      fieldPlaceholder=" "
                      name="PlannerGroup"
                      control={control}
                      required={!schema._def.left.shape.PlannerGroup.isOptional()}
                      defaultValue={defaultValues.PlannerGroup}
                      disabled={readOnly}
                    />

                    {/* <SingleSelectInput
                      items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                      fieldLabel="Request Work Centre"
                      fieldPlaceholder=" "
                      name="MainWorkCentre"
                      control={control}
                      required={!schema._def.left.shape.MainWorkCentre.isOptional()}
                      defaultValue={defaultValues.MainWorkCentre}
                      disabled={readOnly}
                      includeCode={false}
                    /> */}

                    {/* <div className="flex">
                        <TextInput
                          fieldLabel=" "
                          fieldPlaceholder=" "
                          name={defaultValues.EquipmentIdDesc}
                          control={control}
                          disabled={true}
                        />
                      </div> */}
                  </div>
                  {/* {updateForm && (
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <SingleSelectInput
                        items={metadata['PlannerGroup'] ? JSON.parse(metadata['PlannerGroup'].FVALUE) : []}
                        fieldLabel="Planner Group"
                        fieldPlaceholder=" "
                        name="PlannerGroup"
                        control={control}
                        required={!schema._def.left.shape.PlannerGroup.isOptional()}
                        defaultValue={defaultValues.PlannerGroup}
                        disabled={readOnly}
                        includeCode={false}
                      />
                      <SingleSelectInput
                        items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                        fieldLabel="Request Work Centre"
                        fieldPlaceholder=" "
                        name="MainWorkCentre"
                        control={control}
                        required={!schema._def.left.shape.MainWorkCentre.isOptional()}
                        defaultValue={defaultValues.MainWorkCentre}
                        disabled={readOnly}
                        includeCode={false}
                      />
                    </div>
                  )} */}

                  {/* {!updateForm && ( */}
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    {/* <InputTypeAhead
                        fieldLabel="Equipment"
                        fieldPlaceholder="Start typing"
                        name="EquipmentNo"
                        control={control}
                        required={false}
                        disabled={readOnly}
                        defaultValue={defaultValues.EquipmentNo}
                        codeSet={TypeAheadCodeSet.Equip}
                        top="10"
                        codeSetValueField="EquipmentId"
                        codeSetLabelField ="Description"
                      /> */}

                    {/* <TextInput
                        fieldLabel="Equipment Description"
                        fieldPlaceholder=" "
                        name="EquipmentDesc"
                        control={control}
                        required={false}
                        disabled={readOnly}
                      /> */}

                    <SingleSelectInput
                      items={metadata['MainWorkCentre'] ? JSON.parse(metadata['MainWorkCentre'].FVALUE) : []}
                      fieldLabel="Request Work Centre"
                      fieldPlaceholder=" "
                      name="MainWorkCentre"
                      control={control}
                      required={!schema._def.left.shape.MainWorkCentre.isOptional()}
                      defaultValue={defaultValues.MainWorkCentre}
                      disabled={readOnly}
                      includeCode={false}
                    />
                    <InputTypeAhead
                      fieldLabel="Work Centre Plant"
                      fieldPlaceholder=" "
                      name="MainWorkCentrePlant"
                      control={control}
                      required={false}
                      disabled={true}
                      defaultValue={watch('MainWorkCentre') !== '' ? '1000' : ''}
                      hideMagnifyingGlass={true}
                    />
                    {/* <SingleSelectInputCode
                        fieldLabel="Request Work Centre Plant"
                        fieldPlaceholder=" "
                        name="MainWorkCentrePlant"
                        control={control}
                        required={!schema._def.left.shape.MainWorkCentrePlant.isOptional()}
                        defaultValue={defaultValues.MainWorkCentrePlant}
                        disabled={readOnly}
                        codeSet={TypeAheadCodeSet.Workcenter}
                        codeSetLabelField="Description"
                        codeSetValueField="Plant"
                        codeDepFilterField="WorkCenter"
                        codeDepFilterVal={watch('MainWorkCentre')}
                      /> */}
                  </div>
                  {/* )} */}
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <SingleSelectInputCode
                      codeSet={TypeAheadCodeSet.CodeGroup}
                      codeSetLabelField="ShortText"
                      codeSetValueField="CodeGroup"
                      codeDepFilterField="NotificationType"
                      codeDepFilterVal={watch('NotificationType')}
                      fieldLabel="CodeGroup"
                      fieldPlaceholder=" "
                      name="CodeGroup"
                      control={control}
                      required={schema._def.left.shape.CodeGroup.isOptional()}
                      disabled={readOnly}
                    />
                    <SingleSelectInputCode
                      codeSet={TypeAheadCodeSet.Code}
                      codeSetLabelField="ShortText"
                      codeSetValueField="Code"
                      codeDepFilterField="CodeGroup"
                      codeDepFilterVal={watch('CodeGroup')}
                      fieldLabel="Code Group Coding"
                      fieldPlaceholder=" "
                      name="CodeGroupCoding"
                      required={schema._def.left.shape.CodeGroupCoding.isOptional()}
                      control={control}
                      defaultValue={defaultValues.CodeGroupCoding}
                      disabled={readOnly}
                    />
                    {/* <DateInput
                      fieldLabel="Date Created"
                      fieldPlaceholder=" "
                      name="CreateDate"
                      control={control}
                      required={false}
                      disabled={readOnly}
                    /> */}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-1 md:gap-8">
                    {/* <TextInput
                      fieldLabel="Description short"
                      fieldPlaceholder=" "
                      name="NotificationDesc"
                      control={control}
                      required={true}
                      disabled={readOnly}
                    /> */}
                    {!readOnly && (
                      <TextAreaInput
                        fieldLabel="Add Description Long Text"
                        fieldPlaceholder=""
                        name="NotificationLongText"
                        control={control}
                        required={!schema._def.left.shape.NotificationLongText.isOptional()}
                        disabled={readOnly}
                      />
                    )}
                  </div>
                  {updateForm && (
                    <div className="grid grid-cols-1 md:grid-cols-1 md:gap-8">
                      <TextAreaInput
                        fieldLabel="Existing Description Long Text"
                        fieldPlaceholder=""
                        name="NotificationLongTextDisplay"
                        control={control}
                        required={false}
                        disabled={true}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {step === 3 && (
              <div className="mb-4 w-full rounded-md bg-white">
                <div className="grid h-full grid-flow-row grid-cols-1  gap-1 md:grid-cols-1 md:gap-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    {/* <div className="grid md:gap-1"> */}
                    <SingleSelectInput
                      items={
                        metadata['NotificationPriority']?.FVALUE
                          ? JSON.parse(metadata['NotificationPriority'].FVALUE)
                          : []
                      }
                      fieldLabel="Priority"
                      fieldPlaceholder=" "
                      name="NotificationPriority"
                      control={control}
                      required={true}
                      defaultValue={defaultValues.NotificationPriority}
                      disabled={readOnly}
                      includeCode={false}
                    />
                    {/* </div> */}
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <DateInput
                      fieldLabel="Required Start Date"
                      fieldPlaceholder=" "
                      name="RequiredStartDateTime"
                      showTime={true}
                      control={control}
                      required={!schema._def.left.shape.RequiredStartDateTime.isOptional()}
                      disabled={watch('NotificationPriority') === '5' && !readOnly ? false : true}
                    />
                    <DateInput
                      fieldLabel="Required End Date"
                      fieldPlaceholder=" "
                      name="RequiredEndDateTime"
                      control={control}
                      showTime={true}
                      required={!schema._def.left.shape.RequiredEndDateTime.isOptional()}
                      disabled={watch('NotificationPriority') === '5' && !readOnly ? false : true}
                    />
                  </div>
                  {watch('NotificationType') === 'Z1' && (
                    <>
                      <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:gap-1">
                        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                          <SingleSelectInput
                            items={[
                              { code: 'No', description: 'No' },
                              {
                                code: 'Yes',
                                description: 'Yes',
                              },
                            ]}
                            defaultValue={defaultValues.BreakdownIndicator}
                            includeCode={false}
                            fieldLabel="Breakdown"
                            fieldPlaceholder=" "
                            name="BreakdownIndicator"
                            control={control}
                            required={false}
                            disabled={readOnly}
                          />
                          <SingleSelectInput
                            items={metadata['DurationUom'] ? JSON.parse(metadata['DurationUom'].FVALUE) : []}
                            fieldLabel="Breakdown Unit"
                            fieldPlaceholder=" "
                            name="DurationUom"
                            control={control}
                            required={false}
                            defaultValue={defaultValues.DurationUom}
                            disabled={watch('BreakdownIndicator') === 'Yes' && !readOnly ? false : true}
                            includeCode={true}
                          />
                        </div>
                        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                          <DateInput
                            fieldLabel="Malfunction Start Date"
                            fieldPlaceholder=" "
                            name="MalfunctionStart"
                            control={control}
                            showTime={true}
                            required={!schema._def.left.shape.MalfunctionStart.isOptional()}
                            disabled={watch('BreakdownIndicator') === 'Yes' && !readOnly ? false : true}
                          />
                          <DateInput
                            fieldLabel="Malfunction End Date"
                            fieldPlaceholder=" "
                            name="MalfunctionEnd"
                            control={control}
                            showTime={true}
                            required={!schema._def.left.shape.MalfunctionEnd.isOptional()}
                            disabled={watch('BreakdownIndicator') === 'Yes' && !readOnly ? false : true}
                          />
                        </div>
                      </div>
                      <ItemBreakdownInput control={control} readOnly={readOnly} updateForm={updateForm} />
                    </>
                  )}
                </div>
              </div>
            )}
            {step == 4 && (
              <div className="mb-4 w-full rounded-md bg-white">
                <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                  <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                    <InputTypeAhead
                      fieldLabel="Requestor Name"
                      fieldPlaceholder="Start typing"
                      name="RequestorName"
                      control={control}
                      required={true}
                      disabled={readOnly}
                      codeSet={TypeAheadCodeSet.UserID}
                      codeSetValueField="UserID"
                      codeSetLabelField="FullName"
                      top="10"
                    />
                    <div className="grid grid-cols-1 md:grid-cols-3 md:gap-8">
                      <TextInput
                        fieldLabel="Requestor phone no."
                        fieldPlaceholder=" "
                        name="RequestorTelephone"
                        control={control}
                        required={!schema._def.left.shape.RequestorTelephone.isOptional()}
                        disabled={readOnly}
                      />
                      <TextInput
                        fieldLabel="Requestor mobile no."
                        fieldPlaceholder=" "
                        name="RequestorMobile"
                        control={control}
                        required={!schema._def.left.shape.RequestorMobile.isOptional()}
                        disabled={readOnly}
                      />
                      <TextInput
                        fieldLabel="Requestor email"
                        fieldPlaceholder=" "
                        name="RequestorEmail"
                        control={control}
                        required={!schema._def.left.shape.RequestorEmail.isOptional()}
                        disabled={readOnly}
                      />
                    </div>

                    <SingleSelectInput
                      items={metadata['DefenceGroup'] ? JSON.parse(metadata['DefenceGroup'].FVALUE) : []}
                      fieldLabel="Defence Group"
                      fieldPlaceholder=" "
                      name="DefenceGroup"
                      control={control}
                      required={true}
                      defaultValue={defaultValues.DefenceGroup}
                      disabled={readOnly}
                      includeCode={false}
                    />
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        items={[]}
                        fieldLabel="Customer cost centre"
                        fieldPlaceholder="Start typing"
                        name="CustomerCostCentre"
                        control={control}
                        required={!schema._def.left.shape.CustomerCostCentre.isOptional()}
                        disabled={readOnly}
                        defaultValue={defaultValues.CustomerCostCentre}
                        codeSet={TypeAheadCodeSet.CostCentre}
                        top="10"
                        codeSetLabelField="ShortText"
                        codeSetValueField="CostCenter"
                      />

                      <InputTypeAhead
                        items={[]}
                        fieldLabel="Customer WBS"
                        fieldPlaceholder="Start typing"
                        name="CustomerWbs"
                        control={control}
                        required={!schema._def.left.shape.CustomerWbs.isOptional()}
                        disabled={readOnly}
                        defaultValue={defaultValues.CustomerWbs}
                        codeSet={TypeAheadCodeSet.Wbs}
                        top="10"
                        codeSetValueField="WBS"
                      />
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-2 md:gap-8">
                      <InputTypeAhead
                        fieldLabel="Customer Asset"
                        fieldPlaceholder="Start typing"
                        name="CustomerAsset"
                        control={control}
                        required={!schema._def.left.shape.CustomerAsset.isOptional()}
                        disabled={readOnly}
                        defaultValue={defaultValues.CustomerAsset}
                        codeSet={TypeAheadCodeSet.Asset}
                        codeSetValueField="Asset"
                        codeSetLabelField="Description"
                        top="10"
                      />

                      <InputTypeAhead
                        fieldLabel="Customer sub-asset"
                        fieldPlaceholder="Start typing"
                        name="CustomerSubAsset"
                        control={control}
                        required={!schema._def.left.shape.CustomerSubAsset.isOptional()}
                        disabled={readOnly}
                        defaultValue={defaultValues.CustomerSubAsset}
                        codeSet={TypeAheadCodeSet.Asset}
                        codeSetValueField="SubAsset"
                        top="10"
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {step == 5 && (
              <div className="mb-4 w-full rounded-md bg-white">
                <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-1">
                  <TextInput
                    fieldLabel="Contact Name"
                    fieldPlaceholder=" "
                    name="ContactName"
                    control={control}
                    required={!schema._def.left.shape.ContactName.isOptional()}
                    disabled={readOnly}
                  />
                  <TextInput
                    fieldLabel="Street"
                    fieldPlaceholder=" "
                    name="ContactStreet"
                    control={control}
                    required={!schema._def.left.shape.ContactStreet.isOptional()}
                    disabled={readOnly}
                  />
                  <TextInput
                    fieldLabel="House Number"
                    fieldPlaceholder=" "
                    name="ContactHouseNo"
                    control={control}
                    required={!schema._def.left.shape.ContactHouseNo.isOptional()}
                    disabled={readOnly}
                  />
                </div>

                <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-4 md:gap-8">
                  <TextInput
                    fieldLabel="Post Code"
                    fieldPlaceholder=" "
                    name="ContactPostalCode"
                    control={control}
                    required={!schema._def.left.shape.ContactPostalCode.isOptional()}
                    disabled={readOnly}
                  />
                  <TextInput
                    fieldLabel="Suburb"
                    fieldPlaceholder=" "
                    name="ContactCity"
                    control={control}
                    required={!schema._def.left.shape.ContactCity.isOptional()}
                    disabled={readOnly}
                  />
                  <SingleSelectInput
                    items={metadata['ContactCountry'] ? JSON.parse(metadata['ContactCountry'].FVALUE) : []}
                    fieldLabel="Country"
                    fieldPlaceholder=" "
                    name="ContactCountry"
                    control={control}
                    required={!schema._def.left.shape.ContactCountry.isOptional()}
                    defaultValue={defaultValues.ContactCountry}
                    disabled={true}
                    includeCode={false}
                  />
                  <SingleSelectInput
                    items={metadata['ContactRegion'] ? JSON.parse(metadata['ContactRegion'].FVALUE) : []}
                    fieldLabel="State"
                    fieldPlaceholder=" "
                    name="ContactRegion"
                    control={control}
                    required={!schema._def.left.shape.ContactRegion.isOptional()}
                    defaultValue={defaultValues.ContactRegion}
                    disabled={readOnly}
                    includeCode={false}
                  />
                </div>
                <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-2 md:gap-8">
                  <TextInput
                    fieldLabel="Contact Telephone"
                    fieldPlaceholder=" "
                    name="ContactTelephone"
                    control={control}
                    required={!schema._def.left.shape.ContactTelephone.isOptional()}
                    disabled={readOnly}
                  />
                  <TextInput
                    fieldLabel="Contact Fax"
                    fieldPlaceholder=" "
                    name="ContactFax"
                    control={control}
                    required={!schema._def.left.shape.ContactFax.isOptional()}
                    disabled={readOnly}
                  />
                </div>
              </div>
            )}
            {step == 6 && (
              <div className="mb-8 w-full rounded-md bg-white">
                <p className="my-2 text-lg font-bold">File Upload</p>
                <Text mb="1rem" color="redalert">
                  **Upon successful upload, system will refresh the data. And if there's any error, please re-attach the
                  file.
                </Text>
                <div className="grid h-full grid-flow-row grid-cols-1 gap-1 md:grid-cols-1 md:gap-4">
                  {!updateForm ? (
                    <FileInput
                      fileType="*" // All file types
                      fileMax="20 MB"
                      selectedFiles={selectedFiles}
                      onFilesSelected={handleFilesSelected}
                      accept="*"
                    />
                  ) : (
                    <>
                      <FileUpload
                        fieldLabel=""
                        fileType="*"
                        fileMax="20 MB"
                        register=""
                        fieldname=""
                        errorMessage=""
                        validation=""
                        disabled={readOnly}
                        url={getAPIUrl(process.env.REACT_APP_EE_NOTIF_ATTACHMENT)}
                        headers={{
                          NotifNo: defaultValues.NotificationNo,
                        }}
                        accept="*"
                        refreshPage={true}
                      />
                      {defaultValues?.NotifToDir?.results?.length > 0 && (
                        <p className="my-2 text-lg font-bold">Document Uploaded</p>
                      )}
                      {defaultValues?.NotifToDir?.results?.map((file: any, index) => (
                        <div key={index} className="grid grid-cols-2">
                          <div className="px-4 py-2 text-base text-defence-black">{file.DocumentNo}</div>
                          <div className="px-4 py-2 text-base text-defence-black">{file.DocumentDesc}</div>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            )}

            <div className="flex w-full justify-end">
              {step >= 1 && (
                <div className="flex">
                  {!(step === 1) && (
                    <button
                      type="button"
                      onClick={() => {
                        backStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Back
                    </button>
                  )}

                  {!(step === sections.length) && (
                    <button
                      type="button"
                      onClick={() => {
                        nextStep();
                      }}
                      className="button-secondary bg-white"
                    >
                      Next
                    </button>
                  )}
                </div>
              )}

              {!readOnly && step === sections.length && (
                <div>
                  <button type="submit" className="button-primary mr-0 bg-defence-orange-500">
                    Submit
                  </button>
                </div>
              )}
            </div>
            {updateForm && readOnly && false && (
              <div
                className="grid w-full grid-cols-1 place-items-center gap-2 rounded-sm 
                              shadow hover:shadow-lg md:grid-cols-4"
              >
                <div className="w-full">
                  <button
                    type="button"
                    onClick={() => {
                      setReadOnly(!readOnly);
                    }}
                    className="button-primary w-64 bg-defence-orange-700"
                  >
                    Edit/Update From
                  </button>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
};
