import { getAemUrl, getTravelURL } from '../../helper/getOtherAppURL';

const content = {
  MainNav: {
    router: true,
    logoURL: '/images/ServiceConnectLogo.png',
    homeURL: '/serviceconnect',
    altText: 'Australian Government Defence - Service Connect',
    mobileLogoURL: '/images/ServiceConnectMobileLogo.svg',
    searchURL: '/serviceconnect/search',
    placeholder: 'Search',
    navigationItems: [
      { title: 'Home', url: '/serviceconnect', extraRoutes: '/' },
      {
        title: 'All Services',
        url: '/serviceconnect/all-services',
      },
      {
        title: 'Notifications',
        url: '/serviceconnect/notifications',
        notificationBubble: true,
      },
      { title: 'Profile', url: '/serviceconnect/Profile' },
      { title: 'Support', url: '/serviceconnect/support' },
      { title: 'Search', url: '/serviceconnect/search' },
      { title: 'Logout', url: '/logout' },
    ],
  },
  EEPortalMainNav: {
    router: true,
    logoURL: '/images/EstateExchangeLogo.png',
    homeURL: '/eeportal',
    altText: 'Australian Government Defence - Estate Exchange',
    mobileLogoURL: '/images/EstateExchangeLogo.png',
    searchURL: '',
    placeholder: 'Search',
    navigationItems: [
      {
        title: 'Home',
        url: '/eeportal',
      },
      {
        title: 'Service Requests',
        url: '/eeportal/notifications',
      },
      {
        title: 'Work Orders',
        url: '/eeportal/orders',
      },
      {
        title: 'Service Entry Sheets',
        url: '/eeportal/ses',
      },
      {
        title: 'Additional Services',
        url: '/eeportal/other',
      },
      {
        title: 'Support',
        url: '/eeportal/support',
      },
      {
        title: 'Logout',
        url: '/logout',
      },
    ],
  },
  Footer: {
    logoURL: '/images/ServiceConnectLogo.png',
    logoAltText: 'Defence logo',
    acknowledgmentText:
      'Defence acknowledges the Traditional Custodians of the lands, air and seas in which we live, work and train. We pay our respects to their Elders, past, present and emerging. We also pay our respects to the Aboriginal and Torres Strait Islander men and women who have contributed to the defence of Australia in times of peace and war.</br>Aboriginal and Torres Strait Islander peoples are advised that ServiceConnect and its linked services may contain images or content referring to deceased persons.',
    quickAccessTitle: 'Quick Access',
    quickAccess: [
      {
        title: 'My Base',
        url: `${getAemUrl}/content/defence/mybase.html`,
        isExternal: false,
      },
      { title: 'My Service Request', url: '/my-service-request', isExternal: false },
      {
        title: 'My Forms',
        url: `${getAemUrl}/content/defence-forms/my-forms.html`,
        isExternal: false,
      },
      {
        title: 'My Travel',
        url: getTravelURL(),
        isExternal: false,
        condition: 'hasTravel',
      },
      { title: 'All Services', url: '/serviceconnect/all-services', isExternal: false },
      { title: 'Support', url: '/serviceconnect/support', isExternal: false },
    ],
    moreInfoTitle: 'More Information',
    moreInformation: [
      {
        title: 'About ServiceConnect',
        url: '/serviceconnect/learnMore',
        isExternal: false,
      },
      {
        title: 'Privacy',
        url: 'https://www.defence.gov.au/privacy',
        isExternal: true,
      },
      {
        title: 'Privacy Notice',
        url: '/serviceconnect/privacy',
        isExternal: false,
      },
      {
        title: 'Disclaimer',
        url: 'https://www.defence.gov.au/disclaimer',
        isExternal: true,
      },
      {
        title: 'Copyright',
        url: 'https://www.defence.gov.au/copyright',
        isExternal: true,
      },
    ],
  },
  EEPortalFooter: {
    logoURL: '/images/EstateExchangeLogo.png',
    logoAltText: 'Defence logo',
    acknowledgmentText:
      'Defence acknowledges the Traditional Custodians of the lands, air and seas in which we live, work and train. We pay our respects to their Elders, past, present and emerging. We also pay our respects to the Aboriginal and Torres Strait Islander men and women who have contributed to the defence of Australia in times of peace and war.</br>Aboriginal and Torres Strait Islander peoples are advised that ServiceConnect and its linked services may contain images or content referring to deceased persons.',
    quickAccessTitle: 'Quick Access',
    quickAccess: [
      {
        title: 'Service Requests',
        url: '/eeportal/notifications',
        isExternal: false,
      },
      {
        title: 'Work Orders',
        url: '/eeportal/orders',
        isExternal: false,
      },
      {
        title: 'Service Entry Sheets',
        url: '/eeportal/ses',
        isExternal: false,
      },
      {
        title: 'Additional Services',
        url: '/eeportal/other',
        isExternal: false,
      },
      {
        title: 'Support',
        url: '/eeportal/support',
        isExternal: false,
      },
    ],
    moreInfoTitle: 'More Information',
    moreInformation: [
      {
        title: 'About ServiceConnect',
        url: '/serviceconnect/learnMore',
        isExternal: false,
      },
      {
        title: 'Privacy',
        url: 'https://www.defence.gov.au/privacy',
        isExternal: true,
      },
      {
        title: 'Privacy Notice',
        url: '/serviceconnect/privacy',
        isExternal: false,
      },
      {
        title: 'Disclaimer',
        url: 'https://www.defence.gov.au/disclaimer',
        isExternal: true,
      },
      {
        title: 'Copyright',
        url: 'https://www.defence.gov.au/copyright',
        isExternal: true,
      },
    ],
  },
};
export default content;
